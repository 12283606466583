import React, { useState } from 'react'
import { format } from 'date-fns'
import { Typography, Divider, CircularProgress } from '@material-ui/core'
import { Button, Dialog } from 'supplyvue-ui'
import { ScenarioCardProps, MenuItems } from './ScenarioCard.types'
import { useStyles } from './ScenarioCard.styles'
import CheckCircle from '@material-ui/icons/CheckCircle'
import {
  PLAN_TEMPLATE_BUTTON_LABEL,
  REPLINISHMENT_SETTINGS_BUTTON_LABEL,
  CREATE_NEW_SCENARIO_BUTTON_LABEL,
  GENERATE_PLAN_BUTTON_LABEL,
} from './ScenarioCard.constants'
import AddCircle from '@material-ui/icons/AddCircle'
import { MenuButton } from 'Features/Project/Scenario/ScenarioCard/MenuButton'
import { useHistory } from 'react-router-dom'
import { CloneScenarioModal } from 'Features/Project/Scenario/CloneScenarioModal/CloneScenarioModal.view'
import MoreHorizeIcon from '@material-ui/icons/MoreHoriz'
import { LockOpenOutlined, LockOutlined } from '@material-ui/icons'
import { IconButton, Box } from '@mui/material'
export const ScenarioCard = ({
  scenario,
  snapshotId,
  projectId,
  index,
  handleNewScenarioClick,
  handleSelectedPlanTemplateClick,
  handleSelectedReplenishmentPolicyClick,
  handleViewScenarioClick,
  handleDeleteScenarioClick,
  handleCloneScenarioClick,
  isCloning,
  handleCloneScenarioNameChange,
  handlePlanGenarionClick,
  cloneScenarioName,
  status,
  access = 'public',
  projectAccess,
  isProjectAccessUpdating,
  handleScenarioAccess,
  enableLock,
}: ScenarioCardProps) => {
  const {
    scenarioId,
    scenarioName,
    createdOn,
    planTemplateId,
    replenishmentPolicyId,
    templateGenerationPending,
    safetyStockCalculated,
    planGenerationStatus,
  } = scenario

  const CLONING_STATUSES = ['CLONING', 'BEING CLONED', 'CREATING', 'DELETING']

  const history = useHistory()
  const [cloneModal, setCloneModal] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const classes = useStyles()

  const handleCloneModalClose = () => {
    handleCloneScenarioNameChange('')
    setCloneModal(false)
  }

  const handlePrimaryButtonClick = () => {
    setShowDialog(false)
    handleDeleteScenarioClick(scenarioId)
  }
  const isCreated = !!createdOn
  const buttonEnabled =
    isCreated &&
    safetyStockCalculated === 'COMPLETED' &&
    templateGenerationPending === 0

  const getStartButton = () => {
    if (buttonEnabled) {
      if (planGenerationStatus === 'COMPLETED') {
        return <CheckCircle color="inherit" />
      }
    }
    return null
  }

  const toPascalCase = (str: string): string => {
    return str.replace(/(\w)(\w*)/g, (match, firstChar, rest) => {
      return firstChar.toUpperCase() + rest.toLowerCase()
    })
  }

  const handleClonePlan = () => {
    handleCloneScenarioClick(scenarioId)
    setCloneModal(false)
  }

  const handleEditPlan = () => {
    history.push(
      `/projects/${projectId}/snapshots/${snapshotId}/edit/${planTemplateId}/${replenishmentPolicyId}/scenarios/${scenarioId}`
    )
  }

  const menuData: MenuItems | [] = [
    {
      text: 'View',
      action: () => handleSelectedPlanTemplateClick(scenarioId, planTemplateId),
      data: { Data: 0 },
    },
    {
      text: 'Clone',
      action: () => setCloneModal(true),
      data: { Data: 1 },
    },
    {
      text: 'Delete',
      action: () => setShowDialog(true),
      data: { Data: 2 },
    },
    {
      text: 'Edit',
      action: () => handleEditPlan(),
      data: { Data: 3 },
    },
    {
      text: 'Run Templates',
      action: () => handleSelectedPlanTemplateClick(scenarioId, planTemplateId),
      data: { Data: 4 },
    },
    {
      text: 'Run Inventory',
      action: () =>
        handleSelectedReplenishmentPolicyClick(
          scenarioId,
          replenishmentPolicyId
        ),
      data: { Data: 5 },
    },
  ]

  return (
    <>
      <Box className={classes.card}>
        <Box className={classes.wrapper}>
          <Box className={classes.nameWrapper}>
            {isCreated ? (
              <Box>
                <Box display="flex">
                  <Box>
                    <Typography
                      variant="h3"
                      className={
                        CLONING_STATUSES.includes(
                          status ? status?.toUpperCase() : 'NULL'
                        )
                          ? classes.subTitle
                          : classes.name
                      }
                    >
                      {scenarioName}
                    </Typography>
                  </Box>
                  {enableLock && (
                    // Enable Lock means the current user is accessing the own project
                    <Box marginLeft={2}>
                      {access === 'private' && (
                        <IconButton
                          onClick={() =>
                            handleScenarioAccess('public', scenarioId)
                          }
                          disabled={
                            projectAccess?.projectAccess?.visibility ===
                            'private'
                          }
                        >
                          {isProjectAccessUpdating ? (
                            <CircularProgress size={20} />
                          ) : (
                            <LockOutlined color="primary" />
                          )}
                        </IconButton>
                      )}
                      {access === 'public' && (
                        <IconButton
                          onClick={() =>
                            handleScenarioAccess('private', scenarioId)
                          }
                        >
                          {isProjectAccessUpdating ? (
                            <CircularProgress size={20} />
                          ) : (
                            <LockOpenOutlined color="primary" />
                          )}
                        </IconButton>
                      )}
                    </Box>
                  )}
                </Box>
                <Typography variant="body2" className={classes.subTitle}>
                  {format(new Date(createdOn), 'dd/MM/yyyy')}
                </Typography>
                {CLONING_STATUSES.includes(
                  status ? status?.toUpperCase() : 'NULL'
                ) && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body2" className={classes.cloning}>
                      {toPascalCase(status?.toLowerCase() as string)}
                    </Typography>
                    <CircularProgress className={classes.subTitle} size={16} />
                  </Box>
                )}
              </Box>
            ) : (
              <Button
                className={classes.textButton}
                onClick={() => handleNewScenarioClick(index)}
                startIcon={<AddCircle />}
              >
                <Typography
                  variant="h4"
                  color={!isCreated ? 'inherit' : 'primary'}
                >
                  {CREATE_NEW_SCENARIO_BUTTON_LABEL}
                </Typography>
              </Button>
            )}
          </Box>
          <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
          <Box className={classes.buttonWrapper}>
            <Button
              className={classes.textButton}
              onClick={() =>
                handleSelectedPlanTemplateClick(scenarioId, planTemplateId)
              }
              startIcon={
                <CheckCircle
                  color={
                    templateGenerationPending === 0 ? 'inherit' : 'disabled'
                  }
                />
              }
              disabled={
                !isCreated ||
                CLONING_STATUSES.includes(
                  status ? status?.toUpperCase() : 'NULL'
                )
              }
            >
              <Typography
                variant="h4"
                color={
                  !isCreated ||
                  CLONING_STATUSES.includes(
                    status ? status?.toUpperCase() : 'NULL'
                  )
                    ? 'inherit'
                    : 'primary'
                }
              >
                {PLAN_TEMPLATE_BUTTON_LABEL}
              </Typography>
            </Button>
          </Box>
          <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
          <Box className={classes.buttonWrapper}>
            <Button
              className={classes.textButton}
              onClick={() =>
                handleSelectedReplenishmentPolicyClick(
                  scenarioId,
                  replenishmentPolicyId
                )
              }
              disabled={
                !isCreated ||
                CLONING_STATUSES.includes(
                  status ? status?.toUpperCase() : 'NULL'
                )
              }
              startIcon={
                <CheckCircle
                  color={
                    safetyStockCalculated === 'COMPLETED'
                      ? 'inherit'
                      : 'disabled'
                  }
                />
              }
            >
              <Typography
                variant="h4"
                color={
                  !isCreated ||
                  CLONING_STATUSES.includes(
                    status ? status?.toUpperCase() : 'NULL'
                  )
                    ? 'inherit'
                    : 'primary'
                }
              >
                {REPLINISHMENT_SETTINGS_BUTTON_LABEL}
              </Typography>
            </Button>
          </Box>
          <Box display="none">
            <Button
              className={classes.outlineButton}
              onClick={() => handlePlanGenarionClick(scenarioId)}
              variant="outlined"
              disabled={
                !buttonEnabled ||
                CLONING_STATUSES.includes(
                  status ? status?.toUpperCase() : 'NULL'
                )
              }
              startIcon={getStartButton()}
            >
              <Typography
                variant="h4"
                color={!buttonEnabled ? 'inherit' : 'primary'}
              >
                {planGenerationStatus === 'PROCESSING' && buttonEnabled && (
                  <CircularProgress
                    color="inherit"
                    size={16}
                    className={classes.progress}
                  />
                )}
                {GENERATE_PLAN_BUTTON_LABEL}
              </Typography>
            </Button>
          </Box>
          <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
          <Box>
            {CLONING_STATUSES.includes(
              status ? status?.toUpperCase() : 'NULL'
            ) ? (
              <MoreHorizeIcon className={classes.subTitle} />
            ) : (
              <MenuButton menuData={menuData} />
            )}
          </Box>
        </Box>
      </Box>
      <Dialog
        title="Delete Scenario?"
        content="Are you sure you want to delete the scenario?"
        primaryButtonLabel="Delete"
        secondaryButtonLabel="Cancel"
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        handlePrimaryButtonClick={handlePrimaryButtonClick}
        handleSecondaryButtonClick={() => setShowDialog(false)}
      />
      <CloneScenarioModal
        isModalOpen={cloneModal}
        handleModalClose={handleCloneModalClose}
        isLoading={isCloning}
        handleConfirmClick={handleClonePlan}
        scenarioName={cloneScenarioName}
        handleScenarioNameChange={handleCloneScenarioNameChange}
      />
    </>
  )
}
