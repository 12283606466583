import { useMemo } from 'react'
import { Column, TableInstance } from 'react-table'
import { Table, ToggleButton } from 'supplyvue-ui'
import {
  SkuGroupManagamentTableProps,
  SkuGroupManagementTableData,
} from './SkuGroupManagement.types'

export const SkuGroupManagementTable = ({
  data,
  handleToggleButtonChange,
  updateTableData,
  paginationDetails,
  onSortByChange,
  onPageSizeChange,
  onPageIndexChange,
  pageSize,
}: SkuGroupManagamentTableProps) => {
  const handleValidation = (value: string | number) => {
    const VALID_NUMBER_PATTERN = /^[+]?((\.\d{0,1})|(\d+(\.\d{0,1})?)|(\d+\.))$/
    const isValid = value.toString().match(VALID_NUMBER_PATTERN)
    return isValid ? '' : 'error'
  }

  const updateData = (
    rowId: string,
    columnId: string,
    value: string | number
  ) => {
    const updatedData = data.map(
      (row: SkuGroupManagementTableData, i: number) => {
        if (i === parseInt(rowId)) {
          return {
            ...row,
            [columnId]: parseFloat(value as string),
          }
        } else return row
      }
    )
    updateTableData(updatedData)
  }

  const columns: Column<SkuGroupManagementTableData>[] = useMemo(
    () => [
      {
        columnWidth: 100,
        Header: 'SKU group code',
        accessor: 'skuGroupCode',
        disableFilters: true,
      },
      {
        columnWidth: 50,
        Header: 'SKU group description',
        accessor: 'skuGroupDescription',
        disableFilters: true,
      },
      {
        columnWidth: 50,
        Header: 'Batch group code',
        accessor: 'batchGroupingCode',
        disableFilters: true,
      },
      {
        columnWidth: 50,
        Header: 'SKU code',
        accessor: 'skuCode',
        disableFilters: true,
      },
      {
        columnWidth: 50,
        Header: 'SKU description',
        accessor: 'skuDescription',
        disableFilters: true,
      },
      {
        columnWidth: 50,
        Header: 'Minimum production order quantity',
        accessor: 'minimumProductOrderQuantity',
        disableFilters: true,
      },
      {
        columnWidth: 50,
        Header: 'Production run rate',
        accessor: 'runRate',
        disableFilters: true,
      },
      {
        columnWidth: 50,
        Header: 'SKU life cycle status',
        accessor: 'skuStatus',
        disableFilters: true,
      },
      {
        columnWidth: 10,
        Header: 'Primary workcentre code',
        accessor: 'primaryWorkCentreCode',
        disableFilters: true,
      },
      {
        columnWidth: 10,
        Header: 'Master SKU',
        accessor: 'masterSku',
        disableFilters: true,
        Cell: (props: TableInstance) => {
          const { skuKey, skuGroupKey, masterSku } = props?.row?.original
          return (
            <ToggleButton
              buttonOne={{ label: 'Y', value: true, tooltipText: 'Yes' }}
              buttonTwo={{ label: 'N', value: false, tooltipText: 'No' }}
              value={!!masterSku}
              handleChange={(event, value) => {
                !!handleToggleButtonChange &&
                  handleToggleButtonChange(event, skuKey, skuGroupKey, !!value)
              }}
            />
          )
        },
      },
    ],
    []
  )

  return (
    <Table
      key={data?.length}
      data={data}
      columns={columns}
      shouldIconsDisplayAtBottom={true}
      headerHeight={75}
      autoResetFilters={false}
      handleValidation={handleValidation}
      updateData={updateData}
      pageSizeOptions={[10, 20, 50, 100]}
      defaultPageSize={pageSize}
      initialPageIndex={paginationDetails?.current_page - 1}
      isTablePaginated
      manualPagination
      pageCount={paginationDetails?.total_pages ?? 0}
      totalRowCount={paginationDetails?.total_records ?? null}
      getPageIndex={onPageIndexChange}
      manualSortBy
      getSortBy={onSortByChange}
      getPageSize={onPageSizeChange}
      autoResetPage={false}
    />
  )
}
