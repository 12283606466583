import { useQuery, useMutation, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import { format } from 'date-fns'
import {
  ReplenishmentSiteParams,
  UpdateReplenishmentSiteParams,
  ReplenishmentSiteParamsResponse,
  ReplenishmentSiteParamsTableData,
  updateIncludeColumnParams,
  ApproveSingleSiteParamsTypes,
  PollingSkuGroupParamsTypes,
} from './SiteParameters.types'
import { SITE_PARAMETER_TYPES } from './SiteParameters.constants'
import { useState } from 'react'

const fetchReplenishmentSiteParamsData = async (
  replenishmentSiteParams: ReplenishmentSiteParams
) => {
  const { data } = await request({
    url: `/site-skugroups`,
    method: 'post',
    data: {
      ...replenishmentSiteParams,
    },
  })
  return data
}

export const useReplenishmentParametersData = (
  replenishmentSiteParams: ReplenishmentSiteParams
) => {
  return useQuery(
    [
      'replenishment-site-params',
      replenishmentSiteParams.projectId,
      replenishmentSiteParams.scenarioId,
      replenishmentSiteParams.replenishmentPolicyId,
      replenishmentSiteParams.baseSourceSiteKey,
      replenishmentSiteParams.sourceSiteKey,
      replenishmentSiteParams.destinationSiteKey,
    ],
    () => fetchReplenishmentSiteParamsData(replenishmentSiteParams),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (
        data: ReplenishmentSiteParamsResponse
      ): ReplenishmentSiteParamsTableData[] => {
        return data.data.map((item) => ({
          ...item,
          serviceLevel: item.serviceLevel && item.serviceLevel + '%',
          replenishmentType: item.replenishmentType ?? '',
        }))
      },
    }
  )
}

const updateReplenishmentParametersData = async (
  updateReplenishmentSiteParams: UpdateReplenishmentSiteParams
) => {
  const { data } = await request({
    url: `/site-skugroups`,
    method: 'put',
    data: {
      ...updateReplenishmentSiteParams,
    },
  })
  return data
}

export const useUpdateReplenishmentParametersData = (
  onSuccessCb: (() => void) | undefined
) => {
  const queryClient = useQueryClient()

  return useMutation(updateReplenishmentParametersData, {
    onSuccess: () => {
      queryClient.invalidateQueries('replenishment-site-params')
      onSuccessCb?.()
    },
  })
}

const updateIncludeColumnData = async (
  updateIncludeColumnParams: updateIncludeColumnParams
) => {
  const { data } = await request({
    url: '/replenishment-policy/site-skugroup',
    method: 'put',
    data: { ...updateIncludeColumnParams },
  })
  return data
}

export const useUpdateIncludeColumnData = (
  onErrorCb?: (message: string, skuGroupKey: number) => void
) => {
  return useMutation(updateIncludeColumnData, {
    onError: ({ response }) => {
      onErrorCb?.(response.data.error, response.data.skuGroupKey)
    },
  })
}

const fetchParameterTypeOptionsData = async (snapshotId: string) => {
  const { REPLENISHMENT_TYPE, SUPPLY_TYPE } = SITE_PARAMETER_TYPES
  const { data } = await request({
    url: `/snapshots/${snapshotId}/mappings?option=${REPLENISHMENT_TYPE}&option=${SUPPLY_TYPE}`,
  })
  return data
}

export const useParameterTypeOptionsData = (snapshotId: string) => {
  return useQuery(['parameter-type-options', snapshotId], () =>
    fetchParameterTypeOptionsData(snapshotId)
  )
}

const downloadReplenishmentSettingsCSV = async (
  replenishmentSiteParams: ReplenishmentSiteParams
) => {
  const { data } = await request({
    url: `/site-skugroups`,
    method: 'post',
    data: {
      ...replenishmentSiteParams,
      download: true,
    },
  })
  const fileName = `site_skugroup__${format(new Date(), 'yyyyMMdd')}`
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  return data
}

export const useDownloadSiteSkusFile = (
  replenishmentSiteParams: ReplenishmentSiteParams
) => {
  const {
    replenishmentPolicyId,
    baseSourceSiteKey,
    sourceSiteKey,
    destinationSiteKey,
  } = replenishmentSiteParams
  return useQuery(
    [
      'site-skus-download',
      replenishmentPolicyId,
      baseSourceSiteKey,
      sourceSiteKey,
      destinationSiteKey,
    ],
    () => downloadReplenishmentSettingsCSV(replenishmentSiteParams),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      enabled: false,
    }
  )
}

const approveSingleSite = async (params: ApproveSingleSiteParamsTypes) => {
  const data = await request({
    method: 'put',
    url: '/safety-stock/calculate',
    data: {
      ...params,
    },
  })
  return data
}

export const useApproveSingleSite = (
  onSuccessCb: (message: string) => void,
  onErrorCb: (message: string) => void,
  onApprovalSuccess: () => void,
  onApprovalFail: () => void
) => {
  const [shouldContinuePolling, setShouldContinuePolling] = useState(false)
  const [queryParams, setQuryParams] = useState<PollingSkuGroupParamsTypes>(
    {} as PollingSkuGroupParamsTypes
  )
  const [initiated, setInitiated] = useState(false)

  const { mutate } = useMutation(approveSingleSite, {
    onSuccess: ({ data }) => {
      onSuccessCb?.(data.message)
      setShouldContinuePolling(true)
      setInitiated(true)
    },
    onError: ({ response: { data } }) => {
      setInitiated(false)
      onErrorCb(data.error)
    },
  })

  useQuery(['polling-sku-groups'], () => siteSkuGroupPolling(queryParams), {
    onSuccess: ({ data }) => {
      if (data.pollData === false) {
        setShouldContinuePolling(false)
        setInitiated(false)
        onApprovalSuccess()
      } else if (data.pollData) {
      }
    },
    onError: () => {
      setShouldContinuePolling(false)
      setInitiated(false)
      onApprovalFail()
    },
    refetchInterval: shouldContinuePolling ? 10000 : false,
    enabled: queryParams.projectId && initiated ? true : false,
    refetchOnWindowFocus: false,
  })

  const triggerApproveSingleSite = (params: PollingSkuGroupParamsTypes) => {
    const alterData = { ...params }
    delete alterData.replenishmentPolicyId
    delete alterData.destinationSiteKey
    mutate(alterData)
    setQuryParams({ ...params })
  }
  return { triggerApproveSingleSite }
}

export const useCalculationStatus = (params: PollingSkuGroupParamsTypes) => {
  const [shouldContinuePolling, setShouldContinuePolling] = useState(true)
  const { data: calculationStatusData, isLoading: isCalculationDataLoading } =
    useQuery(['polling-sku-groups-status'], () => siteSkuGroupPolling(params), {
      onSuccess: (data: Record<string, string | boolean>) => {
        if (data?.pollData === false) {
          setShouldContinuePolling(false)
        }
      },
      select: ({ data }: Record<string, string | boolean>) => data,
      refetchOnWindowFocus: false,
      refetchInterval: shouldContinuePolling ? 10000 : false,
      enabled: shouldContinuePolling,
    })
  return { calculationStatusData, isCalculationDataLoading }
}

const siteSkuGroupPolling = async (params: PollingSkuGroupParamsTypes) => {
  delete params.snapshotId
  delete params.siteKey
  delete params.workcentreKey

  const data = await request({
    method: 'post',
    url: '/site-skugroups',
    data: {
      ...params,
    },
  })
  return data
}
