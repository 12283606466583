import axios from 'axios'
import { getBearerToken } from 'Features/Auth/auth'
import toast from 'react-hot-toast'

const client = axios.create({
  baseURL: process.env.MODELLING_BASE_URL,
})

export const request = async ({ ...options }) => {
  const token = await getBearerToken()
  client.defaults.headers.common.Authorization = `Bearer ${token.idToken.rawIdToken}`
  const tenantId = localStorage.getItem('tenantId')
  if (tenantId) {
    client.defaults.headers.common.Tenant = tenantId
  }
  return client(options)
}

client.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      toast.dismiss()
      toast.error('You are not authorized to access this resource.')
      const interval = setTimeout(() => {
        clearInterval(interval)
        return (window.location.href = '/')
      }, 2000)
    }
    return Promise.reject(error)
  }
)

const clientV2 = axios.create({
  baseURL: process.env.V2_MODELLING_BASE_URL,
})

export const requestV2 = async ({ ...options }) => {
  const token = await getBearerToken()
  clientV2.defaults.headers.common.Authorization = `Bearer ${token.idToken.rawIdToken}`
  const tenantId = localStorage.getItem('tenantId')
  if (tenantId) {
    clientV2.defaults.headers.common.Tenant = tenantId
  }
  return clientV2(options)
}
