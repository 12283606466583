import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2,
    marginBottom: 2,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 10,
    gap: '5px',
  },
  divider: {
    height: '2px',
    background: '#006286',
    width: '100%',
    marginTop: '1rem',
  },
  progress: {
    marginRight: '8px',
  },
}))
