export const TOTAL_CURRENT_DURATION_LABEL = 'Total current duration'
export const TOTAL_CURRENT_SAFETY_STOCK_VOLUME_LABEL =
  'Total current safety stock volume'
export const TOTAL_CURRENT_VALUE_LABEL = 'Total current value'
export const TOTAL_CURRENT_VOLUME_LABEL = 'Total current volume'
export const TOTAL_CURRENT_CYCLE_STOCK_VOLUME_LABEL =
  'Total current cycle stock volume'
export const TOTAL_PROPOSED_DURATION_LABEL = 'Total proposed duration'
export const TOTAL_PROPOSED_SAFETY_STOCK_VOLUME_LABEL =
  'Total proposed safety stock volume'
export const TOTAL_PROPOSED_VALUE_LABEL = 'Total proposed value'
export const TOTAL_PROPOSED_VOLUME_LABEL = 'Total proposed volume'
export const TOTAL_UNUSED_INVENTORY_LABEL = 'Total unused inventory'
