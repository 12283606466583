import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  parameterContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '33.33%',
    padding: '15px',
  },
})
