import { Box, Typography } from '@material-ui/core'
import { useStyles } from './SkuGroupInventoryAnalysis.styles'
import { SkuGroupInventoryAnalysisInfoBoxPropsTypes } from './SkuGroupInventoryAnalysis.types'

export const SkuGroupInventoryAnalysisInfoBox = ({
  label,
  data,
}: SkuGroupInventoryAnalysisInfoBoxPropsTypes) => {
  const classes = useStyles()
  return (
    <Box className={classes.infoBox}>
      <Box className={classes.title}>
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Box className={classes.dataBox}>{data}</Box>
    </Box>
  )
}
