import { useMutation } from 'react-query'
import { format } from 'date-fns'
import { request } from 'Features/Auth/axios-client'
import {
  BulkUpdateParams,
  DownloadSkuGroupSettingsParamsType,
} from '../ProductSettings/ProductSettings.types'

const downloadBatchSettingsData = async ({
  planTemplateId,
  sourceSiteId,
  workcentreId,
  projectId,
}: DownloadSkuGroupSettingsParamsType) => {
  const { data } = await request({
    method: 'post',
    url: `/plan-templates/${planTemplateId}/batch-group-settings/download`,
    data: {
      sourceSiteId,
      workcentreId,
      projectId,
    },
  })
  const fileName = `batch_group_data__${format(new Date(), 'yyyyMMdd')}`
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  return data
}

export const useDownloadBatchSettingsData = () => {
  return useMutation(downloadBatchSettingsData)
}

const uploadBatchBulkUpdateFile = async (
  bulkUpdateParams: BulkUpdateParams
) => {
  const {
    planTemplateId,
    sourceSiteId = 0,
    workcentreId = 0,
    projectId = '',
    snapshotId = '',
    uploadType,
    file,
  } = bulkUpdateParams
  const formData = new FormData()
  formData.append('source_site_key', sourceSiteId.toString())
  formData.append('workcentre_key', workcentreId.toString())
  formData.append('snapshot_id', snapshotId.toString())
  formData.append('project_id', projectId.toString())
  formData.append('upload_type', uploadType)
  formData.append('settings_file', file)
  const { data } = await request({
    method: 'post',
    url: `/plan-templates/${planTemplateId}/batch-group-settings/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
  return data
}

export const useUploadBatchFile = () => {
  return useMutation(uploadBatchBulkUpdateFile)
}
