import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  parameter: {
    margin: '3px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  formElement: {
    minWidth: '100px',
    width: '120px',
    backgroundColor: theme.palette.common.white,
  },

  suffix: {
    marginLeft: '10px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))
