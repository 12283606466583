import { useQuery } from 'react-query'
import { requestV2 } from 'Features/Auth/axios-client'

const fetchInventoryHoldingcost = async (
  replenishmentPolicyId: string,
  sourceSiteId: string,
  site: number
) => {
  const { data } = await requestV2({
    url: `/replenishment-policies/${replenishmentPolicyId}/source-sites/${sourceSiteId}/inventory-holding-cost?site=${site}`,
  })
  return data
}
export const useInventoryHoldingCost = (
  replenishmentPolicyId: string,
  sourceSiteId: string,
  site: number
) => {
  return useQuery(
    ['inventory-holding-cost', replenishmentPolicyId, sourceSiteId, site],
    () => {
      return fetchInventoryHoldingcost(
        replenishmentPolicyId,
        sourceSiteId,
        site
      )
    },
    {
      select: (data) => data.data,
    }
  )
}
