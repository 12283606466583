import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: theme.palette.background.paper,
    paddingTop: '20px',
    paddingLeft: '10px',
    marginRight: '10px',
  },
  paper: {
    height: '100%',
    boxShadow: theme.shadows[4],
    marginTop: '20px',
    marginBottom: '10px',
  },
}))
