import { PROJECT_ACCESS_OPTIONS } from 'Common/Constants/Constants'
import { format, addMinutes } from 'date-fns'
import { Project } from 'Features/Project/ProjectList/ProjectList.types'

export const convertToTitleCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
}

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const replaceUnderScoreWithSpace = (str: string) => {
  return str.replace(/_/gi, ' ')
}

export const formatDateToUTC = (dateString: string) => {
  const date = new Date(dateString)
  return `${format(
    addMinutes(date, date.getTimezoneOffset()),
    'dd/MM/yyyy - HH:mm'
  )} UTC`
}

export const mapDropdownMenuItemsInLabel = (
  items: { id: number; label: string }[]
) => {
  return items.map((item) => {
    return {
      key: item.label,
      value: item.id,
    }
  })
}

export const mapDropdownMenuItemsInName = (
  items: { id: number; label: string; name: string }[]
) => {
  return items.map((item) => {
    return {
      key: item.name,
      value: item.id,
    }
  })
}

export const getDropdownNameFromId = (
  items: { id: number; label: string; name: string }[],
  id: number
) => {
  const name = items
    .filter((item) => item.id === id)
    .map((item) => item.name)[0]
  return name ? name : ''
}

export const getLookUpLabelFromId = (
  items: { id: number; label: string }[],
  id: number
) => {
  return items.filter((item) => item.id === id).map((item) => item.label)[0]
}

export const getLookUpIdFromLabel = (
  items: { id: number; label: string }[],
  label: string
) => {
  return items.filter((item) => item.label === label).map((item) => item.id)[0]
}

export const isInteger = (num: number) => {
  return num % 1 === 0
}

export const generatedBySelect = (
  showPlansOption: string,
  projectData: Project[],
  userEmail: string | null,
  setProjectTableData: (data: Project[]) => void
) => {
  if (showPlansOption === PROJECT_ACCESS_OPTIONS['Generated by me']) {
    const myPlans: Project[] = projectData.filter(
      (row: Project) => row.email === userEmail
    )
    setProjectTableData(myPlans)
  } else if (
    showPlansOption === PROJECT_ACCESS_OPTIONS['Not generated by me']
  ) {
    const myPlans: Project[] = projectData.filter(
      (row: Project) => row.email !== userEmail
    )
    setProjectTableData(myPlans)
  } else if (
    showPlansOption === PROJECT_ACCESS_OPTIONS['Generated by anyone']
  ) {
    setProjectTableData(projectData)
  }
}
