import { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Table } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { SkuTableProps } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.types'
import { Loader } from 'Common/Loader/Loader'
import { useStyles } from './Sku.styles'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'
import { ProductSettings } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/BulkUpdate/BulkUpdate.types'

export const SkuTable = ({
  data,
  setSelectedRows,
  isLoading,
}: SkuTableProps) => {
  const classes = useStyles()

  const skuCodes = [
    ...new Set(
      data.map((item: ProductSettings) => {
        return item.productCode
      })
    ),
  ]

  const sourceSiteCodes = [...new Set(data.map((item) => item.sourceSiteCode))]

  const tableData = data.map((item, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  const columns: GridColDefPremium[] | any[] = useMemo(() => {
    if (!data) {
      return []
    }
    return [
      {
        field: 'productCode',
        headerName: 'SKU group code',
        minWidth: 150,
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuCodes,
              label: 'Sku codes',
            },
          },
        ],
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'sourceSiteCode',
        headerName: 'Source Site',
        headerAlign: 'center',
        minWidth: 100,
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: sourceSiteCodes,
              label: 'Site codes',
            },
          },
        ],
        flex: 0.75,
        align: 'center',
      },
      {
        field: 'workcentreCode',
        headerName: 'Work Centre',
        headerAlign: 'center',
        minWidth: 125,
        type: 'string',
        flex: 1,
        align: 'center',
      },
      {
        field: 'primaryWorkCentreCode',
        headerName: 'Primary work centre',
        minWidth: 150,
        type: 'string',
        flex: 1,
        align: 'center',
      },
      {
        field: 'segment',
        headerName: 'Segment',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'productFormat',
        headerName: 'SKU group format',
        minWidth: 100,
        type: 'string',
        flex: 1,
      },
      {
        field: 'batchGroupingCode',
        headerName: 'Batch Code',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'replenishmentType',
        headerName: 'Replenishment Type',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        type: 'string',
        flex: 0.75,
      },
      {
        field: 'salesRate',
        headerName: 'Sales rate',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        type: 'number',
        flex: 0.75,
      },
      {
        field: 'runRate',
        headerName: 'Run rate',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        type: 'number',
        flex: 0.75,
      },
      {
        field: 'productionUnitOfMeasure',
        headerName: 'UoM',
        headerAlign: 'center',
        minWidth: 50,
        type: 'string',
        flex: 0.5,
        align: 'center',
      },
      {
        field: 'minimumOrderQuantity',
        headerName: 'MOQ',
        headerAlign: 'center',
        minWidth: 50,
        type: 'number',
        flex: 0.5,
      },
      {
        field: 'minimumOrderIncrement',
        headerName: 'MOI',
        headerAlign: 'center',
        minWidth: 50,
        type: 'number',
        flex: 0.5,
      },
      {
        field: 'minimumCycle',
        headerName: 'Min Cycle',
        headerAlign: 'center',
        minWidth: 50,
        flex: 0.75,
        align: 'center',
      },
      {
        field: 'maximumCycle',
        headerName: 'Max Cycle',
        headerAlign: 'center',
        minWidth: 50,
        flex: 0.75,
        align: 'center',
      },
      {
        field: 'include',
        headerName: 'Include',
        headerAlign: 'center',
        minWidth: 50,
        flex: 0.75,
        align: 'center',
      },
    ]
  }, [data])

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(tableData.filter((row) => selectedIds.has(row.id)))
  }

  if (!data) {
    return <Loader />
  }

  return (
    <>
      <Box className={classes.tableContainer}>
        <Table
          rows={tableData}
          checkboxSelection
          columns={columns}
          showCellVerticalBorder
          showColumnVerticalBorder
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
          pagination
          pageSizeOptions={[10, 20, 30, 50]}
          experimentalFeatures={{ lazyLoading: true }}
          loading={isLoading}
        />
      </Box>
    </>
  )
}
