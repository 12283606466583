import { Fragment } from 'react'
import { List, Divider, Box } from '@material-ui/core'
import { SummaryListProps } from './Summary.types'
import { SummaryListItem } from './SummaryListItem.view'
import { useStyles } from './Summary.styles'

export const SummaryList = ({ summaryListData }: SummaryListProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.summaryListContainer}>
      <Column summaryListData={summaryListData?.slice(0, 5)} />
      <Divider orientation="vertical" className={classes.verticalDivider} />
      <Column summaryListData={summaryListData?.slice(5)} />
    </Box>
  )
}

const Column = ({ summaryListData }: SummaryListProps) => {
  const classes = useStyles()
  return (
    <List className={classes.columnContainer}>
      {Boolean(summaryListData?.length) &&
        summaryListData.map((listItem, listIndex) => {
          return (
            <Fragment key={listIndex}>
              <SummaryListItem
                key={listIndex}
                listItem={listItem}
                listIndex={listIndex}
              ></SummaryListItem>
              {listIndex < summaryListData.length - 1 && <Divider />}
            </Fragment>
          )
        })}
    </List>
  )
}
