import { useState } from "react"
import { DOWNLOAD_VERSION } from "Features/Project/DownloadDataModal/DownloadData.constants"
import { Box, Paper, Typography, Divider } from "@material-ui/core";
import { useStyles } from './DownloadDataModal.styles'
import { DropDown } from "supplyvue-ui";
import { DownloadDataViewProps } from "Features/Project/DownloadDataModal/DownloadData.types";


export const DownloadDataView = ({
                                  updateDownloadVersion,
                                  downloadVersion
                                 }: DownloadDataViewProps

) => {

  const classes = useStyles()

  return (
    <>
      <Paper elevation={0}>
        <Box display="flex" minHeight="10vh" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Typography variant="h4">Metric</Typography>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Typography variant="h4">Action</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Divider flexItem className={classes.divider} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Typography variant="h4">Download Version</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <DropDown
                  id="download-dropdown"
                  value={downloadVersion}
                  className={classes.select}
                  fullWidth={true}
                  onChange={(e) => updateDownloadVersion(e.target.value)}
                  menuItems={DOWNLOAD_VERSION}
                  label="download-dropdown"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  )
}
