import { Graph } from 'Common/Graph/Graph'
import { useMemo } from 'react'
import { SkuGroupInventoryAnalysisGraphPropsTypes } from './SkuGroupInventoryAnalysis.types'

export const SkuGroupInventoryAnalysisGraph = ({
  xAxisData,
  forecastAverageY,
  forecastVolumeY,
  salesAverageY,
  salesAveragePlusOneSDY,
  salesAveragePlusTwoSDY,
  totalSalesVolumeY,
}: SkuGroupInventoryAnalysisGraphPropsTypes) => {
  const data = useMemo(
    () => [
      {
        x: xAxisData,
        y: salesAveragePlusTwoSDY,
        mode: 'lines',
        name: 'Sales average plus two SD',
      },
      {
        x: xAxisData,
        y: salesAveragePlusOneSDY,
        mode: 'lines',
        name: 'Sales average plus one SD',
      },
      {
        x: xAxisData,
        y: salesAverageY,
        mode: 'lines',
        name: 'Sales average',
      },
      {
        x: xAxisData,
        y: forecastAverageY,
        mode: 'lines',
        name: 'Forecast average',
      },
      {
        x: xAxisData,
        y: forecastVolumeY,

        mode: 'lines',
        line: {
          color: '#5B76AE',
          width: 3,
        },
        name: 'Forecast volume',
      },
      {
        x: xAxisData,
        y: totalSalesVolumeY,
        marker: {
          color: '#E97724',
        },
        type: 'bar',
        name: 'Total sales volume',
      },
    ],
    [
      xAxisData,
      forecastAverageY,
      forecastVolumeY,
      salesAveragePlusOneSDY,
      salesAveragePlusTwoSDY,
      salesAverageY,
      totalSalesVolumeY,
    ]
  )

  const layout = {
    height: 600,
    yaxis: {
      showgrid: true,
      title: {
        text: 'Total sales volume',
      },
      automargin: true,
    },
    xaxis: {
      zeroline: true,
      nticks: 13,
    },
    ...(xAxisData.length > 0 &&
      {
        // annotations: [ // This may needed.
        //   {
        //     x: xAxisData[xAxisData.length - 1],
        //     y: salesAveragePlusTwoSDY[salesAveragePlusTwoSDY.length - 1],
        //     xref: 'x1',
        //     yref: 'y1',
        //     text: salesAveragePlusTwoSDY.length ? 'Sales average + two SD' : '',
        //     showarrow: true,
        //     arrowhead: 0,
        //     ax: 0,
        //     ay: -7,
        //   },
        //   {
        //     x: xAxisData[xAxisData.length - 1],
        //     y: salesAveragePlusOneSDY[salesAveragePlusOneSDY.length - 1],
        //     xref: 'x1',
        //     yref: 'y1',
        //     text: salesAveragePlusOneSDY.length ? 'Sales average + one SD' : '',
        //     showarrow: true,
        //     arrowhead: 0,
        //     ax: 0,
        //     ay: -7,
        //   },
        //   {
        //     x: xAxisData[xAxisData.length - 1],
        //     y: salesAverageY[salesAverageY.length - 1],
        //     xref: 'x1',
        //     yref: 'y1',
        //     text: salesAverageY.length ? 'Sales average' : '',
        //     showarrow: true,
        //     arrowhead: 0,
        //     ax: 0,
        //     ay: -7,
        //   },
        //   {
        //     x: xAxisData[xAxisData.length - 1],
        //     y: forecastAverageY[forecastAverageY.length - 1],
        //     xref: 'x1',
        //     yref: 'y1',
        //     text: forecastAverageY.length ? 'Forecast average' : '',
        //     showarrow: true,
        //     arrowhead: 0,
        //     ax: 0,
        //     ay: -7,
        //   },
        //   {
        //     x: xAxisData[xAxisData.length - 1],
        //     y: forecastVolumeY[forecastVolumeY.length - 1],
        //     xref: 'x1',
        //     yref: 'y1',
        //     text: forecastVolumeY.length ? 'Forecast volume' : '',
        //     showarrow: true,
        //     arrowhead: 0,
        //     ax: 0,
        //     ay: -7,
        //   },
        // ],
      }),

    legend: { orientation: 'h', x: 0.15, y: -0.1 },
  }
  return <Graph data={data} layout={layout} />
}
