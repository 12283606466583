import { GraphData } from './SequenceOfEvents.types'

export const calculateGraphData = (graphData: GraphData) => {
  const sequenceNumbers = graphData?.map((item) => {
    const sequence = item.graphData.map((graphItem) => graphItem.sequence)
    return Math.max(...sequence)
  })
  const maxSequenceNumber = graphData?.length ? Math.max(...sequenceNumbers) : 0

  const graphDataList: {
    productionTime: number[]
    changeOverTime: number[]
    inBatchChangeOverTime: number[]
    productCode: string[]
    changeOverText: string[]
    inBatchChangeOverText: string[]
  }[] = []
  for (let i = 0; i < maxSequenceNumber; i++) {
    graphDataList[i] = {
      productionTime: [0, 0, 0, 0, 0, 0, 0, 0],
      changeOverTime: [0, 0, 0, 0, 0, 0, 0, 0],
      inBatchChangeOverTime: [0, 0, 0, 0, 0, 0, 0, 0],
      productCode: ['', '', '', '', '', '', '', ''],
      changeOverText: ['', '', '', '', '', '', '', ''],
      inBatchChangeOverText: ['', '', '', '', '', '', '', ''],
    }
  }

  graphData?.forEach((item, graphDataIndex) => {
    item.graphData?.forEach((graphItem, index) => {
      graphDataList[index].changeOverTime[graphDataIndex] =
        graphItem.changeOverTime ?? 0
      graphDataList[index].inBatchChangeOverTime[graphDataIndex] =
        graphItem.inBatchChangeOverTime ?? 0
      graphDataList[index].productionTime[graphDataIndex] =
        graphItem.productionTime ?? 0
      graphDataList[index].productCode[graphDataIndex] =
        graphItem.productCode ?? ''
      graphDataList[index].changeOverText[graphDataIndex] = item.graphData[
        index + 1
      ]?.productCode
        ? `${item.graphData[index]?.productCode} to ${
            item.graphData[index + 1]?.productCode
          }`
        : `${item.graphData[index]?.productCode} to ${
            graphData[graphDataIndex + 1]?.graphData[0]?.productCode
          }`
      graphDataList[index].inBatchChangeOverText[graphDataIndex] = item
        .graphData[index + 1]?.productCode
        ? `${item.graphData[index]?.productCode} to ${
            item.graphData[index + 1]?.productCode
          }`
        : `${item.graphData[index]?.productCode} to ${
            graphData[graphDataIndex + 1]?.graphData[0]?.productCode
          }`
    })
  })

  return graphDataList
}
