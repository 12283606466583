import {
  AccordionDetails as MuiAccordionDetails,
  Typography,
  Checkbox,
  Box,
} from '@material-ui/core'
import { FormControlLabel } from 'Features/Project/WorkcentreModal/FormControlLabel/FormControlLabel.view'

import { useStyles } from './AccordionDetails.styles'
import { AccordionDetailsProps } from './AccordionDetails.types'

export const AccordionDetails = ({
  sourceSiteData,
  handleCheckboxChange,
  readOnly,
}: AccordionDetailsProps) => {
  const classes = useStyles()
  return (
    <MuiAccordionDetails
      id={sourceSiteData.siteLabel}
      classes={{ root: classes.accordionDetailsRoot }}
    >
      {sourceSiteData.siteWorkcentres.map((worcentre) =>
        readOnly ? (
          worcentre.isSelected ? (
            <Box
              key={worcentre.key}
              className={classes.accordionDetailsContent}
            >
              <Typography>{worcentre.code}</Typography>
            </Box>
          ) : null
        ) : (
          <Box key={worcentre.key} className={classes.accordionDetailsContent}>
            <FormControlLabel
              label={worcentre.code}
              control={
                <Checkbox
                  disabled={worcentre.productCount === 0}
                  color="primary"
                  className={classes.checkbox}
                  checked={worcentre.isSelected}
                  onChange={(event) =>
                    handleCheckboxChange(
                      event,
                      sourceSiteData.siteLabel,
                      worcentre.code
                    )
                  }
                />
              }
            />
          </Box>
        )
      )}
    </MuiAccordionDetails>
  )
}
