import { useQuery } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import { Scenario } from '../ProjectDetails/ProjectDetails.types'

const fetchScenarios = async (projectId: string) => {
  const data = await request({
    url: `/projects/${projectId}/details`,
    method: 'GET',
  })
  return data
}

export const useScenarios = (projectId: string) => {
  return useQuery(['scenarios', projectId], () => fetchScenarios(projectId), {
    enabled: !!projectId,
    cacheTime: 0,
    select: ({ data }) => {
      return data?.data?.scenariosList.filter(
        (scenario: Scenario) => scenario.createdOn !== null
      )
    },
  })
}
