import { useMemo } from 'react'
import { Graph } from 'Common/Graph/Graph'
import { AssetUtilisationGraphProps } from './AssetUtilisation.types'

export const AssetUtilisationGraph = ({
  xAxisData,
  availableTime,
  bufferTime,
  changeoverTime,
  productionTime,
}: AssetUtilisationGraphProps) => {
  const hovertemplate = (type: string) => {
    return (
      ` <b>Scenario: </b>%{x}<br>` +
      ` <b>${type}: </b> %{y}h<br>` +
      '<extra></extra>'
    )
  }

  const data = useMemo(() => {
    const trace1 = {
      x: xAxisData,
      y: availableTime,
      name: 'Available time (hours)',
      type: 'bar',
      textfont: { color: 'transparent' },
      width: 0.65,
      hovertemplate: hovertemplate('Available time'),
      marker: {
        color: '#BAB0AC',
      },
    }

    const trace2 = {
      x: xAxisData,
      y: bufferTime,
      name: 'Buffer time (hours)',
      type: 'bar',
      textfont: { color: 'transparent' },
      width: 0.65,
      hovertemplate: hovertemplate('Buffer time'),
      marker: {
        color: '#E15759',
      },
    }

    const trace3 = {
      x: xAxisData,
      y: changeoverTime,
      name: 'Changeover time (hours)',
      type: 'bar',
      textfont: { color: 'transparent' },
      width: 0.65,
      hovertemplate: hovertemplate('SalesHorizon time'),
      marker: {
        color: '#4E79A7',
      },
    }

    const trace4 = {
      x: xAxisData,
      y: productionTime,
      name: 'Production time (hours)',
      type: 'bar',
      textfont: { color: 'transparent' },
      width: 0.65,
      hovertemplate: hovertemplate('Production time'),
      marker: {
        color: '#F28E2B',
      },
    }

    return [trace4, trace3, trace2, trace1]
  }, [xAxisData, availableTime, bufferTime, changeoverTime, productionTime])

  const layout = {
    yaxis: {
      showgrid: true,
      automargin: true,
      title: {
        text: 'Asset utilisation(hours per cycle)',
        standoff: 15,
      },
    },
    xaxis: {
      zeroline: true,
      nticks: 10,
    },
    barmode: 'stack',
    legend: { orientation: 'h', x: 0, y: -0.2 },
  }
  return <Graph data={data} layout={layout} />
}
