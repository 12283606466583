import { useQuery } from 'react-query'
import { request, requestV2 } from 'Features/Auth/axios-client'

const fetchInventoryAnalysisTableData = async (
  replenishmentPolicyId: string,
  sourceSites: string,
  site: string,
  skuGroupKey: string
) => {
  const data = await request({
    url: `/replenishment-policies/${replenishmentPolicyId}/source-sites/${sourceSites}/sku-group-inventory-analysis?site=${site}&sku_group_key=${skuGroupKey}`,
  })
  return data
}
export const useInventoryAnalysisTableData = (
  replenishmentPolicyId: string,
  sourceSites: string,
  site: string,
  skuGroupKey: string
) => {
  return useQuery(
    [
      'analysis-table-data',
      replenishmentPolicyId,
      sourceSites,
      site,
      skuGroupKey,
    ],
    () =>
      fetchInventoryAnalysisTableData(
        replenishmentPolicyId,
        sourceSites,
        site,
        skuGroupKey
      ),
    {
      select: (data) => data?.data,
    }
  )
}

const fetchInventoryAnalysisGraphData = async (
  projectId: string,
  replenishmentPolicyId: string,
  sourceSites: string,
  site: string,
  skuGroupKey: string,
  historicLength: number
) => {
  const data = await requestV2({
    url: `/projects/${projectId}/replenishment-policies/${replenishmentPolicyId}/source-sites/${sourceSites}/sku-group-inventory-analysis-graph?site=${site}&sku_group_key=${skuGroupKey}&historic_length=${historicLength}`,
  })
  return data
}

export const useInventoryAnalysisGraphData = (
  projectId: string,
  replenishmentPolicyId: string,
  sourceSites: string,
  site: string,
  skuGroupKey: string,
  historicLength: number
) => {
  return useQuery(
    [
      'analysis-graph-data',
      projectId,
      replenishmentPolicyId,
      sourceSites,
      site,
      skuGroupKey,
      historicLength,
    ],
    () =>
      fetchInventoryAnalysisGraphData(
        projectId,
        replenishmentPolicyId,
        sourceSites,
        site,
        skuGroupKey,
        historicLength
      ),
    {
      select: (data) => data?.data,
    }
  )
}
