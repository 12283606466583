import { DropDown } from 'supplyvue-ui'
import { FormHelperText } from '@material-ui/core'
import { useMandatoryFieldValidation } from 'Common/hooks/useMandatoryFieldValidation.hook'
import { DropdownFieldProps } from './DropdownField.types'
import { useEffect } from 'react'
import { useStyles } from './DropdownField.styles'

export const DropdownField = ({
  isMandatoryField = false,
  label,
  name,
  handleChange,
  value,
  hasError,
  errorMessage,
  isNumber = false,
  defaultLabel,
  menuItems,
  isDisabled = false,
  getErrorStatus,
}: DropdownFieldProps) => {
  const { error, setFieldValue } = useMandatoryFieldValidation(value, isNumber)
  const classes = useStyles()

  useEffect(() => {
    isMandatoryField && setFieldValue(value)
  }, [value])

  useEffect(() => {
    getErrorStatus(error, name)
  }, [error])

  return (
    <>
      <DropDown
        disabled={isDisabled}
        error={isMandatoryField ? hasError || !!error : hasError}
        label={label}
        name={name}
        defaultLabel={defaultLabel}
        menuItems={menuItems}
        value={value}
        onChange={handleChange}
      />
      <FormHelperText className={classes.error}>
        {isMandatoryField ? errorMessage || error : errorMessage}
      </FormHelperText>
    </>
  )
}
