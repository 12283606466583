import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  evenRow: {
    background: theme.palette.background.grey,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '130px',
  },
  button: {
    width: 'max-content',
    alignSelf: 'flex-end',
    marginRight: '15px',
  },
  error: {
    color: theme.palette.error.main,
    lineHeight: '15px',
  },
  value: {
    lineHeight: '48px',
  },
  settingsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    marginBottom: '20px',
  },
  modal: {
    background: theme.palette.background.grey,
  },
  progress: {
    marginRight: '8px',
  },
  divider: {
    height: '2px',
    background: '#006286',
    width: '100%',
    marginTop: '1rem',
  },
}))
