import React from 'react'
import { useStyles } from './ViewScenario.styles'
import { ViewScenariosTable } from './ScenarioComparison.table'
import { Loader } from 'Common/Loader/Loader'
import { ViewScenariosTableProps } from "Features/ViewScenario/ViewScenario.types";
import { Button } from "supplyvue-ui";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import { toast } from "react-hot-toast";

export const ViewScenarioPage = ({
                                   data, isLoading
                                 }: ViewScenariosTableProps) => {
  const classes = useStyles({ innerWidth: window.innerWidth })

  if (isLoading) {
    return <Loader />
  }

  return (
      <div className={classes.tabContainer}>
        <div className={classes.header}>
          <Typography variant="h4">
            Scenario Analysis
          </Typography>
        </div>
        <div className={classes.ScenariosView}>
          <ViewScenariosTable
            data={data ?? []}
            isLoading={isLoading}
          />
        </div>
      </div>
  )
}
