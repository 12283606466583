import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={943}
      height={601.742}
      viewBox="0 0 943 601.742"
      {...props}
    >
      <path
        d="M167.722 596.506c8.993-7.598 14.455-19.602 13.023-31.288s-10.305-22.428-21.814-24.91-24.627 4.388-28.123 15.63c-1.924-21.674-4.14-44.257-15.664-62.714-10.434-16.714-28.507-28.672-48.093-30.812s-40.208 5.941-52.423 21.4-15.207 37.934-6.651 55.683c6.302 13.075 17.913 22.805 30.079 30.721a194.13 194.13 0 00132.772 29.046z"
        fill="#f2f2f2"
      />
      <path
        d="M33.996 468.801a317.625 317.625 0 0144.264 43.954 322.163 322.163 0 0134.908 51.663 320.845 320.845 0 0114.948 31.651c.898 2.2-2.675 3.158-3.562.982a315.276 315.276 0 00-28.808-54.505 317.26 317.26 0 00-38.636-47.73 316.5 316.5 0 00-25.726-23.403c-1.844-1.502.783-4.101 2.612-2.612z"
        fill="#fff"
      />
      <path
        d="M704.354 0h-487a7.008 7.008 0 00-7 7v330a7.008 7.008 0 007 7h487a7.008 7.008 0 007-7V7a7.008 7.008 0 00-7-7zm5 337a5.002 5.002 0 01-5 5h-487a5.002 5.002 0 01-5-5V7a5.002 5.002 0 015-5h487a5.002 5.002 0 015 5z"
        fill="#3f3d56"
      />
      <path fill="#3f3d56" d="M211.354 28.04h499v2h-499z" />
      <circle cx={228.354} cy={15} r={6} fill="#3f3d56" />
      <circle cx={245.604} cy={15} r={6} fill="#3f3d56" />
      <circle cx={262.854} cy={15} r={6} fill="#3f3d56" />
      <path
        d="M502.854 74.5h-84a7 7 0 010-14h84a7 7 0 010 14zM560.354 108.5h-199a7 7 0 010-14h199a7 7 0 010 14z"
        fill="#ccc"
      />
      <path
        d="M620.035 317.45a38.138 38.138 0 1138.138-38.137 38.181 38.181 0 01-38.138 38.138zm0-73.645a35.508 35.508 0 1035.508 35.508 35.548 35.548 0 00-35.508-35.508zM300.3 215.308a38.138 38.138 0 1138.137-38.138 38.181 38.181 0 01-38.138 38.138zm0-73.646a35.508 35.508 0 1035.507 35.508 35.548 35.548 0 00-35.508-35.508z"
        fill="#3f3d56"
      />
      <path
        d="M315.113 197.387a3.768 3.768 0 01-3.769-3.762v-21.399a3.767 3.767 0 013.763-3.762h.006a3.767 3.767 0 013.763 3.762v21.399a3.767 3.767 0 01-3.763 3.762zm-9.824 0a3.779 3.779 0 01-3.816-3.762v-11.527a3.766 3.766 0 013.762-3.762h.054a3.766 3.766 0 013.763 3.762v11.527a3.767 3.767 0 01-3.763 3.762zm-9.919 0a3.768 3.768 0 01-3.769-3.762v-32.91a3.767 3.767 0 013.762-3.762h.007a3.767 3.767 0 013.763 3.763v32.909a3.767 3.767 0 01-3.763 3.762zm-9.881 0a3.762 3.762 0 01-3.767-3.765l.005-4.943a3.762 3.762 0 017.524.003v4.943a3.767 3.767 0 01-3.762 3.762z"
        fill="#a2bfc9"
      />
      <path
        d="M878.842 599.308a38.138 38.138 0 1138.138-38.138 38.181 38.181 0 01-38.138 38.138zm0-73.646a35.508 35.508 0 1035.508 35.508 35.548 35.548 0 00-35.508-35.508z"
        fill="#3f3d56"
      />
      <path
        d="M889.356 554.551l-7.436 11.37a4.028 4.028 0 01.919 2.575 4.09 4.09 0 01-8.181 0 4.803 4.803 0 01.07-.785l-4.907-2.858a4.075 4.075 0 01-4.852.612l-5.222 4.891v4.876a3.651 3.651 0 003.636 3.643h30.911a3.659 3.659 0 003.643-3.643V557.15l-4.993-3.321a4.106 4.106 0 01-3.588.722z"
        fill="#a2bfc9"
      />
      <path
        d="M866.987 557.849a4.09 4.09 0 014.09 4.09v.527l5.12 2.866a4.083 4.083 0 012.59-.927 4.522 4.522 0 01.888.094l7.45-11.534a4.076 4.076 0 117.397-2.355 4.335 4.335 0 01-.11.966l3.525 2.355v-6.823a3.659 3.659 0 00-3.643-3.643h-30.911a3.651 3.651 0 00-3.636 3.643v19.543l3.408-3.267a4.083 4.083 0 013.832-5.496z"
        fill="#a2bfc9"
      />
      <path
        d="M462.367 215.308a38.138 38.138 0 1138.138-38.138 38.181 38.181 0 01-38.138 38.138zm0-73.646a35.508 35.508 0 1035.507 35.508 35.548 35.548 0 00-35.507-35.508z"
        fill="#3f3d56"
      />
      <path
        d="M442.105 190.995a2.773 2.773 0 01-1.964-4.731l14.092-14.132a2.352 2.352 0 011.675-.695h.002a2.352 2.352 0 011.675.693l6.535 6.535a1.411 1.411 0 001.005.417 1.41 1.41 0 001.006-.417l9.227-9.242a.473.473 0 00-.001-.67l-2.985-2.98a1.42 1.42 0 011.003-2.428h10.604a1.423 1.423 0 011.421 1.422v10.597a1.421 1.421 0 01-2.426 1.005l-2.99-2.99a.474.474 0 00-.67 0l-12.494 12.486a2.352 2.352 0 01-1.675.693 2.353 2.353 0 01-1.674-.694l-6.539-6.538a1.456 1.456 0 00-2.01 0l-10.856 10.856a2.754 2.754 0 01-1.961.813z"
        fill="#a2bfc9"
      />
      <path
        d="M462.367 315.308a38.138 38.138 0 1138.138-38.138 38.181 38.181 0 01-38.138 38.138zM620.367 212.308a38.138 38.138 0 1138.138-38.138 38.181 38.181 0 01-38.138 38.138zM300.3 239.032a38.281 38.281 0 00-18.69 4.9 35.517 35.517 0 016.8 69.48 36.627 36.627 0 004.55 1.19 37.747 37.747 0 007.34.71 38.14 38.14 0 100-76.28z"
        fill="#e6e6e6"
      />
      <path
        d="M285.83 241.892a36.987 36.987 0 00-7.34-.72 38.18 38.18 0 107.34.72zm2.58 71.52a35.512 35.512 0 11-9.92-69.61c1.05 0 2.09.05 3.12.13a35.517 35.517 0 016.8 69.48z"
        fill="#3f3d56"
      />
      <path
        d="M300.696 272.598a21.392 21.392 0 00-25.579-16.105c-.54.123-1.099.275-1.592.426l5.097 22.396 22.396-5.087a37.97 37.97 0 00-.322-1.63z"
        fill="#a2bfc9"
      />
      <path
        d="M298.527 278.16l-22.406 5.087-5.087-22.396a21.382 21.382 0 1027.474 17.308z"
        fill="#a2bfc9"
      />
      <path
        d="M692.236 457.45a38.138 38.138 0 1138.138-38.137 38.181 38.181 0 01-38.138 38.138zm0-73.645a35.508 35.508 0 1035.508 35.508 35.548 35.548 0 00-35.508-35.508z"
        fill="#3f3d56"
      />
      <path
        d="M708.693 434.594a7.058 7.058 0 117.053-7.063 7.061 7.061 0 01-7.053 7.063zm-16.457 0a7.061 7.061 0 01-7.053-7.053v-16.456a7.053 7.053 0 0114.107 0v16.456a7.061 7.061 0 01-7.054 7.053zm-16.45 0a7.066 7.066 0 01-7.059-7.058v-6.575a7.058 7.058 0 0114.116 0v6.575a7.066 7.066 0 01-7.058 7.058z"
        fill="#a2bfc9"
      />
      <path
        fill="#a0616a"
        d="M208.63 590.417l11.625-1.319.444-45.465-17.157 1.947 5.088 44.837z"
      />
      <path
        d="M243.898 597.348l-36.534 4.146-1.602-14.116 22.419-2.544a14.206 14.206 0 0115.717 12.514z"
        fill="#2f2e41"
      />
      <path
        fill="#a0616a"
        d="M89.081 589.691l11.699-.001 5.565-45.125-17.266.001.002 45.125z"
      />
      <path
        d="M123.343 600.553l-36.769.001v-14.206h22.562a14.206 14.206 0 0114.207 14.205zM173.019 387.895l6.68 21.728 36.262 78.47 9.542 74.432-21.948.954-13.36-68.707-45.804-49.621-40.08 129.78-18.13-1.91s15.268-167.95 36.262-185.126c0 0 34.353-16.223 50.576 0z"
        fill="#2f2e41"
      />
      <circle cx={143.388} cy={270.705} r={23.438} fill="#a0616a" />
      <path
        d="M175.404 392.189s2.863-97.335-37.216-90.655-23.38 100.675-23.38 100.675 8.59 17.177 29.583-1.909 31.013-8.111 31.013-8.111z"
        fill="#a2bfc9"
      />
      <path
        d="M258.378 311.172a9.596 9.596 0 01-14.588 1.922l-30.02 16.174 1.571-17.65 28.267-12.653a9.648 9.648 0 0114.77 12.207z"
        fill="#a0616a"
      />
      <path
        d="M143.164 313.418l48.73 8.237 43.671-20.615 6.63 14.177-47.806 30.45s-50.612 3.437-62.314-9.91c-4.388-5.006-4.89-9.561-3.802-13.3a13.15 13.15 0 0114.89-9.04z"
        fill="#a2bfc9"
      />
      <path
        d="M140.6 295.331a.967.967 0 00-.136-1.542 13.63 13.63 0 011.313-23.893c6.89-3.285 16.228-.729 21.322-7.042a11.841 11.841 0 002.197-9.617c-1.099-5.963-5.582-10.39-10.602-13.426A38.58 38.58 0 0096.4 277.156c.648 5.739 2.419 12.174-.994 16.834-3.003 4.1-8.672 4.87-13.652 5.888-10.681 2.186-20.986 7.228-28.234 15.372s-11.116 19.57-8.93 30.251 10.953 20.069 21.73 21.724c8.77 1.348 17.827-2.377 24.268-8.481s10.513-14.348 13.081-22.843c3.048-10.082 4.25-20.946 9.753-29.927 5.385-8.789 16.99-15.089 26.085-10.512a.975.975 0 001.094-.13z"
        fill="#2f2e41"
      />
      <path
        d="M819.808 468.816a9.69 9.69 0 003.466-14.45l22.582-124.975-21.073-.278L809.1 452.697a9.743 9.743 0 0010.707 16.119zM754.406 462.891l-8 38 1 85h15l7-83 11-35-26-5z"
        fill="#ffb8b8"
      />
      <path fill="#ffb8b8" d="M803.98 465.742l-8 38 1 85h15l7-83 11-35-26-5z" />
      <path
        d="M742.167 584.925l23.644-.06.037 14.887-38.53.097a14.887 14.887 0 0114.85-14.924zM792.11 586.532l23.644-.06.037 14.888-38.53.096a14.887 14.887 0 0114.849-14.924z"
        fill="#2f2e41"
      />
      <circle cx={790.906} cy={251.973} r={24.561} fill="#ffb8b8" />
      <path
        d="M845.184 312.722c-7.473-11.725-30.935-39.533-65.847-23.571a14.843 14.843 0 00-8.567 14.629c1.69 22.299 3.82 79.113-14.364 113.11 0 0 53 16 82 1 0 0-10-67 0-80a53.904 53.904 0 008.013-13.726 12.891 12.891 0 00-1.235-11.442z"
        fill="#3f3d56"
      />
      <path
        d="M835.406 311.89l9.813 4.09a3.718 3.718 0 012.285 3.277l.902 21.634-22-2z"
        fill="#3f3d56"
      />
      <path
        d="M760.406 414.89s-22 41-18 79l31 5 22-53-5 54 32 1s21-78 14-85z"
        fill="#2f2e41"
      />
      <path
        d="M729.16 418.55a9.69 9.69 0 007.708-12.704l44.629-84.13-19.984-6.693-37.633 84.91a9.743 9.743 0 005.28 18.617z"
        fill="#ffb8b8"
      />
      <path
        d="M787.59 298.941l-8.266-3.698s-7.009-4.345-13.221 5.216-12.924 30.512-12.924 30.512l26.97 7.589z"
        fill="#3f3d56"
      />
      <path
        d="M794.84 263.798c.882-3.062.627-7.205-2.34-8.363-1.548-.604-3.273-.128-4.925.041a13.141 13.141 0 01-9.08-2.5c-3.071-2.282-4.968-5.77-6.768-9.146l-2.724-5.106a22.182 22.182 0 01-1.546-3.327c-1.516-4.507.13-9.726 3.5-13.082a18.976 18.976 0 0112.962-5.082 34.984 34.984 0 0113.851 2.967 61.26 61.26 0 0120.653 13.875c3.832 3.92 7.32 9.027 6.52 14.45-.623 4.222-3.7 7.602-6.635 10.7l-10.645 11.237c-1.885 1.99-3.9 4.061-6.531 4.826s-6.024-.359-6.741-3.004c0 0-.433-5.424.45-8.486z"
        fill="#2f2e41"
      />
      <path d="M942 601.742H1a1 1 0 010-2h941a1 1 0 010 2z" fill="#3f3d56" />
      <path
        d="M634.035 295.313h-28a3 3 0 010-6h28a3 3 0 010 6zM634.035 282.313h-28a3 3 0 010-6h28a3 3 0 010 6zM634.035 269.313h-28a3 3 0 010-6h28a3 3 0 010 6z"
        fill="#a2bfc9"
      />
    </svg>
  )
}

export default SvgComponent
