import { ReplenishmentSiteParamsTableData } from './SiteParameters.types'
import { Node, Edge } from 'react-flow-renderer'

export const getDistinctValues = (
  property: keyof ReplenishmentSiteParamsTableData,
  data: ReplenishmentSiteParamsTableData[]
) => {
  return [
    ...new Set<string>(
      data
        .filter(
          (item: ReplenishmentSiteParamsTableData) =>
            item[property] && item[property] !== null
        )
        .map((item: ReplenishmentSiteParamsTableData) =>
          item[property]?.toString()
        )
    ),
  ]
}

export const getEdgeLabel = (overlayElement: Node | Edge): string => {
  const { source, target } = overlayElement as Edge

  const sourceData = overlayElement.data.nodes.filter(
    (item: { id: number; label: string }) => {
      return item.id === parseInt(source)
    }
  )
  const targetData = overlayElement.data.nodes.filter(
    (item: { id: number; label: string }) => item.id === parseInt(target)
  )
  return `${sourceData[0].label} ---- ${targetData[0].label}`
}
