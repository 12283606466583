import { Table } from 'supplyvue-ui'
import { Column, TableInstance } from 'react-table'
import {
  UtilisationOutputsTableProps,
  UtilisationOutputsColumn,
} from './UtilisationOutputs.types'
import { Loader } from 'Common/Loader/Loader'

export const UtilisationOutputsTable = ({
  data,
  isLoading,
}: UtilisationOutputsTableProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 320
    const availableHeight = window.innerHeight - 690
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  const columns: Column<UtilisationOutputsColumn>[] = [
    {
      Header: 'Scenario',
      accessor: 'scenario',
      disableFilters: true,
      disableSortBy: true,
      columnWidth: 120,
    },
    {
      Header: 'Average weekly run time hours',
      accessor: 'averageWeeklyRunTimeHours',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      columnWidth: 120,
    },
    {
      Header: 'Average weekly c/o hours',
      accessor: 'averageWeeklyCOHours',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Average weekly FOQ hours',
      accessor: 'averageWeeklyFOQHours',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Hours utilisation percentage',
      accessor: 'hoursUtilisation',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: (props: TableInstance) => props.value && props.value + '%',
    },
    {
      Header: 'Weekly variability coefficient',
      accessor: 'weeklyVariabilityCoefficient',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
  ]
  if (isLoading) {
    return <Loader />
  }
  return (
    <Table
      data={data}
      height={getTableHeight()}
      columns={columns}
      cellPadding="5px 5px"
    />
  )
}
