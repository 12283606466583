import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  modal: {
    background: theme.palette.background.grey,
  },
  divider: {
    height: '2px',
    background: '#006286',
    width: '100%',
    marginTop: '1rem',
  },
  buttonGroup: {
    marginTop: '20px',
    alignSelf: 'flex-end',
  },
  clearButton: {
    marginRight: '15px',
  },
  progress: {
    marginRight: '8px',
  },
}))
