import { UnitOfMeasureLabelProps } from './UnitOfMeasureLabel.types'
import { Tooltip, Typography } from '@material-ui/core'
import { getDropdownNameFromId } from 'Common/Utils/utils'

export const UnitOfMeasureLabel = ({
  unitOfMeasure,
  id,
  label,
}: UnitOfMeasureLabelProps) => {
  return (
    <Tooltip title={getDropdownNameFromId(unitOfMeasure, id)}>
      <Typography>{label ?? '-'}</Typography>
    </Tooltip>
  )
}
