import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  contents: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    width: '100%',
  },
  accordion: {
    border: `1px solid ${theme.palette.borders.grey}`,
  },
  fieldContainer: { display: 'flex', flexDirection: 'column' },
  parametersContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  section: {
    height: '100%',
    padding: '0px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
  },
  middleSection: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    height: '100%',
  },
  lastSection: {
    alignItems: 'flex-end',
  },
  label: {
    textAlign: 'right',
  },
  parameter: {
    margin: '3px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  formElement: {
    minWidth: '100px',
    width: '180px',
    backgroundColor: theme.palette.common.white,
  },
  dropDownLabel: {
    minWidth: '160px',
    textAlign: 'right',
    paddingRight: '10px',
  },
  button: { marginRight: '20px' },
}))
