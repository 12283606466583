import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
  },
  filtersWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    height: '36px',
    alignSelf: 'center',
    background: theme.palette.borders.grey,
    width: '1px',
    margin: '0px 32px',
  },
}))

export const autocompleteStyles = {
  width: '300px',
  border: 'none',
  backgroundColor: 'white',
}
