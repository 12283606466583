import {
  MAXIMUM_MIN_CYCLE_VALUE,
  MINIMUM_MIN_CYCLE_VALUE,
  FOQ,
} from 'Common/Constants/Constants'
import { ProductSettings } from 'Features/PlanTemplate/StepCard/StepCard.types'
import { ProductSettingsParametersType } from './ProductSettingsForm/ProductSettingsForm.types'
import { getLookUpIdFromLabel } from 'Common/Utils/utils'
import { Option } from 'Features/PlanTemplate/PlanTemplate.types'

export const getMinimumCycleValue = (salesWeekPerMOQ: number) => {
  const salesWeekPerMOQRoundUpValue = Math.ceil(salesWeekPerMOQ)
  if (salesWeekPerMOQ > MAXIMUM_MIN_CYCLE_VALUE) return MINIMUM_MIN_CYCLE_VALUE
  else if (salesWeekPerMOQRoundUpValue > 4) return MAXIMUM_MIN_CYCLE_VALUE
  else if (salesWeekPerMOQRoundUpValue > 2) return 4
  else if (salesWeekPerMOQRoundUpValue > 1) return 2
  else return MINIMUM_MIN_CYCLE_VALUE
}

const calculateMinimumCycle = (
  updatedReplenishmentTypeName: string,
  updatedMinCycle: string | number,
  currentMinCycle: number,
  salesWeeksPerMoq: number
) => {
  return updatedReplenishmentTypeName === FOQ
    ? MINIMUM_MIN_CYCLE_VALUE
    : updatedMinCycle
    ? updatedMinCycle > salesWeeksPerMoq
      ? updatedMinCycle
      : salesWeeksPerMoq > MAXIMUM_MIN_CYCLE_VALUE
      ? MINIMUM_MIN_CYCLE_VALUE
      : getMinimumCycleValue(salesWeeksPerMoq)
    : currentMinCycle
}

const calculateSalesWeeksPerMoq = (moq: number, salesRate: number) => {
  return moq / salesRate
}

export const getCalculatedSettings = (
  editedTableData: ProductSettingsParametersType,
  currentData: ProductSettings,
  replenishmentType: Option[]
) => {
  const { salesRate, minimumOrderQuantity, minimumCycle } = editedTableData

  const updatedSalesRate = salesRate || currentData.salesRate
  const updatedMinimumOrderQuantity =
    minimumOrderQuantity || currentData.minimumOrderQuantity

  const salesWeeksPerMOQ = calculateSalesWeeksPerMoq(
    parseFloat(updatedMinimumOrderQuantity as string),
    parseFloat(updatedSalesRate as string)
  )

  const replenishmentTypeName =
    editedTableData.replenishmentType || currentData.replenishmentType
  const updatedReplenishmentTypeName =
    salesWeeksPerMOQ > MAXIMUM_MIN_CYCLE_VALUE ? FOQ : replenishmentTypeName

  let updatedReplenishmentTypeId
  let updatedMinimumCycle

  if (updatedReplenishmentTypeName !== null) {
    updatedReplenishmentTypeId = getLookUpIdFromLabel(
      replenishmentType,
      updatedReplenishmentTypeName
    )
  }
  if (
    updatedReplenishmentTypeName !== null &&
    minimumCycle != null &&
    currentData.minimumCycle !== null
  ) {
    updatedMinimumCycle = calculateMinimumCycle(
      updatedReplenishmentTypeName,
      minimumCycle,
      currentData.minimumCycle,
      salesWeeksPerMOQ
    )
  }

  return {
    salesRate: updatedSalesRate,
    minimumOrderQuantity: updatedMinimumOrderQuantity,
    replenishmentTypeName: updatedReplenishmentTypeName,
    replenishmentTypeId: updatedReplenishmentTypeId,
    minimumCycle: updatedMinimumCycle,
  }
}

export const getUpdatedProductSettings = (
  editedTableData: ProductSettingsParametersType,
  data: ProductSettings
) => {
  const {
    batchGroupingCode,
    salesRate,
    runRate,
    minimumOrderQuantity,
    minimumOrderIncrement,
    minimumCycle,
    maximumCycle,
    replenishmentTypeId,
    replenishmentType,
    include,
  } = editedTableData

  const latestInclude = typeof include === 'boolean' ? include : data.include
  return {
    ...data,
    ...(salesRate && { salesRate: parseFloat(salesRate as string) }),
    ...(minimumOrderQuantity && {
      minimumOrderQuantity: parseFloat(minimumOrderQuantity as string),
    }),
    ...(replenishmentType && { replenishmentType: replenishmentType }),
    ...(replenishmentTypeId && {
      replenishmentTypeId: parseInt(replenishmentTypeId as string),
    }),
    ...(minimumCycle && { minimumCycle: parseInt(minimumCycle as string) }),
    ...(batchGroupingCode && { batchGroupingCode: batchGroupingCode }),
    ...(runRate && { runRate: parseFloat(runRate as string) }),
    ...(minimumOrderIncrement && {
      minimumOrderIncrement: parseFloat(minimumOrderIncrement as string),
    }),
    ...(maximumCycle && { maximumCycle: parseFloat(maximumCycle as string) }),

    include: latestInclude,
  }
}
