import {
  CompareScenariosTableProps,
} from './CompareScenarios.types'
import { Table as MUITable } from 'Common/MUITable/MUITable'
import { useMemo } from "react";
import { useStyles } from "./CompareScenarios.styles"

export const CompareScenariosTable = ({
  data,
}: CompareScenariosTableProps) => {
  const classes = useStyles()

  const tableData = data.map((item: any, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  const renderPct = (value: number) => {
    if (value){
      return <div>{value} %</div>
    }
    return <div></div>
  }

  const columns: any[] = useMemo(() => {
    if (!data) {
      return []
  }
    return [
      {
        field: 'scenario',
        headerName: 'Scenario',
        type: 'string',
        flex: 1
      },
      {
        field: 'productsCountTotal',
        headerName: 'Products Count',
        type: 'number',
        flex: 1
      },
      {
        field: 'weeklyVolumeTotal',
        headerName: 'Weekly Volume',
        type: 'number',
        flex: 1
      },
      {
        field: 'weeklyHoursTotal',
        headerName: 'Weekly Hours',
        type: 'number',
        flex: 1
      },
      {
        field: 'overCapacityCount',
        headerName: 'Over Capacity Lines',
        type: 'number',
        flex: 1
      },
      {
        field: 'utilisation',
        headerName: 'Utilisation %',
        type: 'number',
        renderCell: (row: any) => renderPct(row.row.utilisation),
        flex: 1
      },
      {
        field: 'changeoverPct',
        headerName: 'Changeover %',
        type: 'number',
        renderCell: (row: any) => renderPct(row.row.changeoverPct),
        flex: 1
      },
      {
        field: "weightedCycleTime",
        headerName: "Cycle Speed",
        type: 'number',
        flex: 1
      },
      {
        field: 'cycle1Sum',
        headerName: 'Cycle 1',
        type: 'number',
        flex: 1
      },
      {
        field: 'cycle2Sum',
        headerName: 'Cycle 2',
        type: 'number',
        flex: 1
      },
      {
        field: 'cycle4Sum',
        headerName: 'Cycle 4',
        type: 'number',
        flex: 1
      },
      {
        field: 'cycle8Sum',
        headerName: 'Cycle 8',
        type: 'number',
        flex: 1
      },
      {
        field: 'foqCount',
        headerName: 'FOQ Count',
        type: 'number',
        flex: 1
      },
      {
        field: 'serviceLevel',
        headerName: 'Service Level %',
        type: 'number',
        renderCell: (row: any) => renderPct(row.row.serviceLevel),
        flex: 1
      },
      {
        field: 'inventoryVolumeTotal',
        headerName: 'Inventory Volume',
        type: 'number',
        flex: 1
      },
      {
        field: 'inventoryDurationTotal',
        headerName: 'Inventory Duration',
        type: 'number',
        flex: 1
      },

    ]

  }, [tableData])

  return (
      <MUITable
        rows={tableData}
        disableRowSelectionOnClick
        columns={columns}
        experimentalFeatures={{ lazyLoading: true }}
        hideFooter={true}
      />
  )
}
