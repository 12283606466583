import { Box, CircularProgress, Typography, Divider } from '@material-ui/core'
import { isEqual } from 'lodash'
import { Button, DropDown, Input, Modal } from 'supplyvue-ui'
import {
  ReplenishmentSettingsOutputFormBulkUpdateViewProps,
  ReplenishmentSettingsOutputFormData,
} from './ReplenishmentSettingsOutput.type'
import { useStyles } from './ReplenishmentSettingsOutput.styles'

export const INITIAL_STATE = {
  batchGroupingCode: '',
  salesRate: '',
  runRate: '',
  minimumOrderQuantity: '',
  minimumOrderIncrement: '',
}

const chosenMethods = [
  { key: 'H', value: 'H' },
  { key: 'F', value: 'F' },
  { key: 'D', value: 'D' },
  { key: '*', value: '*' },
]

const safetyStockType = [
  { key: 'Fixed volume', value: 'Fixed volume' },
  { key: 'Duration based', value: 'Duration based' },
]

export const ReplenishmentSettingsOutputBulkUpdate = ({
  isModalOpen,
  handleModalClose,
  handleSaveClick,
  isUpdating,
  data,
  setData,
}: ReplenishmentSettingsOutputFormBulkUpdateViewProps) => {
  const classes = useStyles()
  const handleDropdownChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    setData({
      ...data,
      [name]: event.target.value as string,
    })
  }

  const handleSaveButtonClick = async () => {
    const dataDummy = { ...data }
    for (const key in dataDummy) {
      const dkey: keyof ReplenishmentSettingsOutputFormData = key as keyof ReplenishmentSettingsOutputFormData
      if (dataDummy[dkey] === '') {
        delete data[dkey]
      }
    }
    handleSaveClick(data)
  }
  const handleClearButtonClick = () => {
    setData({
      method: '',
      safetyStockType: '',
    })
  }

  return (
    <Box>
      <Box>
        <Modal
          disableBackdropClick
          open={isModalOpen}
          handleClose={handleModalClose}
          header={'Edit Selection'}
          body={
            <Box mt={2} mb={2} display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Metric</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Action</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Value</Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Divider flexItem className={classes.divider} />
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Chosen method</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="Chosen method"
                      name="method"
                      defaultLabel="None"
                      menuItems={chosenMethods}
                      value={data.method}
                      onChange={handleDropdownChange}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Safety stock type</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="Safety stock type"
                      name="safetyStockType"
                      defaultLabel="None"
                      menuItems={safetyStockType}
                      value={data.safetyStockType}
                      onChange={handleDropdownChange}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                mt={4}
                justifyContent="space-between"
              >
                <Box display="flex" width="100px" justifyContent="flex-end">
                  {/*dummy block*/}
                </Box>
              </Box>
              <Box display="flex" alignSelf="flex-end" mt="10px">
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleClearButtonClick}
                  disabled={isEqual(INITIAL_STATE, data)}
                >
                  Clear
                </Button>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleSaveButtonClick}
                  disabled={
                    isUpdating ||
                    (data.method === '' && data.safetyStockType === '')
                  }
                >
                  {isUpdating && (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      className={classes.progress}
                    />
                  )}
                  Apply changes
                </Button>
              </Box>
            </Box>
          }
          className={classes.modal}
        />
      </Box>
    </Box>
  )
}
