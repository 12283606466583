import { useMemo } from 'react'

import { Graph } from 'Common/Graph/Graph'
import { SummaryGraphProps } from './Summary.types'
import { colors } from './Summary.styles'

export const SummaryGraph = ({
  xAxisData,
  yAxisDataOne,
  yAxisDataTwo,
  yAxisDataThree,
  capacityReferenceValue,
  foqAdjustedCapacity,
  averageUtilisationPercentage,
  averageUtilisationHours,
  traceOneName,
  traceTwoName,
  traceThreeName,
  tooltipText,
}: SummaryGraphProps) => {
  const hovertemplate = (traceOneName: string, traceTwoName: string) => {
    return (
      ' <b>Week: </b>%{x}<br>' +
      ` <b>${traceOneName}: </b> %{y}h<br>` +
      ` <b>${traceTwoName}:</b>  %{customdata}h<br>` +
      ' <b>Utilisation: </b>%{text}%<br>' +
      '<extra></extra>'
    )
  }

  const data = useMemo(() => {
    const trace1 = {
      x: xAxisData,
      y: yAxisDataOne,
      name: traceOneName,
      type: 'bar',
      text: tooltipText,
      customdata: yAxisDataTwo,
      marker: {
        color: '0077b6',
      },
      textfont: { color: 'transparent' },
      width: 0.45,
      hovertemplate: hovertemplate(traceOneName, traceTwoName),
    }

    const trace2 = {
      x: xAxisData,
      y: yAxisDataTwo,
      type: 'bar',
      text: tooltipText,
      customdata: yAxisDataOne,
      marker: {
        color: 'fe7f2d',
      },
      textfont: { color: 'transparent' },
      name: traceTwoName,
      width: 0.45,
      hovertemplate: hovertemplate(traceTwoName, traceOneName),
    }

    const trace3 = {
      x: xAxisData,
      y: yAxisDataThree,
      type: 'bar',
      text: tooltipText,
      customdata: yAxisDataOne,
      marker: {
        color: 'ffd166',
      },
      textfont: { color: 'transparent' },
      name: traceThreeName,
      width: 0.45,
      hovertemplate: hovertemplate(traceThreeName, traceOneName),
    }

    return [trace1, trace2, trace3]
  }, [xAxisData, yAxisDataOne, yAxisDataTwo, yAxisDataThree])

  const layout = {
    height: 630,
    barmode: 'stack',
    annotations: [
      {
        x: 1,
        y: capacityReferenceValue,
        xref: 'paper',
        yref: 'y',
        text: `${'Capacity:' + capacityReferenceValue}h`,
        yanchor: 'bottom',
        showarrow: false,
        ax: 0,
        ay: -40,
        font: {
          color: colors.fontColor,
        },
      },
      {
        x: 0,
        y: foqAdjustedCapacity,
        xref: 'paper',
        yref: 'y',
        text: `${'Adjusted Capacity: ' + capacityReferenceValue}h`,
        yanchor: 'bottom',
        showarrow: false,
        ax: 0,
        ay: -40,
        font: {
          color: colors.fontColor,
        },
      },
      {
        x: 1,
        y: averageUtilisationHours,
        xref: 'paper',
        yref: 'y',
        text: `Average utilisation: ${averageUtilisationHours}h (${averageUtilisationPercentage}%)`,
        yanchor: 'bottom',
        showarrow: false,
        ax: 0,
        ay: -40,
        font: {
          color: colors.fontColor,
        },
      },
    ],
    shapes: [
      {
        type: 'line',
        xref: 'paper',
        x0: 0,
        x1: 1,
        yref: 'y',
        opacity: 0.5,
        y0: capacityReferenceValue,
        y1: capacityReferenceValue,
        line: {
          width: 1,
          color: colors.referenceLineColor,
        },
      },
      {
        type: 'line',
        xref: 'paper',
        x0: 0,
        x1: 1,
        yref: 'y',
        opacity: 0.5,
        y0: foqAdjustedCapacity,
        y1: foqAdjustedCapacity,
        line: {
          width: 1,
          color: colors.referenceLineColor,
        },
      },
      {
        type: 'line',
        xref: 'paper',
        x0: 0,
        x1: 1,
        yref: 'y',
        y0: averageUtilisationHours,
        y1: averageUtilisationHours,
        opacity: 0.5,
        line: {
          width: 1,
          color: colors.referenceLineColor,
        },
      },
    ],
    hoverlabel: {
      bordercolor: colors.hoverBorderColor,
      bgcolor: colors.hoverBackgroundColor,
      font: { color: colors.fontColor, family: 'proxima-nova' },
      style: {
        padding: '20px',
      },
    },
    yaxis: {
      title: {
        text: 'Hours',
        standoff: 1,
      },
      tickmode: 'array',
      linewidth: 1,
      showgrid: true,
      linecolor: colors.lineColor,
      showline: true,
      tickfont: {
        family: 'proxima-nova',
        size: 14,
        color: colors.fontColor,
      },
    },
    xaxis: {
      type: 'category',
      title: {
        text: 'Week',
      },
      showline: true,
      linewidth: 1,
      linecolor: colors.lineColor,
      tickfont: {
        family: 'proxima-nova',
        size: 14,
        color: colors.fontColor,
      },
    },
    legend: {
      orientation: 'h',
      x: 0.15,
      y: 1.2,
    },
    margin: {
      l: 60,
      r: 50,
      b: 50,
      t: 50,
    },
  }
  return <Graph data={data} layout={layout} />
}
