import { Box, Typography } from '@material-ui/core'
import { AssetUtilisationGraph } from './AssetUtilisation/AssetUtilisation.graph'
import { UtilisationDriverTable } from './UtilisationDriverTable/UtilisationDriver.table'
import { UtilisationOutputsTable } from './UtilisationOutputsTable/UtilisationOutputs.table'
import { UtilisationAnalysisViewProps } from './UtilisationAnalysis.types'

export const UtilisationAnalysisView = ({
  utilisationDrivers,
  utilisationOutputs,
  assetUtilisationGraph,
}: UtilisationAnalysisViewProps) => {
  return (
    <Box display="flex">
      <Box width={'40%'}>
        <Typography variant="h4">
          Asset utilisation for different scenarios
        </Typography>
        <AssetUtilisationGraph
          xAxisData={assetUtilisationGraph.map((item) => item.label)}
          availableTime={assetUtilisationGraph.map(
            (item) => item.availableTime ?? 0
          )}
          bufferTime={assetUtilisationGraph.map((item) => item.bufferTime ?? 0)}
          changeoverTime={assetUtilisationGraph.map(
            (item) => item.changeOverTime ?? 0
          )}
          productionTime={assetUtilisationGraph.map(
            (item) => item.productionTime ?? 0
          )}
        />
      </Box>
      <Box display="flex" width="60%" flexDirection="column">
        <Box mb={2}>
          <Typography variant="h4">Utilisation drivers</Typography>
          <UtilisationDriverTable data={utilisationDrivers} isLoading={false} />
        </Box>
        <Box>
          <Typography variant="h4">Utilisation outputs</Typography>
          <UtilisationOutputsTable
            data={utilisationOutputs}
            isLoading={false}
          />
        </Box>
      </Box>
    </Box>
  )
}
