import { format } from 'date-fns'
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { useStyles } from './ProjectCard.styles'
import { ProjectCardProps } from './ProjectCard.types'
import SnapshotIcon from 'Assets/icons/snapshot.svg'
import WorkcentreIcon from 'Assets/icons/workcentre.svg'
import ScenarioIcon from 'Assets/icons/scenario.svg'
import UserIcon from 'Assets/icons/user.svg'
import DateIcon from 'Assets/icons/date.svg'
import TimeIcon from 'Assets/icons/time.svg'
import {
  DeleteOutline,
  ChevronRight,
  LockOutlined,
  LockOpenOutlined,
} from '@material-ui/icons'
import { PROJECT_VISIBLITY } from 'Common/Constants/Constants'
import { useEffect, useState } from 'react'

export const ProjectCardView = ({
  projectId,
  projectName,
  snapshotName,
  snapshotLength,
  snapshotEndDate,
  workcentres,
  scenarios,
  createdAt,
  createdBy,
  handleClick,
  handleDelete,
  userEmail,
  projectOwnerEmail,
  projectAccessType,
  handlePlanAccessChangeClick,
  isProjectAccessUpdating = false,
}: ProjectCardProps) => {
  const classes = useStyles()
  const [projectCardAccessUpdating, setProjectCardAccessUpdating] = useState(
    false
  )

  useEffect(() => {
    if (isProjectAccessUpdating === false) setProjectCardAccessUpdating(false)
  }, [isProjectAccessUpdating])

  return (
    <Card className={classes.wrapper}>
      <CardContent className={classes.content}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography
            className={classes.title}
            variant="h3"
            color="textSecondary"
          >
            {projectName}
          </Typography>
          {userEmail === projectOwnerEmail && (
            <Box onClick={() => handleDelete(projectId, projectName)}>
              <DeleteOutline className={classes.deleteIcon} color="primary" />
            </Box>
          )}
        </Box>
        {!!snapshotName && (
          <Box className={classes.detail}>
            <Box className={classes.detailIcon}>
              <SnapshotIcon />
            </Box>
            <Box className={classes.detailSnapshot}>
              <Typography
                component="span"
                className={classes.bold}
                variant="body1"
              >
                {snapshotName}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                className={classes.secondaryText}
              >
                {snapshotLength} weeks, End date: {snapshotEndDate}
              </Typography>
            </Box>
            {userEmail === projectOwnerEmail && (
              <Box className={classes.publicPrivateIconContainer}>
                {isProjectAccessUpdating && projectCardAccessUpdating ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    {projectAccessType?.projectAccess?.visibility ===
                      PROJECT_VISIBLITY['PUBLIC'] && (
                      <LockOpenOutlined
                        className={classes.deleteIcon}
                        color="primary"
                        onClick={() => {
                          setProjectCardAccessUpdating(true)
                          const state = { ...projectAccessType }
                          state.projectAccess.visibility = 'private'
                          for (const key in state.scenarioAccess) {
                            state.scenarioAccess[key] = 'private'
                          }
                          handlePlanAccessChangeClick(projectId, state)
                        }}
                      />
                    )}
                    {projectAccessType?.projectAccess?.visibility ===
                      PROJECT_VISIBLITY['PRIVATE'] && (
                      <LockOutlined
                        className={classes.deleteIcon}
                        color="primary"
                        onClick={() => {
                          setProjectCardAccessUpdating(true)
                          const access = {
                            projectAccess: {
                              visibility: 'public' as 'public' | 'private',
                            },
                            scenarioAccess: {
                              ...projectAccessType.scenarioAccess,
                            },
                          }
                          handlePlanAccessChangeClick(projectId, access)
                        }}
                      />
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
        )}

        {!!workcentres && (
          <Box className={classes.detail}>
            <Box className={classes.detailIcon}>
              <WorkcentreIcon />
            </Box>
            <Box>
              <Typography variant="body1">
                <Box component="span" className={classes.bold}>
                  {workcentres}
                </Box>{' '}
                {workcentres > 1 ? 'Work centres' : 'Work centre'}
              </Typography>
            </Box>
          </Box>
        )}

        {scenarios && scenarios > 0 && (
          <Box className={classes.detail}>
            <Box className={classes.detailIcon}>
              <ScenarioIcon />
            </Box>
            <Box>
              <Typography variant="body1">
                <Box component="span" className={classes.bold}>
                  {scenarios}
                </Box>{' '}
                {scenarios > 1 ? 'Scenarios' : 'Scenario'}
              </Typography>
            </Box>
          </Box>
        )}
      </CardContent>
      <Box className={classes.goToIcon} onClick={() => handleClick(projectId)}>
        <Typography className={classes.viewFont}>View</Typography>
        <ChevronRight />
      </Box>
      <CardActions className={classes.footer}>
        <Box className={classes.footerDetail}>
          <Box className={classes.footerDetailIcon}>
            <UserIcon />
          </Box>
          <Typography className={classes.secondaryText} variant="body1">
            {createdBy}
          </Typography>
        </Box>
        <Box className={classes.timestamp}>
          <Box className={classes.footerDetail} mb={0.5}>
            <Box className={classes.footerDetailIcon}>
              <DateIcon />
            </Box>
            <Typography
              component="span"
              variant="body2"
              className={classes.footerText}
            >
              {format(new Date(createdAt), 'dd/MM/yyyy')}
            </Typography>
          </Box>
          <Box className={classes.footerDetail}>
            <Box className={classes.footerDetailIcon}>
              <TimeIcon />
            </Box>
            <Typography
              component="span"
              variant="body2"
              className={classes.footerText}
            >
              {format(new Date(createdAt), 'HH:mm')}
            </Typography>
          </Box>
        </Box>
      </CardActions>
    </Card>
  )
}
