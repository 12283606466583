import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={866.331}
      height={605.74}
      viewBox="0 0 866.331 605.74"
      {...props}
    >
      <path
        d="M731.468 420.659a9.146 9.146 0 011.931-13.89l-7.251-31.683 15.818 5.918 4.462 29.178a9.195 9.195 0 01-14.96 10.477z"
        fill="#ffb8b8"
      />
      <path
        d="M730.374 395.514l-13.906-26.885 1.88-67.697.894 1.883c1.041 2.193 25.44 53.846 23.7 71.138l2.789 15.802z"
        fill="#3f3d56"
      />
      <path
        fill="#ffb8b8"
        d="M721.307 594.201h11.151l5.304-43.009-16.457.001.002 43.008z"
      />
      <path
        d="M718.463 590.561h21.961a13.994 13.994 0 0113.993 13.993v.454l-35.954.002z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M611.134 585.727l10.743 2.987 16.634-40.015-15.856-4.409-11.521 41.437z"
      />
      <path
        d="M609.369 581.458l21.156 5.882h.002a13.994 13.994 0 019.732 17.232l-.121.438-34.64-9.631zM710.19 577.434l-22.504-161.126-48.604 160.215-38.217-13.05 54.774-214.532.36-.026 57.381-3.991 41.457 219.488z"
        fill="#2f2e41"
      />
      <path
        d="M704.238 273.015s-20.919-10.914-49.113-6.367c0 0-15.007 36.205 0 50.618l4.092 36.24s38.2 22.739 50.933-3.637l-1.819-30.014s9.095-14.395 1.82-24.933a34.82 34.82 0 01-5.913-21.907z"
        fill="#a2bfc9"
      />
      <path
        d="M706.888 437.857l3.672-76.188-10.391-90.634.477-.072c13.63-2.056 18.002 21.427 18.182 22.428l23.726 134.142zM660.632 438.65l-41.944-4.66.011-.458c.149-6.21 3.759-152.204 13.875-162.544 10.175-10.401 29.377-5.9 30.19-5.703l.404.097-3.451 61.777z"
        fill="#3f3d56"
      />
      <path
        d="M668.357 351.393a9.146 9.146 0 01-11.907-7.409l-31.895-6.249 11.835-12.049 28.474 7.782a9.195 9.195 0 013.493 17.925z"
        fill="#ffb8b8"
      />
      <path
        d="M651.337 344.08l-10.393-2.235c-15.342 4.614-42.122-11.63-52.946-18.845-2.036-1.358-2.697-4.048-1.963-7.994a23.31 23.31 0 018.567-13.915l38.12-30.225 9.621-1.609 2.858 16.195-24.287 26.086 35.752 19.46z"
        fill="#3f3d56"
      />
      <path
        d="M647.226 252.938v-21a33.5 33.5 0 1167 0v21a4.505 4.505 0 01-4.5 4.5h-58a4.505 4.505 0 01-4.5-4.5z"
        fill="#2f2e41"
      />
      <circle cx={679.38} cy={232.671} r={24.561} fill="#ffb8b8" />
      <path
        d="M654.145 232.576a2.5 2.5 0 01-.585-1.993l2.909-20.26a2.504 2.504 0 011.415-1.919c14.85-6.95 29.91-6.959 44.76-.026a2.52 2.52 0 011.428 2.036l1.942 20.286a2.5 2.5 0 01-2.488 2.738H698.6a2.51 2.51 0 01-2.265-1.442l-2.126-4.555a1.5 1.5 0 00-2.848.448l-.42 3.359a2.504 2.504 0 01-2.48 2.19h-32.427a2.5 2.5 0 01-1.89-.862z"
        fill="#2f2e41"
      />
      <path fill="#e6e6e6" d="M0 40.366h551V383.48H0z" />
      <path
        fill="#fff"
        d="M36.949 106.466h100.916v65.595H36.949zM162.344 106.466H263.26v65.595H162.344zM287.74 106.466h100.916v65.595H287.74zM413.135 106.466h100.916v65.595H413.135zM36.949 199.309h100.916v65.595H36.949zM162.344 199.309H263.26v65.595H162.344zM287.74 199.309h100.916v65.595H287.74zM413.135 199.309h100.916v65.595H413.135zM36.949 292.151h100.916v65.595H36.949zM162.344 292.151H263.26v65.595H162.344zM287.74 292.151h100.916v65.595H287.74zM413.135 292.151h100.916v65.595H413.135z"
      />
      <circle cx={144.31} cy={65.595} r={15.137} fill="#fff" />
      <circle cx={394.581} cy={65.595} r={15.137} fill="#fff" />
      <path
        fill="#e6e6e6"
        d="M138.255 0h12.11v69.632h-12.11zM388.526 0h12.11v69.632h-12.11z"
      />
      <path
        d="M486.414 139.264a22.757 22.757 0 11-3.527-12.19 22.757 22.757 0 013.527 12.19z"
        fill="#a2bfc9"
      />
      <path
        d="M482.887 127.073l-22.464 22.46c-1.412-2.186-9.277-12.344-9.277-12.344a31.827 31.827 0 013.22-2.842l6.522 8.697 19.368-19.369a22.727 22.727 0 012.631 3.398z"
        fill="#fff"
      />
      <path
        d="M235.623 232.106a22.757 22.757 0 11-3.527-12.19 22.757 22.757 0 013.527 12.19z"
        fill="#a2bfc9"
      />
      <path
        d="M232.096 219.916l-22.464 22.46c-1.412-2.186-9.277-12.344-9.277-12.344a31.827 31.827 0 013.22-2.843l6.522 8.697 19.369-19.368a22.726 22.726 0 012.63 3.398z"
        fill="#fff"
      />
      <path
        d="M110.228 232.106a22.757 22.757 0 11-3.527-12.19 22.757 22.757 0 013.527 12.19z"
        fill="#a2bfc9"
      />
      <path
        d="M106.7 219.916l-22.463 22.46c-1.412-2.186-9.278-12.344-9.278-12.344a31.827 31.827 0 013.22-2.843l6.523 8.697 19.368-19.368a22.727 22.727 0 012.63 3.398z"
        fill="#fff"
      />
      <path
        d="M865.33 605.74h-381a1 1 0 010-2h381a1 1 0 010 2z"
        fill="#3f3d56"
      />
    </svg>
  )
}

export default SvgComponent
