import { ContentViewProps } from 'Features/NewPlanTemplate/WorkcentreSettings/WorkcentreSettingsContent/WorkcentreSettingsContent.types'
import { WorkcentreRouting } from 'Features/NewPlanTemplate/WorkcentreSettings/WorkcentreRouting/WorkcentreRouting.view'
import { ParametersView } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/Parameters.View'
import { SalesHorizonView } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/SalesHorizon.View'
import { Box } from '@material-ui/core'
import { useStyles } from 'Features/NewPlanTemplate/WorkcentreSettings/WorkcentreSettings.style'
export const ContentView = ({
  step,
  dropdownOptions,
  batchGroupingCodes,
}: ContentViewProps) => {
  const classes = useStyles()

  switch (step) {
    case 2:
      return <WorkcentreRouting />
    case 1:
      return (
        <ParametersView
          dropdownOptions={dropdownOptions}
          batchGroupingCodes={batchGroupingCodes}
        />
      )
    case 0:
      return <SalesHorizonView />
    default:
      return (
        <>
          <Box>No Content</Box>
        </>
      )
  }
}
