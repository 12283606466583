import { Table } from 'supplyvue-ui'
import { Column } from 'react-table'
import {
  UtilisationDriverTableProps,
  UtilisationDriverColumn,
} from './UtilisationDriver.types'
import { Loader } from 'Common/Loader/Loader'

export const UtilisationDriverTable = ({
  data,
  isLoading,
}: UtilisationDriverTableProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 300
    const availableHeight = window.innerHeight - 690
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  const columns: Column<UtilisationDriverColumn>[] = [
    {
      Header: 'Scenario',
      accessor: 'scenario',
      disableFilters: true,
      disableSortBy: true,
      columnWidth: 120,
    },
    {
      Header: 'Average weekly volume',
      accessor: 'averageWeeklyVolume',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      columnWidth: 120,
    },
    {
      Header: 'Total products',
      accessor: 'totalProducts',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      columnWidth: 100,
    },
    {
      Header: 'FOC count',
      accessor: 'FOCCount',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      columnWidth: 140,
    },
    {
      Header: 'Average run rate',
      accessor: 'averageRunRate',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Average c/o time',
      accessor: 'averageCOTime',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Average c/o per week',
      accessor: 'averageCOPerWeek',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      columnWidth: 120,
    },
    {
      Header: 'Available hours',
      accessor: 'availableHours',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
  ]
  if (isLoading) {
    return <Loader />
  }
  return (
    <Table
      data={data}
      height={getTableHeight()}
      columns={columns}
      cellPadding="5px 5px"
    />
  )
}
