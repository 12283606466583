import { Box, Typography } from '@material-ui/core'
import { AnalysisSummaryTable } from 'Features/InventoryAnalysis/AnalysisSummary/AnalysisSummary.table'
import { useStyles } from './SkuGroupInventoryAnalysis.styles'
import { SkuGroupInventoryAnalysisTablesPropsTypes } from './SkuGroupInventoryAnalysis.types'
import { SkuGroupInventoryAnalysisInfoBox } from './SkuGroupInventoryAnalysisInfoBox.view'
export const SkuGroupInventoryAnalysisTables = ({
  summaryData,
  reportingUnitOfMeasure,
}: SkuGroupInventoryAnalysisTablesPropsTypes) => {
  const classes = useStyles()
  return (
    <>
      <Box marginTop={4} className={classes.tableContainer}>
        <Box className={`${classes.clickableBox}  ${classes.summaryBox}`}>
          <Typography variant="h4">By forecast error</Typography>
        </Box>
        <AnalysisSummaryTable
          data={summaryData.forecastError}
          reportingUnitOfMeasure={reportingUnitOfMeasure}
        />
        <SkuGroupInventoryAnalysisInfoBox label="Forecast accuracy" data="" />
      </Box>

      <Box marginTop={4} className={classes.tableContainer}>
        <Box className={`${classes.clickableBox}  ${classes.summaryBox}`}>
          <Typography variant="h4">By demand variability</Typography>
        </Box>
        <AnalysisSummaryTable
          data={summaryData.demandVariability}
          reportingUnitOfMeasure={reportingUnitOfMeasure}
        />
        <SkuGroupInventoryAnalysisInfoBox label="Demand variability" data="" />
      </Box>

      <Box marginTop={4} className={classes.tableContainer}>
        <Box className={`${classes.clickableBox}  ${classes.summaryBox}`}>
          <Typography variant="h4">Hybrid</Typography>
        </Box>
        <AnalysisSummaryTable
          data={summaryData.hybrid}
          reportingUnitOfMeasure={reportingUnitOfMeasure}
        />
        <SkuGroupInventoryAnalysisInfoBox label="Hybrid" data="" />
      </Box>

      <Box marginTop={4} className={classes.tableContainer}>
        <Box className={`${classes.clickableBox}  ${classes.summaryBox}`}>
          <Typography variant="h4">Baseline</Typography>
        </Box>
        <AnalysisSummaryTable
          data={summaryData.baseline}
          reportingUnitOfMeasure={reportingUnitOfMeasure}
        />
        <SkuGroupInventoryAnalysisInfoBox label="Unused inventory" data="" />
      </Box>
    </>
  )
}
