import { useMutation, useQuery, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import { BulkUpdate } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/BulkUpdate/BulkUpdate.types'
import { WorkcentreSettings } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/SalesHorizon.types'

const updateGroupSettings = async (updateData: BulkUpdate) => {
  const data = await request({
    url: `/plan-templates/${updateData.planTemplateId}/bulk-settings`,
    method: 'put',
    data: updateData,
  })
  return data
}

export const useUpdateGroupSettings = (
  onSuccess: (updateData: WorkcentreSettings[]) => void,
  onError: () => void
) => {
  const queryClient = useQueryClient()
  return useMutation(updateGroupSettings, {
    onSuccess: async ({ data }, variables) => {
      await queryClient.invalidateQueries([
        'workcentre-settings-all',
        variables.snapshotId,
        variables.projectId,
        variables.planTemplateId,
      ])
      onSuccess(data?.data?.settings)
    },
    onError: () => {
      onError()
    },
  })
}
