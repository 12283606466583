import { Typography } from '@material-ui/core'
import { useMemo } from 'react'
import { Column, TableInstance } from 'react-table'

import {
  Table,
  ToggleButton,
  ColumnFilter,
  multiSelectFilter,
} from 'supplyvue-ui'
import {
  ReplenishmentSiteParamsTableData,
  SiteParameterTableProps,
} from './SiteParameterTable.types'

const calculateTableHeight = (): number => {
  return window.innerHeight - 550
}

export const SiteParameterTable = ({
  data,
  handleToggleButtonChange,
  disabledRows,
  getFilteredRows,
  updatingRowKeys,
  updateTableData,
  updateEditedCellValue,
  loadingStatus,
}: SiteParameterTableProps) => {
  const handleValidation = (value: string | number) => {
    const VALID_NUMBER_PATTERN = /^[+]?((\.\d{0,1})|(\d+(\.\d{0,1})?)|(\d+\.))$/
    const isValid = value.toString().match(VALID_NUMBER_PATTERN)
    return isValid ? '' : 'error'
  }

  const updateData = (
    rowId: string,
    columnId: string,
    value: string | number
  ) => {
    let skuGroupCode = null
    const updatedData = data.map((row: ReplenishmentSiteParamsTableData, i) => {
      if (i === parseInt(rowId)) {
        skuGroupCode = row.skuGroupCode
        return {
          ...row,
          [columnId]: parseFloat(value as string),
        }
      } else return row
    })
    updateTableData(updatedData)
    updateEditedCellValue(skuGroupCode, columnId, parseFloat(value as string))
  }

  const columns: Column<ReplenishmentSiteParamsTableData>[] = useMemo(
    // CHANGED FILTERS
    () => [
      {
        columnWidth: 100,
        Header: 'SKU group code',
        accessor: 'skuGroupCode',
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 100,
        Header: 'Work centre',
        accessor: 'workcentre',
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 100,
        Header: 'Primary Work centre',
        accessor: 'primaryWorkcentreCode',
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 70,
        Header: 'Supply type',
        accessor: 'supplyType',
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 80,
        Header: 'Replenishment type',
        accessor: 'replenishmentType',
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 80,
        Header: 'Segment',
        accessor: 'segment',
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 80,
        Header: 'Planning period',
        accessor: 'planningPeriod',
        numeric: true,
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 100,
        Header: 'Deployment lead time (days)',
        accessor: 'deploymentLeadTime',
        numeric: true,
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 80,
        Header: 'Replenishment cycle (weeks)',
        accessor: 'replenishmentCycle',
        numeric: true,
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 50,
        Header: 'MOQ',
        accessor: 'minimumOrderQuantity',
        numeric: true,
        Filter: ColumnFilter,
        filter: multiSelectFilter,

      },
      {
        columnWidth: 50,
        Header: 'MOI',
        accessor: 'minimumOrderIncrement',
        numeric: true,
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 50,
        Header: 'Service level',
        accessor: 'serviceLevel',
        numeric: true,
        Filter: ColumnFilter,
        filter: multiSelectFilter,
      },
      {
        columnWidth: 10,
        Header: 'Stocked',
        accessor: 'include',
        Filter: ColumnFilter,
        filter: multiSelectFilter,
        disableSortBy: true,
        Cell: (props: TableInstance) => {
          const isDisabled = !!updatingRowKeys.find(
            (item) => item === props.row.original.skuGroupKey
          )

          return (
            <ToggleButton
              buttonOne={{ label: 'Y', value: true, tooltipText: 'Yes' }}
              buttonTwo={{ label: 'N', value: false, tooltipText: 'No' }}
              value={!!props.row.original.include}
              handleChange={(event, value) =>
                !!handleToggleButtonChange &&
                handleToggleButtonChange(
                  event,
                  value,
                  props.row.original.skuGroupKey
                )
              }
              isDisabled={isDisabled}
            />
          )
        },
      },
    ],
    []
  )

  return (
    <>
      {data.length ? (
        <Table
          key={data.length}
          data={data}
          columns={columns}
          stickyHeader={true}
          height={calculateTableHeight()}
          shouldIconsDisplayAtBottom={true}
          disabledRows={disabledRows}
          headerHeight={70}
          getFilteredRows={getFilteredRows}
          autoResetFilters={false}
          handleValidation={handleValidation}
          tableCellLoadingStatus={loadingStatus}
          updateData={updateData}
        />
      ) : (
        <Typography variant="body1">
          This site doesn&apos;t have any SKUGroups
        </Typography>
      )}
    </>
  )
}
