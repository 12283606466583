import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: '15px',
  },
  drawerContainer: {
    width: '750px',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
  },
  progress: {
    marginRight: '8px',
  },
  modal: {
    background: theme.palette.background.grey,
  },
  divider: {
    height: '2px',
    background: '#006286',
    width: '100%',
    marginTop: '1rem',
  },
}))
