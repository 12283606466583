import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
  },
  row: {
    display: 'flex',
    marginBottom: '24px',
    justifyContent: 'space-between',
  },
  downloadWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '16px',
  },
  downloadButton: {
    backgroundColor: theme.palette.common.white,
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  editButton: {
    backgroundColor: theme.palette.common.white,
    marginRight: '16px',
  },
  drawerControl: {
    padding: '16px',
    paddingBottom: '0px',
    float: 'right',
  },
  iconButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
  },
  segmentAnalysisButton: {
    marginRight: '16px',
  },
  tabs: { borderBottom: `1px solid ${theme.palette.borders.grey}` },
  activeTab: { backgroundColor: theme.palette.background.paper },
}))
