import { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Divider,
  Popover,
  Typography,
} from '@material-ui/core'
import { Button } from 'supplyvue-ui'
import { useDropzone } from 'react-dropzone'
import { useStyles } from './InstructionsCard.styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import { MAX_FILE_SIZE } from 'Common/Constants/Constants'
import { toast } from 'react-hot-toast'
import { InstructionsCardViewProps } from './InstructionsCard.types'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

export const InstructionsCardView = ({
  isChangeoverDataLoaded,
  handleDownloadFile,
  handleUploadFile,
  isUploadingFile,
}: InstructionsCardViewProps) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: '.csv',
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
  })
  const classes = useStyles({ isChangeoverDataLoaded })
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'snapshot-popover' : undefined

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if (fileRejections.length) {
      toast.error('File size cannot exceed 50MB')
    } else if (acceptedFiles.length) {
      handleUploadFile(acceptedFiles[0])
    }
  }, [acceptedFiles, fileRejections])

  const InstructionsCardDetails = () => (
    <Box className={classes.instructionWrapper}>
      <Typography variant="h4">Bulk update instructions</Typography>
      <Box className={classes.instruction}>
        <ChevronRightIcon />
        <Typography variant="body1">
          Download the comma-separated file
        </Typography>
      </Box>
      <Box className={classes.instruction}>
        <ChevronRightIcon />
        <Typography variant="body1">
          Enter or update the changeover time and changeover cost values and
          save
        </Typography>
      </Box>
      <Box className={classes.instruction}>
        <ChevronRightIcon />
        <Typography variant="body1">Upload the modified file</Typography>
      </Box>
      <Box className={classes.instruction}>
        <ChevronRightIcon />
        <Typography variant="body1">
          Please do not add or remove rows or columns from the downloaded file
        </Typography>
      </Box>
    </Box>
  )

  return (
    <Box className={classes.wrapper}>
      {!isChangeoverDataLoaded && <InstructionsCardDetails />}
      <Box display="flex">
        <Divider orientation="vertical" flexItem />
        {isChangeoverDataLoaded && (
          <Button
            className={classes.textbutton}
            color="primary"
            startIcon={<InfoOutlinedIcon />}
            onClick={handlePopoverOpen}
          />
        )}
        <Button
          className={classes.buttonMargin}
          variant="outlined"
          startIcon={<GetAppIcon />}
          onClick={handleDownloadFile}
        >
          Download CSV file
        </Button>
        <Box component="span" {...getRootProps()}>
          <input {...getInputProps()} />
          <Button
            variant="outlined"
            startIcon={
              isUploadingFile ? <CircularProgress size={18} /> : <PublishIcon />
            }
          >
            Upload CSV file
          </Button>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <InstructionsCardDetails />
      </Popover>
    </Box>
  )
}
