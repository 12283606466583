import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

export const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    fontWeight: 600,
  },
  icon: {
    width: '17px',
    height: '17px',
  },
  capacity: {
    fontSize: '14px',
    marginRight: '20px',
  },
  configureButton: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  runAllButton: {
    display: 'flex',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '5px',
  },
  exportModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 175,
    width: 500,
  },
  inputData: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    gap: '15px',
  },
})
