import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '16px 36px',
    boxShadow: '0 1px 6px 1px rgba(197,197,197,0.5)',
    borderRadius: 0,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'none',
  },
}))
