import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useStyles, autocompleteStyles } from './ScenarioFilters.styles'
import {
  ScenarioFiltersProps,
  SourceSiteOption,
  WorkCenterOption,
} from './ScenarioFilters.types'

export const ScenarioFiltersView = ({
  sourceSiteList,
  workcenterList,
  selectedSourceSite,
  selectedWorkcenter,
  handleSourceSiteChange,
  handleWorkcenterChange,
}: ScenarioFiltersProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.filtersWrapper}>
      <Box mr="32px">
        <Autocomplete
          size="small"
          options={sourceSiteList}
          getOptionLabel={(option: SourceSiteOption) => option.label}
          value={selectedSourceSite}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select source site"
              variant="outlined"
            />
          )}
          style={autocompleteStyles}
          onChange={handleSourceSiteChange}
        />
      </Box>
      <Autocomplete
        size="small"
        options={workcenterList}
        getOptionLabel={(option: WorkCenterOption) => option.code}
        value={selectedWorkcenter}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select work centre"
            variant="outlined"
          />
        )}
        style={autocompleteStyles}
        onChange={handleWorkcenterChange}
        disabled={!selectedSourceSite}
      />
    </Box>
  )
}
