import { FormControlLabel as MuiFormControlLabel } from '@material-ui/core'
import { FormControlLabelProps } from './FormControlLabel.types'

export const FormControlLabel = ({
  label,
  control,
  ...otherProps
}: FormControlLabelProps) => {
  return (
    <MuiFormControlLabel
      aria-label={label}
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      control={control}
      label={label}
      {...otherProps}
    />
  )
}
