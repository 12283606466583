import { Box } from '@material-ui/core'
import { Page } from 'Common/Page/Page'
import { Button, PageHeader } from 'supplyvue-ui'
import {
  useReplenishmentPolicyInputs,
  useUpdateReplenishmentPolicyInputs,
} from './ReplenishmentPolicyInputs.data'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { useParams, useHistory } from 'react-router-dom'
import { ReplenishmentPolicyInputsTable } from './ReplenishmentPolicyInputs.table'
import {
  ReplenishmentPolicyInputsParamsType,
  ReplenishmentPolicyInputsColumns,
  LoadingStatusType,
} from './ReplenishmentPolicyInputs.types'
import { useEffect, useState } from 'react'
import { Loader } from 'Common/Loader/Loader'
import { toast } from 'react-hot-toast'

export const ReplenishmentPolicyInputsPage = () => {
  const {
    projectId,
    scenarioId,
    replenishmentPolicyId,
  } = useParams<ReplenishmentPolicyInputsParamsType>()
  useBreadcrumbsData({
    project_id: projectId,
    scenario_id: scenarioId,
    replenishment_policy_id: replenishmentPolicyId,
  })
  const history = useHistory()

  const InitialLoadingStatus = {
    isLoading: false,
    columnId: '',
    siteKey: null,
  }
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType>(
    InitialLoadingStatus
  )

  const { data, isLoading } = useReplenishmentPolicyInputs(
    replenishmentPolicyId
  )

  const onSuccess = (message: string) => {
    toast.success(message)
    setLoadingStatus(InitialLoadingStatus)
  }

  const onError = (message: string) => {
    toast.error(message)
    setLoadingStatus(InitialLoadingStatus)
  }

  const { mutate: updateCellData } = useUpdateReplenishmentPolicyInputs({
    onSuccess,
    onError,
  })

  const [tableData, setTableData] = useState<
    ReplenishmentPolicyInputsColumns[]
  >(data)

  const handleNetWorkReplenishmentClick = () => {
    history.push(
      `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings`
    )
  }

  const updateTableData = (tableData: ReplenishmentPolicyInputsColumns[]) => {
    setTableData(tableData)
  }
  const updateEditedCellValue = (
    siteKey: number | null,
    columnId: string,
    value: number
  ) => {
    setLoadingStatus({ columnId: columnId, isLoading: true, siteKey })
    updateCellData({
      replenishmentPolicyId: replenishmentPolicyId,
      data: { siteKey: siteKey as number, [columnId]: value },
    })
  }

  useEffect(() => {
    setTableData(data)
  }, [data])

  if (isLoading) {
    return <Loader />
  }
  return (
    <Page>
      <Box display="flex" flexDirection="column" flex={1}>
        <PageHeader title="Network capacity settings" />
        <Box>
          <ReplenishmentPolicyInputsTable
            data={tableData ?? data}
            isLoading={isLoading}
            updateTableData={updateTableData}
            updateEditedCellValue={updateEditedCellValue}
            loadingStatus={loadingStatus}
          ></ReplenishmentPolicyInputsTable>
        </Box>
        <Box display="flex" justifyContent="flex-end" paddingTop="20px">
          <Button onClick={handleNetWorkReplenishmentClick} variant="contained">
            Network replenishment settings
          </Button>
        </Box>
      </Box>
    </Page>
  )
}
