import { request, requestV2 } from 'Features/Auth/axios-client'
import { useQuery } from 'react-query'
import { format } from 'date-fns'
import {
  AnalysisMethod,
  AnalysisMeasure,
  StockType,
} from './InventoryAnalysis.types'

const fetchAnalysisSummary = async (
  projectId: string,
  replenishmentPolicyId: string,
  baseSourceSiteKey: string,
  siteKey: number,
  replenishmentType: string,
  cycleTime: number | ''
) => {
  const siteKeyQS = `site=${siteKey}`
  const replenishmentTypeQS = replenishmentType
    ? `&replenishment_type_filter=${replenishmentType}`
    : ''
  const replenishmentTCycleQS = cycleTime
    ? `&replenishment_cycle_filter=${cycleTime}`
    : ''
  const queryString = `${siteKeyQS}${replenishmentTypeQS}${replenishmentTCycleQS}`

  const data = await requestV2({
    url: `projects/${projectId}/replenishment-policies/${replenishmentPolicyId}/source-sites/${baseSourceSiteKey}/safety-stock-summary?${queryString}`,
  })
  return data
}

export const useAnalysisSummary = (
  projectId: string,
  replenishmentId: string,
  sourceSiteId: string,
  siteKey: number,
  replenishmentType: string,
  cycleTime: number | ''
) => {
  return useQuery(
    [
      'analysis-summary',
      projectId,
      replenishmentId,
      sourceSiteId,
      siteKey,
      replenishmentType,
      cycleTime,
    ],
    () =>
      fetchAnalysisSummary(
        projectId,
        replenishmentId,
        sourceSiteId,
        siteKey,
        replenishmentType,
        cycleTime
      ),
    {
      select: (response) => response?.data,
      refetchOnWindowFocus: false,
    }
  )
}

const fetchSegmentGraph = async (
  replenishmentPolicyId: string,
  baseSourceSiteKey: string,
  siteKey: number,
  method: AnalysisMethod,
  measure: AnalysisMeasure,
  stockType: StockType,
  replenishmentType: string,
  cycleTime: number | ''
) => {
  const replenishmentTypeQS = replenishmentType
    ? `&replenishment_type_filter=${replenishmentType}`
    : ''
  const replenishmentTCycleQS = cycleTime
    ? `&replenishment_cycle_filter=${cycleTime}`
    : ''
  const queryString = `${replenishmentTypeQS}${replenishmentTCycleQS}`
  const data = await requestV2({
    url: `/replenishment-policies/${replenishmentPolicyId}/source-sites/${baseSourceSiteKey}/segmentation-graph?site=${siteKey}&method=${method}&measure=${measure}&stock_type=${stockType}${queryString}`,
  })
  return data
}

export const useSegmentGraph = (
  replenishmentId: string,
  siteId: string,
  siteKey: number,
  method: AnalysisMethod,
  measure: AnalysisMeasure,
  stockType: StockType,
  replenishmentType: string,
  cycleTime: number | ''
) => {
  return useQuery(
    [
      'segment-graph',
      replenishmentId,
      siteId,
      siteKey,
      method,
      measure,
      stockType,
      replenishmentType,
      cycleTime,
    ],
    () =>
      fetchSegmentGraph(
        replenishmentId,
        siteId,
        siteKey,
        method,
        measure,
        stockType,
        replenishmentType,
        cycleTime
      ),
    {
      select: (response) => response.data?.data,
      refetchOnWindowFocus: false,
    }
  )
}

//KPI Table

const fetchKPIData = async (
  replenishmentPolicyId: string,
  sourceSiteKey: string,
  site: string,
  method: AnalysisMethod,
  download: string,
  replenishmentType: string,
  cycleTime: number | ''
) => {
  const replenishmentTypeQS = replenishmentType
    ? `&replenishment_type_filter=${replenishmentType}`
    : ''
  const replenishmentTCycleQS = cycleTime
    ? `&replenishment_cycle_filter=${cycleTime}`
    : ''
  const queryString = `${replenishmentTypeQS}${replenishmentTCycleQS}`
  const data = await requestV2({
    url: `/replenishment-policies/${replenishmentPolicyId}/source-sites/${sourceSiteKey}/kpi-table?site=${site}&method=${method}&download=${download}${queryString}`,
  })
  return data
}

export const useKPIData = (
  replenishmentPolicyId: string,
  sourceSiteKey: string,
  site: string,
  method: AnalysisMethod,
  download = 'false',
  replenishmentType: string,
  cycleTime: number | ''
) => {
  return useQuery(
    [
      'kpi-table-data',
      replenishmentPolicyId,
      sourceSiteKey,
      site,
      method,
      download,
      replenishmentType,
      cycleTime,
    ],
    () =>
      fetchKPIData(
        replenishmentPolicyId,
        sourceSiteKey,
        site,
        method,
        download,
        replenishmentType,
        cycleTime
      ),
    {
      select: (response) => response?.data?.data,
      refetchOnWindowFocus: false,
    }
  )
}

const downloadKPIData = async (
  replenishmentPolicyId: string,
  sourceSiteKey: string,
  site: string,
  method: AnalysisMethod,
  download: string,
  replenishmentType: string,
  cycleTime: number | ''
) => {
  const replenishmentTypeQS = replenishmentType
    ? `&replenishment_type_filter=${replenishmentType}`
    : ''
  const replenishmentTCycleQS = cycleTime
    ? `&replenishment_cycle_filter=${cycleTime}`
    : ''
  const queryString = `${replenishmentTypeQS}${replenishmentTCycleQS}`
  const { data } = await request({
    url: `/replenishment-policies/${replenishmentPolicyId}/source-sites/${sourceSiteKey}/kpi-table?site=${site}&method=${method}&download=${download}${queryString}`,
  })
  const fileName = `kpi_table__${format(new Date(), 'yyyyMMdd')}`
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  return data
}

export const useDownloadKPIData = (
  replenishmentPolicyId: string,
  sourceSiteKey: string,
  site: string,
  method: AnalysisMethod,
  download = 'true',
  replenishmentType: string,
  cycleTime: number | ''
) => {
  return useQuery(
    [
      'kpi-table-data-download',
      replenishmentPolicyId,
      sourceSiteKey,
      site,
      method,
      download,
      replenishmentType,
      cycleTime,
    ],
    () =>
      downloadKPIData(
        replenishmentPolicyId,
        sourceSiteKey,
        site,
        method,
        download,
        replenishmentType,
        cycleTime
      ),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      enabled: false,
    }
  )
}

const fetchSiteLabel = async (
  replenishmentPolicyId: string,
  sourceSiteId: number,
  siteId: number
) => {
  const { data } = await request({
    url: `/app-labels?replenishment_policy_id=${replenishmentPolicyId}&base_source_site_id=${sourceSiteId}&site_id=${siteId}`,
  })
  return data
}

export const useSiteLabel = (
  replenishmentPolicyId: string,
  sourceSiteId: number,
  siteId: number
) => {
  return useQuery(
    ['site-label', replenishmentPolicyId, sourceSiteId, siteId],
    () => fetchSiteLabel(replenishmentPolicyId, sourceSiteId, siteId),
    {
      select: (data) => data.data,
    }
  )
}

const fetchDropdownOptionsData = async (snapshotId: string) => {
  const { data } = await request({
    url: `/snapshots/${snapshotId}/mappings?option=replenishmentType`,
  })
  return data
}

export const useDropdownOptionsData = (snapshotId: string) => {
  return useQuery(
    ['dropdown-options', snapshotId],
    () => fetchDropdownOptionsData(snapshotId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
      enabled: Boolean(snapshotId),
    }
  )
}
