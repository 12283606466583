import { CircularProgress } from '@material-ui/core'
import { Page } from 'Common/Page/Page'

export const Loader = () => {
  return (
    <Page center>
      <CircularProgress />
    </Page>
  )
}
