import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  valueText: {
    textAlign: 'right',
  },
  columnContainer: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    height: '100%',
    padding: '0px',
  },
  summaryListContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  verticalDivider: {
    height: '90%',
    margin: '0px 10px',
  },
  listItem: { padding: '8px 0px', alignItems: 'flex-start' },
  tabs: { borderBottom: `1px solid ${theme.palette.borders.grey}` },
  activeTab: { backgroundColor: theme.palette.background.paper },
}))

export const colors = {
  lineColor: '#E7E7E7',
  fontColor: '#000',
  hoverBorderColor: '#000',
  hoverBackgroundColor: '#FFF',
  referenceLineColor: '#006286',
}
