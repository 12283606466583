import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: `1px solid ${theme.palette.borders.grey}`,
    padding: '32px',
    marginBottom: '16px',
  },
  label: {
    marginBottom: '8px',
  },
  input: {
    width: '150px',
  },
  select: {
    minWidth: '150px',
  },
  column: {
    display: 'flex',
    justifyContent: 'center',
    width: '150px',
    marginRight: '64px',
  },
  listWrapper: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    backgroundColor: theme.palette.background.grey,
    borderLeft: `4px solid ${theme.palette.error.light}`,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  listText: {
    fontWeight: 'bold',
  },
  modal: {
    background: theme.palette.background.grey,
  },
  buttonMargin: {
    marginRight: '16px',
  },
}))
