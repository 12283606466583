import { StepContentViewProps } from 'Features/NewPlanTemplate/StepContentView/StepContent.types'
import { NetworkRoutingSettingsView } from 'Features/NewPlanTemplate/NetworkRoutingSettings/NetworkRoutingSettings.View'
import { WorkcentreSettingsView } from 'Features/NewPlanTemplate/WorkcentreSettings/WorkcentreSettings.View'
import { ReplenishmentSettingsView } from 'Features/NewPlanTemplate/ReplenishmentSettings/ReplenishmentSettings.view'
import { CostingView } from 'Features/NewPlanTemplate/Costing/Costing.View'
import { Box } from '@material-ui/core'

export const StepContentView = ({
  step,
  dropdownOptions,
  workcentreSettingsData,
}: StepContentViewProps) => {
  switch (step) {
    case 2:
      return <NetworkRoutingSettingsView />
    case 0:
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <WorkcentreSettingsView
            dropdownOptions={dropdownOptions}
            workcentreSettingsData={workcentreSettingsData}
          />
        </Box>
      )
    case 1:
      return <ReplenishmentSettingsView />
    case 3:
      return <CostingView />
    default:
      return <div>No Content</div>
  }
}
