import { Box, TextField } from '@material-ui/core'
import { DropDown } from 'supplyvue-ui'
import { CYCLE_TIME_MENU_ITEMS } from './InventoryAnalysisFilters.constants'
import { InventoryAnalysisFiltersViewProps } from './InventoryAnalysisFilters.types'
import {
  autocompleteStyles,
  useStyles,
} from './InventoryAnalysisFilters.styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { SiteCodeOption } from '../AnalysisSummary/AnalysisSummary.types'

export const InventoryAnalysisFiltersView = ({
  replenishmentTypeMenuItems,
  replenishmentType,
  cycleTime,
  handleReplenishmentTypeChange,
  handleCycleTimeChange,
  nodes,
  handleSiteCodeChange,
  selectedSiteCode,
  hideCycleTimeDropdown,
  hideReplenishmentTypeDropdown,
  hideSideCodeDropdown,
  hideWorkcenterDropdown,
  handleWorkcentreChange,
  workcenter,
  workcentreOptions,
}: InventoryAnalysisFiltersViewProps) => {
  const classes = useStyles()
  return (
    <Box display="flex" justifyContent="flex-end">
      {!hideSideCodeDropdown && (
        <Box minWidth="190px">
          <Autocomplete
            size="small"
            options={nodes ?? []}
            getOptionLabel={(option: SiteCodeOption) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select sitecode"
                variant="outlined"
              />
            )}
            style={autocompleteStyles}
            onChange={handleSiteCodeChange}
            value={selectedSiteCode}
          />
        </Box>
      )}
      {!hideReplenishmentTypeDropdown && (
        <Box ml={2} minWidth="190px">
          <DropDown
            label="Replenishment type"
            onChange={handleReplenishmentTypeChange}
            value={replenishmentType}
            defaultLabel="Replenishment type"
            menuItems={replenishmentTypeMenuItems}
            className={classes.background}
            fullWidth
          />
        </Box>
      )}
      {!hideCycleTimeDropdown && (
        <Box ml={2} minWidth="120px">
          <DropDown
            label="Cycle time"
            onChange={handleCycleTimeChange}
            value={cycleTime}
            defaultLabel="Cycle time"
            menuItems={CYCLE_TIME_MENU_ITEMS}
            className={classes.background}
            fullWidth
          />
        </Box>
      )}
      {!hideWorkcenterDropdown && (
        <Box ml={2} minWidth="190px">
          <DropDown
            label="Work centre"
            onChange={handleWorkcentreChange}
            value={workcenter}
            defaultLabel="Work centre"
            menuItems={workcentreOptions ?? []}
            className={classes.background}
            fullWidth
          />
        </Box>
      )}
    </Box>
  )
}
