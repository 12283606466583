import { useMemo } from 'react'
import { Table } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { GridRowSelectionModel, GridToolbar } from '@mui/x-data-grid-premium'
import { BatchTableProps } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/MakerBatch/MakerBatch.types'
import { Loader } from 'Common/Loader/Loader'
import { useStyles } from './MakerBatch.styles'

export const BatchTable = ({ data, setSelectedRows }: BatchTableProps) => {
  const classes = useStyles()

  const tableData = data.map((item, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  // @ts-ignore
  const columns: GridColDefPremium[] | any[] = useMemo(() => {
    if (!data) {
      return []
    }

    return [
      {
        headerName: 'Batch group code',
        field: 'batchGroupingCode',
        minWidth: 100,
        type: 'string',
        flex: 1,
      },
      {
        headerName: 'Workcentre',
        field: 'workcentreCode',
        minWidth: 100,
        type: 'string',
        flex: 1,
      },
      {
        headerName: 'Source Site',
        field: 'sourceSiteCode',
        minWidth: 100,
        type: 'string',
        flex: 1,
      },
      {
        headerName: 'Replenishment type',
        field: 'replenishmentType',
        minWidth: 100,
        type: 'string',
        flex: 1,
      },
      {
        headerName: 'Sales rate',
        field: 'salesRate',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Run rate',
        field: 'runRate',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'UoM',
        field: 'unitOfMeasure',
        minWidth: 100,
        type: 'string',
        flex: 1,
      },
      {
        headerName: 'Minimum batch size',
        field: 'minimumBatchSize',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Minimum batch increment',
        field: 'minimumBatchSizeIncrement',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Min cycle',
        field: 'minimumCycle',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Max cycle',
        field: 'maximumCycle',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
    ]
  }, [data])

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(tableData.filter((row) => selectedIds.has(row.id)))
  }

  if (!data) {
    return <Loader />
  }

  return (
    <div className={classes.tableContainer}>
      <Table
        rows={tableData}
        checkboxSelection
        columns={columns}
        showCellVerticalBorder
        showColumnVerticalBorder
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
        pagination
        pageSizeOptions={[10, 20, 30, 50]}
        experimentalFeatures={{ lazyLoading: true }}
      />
    </div>
  )
}
