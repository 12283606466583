import { Box } from '@material-ui/core'
import { format } from 'date-fns'
import { ErrorFallbackCard } from 'supplyvue-ui'

const Message = () => {
  return (
    <>
      Apologies, SupplyVue has encountered an error. Please try returning to the{' '}
      <Box
        component="a"
        onClick={() => (window.location.href = window.location.origin)}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          color: '#006286',
          fontWeight: 'bold',
        }}
      >
        application home page
      </Box>
      . If the error persists, please contact your SupplyVue administrator
      quoting the information below.
    </>
  )
}

export const ErrorFallback = () => {
  return (
    <Box
      component="main"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
    >
      <Box width="70%" m="auto">
        <ErrorFallbackCard
          title="Unexpected Error"
          message={<Message />}
          time={format(new Date(), 'dd.MM.yyyy - HH:mm')}
          errorCodeLabel="Error code"
          errorCodeValue="TBD"
          errorDescriptionLabel="Error description"
          errorDescriptionValue="To be tied to error"
        />
      </Box>
    </Box>
  )
}
