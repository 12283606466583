import { SkuGroupInventoryAnalysisDropdownsTypes } from './SkuGroupInventoryAnalysis.types'
import { DropDown } from 'supplyvue-ui'
import {
  autocompleteStyles,
  useStyles,
} from './SkuGroupInventoryAnalysis.styles'
import { ChangeEvent } from 'react'
import { Box, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { SiteCodeOption } from 'Features/ReplenishmentPolicy/ReplenishmentPolicy.types'
export const SkuGroupInventoryAnalysisDropdowns = ({
  skuGroupOptions,
  sourceSiteCodeOptions,
  replenishmentSiteCodeOptions,
  skuGroupValue,
  baseSourceSiteValue,
  replenishmentSiteValue,
  handleSkuChange,
  handleReplenishmentSiteChange,
  handleSourceSiteChange,
}: SkuGroupInventoryAnalysisDropdownsTypes) => {
  const classes = useStyles()
  return (
    <Box display="flex">
      <Box width="33%" display="flex" alignItems="center">
        <Box mr={2}>
          <Typography variant="h4">Base source site code</Typography>
        </Box>
        <Box>
          <Autocomplete
            size="small"
            options={sourceSiteCodeOptions ?? []}
            getOptionLabel={(option: SiteCodeOption) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select sitecode"
                variant="outlined"
              />
            )}
            style={autocompleteStyles}
            onChange={(
              event: ChangeEvent<Record<string, unknown>>,
              value: SiteCodeOption | null
            ) => handleSourceSiteChange(value)}
            value={baseSourceSiteValue}
          />
        </Box>
      </Box>
      <Box width="33%" display="flex" alignItems="center">
        <Box mr={2}>
          <Typography variant="h4">Replenishment site code</Typography>
        </Box>
        <Box>
          <Autocomplete
            size="small"
            options={replenishmentSiteCodeOptions ?? []}
            getOptionLabel={(option: SiteCodeOption) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select replenishment sitecode"
                variant="outlined"
              />
            )}
            style={autocompleteStyles}
            onChange={(
              event: ChangeEvent<Record<string, unknown>>,
              value: SiteCodeOption | null
            ) => handleReplenishmentSiteChange(value)}
            value={replenishmentSiteValue}
          />
        </Box>
      </Box>
      <Box width="33%" display="flex" alignItems="center">
        <Box>
          <Typography variant="h4">SKU group</Typography>
        </Box>
        <Box>
          <DropDown
            className={classes.select}
            label="SKU Group"
            defaultLabel="Select a SKU group"
            menuItems={skuGroupOptions}
            value={skuGroupValue}
            onChange={(
              e: ChangeEvent<{
                name?: string | undefined
                value: unknown
              }>
            ) => handleSkuChange(e.target.value as number)}
          />
        </Box>
      </Box>
    </Box>
  )
}
