import { Box, Typography, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { autocompleteStyles } from '../ReplenishmentPolicy.styles'
import {
  SiteCodeOption,
  WorkCenterOption,
  SourceSiteOption,
  ReplenishmentPolicyPageParams,
} from '../ReplenishmentPolicy.types'
import {
  useSourceSitesData,
  useSiteConnectionsDataFake,
} from '../ReplenishmentPolicy.data'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { SiteParameterDropdownParamsTypes } from './SiteParameters.types'
// This component needs refactoring.
export const SiteParametersDropdowns = ({
  handleSiteChange,
  handleSourceSiteChange,
  handleWorkcenterChange,
  selectedSite,
  sourceSiteId,
}: SiteParameterDropdownParamsTypes) => {
  const {
    projectId,
    scenarioId,
    replenishmentPolicyId,
    workcentreId,
  } = useParams<ReplenishmentPolicyPageParams>()

  const [
    selectedSourceSite,
    setSelectedSourceSite,
  ] = useState<SourceSiteOption | null>(null)
  const [workcenters, setWorkcenters] = useState<WorkCenterOption[] | []>([])
  const [
    selectedWorkcenter,
    setSelectedWorkcenter,
  ] = useState<WorkCenterOption | null>(null)

  const [
    selectedSiteCode,
    setSelectedSiteCode,
  ] = useState<SiteCodeOption | null>(null)

  const { data: sourceSites } = useSourceSitesData(projectId, scenarioId)

  const {
    data: siteConnections,
    refetch: refetchSiteConnections,
  } = useSiteConnectionsDataFake(
    projectId,
    sourceSites?.snapshotId,
    scenarioId,
    replenishmentPolicyId,
    selectedSourceSite?.value,
    selectedWorkcenter?.key,
    () => null,
    () => null
  )

  useEffect(() => {
    if (sourceSites) {
      const selectedSourceSiteFromSummaryPage = sourceSites.baseSourceSites.filter(
        (sourceSite: SourceSiteOption) =>
          sourceSite.value === parseInt(sourceSiteId?.toString())
      )[0]
      setSelectedSourceSite(selectedSourceSiteFromSummaryPage)
      if (selectedSourceSiteFromSummaryPage) {
        const selectedWorkcentreFromSummaryPage = selectedSourceSiteFromSummaryPage.workcentres.filter(
          (workcenter: WorkCenterOption) =>
            workcenter.key === parseInt(workcentreId)
        )[0]
        setWorkcenters(selectedSourceSiteFromSummaryPage.workcentres)
        setSelectedWorkcenter(selectedWorkcentreFromSummaryPage)
      }
    }
  }, [sourceSites, sourceSiteId, workcentreId])

  useEffect(() => {
    if (selectedWorkcenter?.key || selectedSourceSite?.value) {
      refetchSiteConnections()
    }
  }, [
    selectedWorkcenter?.key,
    selectedSourceSite?.value,
    refetchSiteConnections,
    //selectedSiteCode,
  ])

  useEffect(() => {
    const defaultSelected = siteConnections?.data?.nodes.filter(
      (connection: SiteCodeOption) => {
        return connection.label === selectedSite?.label
      }
    )

    if (defaultSelected?.length > 0) {
      setSelectedSiteCode(defaultSelected[0])
    }
  }, [siteConnections])

  // useEffect(() => {
  //   // Calculating target
  //   if (selectedSiteCode) {
  //     const { id } = selectedSiteCode
  //     const connection = siteConnections?.data.connections.filter(
  //       (connection: SiteConnectionEdge) => parseInt(connection.source) == id
  //     )[0]
  //     setCurrentTarget(parseInt(connection?.target ?? id))
  //   }
  // }, [selectedSiteCode])
  const handleSourceSiteCodeChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    option: SourceSiteOption | null
  ) => {
    if (option?.value) {
      setSelectedSourceSite(option)
      setWorkcenters(option.workcentres)
      setSelectedSiteCode(null)
      handleSourceSiteChange(option.value)
    } else {
      setSelectedSourceSite(null)
      setSelectedWorkcenter(null)
      setSelectedSiteCode(null)
      handleSourceSiteChange(null)
    }
  }

  const handleWorkcenterCodeChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    option: WorkCenterOption | null
  ) => {
    if (option) {
      setSelectedWorkcenter(option)
      handleWorkcenterChange(option.key)
    } else {
      setSelectedWorkcenter(null)
      setSelectedSiteCode(null)
      handleWorkcenterChange(null)
    }
  }

  const handleSiteCodeChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    option: SiteCodeOption | null
  ) => {
    if (option) {
      setSelectedSiteCode(option)
      handleSiteChange(option)
    } else {
      setSelectedWorkcenter(selectedWorkcenter)
      setSelectedSiteCode(null)
      handleSiteChange(null)
    }
  }
  return (
    <>
      <Box mr="24px">
        <Typography variant="body1" component="span">
          Source site
        </Typography>
      </Box>
      <Box mr="32px">
        <Autocomplete
          size="small"
          options={sourceSites?.baseSourceSites}
          getOptionLabel={(option: SourceSiteOption) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select source site"
              variant="outlined"
            />
          )}
          style={autocompleteStyles}
          onChange={handleSourceSiteCodeChange}
          value={selectedSourceSite}
        />
      </Box>
      <Box mr="32px">
        <Autocomplete
          size="small"
          options={workcenters}
          getOptionLabel={(option: WorkCenterOption) => option.code}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select work centre"
              variant="outlined"
            />
          )}
          style={autocompleteStyles}
          onChange={handleWorkcenterCodeChange}
        />
      </Box>
      <Box>
        <Autocomplete
          size="small"
          options={siteConnections?.data?.nodes ?? []}
          getOptionLabel={(option: SiteCodeOption) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select sitecode"
              variant="outlined"
            />
          )}
          style={autocompleteStyles}
          onChange={handleSiteCodeChange}
          value={selectedSiteCode}
        />
      </Box>
    </>
  )
}
