import React, { useMemo, useState } from 'react'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { Loader } from 'Common/Loader/Loader'
import { useStyles } from './PlanTemplateGeneration.styles'
import { SummaryList } from './Summary/SummaryList.view'
import {
  WeeklyData,
  PlanTemplateGeneratorProps,
  PlanTemplateGererationActionType,
} from './PlanTemplateGeneration.types'
import { WorkcentreConstraintsView } from './WorkcentreConstraints/WorkcentreConstraints.view'
import { CycleSpeedView } from './CycleSpeed/CycleSpeed.view'
import { SummaryGraph } from './Summary/Summary.graph'
import { ValidationListView } from './ValidationList/ValidationList.view'
import { isInteger } from 'Common/Utils/utils'
import { SummaryItem } from './Summary/Summary.types'
import { PlanDetailsView } from './PlanDetails/PlanDetails.view'
import { FieldName } from './WorkcentreConstraints/WorkcentreConstraints.types'
import {
  SET_BUFFER,
  SET_OPTIMIZATION,
  SET_WORKCENTRE_CAPACITY,
  WORKCENTRE_CAPACITY,
  BUFFER,
  COMBINED_BUFFER,
  COMBINED_WORKCENTRE_CAPACITY,
  SET_COMBINED_BUFFER,
  SET_COMBINED_WORKCENTRE_CAPACITY,
} from './PlanTemplateGeneration.constants'
import { SummaryGraphToggleView } from './Summary/SummaryGraphToggle.view'
import { SequenceOfEventsGraph } from './Summary/SequenceOfEvents/SequenceOfEvents.graph'
import { BarChartRounded, InsertChartRounded } from '@material-ui/icons'

export const PlanTemplateGenerator = ({
  planTemplateGenerationState,
  updatePlanTemplateGenerationState,
  handleCalculatePlanTemplate,
  isCalculating,
  handlePlanDetailsClick,
  planTemplateBasedOn,
  graphData,
}: PlanTemplateGeneratorProps) => {
  const classes = useStyles()

  const {
    buffer,
    combinedBuffer,
    defaultWorkCenterCapacity,
    workcentreCapacity,
    combinedWorkcentreCapacity,
    optimization,
    isTemplateGenerating,
    planTemplateGererationValidations,
    isInitialCycleSpeedAndSummaryDataFetched,
    isCycleSpeedOrSummaryDataLoading,
    cycleSpeedData,
    summaryData,
  } = planTemplateGenerationState

  const updateInputField = (value: string, fieldName: FieldName) => {
    let type = '' as PlanTemplateGererationActionType
    if (fieldName === WORKCENTRE_CAPACITY) {
      type = SET_WORKCENTRE_CAPACITY
    } else if (fieldName === BUFFER) {
      type = SET_BUFFER
    } else if (fieldName === COMBINED_BUFFER) {
      type = SET_COMBINED_BUFFER
    } else if (fieldName === COMBINED_WORKCENTRE_CAPACITY) {
      type = SET_COMBINED_WORKCENTRE_CAPACITY
    }
    updatePlanTemplateGenerationState({
      type: type,
      payload: value,
    })
  }

  const handleOptimizationChange = (
    e: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    updatePlanTemplateGenerationState({
      type: SET_OPTIMIZATION,
      payload:
        newValue === 0
          ? 'constrained'
          : newValue === 1
          ? 'unconstrained'
          : newValue === 2
          ? 'costOptimized'
          : newValue === 3
          ? 'carbonCost'
          : newValue === 4
          ? 'alternativeConstrained'
          : 'alternativeUnconstrained',
    })
  }

  const getAverageUtilisationHours = () => {
    return summaryData?.summary?.filter(
      (summary: SummaryItem) => summary.label === 'Average utilised hours'
    )[0].value
  }

  const summaryGraphXAxisData = useMemo(
    () =>
      summaryData?.graphData?.weeklyData?.map(
        (data: WeeklyData) => data.weekNo
      ),
    [summaryData]
  )

  const summaryGraphYAxisDataOne = useMemo(
    () =>
      summaryData?.graphData?.weeklyData?.map(
        (data: WeeklyData) => data.totalProductionTime
      ),
    [summaryData]
  )

  const summaryGraphYAxisDataTwo = useMemo(
    () =>
      summaryData?.graphData?.weeklyData?.map(
        (data: WeeklyData) => data.totalChangeoverTime
      ),
    [summaryData]
  )

  const summaryGraphYAxisDataThree = useMemo(
    () =>
      summaryData?.graphData?.weeklyData?.map(
        (data: WeeklyData) => data.totalBatchChangeoverTime
      ),
    [summaryData]
  )

  const getAverageUtilisation = () => {
    const utilisation = summaryData?.graphData?.averageUtilisation
    return isInteger(utilisation ?? 0) ? utilisation : utilisation?.toFixed(1)
  }

  const NoData = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography>
        Press the &apos;Calculate&apos; button to generate the plan template
        details
      </Typography>
    </Box>
  )

  const NoGraphData = () => {
    const [isHovered, setIsHovered] = useState(false)

    const handleMouseEnter = () => {
      setIsHovered(true)
    }

    const handleMouseLeave = () => {
      setIsHovered(false)
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        justifyItems="center"
        alignItems="center"
        className={classes.templateBox}
      >
        <Box
          className={classes.innerBox}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            display="flex"
            justifyContent="space-around"
            className={classes.iconBox}
          >
            {isHovered ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                onClick={handleCalculatePlanTemplate}
              >
                <InsertChartRounded className={classes.templateIcon} />
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                onClick={handleCalculatePlanTemplate}
              >
                <BarChartRounded
                  className={classes.templateIcon}
                  color="primary"
                />
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Typography color="primary" variant="h6">
              Run Template
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  const isCycleSpeedOrSummaryDataCurrentlyLoading =
    isInitialCycleSpeedAndSummaryDataFetched && isCycleSpeedOrSummaryDataLoading

  if (
    !isInitialCycleSpeedAndSummaryDataFetched &&
    isCycleSpeedOrSummaryDataLoading
  ) {
    return <Loader />
  }

  return (
    <Box display="flex" flexDirection="column" flex="auto">
      {isTemplateGenerating ? (
        <Box className={classes.templateGeneratingCard}>
          <Box>
            <Loader />
          </Box>
          <Box mb={1}>
            <Typography variant="h2">Calculating plan template</Typography>
          </Box>
          <Typography variant="h4">
            This may take a while. You can return to this screen after a few
            minutes.
          </Typography>
        </Box>
      ) : isInitialCycleSpeedAndSummaryDataFetched ? (
        <>
          <WorkcentreConstraintsView
            title="Calculate plan template"
            buffer={buffer}
            defaultWorkCenterCapacity={defaultWorkCenterCapacity}
            workcentreCapacity={workcentreCapacity}
            updateInputField={updateInputField}
            handleOptimizationChange={handleOptimizationChange}
            tabIndex={
              optimization === 'constrained'
                ? 0
                : optimization === 'unconstrained'
                ? 1
                : optimization === 'costOptimized'
                ? 2
                : 3
            }
            handlePrimaryButtonClick={handleCalculatePlanTemplate}
            isPrimaryButtonDisabled={isCalculating}
            planTemplateBasedOn={planTemplateBasedOn}
            combinedBuffer={combinedBuffer}
            combinedWorkcentreCapacity={combinedWorkcentreCapacity}
          />
          <Grid container spacing={2}>
            <Grid item md={6}>
              {!isCycleSpeedOrSummaryDataCurrentlyLoading && (
                <Paper className={classes.paper}>
                  <SummaryGraphToggleView
                    TotalledGraphView={
                      summaryData?.graphData ? (
                        <SummaryGraph
                          traceOneName={'Total production time'}
                          traceTwoName={'Total changeover time'}
                          traceThreeName={'Total Batch changeover time'}
                          xAxisData={summaryGraphXAxisData ?? []}
                          yAxisDataOne={summaryGraphYAxisDataOne ?? []}
                          yAxisDataTwo={summaryGraphYAxisDataTwo ?? []}
                          yAxisDataThree={summaryGraphYAxisDataThree ?? []}
                          capacityReferenceValue={
                            summaryData?.graphData?.workcentreCapacity
                          }
                          foqAdjustedCapacity={
                            summaryData?.graphData?.foqAdjustedCapacity ?? 0
                          }
                          averageUtilisationPercentage={getAverageUtilisation()}
                          averageUtilisationHours={getAverageUtilisationHours()}
                          tooltipText={summaryData?.graphData?.weeklyData.map(
                            (data: WeeklyData) =>
                              data.percentageOfUtilisation.toFixed(1)
                          )}
                        />
                      ) : (
                        <NoGraphData />
                      )
                    }
                    SequencedGraphView={
                      graphData.length > 0 ? (
                        <SequenceOfEventsGraph
                          graphData={graphData}
                          capacityReferenceValue={
                            summaryData?.graphData?.workcentreCapacity ?? 0
                          }
                          averageUtilisationPercentage={getAverageUtilisation()}
                          averageUtilisationHours={getAverageUtilisationHours()}
                        />
                      ) : (
                        <NoGraphData />
                      )
                    }
                  />
                </Paper>
              )}
            </Grid>

            <Grid item md={6}>
              {!isCycleSpeedOrSummaryDataCurrentlyLoading && (
                <Paper className={classes.paper}>
                  <Box
                    pt="16px"
                    pb="24px"
                    px={3}
                    display="flex"
                    flexDirection="column"
                    height="800px"
                  >
                    <PlanDetailsView
                      handlePlanDetailsClick={handlePlanDetailsClick}
                      isDisabled={
                        cycleSpeedData?.templateGenerationStatus !== 'COMPLETED'
                      }
                    />
                    {!!planTemplateGererationValidations?.length && (
                      <Box>
                        <Typography variant="h3">
                          Invalid plan template inputs
                        </Typography>
                      </Box>
                    )}

                    {!!planTemplateGererationValidations?.length ? (
                      <ValidationListView
                        validations={planTemplateGererationValidations}
                      />
                    ) : Array.isArray(summaryData) &&
                      summaryData?.length === 0 ? (
                      <NoData />
                    ) : (
                      <>
                        <SummaryList
                          summaryListData={summaryData?.summary ?? []}
                        />
                        {!isCycleSpeedOrSummaryDataCurrentlyLoading &&
                          (cycleSpeedData?.templateGenerationStatus ===
                            'UNSTARTED' ||
                            cycleSpeedData?.templateGenerationStatus ===
                              'COMPLETED') &&
                          !Array.isArray(cycleSpeedData?.data) && (
                            <Box className={classes.summaryGraphWrapper}>
                              <CycleSpeedView
                                data={cycleSpeedData?.data}
                                optimization={
                                  cycleSpeedData?.constrained
                                    ? 'constrained'
                                    : 'unconstrained'
                                }
                              />
                            </Box>
                          )}
                      </>
                    )}
                  </Box>
                </Paper>
              )}
            </Grid>
          </Grid>
          {isCycleSpeedOrSummaryDataCurrentlyLoading && <Loader />}
        </>
      ) : (
        <Loader />
      )}
    </Box>
  )
}
