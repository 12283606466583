import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'

import {
  useSnapshotData,
  useImportSnapshotData,
  useUpdateProject,
  useProjectData,
  useWorkcentresData,
  useCreateScenarioData,
  useDeleteScenarioData,
  useUpdateCostOfCapital,
} from './ProjectDetails.data'
import { CONSTANTS } from 'Features/Project/SnapshotModal/SnapshotModal.constants'
import {
  SnapshotDetails,
  ImportStatus,
} from 'Features/Project/SnapshotModal/SnapshotModal.types'
import { Scenario, TemplateSelectionOption } from './ProjectDetails.types'
import {
  getSelectedWorkcentres,
  getSelctedWorkcentresCount,
} from './ProjectDetails.utils'
import { SourceSiteDetails } from 'Features/Project/WorkcentreModal/WorkcentreModal.types'
export const DEFAULT_CREATE_OPTION = ''

export const useProjectDetails = (projectId: string) => {
  /** Begin Project Details */
  const {
    isLoading: isProjectDetailsLoading,
    data: projectDetails,
    isFetching: isProjectDetailsFetching,
  } = useProjectData(projectId)

  /** End Project Details */
  /** Begin Snapshot Details */
  const [isSnapshotModalOpen, setIsSnapshotModalOpen] = useState(false)

  const {
    isLoading: isSnapshotDataLoading,
    isFetching: isSnapshotDataFetching,
    data: snapshotData,
  } = useSnapshotData()

  const [importStatus, setImportStatus] = useState<ImportStatus>(
    CONSTANTS.IMPORT_STATUS.START
  )

  const onSuccess = (message: string) => {
    setImportStatus(CONSTANTS.IMPORT_STATUS.FINISHED)
    handleWorkcentreModalClose()
    handleSnapshotModalClose()
    toast.success(message)
  }

  const onError = (message: string) => {
    if (!projectDetails?.snapshotDetails)
      setImportStatus(CONSTANTS.IMPORT_STATUS.START)
    else setImportStatus(CONSTANTS.IMPORT_STATUS.FINISHED)
    toast.error(message)
  }

  const { mutate: importSnapshot } = useImportSnapshotData({
    onSuccess,
    onError,
  })

  const { mutate: updateProject, isLoading: isProjectUpdating } =
    useUpdateProject({
      onSuccess,
      onError,
    })

  const handleSnaphotModalOkButtonClick = (
    snapshotName: string,
    endDate: Date,
    snapshotLength: number,
    selectedSnapshot: SnapshotDetails,
    projectUserId: string
  ) => {
    if (importStatus === CONSTANTS.IMPORT_STATUS.START) {
      setImportStatus(CONSTANTS.IMPORT_STATUS.INPROGRESS)
      selectedSnapshot
        ? updateProject({
            projectId: projectId,
            projectUserId: projectUserId,
            snapshotId: selectedSnapshot.snapshotId,
            snapshotUserId: selectedSnapshot.snapshotUserId,
          })
        : importSnapshot({
            snapshotName,
            endDate,
            snapshotLength,
            projectId,
            projectUserId,
          })
    } else if (importStatus === CONSTANTS.IMPORT_STATUS.FINISHED) {
      handleSnapshotModalClose()
    }
  }

  const handleSnapshotModalClose = () => {
    setIsSnapshotModalOpen(false)
  }

  /** End Snapshot Details */

  /** Begin Cost of capital  */
  const { isLoading: isCostOfCapitalUpdating, mutate: updateCostOfCapital } =
    useUpdateCostOfCapital({
      onSuccess: (message) => {
        toast.success(message)
      },
      onError: (message) => {
        toast.error(message)
      },
    })

  const handleCostOfCapitalUpdate = (
    snapshotId: string,
    costOfCapital: number
  ) => {
    updateCostOfCapital({
      projectId: projectId,
      snapshotId,
      costOfCapital,
    })
  }
  /** End Cost of capital  */

  /** Begin Workcentre Details */

  const [isWorkcentreModalOpen, setIsWorkcentreModalOpen] = useState(false)
  const projectUserId = projectDetails?.projectUserId
  const {
    isLoading: isWorkcentreDataLoading,
    data: workcentreData,
    refetch: refetchWorkcentreData,
  } = useWorkcentresData(
    projectUserId,
    projectId,
    projectDetails?.snapshotDetails?.snapshotId
  )

  useEffect(() => {
    if (
      projectDetails &&
      workcentreData &&
      projectDetails?.workcentreCount !==
        getSelctedWorkcentresCount(workcentreData)
    ) {
      refetchWorkcentreData()
    }
  }, [projectDetails, workcentreData])

  const handleWorkcentreModalClose = () => {
    setIsWorkcentreModalOpen(false)
  }

  const handleWorkcentreModalOkButtonClick = (
    sourceSiteDetails: SourceSiteDetails[]
  ) => {
    const siteWorkcentres = getSelectedWorkcentres(sourceSiteDetails)
    updateProject({
      projectId,
      projectUserId,
      siteWorkcentres,
    })
  }

  /** End Workcentre Details */

  /** Begin Scenario Details */
  const [scenarioIndex, setScenarioIndex] = useState(0)
  const [scenarioName, setScenarioName] = useState('')

  const [templateSelectionOption, setTemplateSelectionOption] =
    useState<TemplateSelectionOption>({
      option: 0,
      scenarioId: null,
      projectId: null,
    })
  const [errorMessage, setErrorMessage] = useState('')

  const [isCreateScenarioModalOpen, setIsCreateScenarioModalOpen] =
    useState(false)

  const onCreateScenarioSuccess = (message: string) => {
    handleCreateScenarioModalClose()
    toast.success(message)
  }

  const onCreateScenarioError = (message: string) => {
    handleCreateScenarioModalClose()
    toast.error(message)
  }

  const onDeleteScenarioSuccess = (message: string) => {
    handleCreateScenarioModalClose()
    toast.success(message)
  }

  const { isLoading: isCreatingScenario, mutate: createScenario } =
    useCreateScenarioData({ onCreateScenarioSuccess, onCreateScenarioError })

  const { mutate: deleteScenario } = useDeleteScenarioData({
    onDeleteScenarioSuccess,
  })

  const handleCreateScenarioModalClose = () => {
    setIsCreateScenarioModalOpen(false)
    setScenarioName('')
    setErrorMessage('')
    setScenarioIndex(0)
  }

  const handleCreateScenario = () => {
    const { scenariosList } = projectDetails
    if (
      scenariosList.find(
        (scenario: Scenario) =>
          scenario.scenarioName?.toLowerCase() === scenarioName?.toLowerCase()
      )
    ) {
      setErrorMessage('Scenario name already exists')
    } else {
      setErrorMessage('')
      createScenario({
        projectId,
        projectUserId: projectDetails?.projectUserId,
        scenarioName,
        snapshotId: projectDetails?.snapshotDetails?.snapshotId,
        snapshotUserId: projectDetails?.snapshotDetails?.snapshotUserId,
        sequenceId: scenarioIndex,
        templateSelectionOption,
      })
    }
  }

  const handleDeleteScenario = (scenarioId: string) => {
    deleteScenario({
      projectId,
      projectUserId: projectDetails?.projectUserId,
      scenarioId,
    })
  }

  return {
    projectDetails: {
      isLoading: isProjectDetailsLoading,
      isCostOfCapitalLoading: isCostOfCapitalUpdating,
      handleCostOfCapitalUpdate,
      ...projectDetails,
    },

    snapshot: {
      importStatus,
      isLoading: isSnapshotDataLoading || isSnapshotDataFetching,
      data: snapshotData,
      isModalopen: isSnapshotModalOpen,
      setIsModalOpen: setIsSnapshotModalOpen,
      handleModalClose: handleSnapshotModalClose,
      handleOkButtonClick: handleSnaphotModalOkButtonClick,
    },
    workcentre: {
      data: workcentreData || [],
      isUpdating: isProjectUpdating || isProjectDetailsFetching,
      isLoading: isWorkcentreDataLoading,
      isModalopen: isWorkcentreModalOpen,
      setIsModalOpen: setIsWorkcentreModalOpen,
      handleModalClose: handleWorkcentreModalClose,
      handleOkButtonClick: handleWorkcentreModalOkButtonClick,
    },
    scenario: {
      isModalOpen: isCreateScenarioModalOpen,
      setIsModalOpen: setIsCreateScenarioModalOpen,
      handleModalClose: handleCreateScenarioModalClose,
      name: scenarioName,
      setName: setScenarioName,
      templateSelectionOption: templateSelectionOption,
      setTemplateSelectionOption: setTemplateSelectionOption,
      scenarioIndex,
      setScenarioIndex,
      errorMessage,
      handleCreateButtonClick: handleCreateScenario,
      isCreatingScenario,
      handleDeleteScenarioClick: handleDeleteScenario,
    },
  }
}
