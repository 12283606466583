import { MenuItem } from './AnalysisGraph.types'

export const INVENTORY_MEASURE: MenuItem[] = [
  {
    key: 'Inventory volume',
    value: 'volume',
  },
  {
    key: 'Inventory value',
    value: 'value',
  },
  {
    key: 'Inventory duration',
    value: 'duration',
  },
]

export const STOCK_TYPE: MenuItem[] = [
  {
    key: 'BAU stock',
    value: 'BAU_stock',
  },
  {
    key: 'Cycle stock',
    value: 'cycle_stock',
  },
  {
    key: 'Safety stock',
    value: 'safety_stock',
  },
]
