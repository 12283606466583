import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  cycleSpeedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    paddingTop: '20px',
    paddingBottom: '40px',
    width: '140px',
    minWidth: '100px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  paper: {
    height: '455px',
    boxShadow: theme.shadows[2],
  },
}))
