import { Modal, Button } from 'supplyvue-ui'
import { DownloadDataView } from './DownloadData.view'
import { useStyles } from "./DownloadDataModal.styles"
import { Dispatch, SetStateAction } from 'react'
import { DownloadDataModalProps } from "Features/Project/DownloadDataModal/DownloadData.types";

export const DownloadDataModalView = ({
                                        isModalOpen,
                                        handleModalSave,
                                        handleModalCancel,
                                        updateDownloadVersion,
                                        downloadVersion
                                      }: DownloadDataModalProps) => {

  const classes = useStyles()

  const body = (
    <div className={classes.contentWrapper}>
      <DownloadDataView
        updateDownloadVersion={updateDownloadVersion}
        downloadVersion={downloadVersion}
      />
    </div>
  )

  const footer = (
    <>
      <div className={classes.footer}>
        <Button
          onClick={handleModalCancel}
          variant="outlined"
          className={classes.button}
          style={{ marginRight: '5px' }}
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          onClick={handleModalSave}
          variant="contained"
        >
          Download
        </Button>
      </div>
    </>
  )

  return (
    <Modal header="Download Data" open={isModalOpen} handleClose={handleModalCancel} body={body} footer={footer}/>
  )
}

