import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  select: {
    marginLeft: '20px',
    backgroundColor: 'white',
    width: '200px',
    height: '43px',
  },
  tableContainer: {
    display: 'flex',
    alignItems: 'column',
  },
  clickableBox: {
    width: '140px',
    boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.12)',
    textAlign: 'left',
    verticalAlign: 'center',
    cursor: 'pointer',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  summaryBox: {
    backgroundColor: '#c5e2ec21',
  },
  infoBox: {
    width: '33%',
    marginLeft: '10px',
    border: '1px solid rgba(224, 224, 224, 1)',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    height: '20%',
    backgroundColor: '#c5e2ec21',
    alignItems: 'center',
    display: 'flex',
    padding: '15px',
  },
  dataBox: {
    padding: '15px',
  },
}))

export const autocompleteStyles = {
  width: '270px',
  border: 'none',
  backgroundColor: 'white',
}
