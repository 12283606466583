import { WorkcentreDetails, SourceSiteDetails } from './WorkcentreModal.types'

export const getSelectedWorkcentres = (workcentres: WorkcentreDetails[]) => {
  return workcentres.filter((workcentre) => workcentre.isSelected)
}

export const isSomeWorkCentresSelected = (workcentres: WorkcentreDetails[]) => {
  const selectedWorkcentres = getSelectedWorkcentres(workcentres)
  const zeroProductWorkcenter = getZeroProductWorkCenter(workcentres)
  return (
    selectedWorkcentres.length > 0 &&
    selectedWorkcentres.length <
      workcentres.length - zeroProductWorkcenter.length
  )
}

export const isAnyWorkCentresSelected = (workcentres: WorkcentreDetails[]) => {
  const selectedWorkcentres = getSelectedWorkcentres(workcentres)
  return selectedWorkcentres.length > 0
}

export const isAnySourceSitesSelected = (
  sourceSiteDetails: SourceSiteDetails[]
) => {
  const selectedSourceSites = sourceSiteDetails.filter(
    (sourceSite) => sourceSite.isSelected
  )
  return selectedSourceSites.length > 0
}

export const isSomeSourceSitesSelected = (
  sourceSiteDetails: SourceSiteDetails[]
) => {
  const selectedSourceSites = sourceSiteDetails.filter(
    (sourceSite) => sourceSite.isSelected
  )
  const zeroProductSourcesites = getZeroProductSourceSites(sourceSiteDetails)
  return (
    selectedSourceSites.length > 0 &&
    selectedSourceSites.length <
      sourceSiteDetails.length - zeroProductSourcesites.length
  )
}

const getZeroProductWorkCenter = (workcentres: WorkcentreDetails[]) => {
  return workcentres.filter((workcentre) => workcentre.productCount === 0)
}

export const isAllWorkcentresSelected = (workcentres: WorkcentreDetails[]) => {
  if (isAllProductCountsInSiteWorkCentersZero(workcentres)) {
    return false
  }
  const selectedWorkcentres = getSelectedWorkcentres(workcentres)
  const zeroProductWorkcenter = getZeroProductWorkCenter(workcentres)
  return (
    selectedWorkcentres.length ===
    workcentres.length - zeroProductWorkcenter.length
  )
}

export const isAllSourceSitesSelected = (
  sourceSiteDetails: SourceSiteDetails[]
) => {
  if (isAllProductCountsInSourcesitesZero(sourceSiteDetails)) {
    return false
  }
  const selectedSourceSites = sourceSiteDetails.filter((sourceSite) =>
    isAllWorkcentresSelected(sourceSite.siteWorkcentres)
  )
  const zeroProductSourcesites = getZeroProductSourceSites(sourceSiteDetails)
  return (
    selectedSourceSites.length ===
    sourceSiteDetails.length - zeroProductSourcesites.length
  )
}

const getZeroProductSourceSites = (sourceSiteDetails: SourceSiteDetails[]) => {
  return sourceSiteDetails.filter((sourceSite) => {
    return isAllProductCountsInSiteWorkCentersZero(sourceSite.siteWorkcentres)
  })
}

export const isAllProductCountsInSourcesitesZero = (
  sourceSiteDetails: SourceSiteDetails[]
) => {
  return sourceSiteDetails.every((sourceSite) => {
    return (
      isAllProductCountsInSiteWorkCentersZero(sourceSite.siteWorkcentres) ===
      true
    )
  })
}
export const isAllProductCountsInSiteWorkCentersZero = (
  siteWorkcentres: WorkcentreDetails[]
) => {
  return siteWorkcentres.every((workcentre) => workcentre.productCount === 0)
}
