import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
  },
  sourceSiteWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '40px',
  },
  button: {
    display: 'flex',
    borderRadius: 0,
  },

  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    height: '100%',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  divider: {
    alignSelf: 'center',
    height: '30px',
    margin: '0px 16px',
  },
  networkOverlayGraph: {
    width: '100%',
    borderRight: `1px solid ${theme.palette.borders.grey}`,
  },
}))

export const autocompleteStyles = {
  width: '300px',
  border: 'none',
  backgroundColor: 'white',
}

export const networkGraphStyles = {
  width: '100%',
  height: '70vh',
  background: 'white',
  margin: 'auto',
}

export const stockDurationText = {
  fontSize: '8px',
}
