import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  icon: {
    width: '17px',
    height: '17px',
  },
  textbutton: {
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  divider: {
    margin: '0px 16px',
  },
  popperText: {
    padding: '16px',
  },
  progress: {
    marginTop: '6px',
    cursor: 'progress',
  },
  costOfCapital: {
    paddingTop: '5px',
  },
  textbox: {
    margin: '0px 10px',
  },
  textBox: {
    width: '40px',
    backgroundColor: theme.palette.background.paper,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 3,
  },
}))
