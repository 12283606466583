import { useMemo, useState } from 'react'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'
import { ProductSettingsTableProps } from './ProductSettings.types'
import { ProductSettings } from 'Features/PlanTemplate/StepCard/StepCard.types'
import { UnitOfMeasureLabel } from './UnitOfMeasureLabel/UnitOfMeasureLabel.view'
import { Typography, Tooltip } from '@material-ui/core'
import { get, has, round } from 'lodash'
import { BlankTable as MUITable } from 'Common/MUITable/MUITable'
import {
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowClassNameParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium'
import { Loader } from 'Common/Loader/Loader'
import {
  TaskAlt,
  PublishedWithChanges,
  RemoveCircleOutline,
  AddTaskOutlined,
} from '@mui/icons-material'
import { useStyles } from './ProductSettings.styles'
import { Button } from 'supplyvue-ui'
import {
  CheckCircleOutlined,
  RadioButtonUnchecked,
  SettingsOutlined,
} from '@material-ui/icons'
import { FLEXIBLE_TEMPLATE_PARAMETERS } from 'Features/PlanTemplate/WorkcenterSettings/WorkcenterSettings.constants'

export const ProductSettingsTable = ({
  data,
  setData,
  setSelectedRows,
  unitOfMeasure,
  adjustedValues,
  settingsOption,
}: ProductSettingsTableProps) => {
  const classes = useStyles()

  if (!data) {
    return <Loader />
  }

  const skuCodes = [
    ...new Set(
      data.map((item: ProductSettings) => {
        return item.productCode
      })
    ),
  ]

  type RenderStatusProps = {
    salesRate: number
    currentCycle: number
    include: boolean
    minimumOrderQuantity: number
  }

  const RenderStatus = ({
    salesRate,
    currentCycle,
    include,
    minimumOrderQuantity,
  }: RenderStatusProps) => {
    if (!include) {
      return <div></div>
    }

    if (settingsOption === FLEXIBLE_TEMPLATE_PARAMETERS) {
      return <div></div>
    }
    if (currentCycle === null) {
      return (
        <Tooltip title="New Sku">
          <AddTaskOutlined sx={{ color: '#44a6c6' }}></AddTaskOutlined>
        </Tooltip>
      )
    }

    if (salesRate > 0) {
      if (currentCycle !== -1) {
        const naturalCycle = round(minimumOrderQuantity / salesRate, 1)
        if (naturalCycle > currentCycle) {
          return (
            <Tooltip title="SKU has been slowed to FOQ">
              <PublishedWithChanges sx={{ color: 'orange' }} />
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title="Unchanged">
              <TaskAlt sx={{ color: 'green' }}></TaskAlt>
            </Tooltip>
          )
        }
      } else {
        return (
          <Tooltip title="New Sku">
            <AddTaskOutlined sx={{ color: '#44a6c6' }}></AddTaskOutlined>
          </Tooltip>
        )
      }
    } else {
      if (currentCycle === -1) {
        return (
          <Tooltip title="Unchanged">
            <TaskAlt sx={{ color: 'green' }}></TaskAlt>
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title="Removed from template">
            <RemoveCircleOutline sx={{ color: 'red' }} />
          </Tooltip>
        )
      }
    }
  }

  const renderValue = (value: number) => {
    if (value != -1) {
      return <div>{value}</div>
    } else {
      return <div></div>
    }
  }

  type keepColumn = 'keepCurrentStartWeek' | 'keepCurrentCycleTime'

  const toggleSelection = (id: number, field: keepColumn) => {
    setData((prevRows) =>
      prevRows.map((row) => {
        if (row.id === id) {
          if (field === 'keepCurrentCycleTime') {
            return {
              ...row,
              [field]: !row[field],
              fixedCycleTime: !row[field] ? row.currentCycleTime : -1,
            }
          }

          return { ...row, [field]: !row[field] }
        }
        return row
      })
    )
  }

  const renderCheckBox = (
    value: boolean,
    id: number,
    field: keepColumn,
    included: boolean
  ) => {
    return (
      <div>
        {value ? (
          <CheckCircleOutlined
            style={{
              cursor: included ? 'pointer' : 'default',
            }}
            color={included ? 'primary' : 'disabled'}
            onClick={included ? () => toggleSelection(id, field) : () => {}}
          />
        ) : (
          <RadioButtonUnchecked
            style={{
              cursor: included ? 'pointer' : 'default',
            }}
            color={included ? 'primary' : 'disabled'}
            onClick={included ? () => toggleSelection(id, field) : () => {}}
          />
        )}
      </div>
    )
  }

  const toggleColumns = [
    'currentCycleTime',
    'currentStartWeek',
    'currentProductionSequence',
    'fixedCycleTime',
    'fixedStartWeek',
    'fixedProductionSequence',
  ]

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      currentCycleTime: false,
      currentStartWeek: false,
      currentProductionSequence: false,
      fixedCycleTime: false,
      fixedStartWeek: false,
      fixedProductionSequence: false,
    })

  const [settingsStatus, setSettingsStatus] = useState<boolean>(false)

  const handleToggleColumns = () => {
    const allVisible = toggleColumns.every((col) => columnVisibilityModel[col])
    setSettingsStatus(!settingsStatus)

    const newVisibilityModel = toggleColumns.reduce((acc: object, col) => {
      acc[col] = !allVisible
      return acc
    }, {})

    setColumnVisibilityModel((prev) => ({
      ...prev,
      ...newVisibilityModel,
    }))
  }

  const MUIColumns: GridColDef[] = useMemo(() => {
    if (!data) {
      return []
    }

    return [
      {
        field: 'productCode',
        headerName: 'SKU group code',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuCodes,
              label: 'sku Code',
            },
          },
        ],
        renderCell: (params: GridCellParams) => {
          const {
            productCode,
            description,
            salesRate,
            minimumOrderQuantity,
            currentCycleTime,
            include,
          } = params.row

          return (
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '95%',
                  justifyContent: 'space-between',
                  marginTop: 2,
                }}
              >
                <Typography variant="h4">{description}</Typography>
                <Typography variant="subtitle1">{productCode}</Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '5%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <RenderStatus
                  salesRate={salesRate}
                  currentCycle={currentCycleTime}
                  include={include}
                  minimumOrderQuantity={minimumOrderQuantity}
                />
              </div>
            </div>
          )
        },
        width: 450,
      },
      {
        field: 'primaryWorkCentreCode',
        headerName: 'Primary Work Centre',
        type: 'string',
        flex: 1,
      },
      {
        field: 'segment',
        headerName: 'Segment',
        type: 'string',
        flex: 1,
      },
      {
        field: 'productFormat',
        headerName: 'SKU group format',
        type: 'string',
        flex: 1,
      },
      {
        field: 'batchGroupingCode',
        headerName: 'Batch group code',
        type: 'string',
        flex: 1,
      },
      {
        field: 'replenishmentType',
        headerName: 'Replenishment type',
        type: 'string',
        renderCell: (params: GridCellParams) => {
          const { skuGroupKey, replenishmentType } = params.row
          const isAdjusted = has(
            get(adjustedValues, `${skuGroupKey}`) ?? {},
            'replenishmentType'
          )

          return (
            <Typography color={isAdjusted ? 'error' : 'inherit'}>
              {replenishmentType ?? '-'}
            </Typography>
          )
        },
        flex: 1,
      },
      {
        field: 'salesRate',
        headerName: 'Sales rate',
        type: 'number',
      },
      {
        field: 'runRate',
        headerName: 'Run rate',
        type: 'number',
        renderCell: (params: GridCellParams) => {
          const { runRate, runRateUnitOfMeasure } = params?.row
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                justifyContent: 'flex-start',
                gap: 4,
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" component="span">
                {runRate.toFixed(1).toString()}
              </Typography>
              <Typography variant="body2" component="span">
                {runRateUnitOfMeasure}
              </Typography>
            </div>
          )
        },
      },
      {
        field: 'minimumOrderIncrement',
        headerName: 'MOI',
        type: 'number',
      },
      {
        field: 'minimumOrderQuantity',
        headerName: 'MOQ',
        type: 'number',
      },
      {
        field: 'productionUnitOfMeasure',
        headerName: 'UoM',
        type: 'string',
        renderCell: (params: GridCellParams) => (
          <UnitOfMeasureLabel
            id={params.row.productionUnitOfMeasureId}
            label={params.row.productionUnitOfMeasure}
            unitOfMeasure={unitOfMeasure}
          />
        ),
      },
      {
        field: 'naturalCycle',
        headerName: 'Natural Cycle',
        type: 'number',
        renderCell: (params: GridCellParams) => {
          const { minimumOrderQuantity, salesRate } = params.row
          return salesRate > 0 ? round(minimumOrderQuantity / salesRate, 1) : 0
        },
      },
      {
        field: 'keepCurrentCycleTime',
        headerName: 'Keep Current Cycle',
        type: 'boolean',
        renderCell: (params: GridCellParams) => {
          return renderCheckBox(
            params.value as boolean,
            params.id as number,
            params.field as keepColumn,
            params.row.include
          )
        },
      },
      {
        field: 'keepCurrentStartWeek',
        headerName: 'Keep Current Start Week',
        type: 'boolean',
        renderCell: (params: any) => {
          return renderCheckBox(
            params.value as boolean,
            params.id as number,
            params.field as keepColumn,
            params.row.include
          )
        },
      },
      {
        field: 'currentCycleTime',
        headerName: 'Current Cycle',
        type: 'number',
        hide: true,
        renderCell: (params: any) => {
          const { currentCycleTime } = params.row
          return renderValue(currentCycleTime)
        },
      },
      {
        field: 'currentStartWeek',
        headerName: 'Current Start Week',
        hide: true,
        type: 'number',
        renderCell: (params: any) => {
          const { currentStartWeek } = params.row
          return renderValue(currentStartWeek)
        },
      },
      {
        field: 'currentProductionSequence',
        headerName: 'Current Production Sequence',
        hide: true,
        type: 'number',
        renderCell: (params: any) => {
          const { currentProductionSequence } = params.row
          return renderValue(currentProductionSequence ?? -1)
        },
      },
      {
        field: 'fixedCycleTime',
        headerName: 'Fixed Cycle',
        editable: true,
        type: 'number',
        hide: true,
        renderCell: (params: any) => {
          const { fixedCycleTime } = params.row
          return renderValue(fixedCycleTime ?? -1)
        },
      },
      {
        field: 'fixedStartWeek',
        headerName: 'Fixed Start Week',
        hide: true,
        type: 'number',
        renderCell: (params: any) => {
          const { fixedStartWeek } = params.row
          return renderValue(fixedStartWeek ?? -1)
        },
      },
      {
        field: 'fixedProductionSequence',
        headerName: 'Fixed Production Sequence',
        hide: true,
        type: 'number',
        renderCell: (params: any) => {
          const { fixedProductionSequence } = params.row
          return renderValue(fixedProductionSequence ?? -1)
        },
      },
      {
        field: 'minimumCycle',
        headerName: 'Min cycle',
        type: 'number',
        renderCell: (params: any) => {
          const { skuGroupKey, minimumCycle } = params.row
          const isAdjusted = has(
            get(adjustedValues, `${skuGroupKey}`) ?? {},
            'minimumCycle'
          )

          return (
            <Typography color={isAdjusted ? 'error' : 'inherit'}>
              {minimumCycle ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'maximumCycle',
        headerName: 'Max cycle',
        type: 'number',
        renderCell: (params: any) => {
          const { skuGroupKey, maximumCycle } = params.row
          const isAdjusted = has(
            get(adjustedValues, `${skuGroupKey}`) ?? {},
            'maximumCycle'
          )

          return (
            <Typography color={isAdjusted ? 'error' : 'inherit'}>
              {maximumCycle ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'include',
        headerName: 'Include',
        type: 'boolean',
      },
    ]
  }, [data, adjustedValues])

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(data.filter((row) => selectedIds.has(row.id)))
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        maxWidth: '120%',
        marginBottom: '32px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '3px',
        }}
      >
        <Button
          color="primary"
          variant={settingsStatus ? 'outlined' : 'contained'}
          onClick={handleToggleColumns}
          icon={<SettingsOutlined />}
        >
          Advanced Settings
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <MUITable
          rows={data}
          checkboxSelection
          style={{ flexGrow: 1, height: '100%' }}
          columns={MUIColumns.map((col: GridColDef) => ({
            ...col,
            cellClassName: toggleColumns.includes(col.field)
              ? 'toggle-column'
              : '',
          }))}
          getRowClassName={(params: GridRowClassNameParams) =>
            !params.row.include ? classes.inactiveRow : ''
          }
          showCellVerticalBorder
          showColumnVerticalBorder
          columnVisibilityModel={columnVisibilityModel}
          initialState={{
            pagination: { paginationModel: { pageSize: 15 } },
          }}
          isCellEditable={(params: GridCellParams) => {
            return !params.row.keepCurrentCycle && params.row.include
          }}
          pageSizeOptions={[15, 30, 50]}
          onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
          disableRowSelectionOnClick
          pagination
          sx={{
            '& .toggle-column': {
              backgroundColor: 'rgba(208, 231, 255, 0.5)',
            },
          }}
        />
      </div>
    </div>
  )
}
