import { Accordion as MuiAccordion } from '@material-ui/core'

import { AccordionProps } from './Accordions.types'
import { useStyles } from './Accordions.styles'
import { SourceSiteDetails } from 'Features/Project/WorkcentreModal/WorkcentreModal.types'
import { AccordionSummary } from 'Features/Project/WorkcentreModal/AccordionSummary/AccordionSummary.view'
import { AccordionDetails } from 'Features/Project/WorkcentreModal/AccordionDetails/AccordionDetails.view'
import { isAnyWorkCentresSelected } from 'Features/Project/WorkcentreModal/WorkcentreModal.utils'

export const Accordions = ({
  sourceSiteDetails,
  handleCheckboxChange,
  readOnly,
}: AccordionProps) => {
  const classes = useStyles()
  const Accordion = (data: SourceSiteDetails) => {
    return (
      <MuiAccordion
        defaultExpanded={readOnly}
        classes={{
          root: classes.accordionRoot,
        }}
        key={data.siteKey}
      >
        <AccordionSummary
          readOnly={readOnly}
          sourceSiteData={data}
          handleCheckboxChange={handleCheckboxChange}
        />
        <AccordionDetails
          readOnly={readOnly}
          sourceSiteData={data}
          handleCheckboxChange={handleCheckboxChange}
        />
      </MuiAccordion>
    )
  }

  return (
    <>
      {sourceSiteDetails.map((data) =>
        readOnly
          ? isAnyWorkCentresSelected(data.siteWorkcentres)
            ? Accordion(data)
            : null
          : Accordion(data)
      )}
    </>
  )
}
