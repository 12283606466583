import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: '24px',
  },
  contentWrapper: {
    boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.12)',
  },
  header: {
    backgroundColor: '#c5e2ec21',
  },

  cell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  wrapper: {
    padding: '15px',
  },
  tableContainer: {
    display: 'flex',
    alignItems: 'column',
  },
  clickableBox: {
    width: '140px',
    boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.12)',
    textAlign: 'left',
    verticalAlign: 'center',
    cursor: 'pointer',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  selectedSummary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  summaryBox: {
    backgroundColor: '#c5e2ec21',
  },
  columnHeader: {
    margin: '4px',
  },
  baseLineBox: {
    cursor: 'default',
  },
}))

export const autocompleteStyles = {
  width: '300px',
  border: 'none',
  backgroundColor: 'white',
}
