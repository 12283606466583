import { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Table } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { Loader } from 'Common/Loader/Loader'
import { useStyles } from './WorkcentreRouting.styles'
import { WorkcentreRoutingTableProps } from './WorkcentreRouting.types'
import EditIcon from '@material-ui/icons/Edit'

export const WorkcentreRoutingTable = ({
  tableData,
  setTableData,
  totalSalesVolume,
  setVolumeSplit,
}: WorkcentreRoutingTableProps) => {
  const classes = useStyles()

  const handleRowUpdate = (newRow) => {
    const updatedRows = tableData.map((row) =>
      row.id === newRow.id ? { ...row, ...newRow } : row
    )

    const newRows = updatedRows.map((row) => {
      return {
        ...row,
        volume: ((totalSalesVolume * row.volumeSplit) / 100).toFixed(1),
      }
    })

    setTableData(newRows)

    return {
      ...newRow,
      volume: ((totalSalesVolume * newRow.volumeSplit) / 100).toFixed(1),
    }
  }

  const calculateColumnTotal = (field) => {
    const result = tableData.reduce(
      (total, row) => total + (parseFloat(row[field]) || 0),
      0
    )

    if (field === 'volumeSplit') {
      setVolumeSplit(result)
    }
    return result.toFixed(1)
  }

  const EditableCell = ({ value }) => {
    return (
      <div style={{ position: 'relative', paddingRight: 24 }}>
        {value}
        <EditIcon
          fontSize="small"
          style={{
            position: 'absolute',
            right: 0,
            cursor: 'pointer',
            paddingLeft: 3,
          }}
        />
      </div>
    )
  }

  const columns: GridColDefPremium[] | any[] = useMemo(() => {
    if (!tableData) {
      return []
    }

    return [
      {
        field: 'workcentreCode',
        headerName: 'Workcentre',
        minWidth: 200,
        type: 'string',
      },
      {
        field: 'sourceSiteCode',
        headerName: 'Source site',
        minWidth: 150,
        type: 'string',
        cellClassName: 'borderRight',
      },
      {
        field: 'volumeSplit',
        headerName: 'Volume Split %',
        minWidth: 150,
        type: 'number',
        flex: 1,
        editable: true,
        align: 'right',
      },
      {
        field: 'volume',
        headerName: 'Sales Volume',
        minWidth: 150,
        type: 'number',
        align: 'right',
      },
    ]
  }, [tableData])

  const totalsRow = {
    id: -1,
    name: 'Total',
    volumeSplit: calculateColumnTotal('volumeSplit'),
    volume: calculateColumnTotal('volume'),
  }

  if (!tableData) {
    return <Loader />
  }

  return (
    <Box className={classes.table}>
      <Table
        rows={[...tableData, totalsRow]}
        columns={columns.map((column) => ({
          ...column,
          cellClassName:
            column.field === 'sourceSiteCode' ? classes.borderRight : '',
          headerClassName:
            column.field === 'sourceSiteCode' ? classes.borderRight : '',
          renderCell: (params) => {
            if (column.editable) {
              return <EditableCell value={params.value} />
            }
            return params.value
          },
        }))}
        experimentalFeatures={{ lazyLoading: true }}
        processRowUpdate={handleRowUpdate}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 30, 50]}
      />
    </Box>
  )
}
