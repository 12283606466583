import { UseBreadcrumbsDataParams } from './Breadcrumbs.types'
import { useQuery } from 'react-query'
import { request } from 'Features/Auth/axios-client'

const fetchBreadcrumbsLabels = async (keys: UseBreadcrumbsDataParams) => {
  const projectId = keys.project_id ? `project_id=${keys.project_id}` : ''
  const planTemplateId = keys.plan_template_id
    ? `&plan_template_id=${keys.plan_template_id}`
    : ''
  const scenarioId = keys.scenario_id ? `&scenario_id=${keys.scenario_id}` : ''
  const replenishmentPolicyId = keys.replenishment_policy_id
    ? `&replenishment_policy_id=${keys.replenishment_policy_id}`
    : ''
  const baseSourceSiteId = keys.base_source_site_id
    ? `&base_source_site_id=${keys.base_source_site_id}`
    : ''
  const siteId = keys.site_id ? `&site_id=${keys.site_id}` : ''

  const demandScenarioId = keys.demand_scenario_id
    ? `&demand_scenario_id=${keys.demand_scenario_id}`
    : ''
  const workCenterId = keys.work_center_id
    ? `&work_center_id=${keys.work_center_id}`
    : ''

  const queryString = `${projectId}${scenarioId}${planTemplateId}${replenishmentPolicyId}${baseSourceSiteId}${siteId}${demandScenarioId}${workCenterId}`
  const { data } = await request({
    url: `/app-labels?${queryString}`,
  })

  return data
}

export const useBreadcrumbsData = (keys: UseBreadcrumbsDataParams) => {
  return useQuery(['breadcrumbs'], () => fetchBreadcrumbsLabels(keys), {
    select: (data) => data.data,
    refetchOnWindowFocus: false,
  })
}
