import { DROPDOWN_OPTIONS } from './NewPlanTemplate.constants'
import { request } from 'Features/Auth/axios-client'
import { useQuery } from 'react-query'

const fetchDropdownOptionsData = async (snapshotId: string) => {
  const { REPLENISHMENT_TYPE, SUPPLY_TYPE, UNIT_OF_MEASURE } = DROPDOWN_OPTIONS
  const { data } = await request({
    url: `/snapshots/${snapshotId}/mappings?option=${REPLENISHMENT_TYPE}&config=PlanTemplate&option=${SUPPLY_TYPE}&option=${UNIT_OF_MEASURE}`,
  })
  return data
}

export const useDropdownOptionsData = (snapshotId: string) => {
  return useQuery(
    ['dropdown-options', snapshotId],
    () => fetchDropdownOptionsData(snapshotId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  )
}

const fetchWorkcentreSettings = async (
  snapshotId: string,
  projectId: string,
  planTemplateId: string
) => {
  const { data } = await request({
    url: `/plan-template/workcentre-settings`,
    method: 'post',
    data: {
      snapshotId,
      projectId,
      planTemplateId,
    },
  })
  return data
}

export const useWorkcentreSettingsData = (
  snapshotId: string,
  projectId: string,
  planTemplateId: string
) => {
  return useQuery(
    ['workcentre-settings-all', snapshotId, projectId, planTemplateId],
    () => fetchWorkcentreSettings(snapshotId, projectId, planTemplateId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  )
}
