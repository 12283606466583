import { ChangeEvent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Box,
  Drawer,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
} from '@material-ui/core'
import { PageHeader, Button, DrawerControls, TabPanel } from 'supplyvue-ui'
import { Page } from 'Common/Page/Page'
import { AnalysisGraphView } from './AnalysisGraph/AnalysisGraph.view'
import { AnalysisSummaryView } from './AnalysisSummary/AnalysisSummary.view'
import { useStyles } from './InventoryAnalysis.styles'
import { KPIView } from './KPI/KPI.view'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import {
  AnalysisMeasure,
  AnalysisMethod,
  InventoryAnalysisPageParams,
  StockType,
  SiteCodeOption,
} from './InventoryAnalysis.types'
import {
  useAnalysisSummary,
  useSegmentGraph,
  useKPIData,
  useDownloadKPIData,
  useSiteLabel,
  useDropdownOptionsData,
} from './InventoryAnalysis.data'

import {
  useSiteConnectionsData,
  useSourceSitesData,
} from '../ReplenishmentPolicy/ReplenishmentPolicy.data'

import { Loader } from 'Common/Loader/Loader'
import GetAppIcon from '@material-ui/icons/GetApp'
import { SiteParameters } from 'Features/ReplenishmentPolicy/SiteParameters/SiteParameters.view'
import { InventoryAnalysisFiltersView } from './InventoryAnalysisFilters/InventoryAnalysisFilters.view'
import { mapReplenishmentType } from './InventoryAnaylsis.utils'
import {
  useDownloadInventoryModellingFile,
  useInventoryModellingData,
  useUpdateReplenishmentSettingsOutputData,
} from 'Features/ViewInventory/ViewInventory.data'
import { ReplenishmentSettingsOutput } from './ReplenishmentSettingsOutput/ReplenishmentSettingsOutput.view'
import {
  UpdatedCellValues,
  UpdateTableData,
  ViewInventoryColumns,
} from 'Features/ViewInventory/ViewInventory.types'
import { useSegmentGraphData } from 'Features/SegmentAnalysis/SegmentAnalysis.data'
import { SegmentAnalysisView } from 'Features/SegmentAnalysis/SegmentAnalysis.view'
import { keys, merge, pickBy } from 'lodash'
import { toast } from 'react-hot-toast'
import { CUSTOM_METHOD } from 'Features/ViewInventory/ViewInventory.constants'
import { Toolbar } from 'Common/Toolbar'
import { ReplenishmentSettingsOutputBulkUpdate } from './ReplenishmentSettingsOutput/ReplenishmentSettingsOutput.modal.view'
import { ReplenishmentSettingsOutputFormData } from './ReplenishmentSettingsOutput/ReplenishmentSettingsOutput.type'
import { FilteredRows } from 'supplyvue-ui/dist/types/components/Table/Table.types'
const INITIAL_BULK_UPDATE_STATE = {
  method: '',
  safetyStockType: '',
}
export const InventoryAnalysisPage = () => {
  const {
    projectId,
    replenishmentPolicyId,
    sourceSiteId,
    siteKey,
    scenarioId,
  } = useParams<InventoryAnalysisPageParams>()
  useBreadcrumbsData({
    project_id: projectId,
    replenishment_policy_id: replenishmentPolicyId,
    base_source_site_id: sourceSiteId,
    site_id: siteKey,
  })

  const [data, setData] = useState(INITIAL_BULK_UPDATE_STATE)

  const {
    data: inventoryModellingData,
    isLoading: inventoryModellingIsLoading,
  } = useInventoryModellingData(replenishmentPolicyId, sourceSiteId, siteKey)

  const [tableData, setTableData] = useState(inventoryModellingData)
  const [updatedRowValues, setUpdatedRowValues] = useState<UpdatedCellValues[]>(
    []
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [
    filteredRows,
    setFilteredRows,
  ] = useState<null | FilteredRows<ViewInventoryColumns>>(null)
  useEffect(() => {
    setTableData(inventoryModellingData)
    setUpdatedRowValues([])
  }, [inventoryModellingData])

  const updateTableData: UpdateTableData = (
    updatedTableData,
    updatedCellValues
  ) => {
    setUpdatedRowValues((previousUpdatedRows) => {
      const newUpdatedRows = [...previousUpdatedRows]
      let existingUpdatedRow = newUpdatedRows.find(
        (item) => item.skuGroupKey === updatedCellValues?.skuGroupKey
      )
      if (existingUpdatedRow) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newRow = merge(existingUpdatedRow, updatedCellValues) as any

        const filteredRowKeys = keys(
          pickBy(newRow, (value) => {
            return value === null
          })
        )

        filteredRowKeys.forEach((key) => delete newRow[key])
        existingUpdatedRow = newRow
      } else {
        newUpdatedRows.push(updatedCellValues)
      }
      return newUpdatedRows
    })
    setTableData(updatedTableData)
  }

  const onTableUpdateSuccess = (message: string) => {
    handleModalClose()
    toast.success(message)
  }
  const onTableUpdateError = (message: string) => {
    toast.error(message)
  }

  const {
    mutate: updateReplenishmentSettingsOutputTable,
    isLoading: isReplenishmentSettingsOutputTableUpdating,
  } = useUpdateReplenishmentSettingsOutputData({
    onSuccess: onTableUpdateSuccess,
    onError: onTableUpdateError,
  })

  const handleApproveClick = () => {
    let isValid = true
    const newUpdatedRows = updatedRowValues.map((row) => {
      const newRow = { ...row }
      const { method, volume, duration, skuGroupKey } = newRow
      if (method === CUSTOM_METHOD && !volume && !duration) {
        const existingRowInTable = tableData?.find((tableRow: any) => {
          return tableRow.skuGroupKey === skuGroupKey
        })
        if (existingRowInTable) {
          const doCustomMethodsHaveValues =
            !!existingRowInTable?.chosenMethodTotalBAUDuration
              ?.customBAUDuration ||
            !!existingRowInTable?.chosenMethodTotalBAUVolume?.customBAUVolume
          isValid = doCustomMethodsHaveValues
        } else {
          isValid = false
        }
      } else if (method && method !== CUSTOM_METHOD) {
        if (volume) {
          delete newRow.volume
        } else if (duration) {
          delete newRow.duration
        }
      }
      return newRow
    })
    if (isValid) {
      updateReplenishmentSettingsOutputTable({
        sourceSiteId,
        replenishmentPolicyId,
        siteKey: parseInt(siteKey),
        updatedData: newUpdatedRows,
      })
    } else {
      toast.error(
        'Please provide values for chosen method total BAU stock duration for rows with chosen method as *'
      )
    }
  }

  const { refetch: downloadFile } = useDownloadInventoryModellingFile(
    replenishmentPolicyId,
    sourceSiteId,
    siteKey,
    'forecast_error'
  )

  const handleDownload = () => {
    downloadFile()
  }

  const [replenishmentTypeFilter, setReplenishmentTypeFilter] = useState('')
  const [cycleTimeFilter, setCycleTimeFilter] = useState<'' | number>('')
  const [workcentre, setWorkcentre] = useState<number | ''>('')
  const [workcentrerOptions, setWorkcentreOptions] = useState()

  const [analysisMethod, setAnalysisMethod] = useState<AnalysisMethod>(
    'forecast_error'
  )

  const [analysisMeasure, setAnalysisMeasure] = useState<AnalysisMeasure>(
    'volume'
  )

  const [stockType, setStockType] = useState<StockType>('BAU_stock')

  const [openDrawer, setOpenDrawer] = useState(false)

  const [isParametersUpdated, setIsParametersUpdated] = useState<boolean>(false)

  const [
    selectedSiteCode,
    setSelectedSiteCode,
  ] = useState<SiteCodeOption | null>(null)

  const {
    isLoading: isAnalysisSummaryLoading,
    data: analysisSummary,
    isError: analysisSummaryFetchError,
  } = useAnalysisSummary(
    projectId,
    replenishmentPolicyId,
    sourceSiteId,
    parseInt(siteKey),
    replenishmentTypeFilter,
    cycleTimeFilter
  )

  const {
    isLoading: isSegmentGraphLoading,
    data: segmentGraphData,
  } = useSegmentGraph(
    replenishmentPolicyId,
    sourceSiteId,
    parseInt(siteKey),
    analysisMethod,
    analysisMeasure,
    stockType,
    replenishmentTypeFilter,
    cycleTimeFilter
  )

  const { isLoading: isKPITableLoading, data: KPIData } = useKPIData(
    replenishmentPolicyId,
    sourceSiteId,
    siteKey,
    analysisMethod,
    'false',
    replenishmentTypeFilter,
    cycleTimeFilter
  )

  const { refetch: downloadKPICSVFile } = useDownloadKPIData(
    replenishmentPolicyId,
    sourceSiteId,
    siteKey,
    analysisMethod,
    'true',
    replenishmentTypeFilter,
    cycleTimeFilter
  )

  const { isLoading: isSiteLabelLoading, data: siteLabel } = useSiteLabel(
    replenishmentPolicyId,
    parseInt(sourceSiteId),
    parseInt(siteKey)
  )

  const {
    isLoading: isDropdownOptionsLoading,
    data: dropdownOptions,
  } = useDropdownOptionsData(analysisSummary?.snapshotId)

  const {
    data: segmentAnalysisGraphData,
    isLoading: isSegmentAnalysisGraphDataLoading,
  } = useSegmentGraphData(
    replenishmentPolicyId,
    sourceSiteId,
    replenishmentTypeFilter,
    cycleTimeFilter,
    workcentre ?? '',
    Number(siteKey),
    analysisMethod
  )

  const { data: sourceSites } = useSourceSitesData(projectId, scenarioId)

  useEffect(() => {
    if (sourceSites) {
      const currentSourceSite = sourceSites?.baseSourceSites.filter(
        (site: { value: number }) => site.value.toString() === sourceSiteId
      )
      const options = currentSourceSite[0]?.workcentres?.map(
        (workcentre: { code: string; key: number }) => {
          return {
            key: workcentre.code,
            value: workcentre.key,
          }
        }
      )
      setWorkcentreOptions(options)
    }
  }, [sourceSites, sourceSiteId])

  const { data: siteConnections } = useSiteConnectionsData(
    projectId,
    sourceSites?.snapshotId,
    scenarioId,
    replenishmentPolicyId,
    parseInt(sourceSiteId),
    undefined,
    () => null,
    () => null
  )

  useEffect(() => {
    if (siteConnections?.data) {
      const defaultSiteCode = siteConnections.data?.nodes.find(
        (node: SiteCodeOption) => node.id === parseInt(siteKey)
      )
      setSelectedSiteCode(defaultSiteCode)
    }
  }, [siteConnections])

  const history = useHistory()

  const changeSummaryMethod = (newMethod: AnalysisMethod) => {
    setAnalysisMethod(newMethod)
  }

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }

  const handleDrawerClose = () => {
    setOpenDrawer(false)
    if (isParametersUpdated) {
      history.push(
        `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings/${sourceSiteId}`
      )
    }
  }

  const handleParametersUpdate = () => {
    if (!isParametersUpdated) setIsParametersUpdated(true)
  }

  const handleSiteCodeChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    option: SiteCodeOption | null
  ) => {
    if (option) {
      setSelectedSiteCode(option)
      history.push(
        `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings/inventory-analysis/${sourceSiteId}/${option.id}`
      )
    } else {
      setSelectedSiteCode(null)
    }
  }

  const handleViewInventoryTableRowClick = (
    row: ViewInventoryColumns | ViewInventoryColumns[]
  ) => {
    if (!Array.isArray(row)) {
      history.push(
        `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings/inventory-analysis/${sourceSiteId}/${siteKey}/sku-group-inventory-analysis/${row.skuGroupKey}`
      )
    }
  }
  const handleInventoryHandlingCostClick = () => {
    history.push(
      `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings/inventory-analysis/${sourceSiteId}/${siteKey}/inventory-holding-cost`
    )
  }

  const classes = useStyles()
  const [tabIndex, setTabIndex] = useState<number>(0)
  const onTabChange = (
    event: ChangeEvent<Record<string, unknown>>,
    value: number
  ) => {
    setTabIndex(value)
  }

  const getTableWidth = () => {
    const MIN_WIDTH = 1000
    const availableWidth = window.innerWidth - 120
    return availableWidth > MIN_WIDTH ? availableWidth : MIN_WIDTH
  }

  const Filters = ({
    hideSideCodeDropdown,
    hideReplenishmentTypeDropdown,
    hideCycleTimeDropdown,
    hideWorkcenterDropdown,
  }: any) => {
    return (
      <>
        {isDropdownOptionsLoading ? null : (
          <InventoryAnalysisFiltersView
            replenishmentTypeMenuItems={mapReplenishmentType(
              dropdownOptions?.replenishmentType
            )}
            replenishmentType={replenishmentTypeFilter}
            cycleTime={cycleTimeFilter}
            handleReplenishmentTypeChange={(e) =>
              setReplenishmentTypeFilter(e.target.value as string)
            }
            handleCycleTimeChange={(e) =>
              setCycleTimeFilter(e.target.value as number)
            }
            nodes={siteConnections?.data?.nodes.filter(
              (node: SiteCodeOption) => node.approvalStatus === 'APPROVED'
            )}
            handleSiteCodeChange={handleSiteCodeChange}
            selectedSiteCode={selectedSiteCode}
            handleWorkcentreChange={(e) =>
              setWorkcentre(e.target.value as number)
            }
            workcenter={workcentre ?? ''}
            workcentreOptions={workcentrerOptions ?? []}
            hideSideCodeDropdown={!!hideSideCodeDropdown}
            hideReplenishmentTypeDropdown={!!hideReplenishmentTypeDropdown}
            hideCycleTimeDropdown={!!hideCycleTimeDropdown}
            hideWorkcenterDropdown={!!hideWorkcenterDropdown}
          />
        )}
      </>
    )
  }
  if (isAnalysisSummaryLoading || isDropdownOptionsLoading) {
    return <Loader />
  }

  const handleBulkUpdateSaveClick = (
    data: ReplenishmentSettingsOutputFormData
  ) => {
    const bulkUpdateData: UpdatedCellValues[] | undefined = filteredRows?.map(
      ({ original }) => {
        return {
          skuGroupKey: original.skuGroupKey,
          ...data,
        }
      }
    )
    if (bulkUpdateData) {
      updateReplenishmentSettingsOutputTable({
        sourceSiteId,
        replenishmentPolicyId,
        siteKey: parseInt(siteKey),
        updatedData: bulkUpdateData,
      })
    }
  }

  const getFilteredRows = (
    filteredRows: FilteredRows<ViewInventoryColumns>
  ) => {
    setFilteredRows(filteredRows)
  }

  if (analysisSummaryFetchError) {
    toast.error(
      'Fetching safety stock summary failed. Please approve sites and try again',
      { id: 'safety-stock-summary-error', duration: 5000 }
    )
    return null
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setData(INITIAL_BULK_UPDATE_STATE)
  }

  return (
    <Page>
      <Box className={classes.wrapper}>
        <PageHeader title="Inventory modelling analysis" />
        <Paper elevation={1} square className={classes.wrapper}>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={onTabChange}
            aria-label="Totalled or Sequenced"
            className={classes.tabs}
            variant="fullWidth"
          >
            <Tab
              label="Site summary"
              className={tabIndex === 0 ? classes.activeTab : ''}
            />
            <Tab
              label="Savings by SKU group"
              className={tabIndex === 1 ? classes.activeTab : ''}
            />
            <Tab
              label="Replenishment settings output"
              className={tabIndex === 2 ? classes.activeTab : ''}
            />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <Filters hideWorkcenterDropdown />
            <Box className={classes.row}>
              <Box display="flex">
                <AnalysisSummaryView
                  changeSummaryMethod={changeSummaryMethod}
                  method={analysisMethod}
                  summaryData={analysisSummary?.data}
                  reportingUnitOfMeasure={
                    analysisSummary.reportingUnitOfMeasure
                  }
                  nodes={siteConnections?.data?.nodes.filter(
                    (node: SiteCodeOption) => node.approvalStatus === 'APPROVED'
                  )}
                  handleSiteCodeChange={handleSiteCodeChange}
                  selectedSiteCode={selectedSiteCode}
                />
              </Box>
              <Box display="flex" width="55%">
                {isSegmentGraphLoading ? (
                  <Loader />
                ) : (
                  <AnalysisGraphView
                    graphData={segmentGraphData}
                    analysisMeasure={analysisMeasure}
                    stockType={stockType}
                    handleAnalysisMeasureChange={setAnalysisMeasure}
                    handleStockTypeChange={setStockType}
                  />
                )}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <Box>
              <Filters hideSideCodeDropdown />
              <SegmentAnalysisView
                segmentGraphDataLoading={isSegmentAnalysisGraphDataLoading}
                segmentGraphData={segmentAnalysisGraphData}
                method={analysisMethod}
              />
              {isKPITableLoading ? (
                <Loader />
              ) : (
                <>
                  <Box className={classes.downloadWrapper}>
                    <Tooltip title="Download CSV">
                      <IconButton
                        className={classes.iconButton}
                        color="primary"
                        aria-label="Download CSV"
                        onClick={() => downloadKPICSVFile()}
                      >
                        <GetAppIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box width={getTableWidth()}>
                    <KPIView tableData={KPIData} />
                  </Box>
                </>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Box width="14%">
                <Toolbar onEditClick={() => setIsModalOpen(true)} />
              </Box>
              <Box>
                <Filters
                  hideCycleTimeDropdown
                  hideReplenishmentTypeDropdown
                  hideWorkcenterDropdown
                />
              </Box>
            </Box>

            <Box width={getTableWidth()}>
              <ReplenishmentSettingsOutput
                tableData={tableData}
                isLoading={inventoryModellingIsLoading}
                handleDownload={handleDownload}
                updateTableData={updateTableData}
                updatedRowValues={updatedRowValues}
                handleRowClick={handleViewInventoryTableRowClick}
                handleInventoryHandlingCostClick={
                  handleInventoryHandlingCostClick
                }
                handleApproveClick={handleApproveClick}
                isApproveButtonBusy={isReplenishmentSettingsOutputTableUpdating}
                getFilteredRows={getFilteredRows}
              />
            </Box>
          </TabPanel>
        </Paper>
        <Box className={classes.footerWrapper}>
          <Button
            className={classes.editButton}
            color="primary"
            variant="outlined"
            onClick={handleDrawerOpen}
          >
            Edit site replenishment settings
          </Button>
        </Box>
      </Box>
      {!isSiteLabelLoading && (
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={handleDrawerClose}
          transitionDuration={500}
        >
          <Box>
            <Box className={classes.drawerControl}>
              <DrawerControls onClose={handleDrawerClose} />
            </Box>
            <SiteParameters
              snapshotId={analysisSummary?.snapshotId}
              replenishmentPolicyId={replenishmentPolicyId}
              sourceSiteId={parseInt(sourceSiteId)}
              overlayElement={{
                id: siteKey,
                position: {
                  x: 1,
                  y: 1,
                },
              }}
              siteLabel={siteLabel[siteKey]}
              onSuccessCb={handleParametersUpdate}
            />
          </Box>
        </Drawer>
      )}
      <ReplenishmentSettingsOutputBulkUpdate
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        isUpdating={isReplenishmentSettingsOutputTableUpdating}
        data={data}
        setData={setData}
        handleSaveClick={handleBulkUpdateSaveClick}
      ></ReplenishmentSettingsOutputBulkUpdate>
    </Page>
  )
}
