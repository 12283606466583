import { Box, Typography } from '@material-ui/core'
import { ProductSettingsParameterProps } from './ProductSettingsParameter.types'
import { useStyles } from './ProductSettingsParameter.styles'

export const ProductSettingsParameter = ({
  label,
  children,
}: ProductSettingsParameterProps) => {
  const classes = useStyles()
  return (
    <Box className={classes.parameterContainer}>
      <Typography variant="h4">{label}</Typography>
      {children}
    </Box>
  )
}
