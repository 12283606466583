import { GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-premium'
import { AutoCompleteTextBox } from './Autocomplete.view'

export const searchTextOperator: GridFilterOperator[] = [
  {
    label: 'search',
    value: 'searchColumn',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params): boolean => {
        return filterItem.value.has(params.value)
      }
    },
    InputComponent: AutoCompleteTextBox,
  },
]
