import { ImportStatus } from './SnapshotModal.types'

export const SNAPSHOT_LENGTH_MENU_ITEMS = [
  {
    key: '36 months ( 156 weeks / 1095 days )',
    value: 156,
  },
  {
    key: '24 months ( 104 weeks / 730 days )',
    value: 104,
  },
  {
    key: '18 months ( 78 weeks / 548 days )',
    value: 78,
  },
  {
    key: '12 months ( 52 weeks / 365 days )',
    value: 52,
  },
  {
    key: '9 months ( 39 weeks / 274 days )',
    value: 39,
  },
  {
    key: '6 months ( 26 weeks / 183 days )',
    value: 26,
  },
]

export const CONSTANTS = {
  IMPORT_STATUS: {
    START: 'start' as ImportStatus,
    INPROGRESS: 'inprogress' as ImportStatus,
    FINISHED: 'finished' as ImportStatus,
  },
}
