import { useStyles } from './InputParameter.styles'
import { Input } from 'supplyvue-ui'
import { Box, Typography } from '@material-ui/core'

import { InputParameterProps } from './InputParameter.types'

export const InputParameter = (props: InputParameterProps) => {
  const {
    name,
    label,
    value,
    suffix,
    handleInputChange,
    className,
    isDisabled,
  } = props
  const classes = useStyles()
  return (
    <Box className={classes.parameter}>
      <Typography className={className}>{label}:</Typography>
      <Box className={classes.inputContainer}>
        <Input
          ariaLabel={label}
          value={value}
          onChange={handleInputChange}
          name={name}
          className={classes.formElement}
          disabled={isDisabled}
        />
        {suffix && <Typography className={classes.suffix}>{suffix}</Typography>}
      </Box>
    </Box>
  )
}
