import {
  ColumnFilter,
  DropDown,
  EditableCell,
  multiSelectFilter,
  Table,
} from 'supplyvue-ui'
import { Column, TableInstance } from 'react-table'
import {
  ViewInventoryTableProps,
  ViewInventoryColumns,
  ChosenMethodTotalBAUVolume,
  ChosenMethodTotalBAUDuration,
} from './ViewInventory.types'
import { Loader } from 'Common/Loader/Loader'
import { ChangeEvent } from 'react'
import { Box } from '@material-ui/core'
import { useStyles } from './ViewInventory.styles'
import {
  CUSTOM_METHOD,
  DEMAND_VARIABILITY_METHOD,
  FORECAST_ERROR_METHOD,
  HYBRID_METHOD,
} from './ViewInventory.constants'

const safetyStockMethods = [
  { key: HYBRID_METHOD, value: HYBRID_METHOD },
  { key: FORECAST_ERROR_METHOD, value: FORECAST_ERROR_METHOD },
  { key: DEMAND_VARIABILITY_METHOD, value: DEMAND_VARIABILITY_METHOD },
  { key: CUSTOM_METHOD, value: CUSTOM_METHOD },
]

const safetyStockTypes = [
  { key: 'Duration based', value: 'Duration based' },
  { key: 'Fixed volume', value: 'Fixed volume' },
]

const getColumnKey = (columnId: string) => {
  switch (columnId) {
    case 'chosenMethodTotalBAUDuration':
      return 'duration'
    case 'chosenMethodTotalBAUVolume':
      return 'volume'
    case 'chosenMethod':
      return 'method'
    case 'safetyStockType':
      return 'safetyStockType'
    default:
      return columnId
  }
}

export const ViewInventoryTable = ({
  data,
  isLoading,
  //handleRowClick,
  updateTableData,
  updatedRowValues,
  getFilteredRows,
}: ViewInventoryTableProps) => {
  const handleValidation = (value: string | number) => {
    const valueAsNumber = parseFloat(value as string)
    const VALID_NUMBER_PATTERN = /^[+]?((\.\d{0,2})|(\d+(\.\d{0,2})?)|(\d+\.))$/
    const isValid = value?.toString().match(VALID_NUMBER_PATTERN)
    const isEmpty = value === ''
    return (isValid && valueAsNumber > 0) || isEmpty ? '' : 'error'
  }
  const updateData = (
    rowId: string,
    columnId: keyof ViewInventoryColumns,
    value:
      | string
      | number
      | ChosenMethodTotalBAUVolume
      | ChosenMethodTotalBAUDuration
      | null
  ) => {
    const columnKey = getColumnKey(columnId)
    let newUpdatedCellValue = {}
    let updatedData = {}
    const updatedTableData = data.map((row, i) => {
      if (i === parseInt(rowId)) {
        if (columnId === 'chosenMethodTotalBAUDuration') {
          const { customBAUDuration } = value as ChosenMethodTotalBAUDuration
          newUpdatedCellValue = {
            skuGroupKey: row.skuGroupKey,
            [columnKey]: customBAUDuration,
            ['chosenMethodTotalBAUVolume']: {
              ...row.chosenMethodTotalBAUVolume,
              customBAUVolume: (customBAUDuration * row.dailySalesRate).toFixed(
                2
              ),
            },
            ['safetyStockType']: 'Duration based',
          }

          updatedData = {
            ...row,
            [columnId]: value,
            ['chosenMethodTotalBAUVolume']: {
              ...row.chosenMethodTotalBAUVolume,
              customBAUVolume: customBAUDuration * row.dailySalesRate,
            },
            ['safetyStockType']: 'Duration based',
          }
        } else if (columnId === 'chosenMethodTotalBAUVolume') {
          const { customBAUVolume } = value as ChosenMethodTotalBAUVolume
          newUpdatedCellValue = {
            skuGroupKey: row.skuGroupKey,
            [columnKey]: customBAUVolume,
            ['chosenMethodTotalBAUDuration']: {
              ...row.chosenMethodTotalBAUDuration,
              customBAUDuration:
                row.dailySalesRate > 0
                  ? (customBAUVolume / row.dailySalesRate).toFixed(2)
                  : 0,
            },
            ['safetyStockType']: 'Fixed volume',
          }

          updatedData = {
            ...row,
            [columnId]: value,
            ['chosenMethodTotalBAUDuration']: {
              ...row.chosenMethodTotalBAUVolume,
              customBAUDuration:
                row.dailySalesRate > 0
                  ? (customBAUVolume / row.dailySalesRate).toFixed(2)
                  : 0,
            },
            ['safetyStockType']: 'Fixed volume',
          }
        } else {
          newUpdatedCellValue = {
            skuGroupKey: row.skuGroupKey,
            [columnKey]: value,
          }
          updatedData = {
            ...row,
            [columnId]: value,
          }
        }

        return updatedData
      } else return row
    }) as ViewInventoryColumns[]

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateTableData(updatedTableData, newUpdatedCellValue as any)
  }
  const classes = useStyles()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: Column<ViewInventoryColumns>[] | any = [
    {
      Header: 'SKU group',
      accessor: 'skuGroup',
      Filter: ColumnFilter,
      filter: multiSelectFilter,
    },
    {
      Header: 'Base source site',
      accessor: 'baseSourceSite',
      Filter: ColumnFilter,
      filter: multiSelectFilter,
    },
    {
      Header: 'Work centre',
      accessor: 'workcentreCode',
      Filter: ColumnFilter,
      filter: multiSelectFilter,
    },
    {
      Header: 'Segment',
      accessor: 'segment',
      Filter: ColumnFilter,
      filter: multiSelectFilter,
    },
    {
      Header: 'Daily sales rate',
      accessor: 'dailySalesRate',
      Filter: ColumnFilter,
      filter: multiSelectFilter,
      numeric: true,
    },
    {
      Header: 'Service level (%)',
      accessor: 'serviceLevel',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Cycle stock (days)',
      accessor: 'cycleStockDuration',
      Filter: ColumnFilter,
      filter: multiSelectFilter,
      numeric: true,
    },
    {
      Header: 'Replenishment Type',
      accessor: 'replenishmentType',
      Filter: ColumnFilter,
      filter: multiSelectFilter,
      numeric: true,
    },
    {
      Header: 'Safety stock (days / volume)',
      alignCenter: true,
      columns: [
        {
          Header: 'Forecast error method',
          disableFilters: true,
          disableSortBy: true,
          alignCenter: true,
          columns: [
            {
              Header: 'Days',
              accessor: 'ssForecastDuration',
              disableFilters: true,
              disableSortBy: true,
              numeric: true,
            },
            {
              Header: 'Volume',
              accessor: 'ssForecastVolume',
              disableFilters: true,
              disableSortBy: true,
              numeric: true,
            },
          ],
        },
        {
          Header: 'Demand variability method',
          disableFilters: true,
          disableSortBy: true,
          alignCenter: true,
          columns: [
            {
              Header: 'Days',
              accessor: 'ssDemandVariabilityDuration',
              disableFilters: true,
              disableSortBy: true,
              numeric: true,
            },
            {
              Header: 'Volume',
              accessor: 'ssDemandVariabilityVolume',
              disableFilters: true,
              disableSortBy: true,
              numeric: true,
            },
          ],
        },
        {
          Header: 'Hybrid method',
          disableFilters: true,
          disableSortBy: true,
          alignCenter: true,
          columns: [
            {
              Header: 'Days',
              accessor: 'ssHybridDuration',
              disableFilters: true,
              disableSortBy: true,
              numeric: true,
            },
            {
              Header: 'Volume',
              accessor: 'ssHybridVolume',
              disableFilters: true,
              disableSortBy: true,
              numeric: true,
            },
          ],
        },
      ],
    },
    {
      Header: 'Chosen method',
      accessor: 'chosenMethod',
      disableFilters: true,
      disableSortBy: true,
      alignCenter: true,
      columnWidth: 70,
      Cell: (props: TableInstance) => {
        return (
          <Box onClick={(e) => e.stopPropagation()}>
            <DropDown
              className={classes.chosenMethodDropdown}
              label={''}
              menuItems={safetyStockMethods}
              value={props.value}
              onChange={(
                e: ChangeEvent<{
                  name?: string | undefined
                  value: unknown
                }>
              ) => {
                e.stopPropagation()
                props.updateData(
                  props.row.id,
                  props.column.id,
                  e.target.value as string
                )
              }}
            />
          </Box>
        )
      },
    },
    {
      Header: 'Chosen method total BAU stock (days / volume)',
      alignCenter: true,
      columns: [
        {
          Header: 'Days',
          accessor: 'chosenMethodTotalBAUDuration',
          disableFilters: true,
          disableSortBy: true,
          numeric: true,
          columnWidth: 150,
          Cell: (props: TableInstance) => {
            const {
              chosenMethod,
              chosenMethodTotalBAUDuration,
              safetyStockType,
            } = props?.row?.values

            const {
              forecastErrorBAUDuration,
              hybridBAUDuration,
              demandVariabilityBAUDuration,
              customBAUDuration,
            } = chosenMethodTotalBAUDuration

            const value =
              chosenMethod === FORECAST_ERROR_METHOD
                ? forecastErrorBAUDuration
                : chosenMethod === HYBRID_METHOD
                ? hybridBAUDuration
                : chosenMethod === DEMAND_VARIABILITY_METHOD
                ? demandVariabilityBAUDuration
                : customBAUDuration

            const isEditable =
              chosenMethod === CUSTOM_METHOD &&
              safetyStockType === 'Duration based'
            const { skuGroupKey } = props?.row?.original

            // disable duration when volume is edited
            const shouldDisableEdit = !!updatedRowValues.find((row) => {
              return row.skuGroupKey === skuGroupKey && row?.volume
            })

            return (
              <Box onClick={(e) => e.stopPropagation()}>
                {isEditable ? (
                  <EditableCell
                    isLoading={false}
                    value={value}
                    isEditDisabled={shouldDisableEdit}
                    updateData={(updatedValue) => {
                      const newBAUDurationValue = {
                        ...chosenMethodTotalBAUDuration,
                        customBAUDuration:
                          typeof updatedValue === 'string' &&
                          updatedValue === ''
                            ? null
                            : parseFloat(updatedValue as string),
                      }

                      props.updateData(
                        props.row.id,
                        props.column.id,
                        newBAUDurationValue
                      )
                    }}
                    handleValidation={handleValidation}
                    isNumeric
                  />
                ) : (
                  value
                )}
              </Box>
            )
          },
        },
        {
          Header: 'Volume',
          accessor: 'chosenMethodTotalBAUVolume',
          disableFilters: true,
          disableSortBy: true,
          numeric: true,
          columnWidth: 150,
          Cell: (props: TableInstance) => {
            const {
              chosenMethod,
              chosenMethodTotalBAUVolume,
              safetyStockType,
            } = props?.row?.values

            const {
              forecastErrorBAUVolume,
              hybridBAUVolume,
              demandVariabilityBAUVolume,
              customBAUVolume,
            } = chosenMethodTotalBAUVolume

            const value =
              chosenMethod === FORECAST_ERROR_METHOD
                ? forecastErrorBAUVolume
                : chosenMethod === HYBRID_METHOD
                ? hybridBAUVolume
                : chosenMethod === DEMAND_VARIABILITY_METHOD
                ? demandVariabilityBAUVolume
                : customBAUVolume

            const isEditable =
              chosenMethod === CUSTOM_METHOD &&
              safetyStockType === 'Fixed volume'
            const { skuGroupKey } = props?.row?.original

            // disable volume when duration is edited
            const shouldDisableEdit = !!updatedRowValues.find((row) => {
              return row.skuGroupKey === skuGroupKey && row?.duration
            })

            return (
              <Box onClick={(e) => e.stopPropagation()}>
                {isEditable ? (
                  <EditableCell
                    isLoading={false}
                    value={value}
                    isEditDisabled={shouldDisableEdit}
                    updateData={(updatedValue) => {
                      const newBAUVolumeValue = {
                        ...chosenMethodTotalBAUVolume,
                        customBAUVolume:
                          typeof updatedValue === 'string' &&
                          updatedValue === ''
                            ? null
                            : parseFloat(updatedValue as string),
                      }

                      props.updateData(
                        props.row.id,
                        props.column.id,
                        newBAUVolumeValue
                      )
                    }}
                    handleValidation={handleValidation}
                    isNumeric
                  />
                ) : (
                  value
                )}
              </Box>
            )
          },
        },
      ],
    },
    {
      Header: 'Current total BAU stock (days / volume)',
      alignCenter: true,
      columns: [
        {
          Header: 'Days',
          accessor: 'currentTotalBAUDuration',
          disableFilters: true,
          disableSortBy: true,
          numeric: true,
        },
        {
          Header: 'Volume',
          accessor: 'currentTotalBAUVolume',
          disableFilters: true,
          disableSortBy: true,
          numeric: true,
        },
      ],
    },
    {
      Header: 'Safety stock type',
      accessor: 'safetyStockType',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      columnWidth: 150,
      Cell: (props: TableInstance) => {
        return (
          <Box onClick={(e) => e.stopPropagation()}>
            <DropDown
              className={classes.safetyStockDropdown}
              label={''}
              menuItems={safetyStockTypes}
              value={props.value}
              onChange={(
                e: ChangeEvent<{
                  name?: string | undefined
                  value: unknown
                }>
              ) => {
                e.stopPropagation()
                props.updateData(
                  props.row.id,
                  props.column.id,
                  e.target.value as string
                )
              }}
            />
          </Box>
        )
      },
    },
  ]
  if (isLoading) {
    return <Loader />
  }

  return (
    <Table
      data={data}
      columns={columns}
      cellPadding="5px 5px"
      //isRowClickable
      //onClickRow={handleRowClick}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateData={updateData as any}
      pageSizeOptions={[10, 15, 25, { label: 'All', value: data?.length }]}
      defaultPageSize={10}
      isTablePaginated
      getFilteredRows={getFilteredRows}
      autoResetFilters={false}
    />
  )
}
