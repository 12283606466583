import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import { useStyles } from './QuarterlyValue.styles'
import { QuarterlyValueProps } from './QuarterlyValue.types'
import { Input } from 'supplyvue-ui'
export const QuarterlyValue = ({
  label,
  handleValueTypeChange,
  quarterlyValue,
  handleQuarterlyValueChange,
}: QuarterlyValueProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Box display="flex" flexDirection="row">
        <Box display="flex" alignItems="end" width="50px">
          <Typography>{label}:</Typography>
        </Box>
        <Box display="flex" flexDirection="column" marginLeft="10px">
          <Box>
            <FormControl component="fieldset">
              <RadioGroup
                id="radio-group"
                row
                aria-label="position"
                name="position"
                defaultValue="unconstrained"
                value={quarterlyValue.type}
                onChange={handleValueTypeChange}
              >
                <FormControlLabel
                  value="byPercentage"
                  control={<Radio color="primary" />}
                  label="By %"
                />
                <FormControlLabel
                  value="byValue"
                  control={<Radio color="primary" />}
                  label="By value"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box>
            <Input
              fullWidth={false}
              value={quarterlyValue.value}
              onChange={handleQuarterlyValueChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
