import { Box, Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { ValidationListProps } from './ValidationList.types'
import { useStyles } from './ValidationList.styles'

export const ValidationListView = ({ validations }: ValidationListProps) => {
  const classes = useStyles()
  return (
    <Box mt={2} className={classes.wrapper}>
      {validations.map((validation) => {
        return (
          <Box key={validation.ruleName} mb={2.5}>
            <Typography variant="h4" className={classes.ruleName}>
              {validation.ruleName}
            </Typography>
            {validation.errors.map((error) => {
              return (
                <Box key={error.label} className={classes.errors}>
                  <Typography variant="h5" className={classes.label}>
                    {error.label}
                  </Typography>
                  <Box>
                    {error.failedRules.map((rule) => {
                      return (
                        <Box key={rule} className={classes.rule}>
                          <ChevronRightIcon fontSize="small" />
                          <Typography variant="body1">{rule}</Typography>
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}
