import { Box, Checkbox } from '@material-ui/core'

import { FormControlLabel } from 'Features/Project/WorkcentreModal/FormControlLabel/FormControlLabel.view'
import { useStyles } from './SourceSiteSelectionControl.styles'
import {
  isAllSourceSitesSelected,
  isSomeSourceSitesSelected,
  isAllProductCountsInSourcesitesZero,
} from 'Features/Project/WorkcentreModal/WorkcentreModal.utils'
import { SourceSiteSelectionControlProps } from 'Features/Project/WorkcentreModal/SourceSiteSelectionControl/SourceSiteSelectionControl.types'

export const SourceSiteSelectionControl = ({
  sourceSiteDetails,
  handleCheckboxChange,
}: SourceSiteSelectionControlProps) => {
  const classes = useStyles()
  return (
    <Box className={classes.selectAll}>
      <FormControlLabel
        label="Select all source sites"
        classes={{ label: classes.label }}
        control={
          <Checkbox
            disabled={isAllProductCountsInSourcesitesZero(sourceSiteDetails)}
            color="primary"
            indeterminate={isSomeSourceSitesSelected(sourceSiteDetails)}
            checked={isAllSourceSitesSelected(sourceSiteDetails)}
            className={classes.checkbox}
            onChange={(event) => handleCheckboxChange(event)}
          />
        }
      />
    </Box>
  )
}
