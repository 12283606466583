import React, { useState, useEffect } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { PlanTemplatePageParams } from 'Features/NewPlanTemplate/NewPlanTemplate.types'
import {
  useDownloadData,
  useMultiData,
  useUploadInventoryFile,
} from 'Features/NewPlanTemplate/ReplenishmentSettings/ReplenishmentSettings.data'
import { ReplenishmentSettingsTable } from 'Features/NewPlanTemplate/ReplenishmentSettings/ReplenishmentSettings.table'
import { Loader } from 'Common/Loader/Loader'
import { useStyles } from './ReplenishmentSettings.styles'
import { Toolbar } from 'Common/Toolbar'
import { ReplenishmentSettingsBulkUpdate } from 'Features/NewPlanTemplate/ReplenishmentSettings/BulkUpdate/BulkUpdate.view'
import { inputValuesInitialState } from './BulkUpdate/BulkUpdate.constants'
import { dropDownvaluesInitialState } from './BulkUpdate/BulkUpdate.constants'
import { mapDropdownMenuItemsInLabel } from 'Common/Utils/utils'
import { includeDropdownIptions } from 'Features/ReplenishmentPolicy/SiteParameters/SiteParameters.constants'
import { useParameterTypeOptionsData } from 'Features/ReplenishmentPolicy/SiteParameters/SiteParameters.data'
import { useUpdateReplenishmentParametersData } from './ReplenishmentSettings.data'
import {
  ReplenishmentSiteParamsTableData,
  skuGroupKeys,
} from './ReplenishmentSettings.types'
import { Button } from 'supplyvue-ui'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import { format } from 'date-fns'
import { toast } from 'react-hot-toast'
import { useDropzone } from 'react-dropzone'
import { MAX_FILE_SIZE } from 'Common/Constants/Constants'

export const ReplenishmentSettingsView = () => {
  const classes = useStyles()

  const {
    snapshotId,
    projectId,
    scenarioId,
    planTemplateId,
    replenishmentPolicyId,
  } = useParams<PlanTemplatePageParams>()

  const history = useHistory()
  const [tableData, setTableData] = useState<any>([])
  const [selectedRows, setSelectedRows] = useState<
    ReplenishmentSiteParamsTableData[]
  >([])
  const [isSiteCalculating, setIsSiteCalculating] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [dropdownValues, setDropdownValues] = useState(
    dropDownvaluesInitialState
  )

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: '.csv',
      maxFiles: 1,
      maxSize: MAX_FILE_SIZE,
    })

  const onUploadError = () => {
    toast.error('Upload failed')
  }

  const onUploadSuccess = () => {
    toast.success('Upload successful')
  }

  const { mutate: uploadInventoryData, isLoading: isFileUploading } =
    useUploadInventoryFile(onUploadSuccess, onUploadError)

  const handleUploadFile = (file: File) => {
    uploadInventoryData({
      planTemplateId,
      replenishmentPolicyId,
      uploadType: 'Inventory',
      file,
    })
  }

  useEffect(() => {
    if (fileRejections.length) {
      toast.error('File size cannot exceed 50MB')
    } else if (acceptedFiles.length) {
      handleUploadFile(acceptedFiles[0])
    }
  }, [acceptedFiles, fileRejections])

  const handleViewNetwork = () => {
    history.push(
      `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings`
    )
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  const {
    data: multiData,
    isLoading: isMultiLoading,
    isFetching,
  } = useMultiData({
    projectId,
    scenarioId,
    replenishmentPolicyId,
  })

  const onEditClick = () => {
    handleModalOpen()
  }

  const onDownloadSuccess = (data: any) => {
    const fileName = `ReplenishmentSettings__${format(new Date(), 'yyyyMMdd')}`
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.csv`)
    document.body.appendChild(link)
    link.click()
    toast.success('Successfully downloaded Sku Data')
  }

  const onDownloadError = () => {
    toast.error('Error Downloading Sku Settings')
  }

  const { mutate: downloadData, isLoading: isDownloading } = useDownloadData(
    onDownloadSuccess,
    onDownloadError
  )

  const { isLoading: isTypeOptionsloading, data: typeOptionsData } =
    useParameterTypeOptionsData(snapshotId)

  const onSuccess = () => {
    setInputValues(inputValuesInitialState)
    setDropdownValues(dropDownvaluesInitialState)
  }

  const {
    isLoading: isupdatingReplenishmentSiteParams,
    mutate: updateReplenishmentSiteParams,
  } = useUpdateReplenishmentParametersData(onSuccess)

  const handleDropdownChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof dropdownValues
    setDropdownValues({
      ...dropdownValues,
      [name]: event.target.value as string,
    })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'serviceLevel') {
      const value = parseFloat(event.target.value)
      if ((value >= 0 && value < 100) || event.target.value === '') {
        setInputValues({
          ...inputValues,
          [event.target.name]: event.target.value,
        })
      }
    } else if (
      event.target.name === 'replenishmentCycle' ||
      event.target.name === 'deploymentLeadTime'
    ) {
      const regex = /^([0-9]){0,4}$/i
      if (event.target.value === '' || regex.test(event.target.value)) {
        setInputValues({
          ...inputValues,
          [event.target.name]: event.target.value,
        })
      }
    } else if (event.target.name === 'MOI' || event.target.name === 'MOQ') {
      const regex = /^([0-9]){0,15}$/i
      if (event.target.value === '' || regex.test(event.target.value)) {
        setInputValues({
          ...inputValues,
          [event.target.name]: event.target.value,
        })
      }
    } else if (event.target.name === 'planningPeriod') {
      const regex = /^([0-9]){0,15}$/i
      if (event.target.value === '' || regex.test(event.target.value)) {
        setInputValues({
          ...inputValues,
          [event.target.name]: event.target.value,
        })
      }
    }
  }

  const isApplyParametersDisabled = (): boolean => {
    return (
      (dropdownValues.supplyType === '' &&
        dropdownValues.replenishmentType === '' &&
        dropdownValues.includeType === '' &&
        inputValues.replenishmentCycle === '' &&
        inputValues.deploymentLeadTime === '' &&
        inputValues.MOI === '' &&
        inputValues.MOQ === '' &&
        inputValues.planningPeriod === '' &&
        inputValues.serviceLevel === '') ||
      parseFloat(inputValues.serviceLevel) < 50 ||
      parseFloat(inputValues.serviceLevel) > 99.99
    )
  }

  const handleUpdateReplenishmentSiteParams = () => {
    const filteredSkuGroupKeys = selectedRows.reduce(
      (total: skuGroupKeys, currentValue: ReplenishmentSiteParamsTableData) => {
        let baseKey = currentValue.baseSourceSiteKey
        let destKey = currentValue.destinationSiteKey
        if (!total.hasOwnProperty(baseKey)) {
          total[baseKey] = {}
          total[baseKey][destKey] = [currentValue.skuGroupKey]
        } else {
          if (!total[baseKey].hasOwnProperty(destKey)) {
            total[baseKey][destKey] = [currentValue.skuGroupKey]
          } else {
            total[baseKey][destKey].push(currentValue.skuGroupKey)
          }
        }
        return total
      },
      {}
    )

    updateReplenishmentSiteParams({
      projectId,
      replenishmentPolicyId,
      skuGroupDetails: filteredSkuGroupKeys,
      fields: {
        ...(dropdownValues.supplyType && {
          supplyTypeId: parseInt(dropdownValues.supplyType),
        }),
        ...(dropdownValues.replenishmentType && {
          replenishmentTypeId: parseInt(dropdownValues.replenishmentType),
        }),
        ...(dropdownValues.includeType !== '' && {
          include: dropdownValues.includeType,
        }),
        ...(inputValues.replenishmentCycle && {
          replenishmentCycle: parseInt(inputValues.replenishmentCycle),
        }),
        ...(inputValues.deploymentLeadTime && {
          deploymentLeadTime: parseInt(inputValues.deploymentLeadTime),
        }),
        ...(inputValues.MOI && {
          minimumOrderIncrement: parseInt(inputValues.MOI),
        }),
        ...(inputValues.MOQ && {
          minimumOrderQuantity: parseInt(inputValues.MOQ),
        }),
        ...(inputValues.serviceLevel && {
          serviceLevel: parseFloat(inputValues.serviceLevel),
        }),
        ...(inputValues.planningPeriod && {
          planningPeriod: parseInt(inputValues.planningPeriod),
        }),
      },
    })
  }

  const handleClearButtonClick = () => {
    setInputValues(inputValuesInitialState)
    setDropdownValues(dropDownvaluesInitialState)
  }

  const handleDownload = () => {
    downloadData({
      projectId,
      replenishmentPolicyId,
      scenarioId,
    })
  }

  useEffect(() => {
    if (multiData) {
      setTableData(multiData)
    }
  }, [multiData])

  if (isMultiLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 720,
        }}
      >
        <Loader />
      </Box>
    )
  }

  return (
    <Box className={classes.replenishmentView}>
      <ReplenishmentSettingsBulkUpdate
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        inputValues={inputValues}
        dropdownValues={dropdownValues}
        handleDropdownChange={handleDropdownChange}
        handleInputChange={handleInputChange}
        isDataUpdating={isupdatingReplenishmentSiteParams || isFetching}
        isApplyParametersDisabled={isApplyParametersDisabled()}
        handleUpdateReplenishmentSiteParams={
          handleUpdateReplenishmentSiteParams
        }
        supplyTypeOptions={mapDropdownMenuItemsInLabel(
          typeOptionsData.data.supplyType
        )}
        replenishmentTypeOptions={mapDropdownMenuItemsInLabel(
          typeOptionsData.data.replenishmentType
        )}
        includeTypeOptions={includeDropdownIptions}
        handleClearButtonClick={handleClearButtonClick}
        isCalculating={isSiteCalculating}
      />
      <Box className={classes.buttonPanel}>
        <Box className={classes.bulkUpdate}>
          <Toolbar onEditClick={onEditClick} />
        </Box>
        <Box className={classes.buttonGroup}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={handleDownload}
            startIcon={
              !isDownloading ? (
                <GetAppIcon />
              ) : (
                <CircularProgress
                  color="inherit"
                  size={16}
                  sx={{
                    marginRight: '8px',
                  }}
                />
              )
            }
            disabled={isDownloading}
          >
            Download CSV File
          </Button>
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              className={classes.button}
              variant="outlined"
              disabled={isFileUploading}
              startIcon={
                !isFileUploading ? (
                  <PublishIcon />
                ) : (
                  <CircularProgress
                    color="inherit"
                    size={16}
                    sx={{
                      marginRight: '8px',
                    }}
                  />
                )
              }
            >
              Upload CSV File
            </Button>
          </Box>

          <Button
            className={classes.button}
            variant="contained"
            disabled={false}
            onClick={handleViewNetwork}
          >
            View Network
          </Button>
        </Box>
      </Box>
      <ReplenishmentSettingsTable
        data={tableData}
        setSelectedRows={setSelectedRows}
        isLoading={isMultiLoading || isFileUploading || isFetching}
      />
    </Box>
  )
}
