import { Box } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { PlanTemplatePageParams } from 'Features/NewPlanTemplate/NewPlanTemplate.types'
import { useWorkcentreSettingsData } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.data'
import { useUpdatGroupSettings } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/MakerBatch/MakerBatch.data'
import { useEffect, useRef, useState } from 'react'
import { BatchTable } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/MakerBatch/MakeBatch.table'
import { Loader } from 'Common/Loader/Loader'
import {
  BatchSettings,
  MakerBatchProps,
} from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/MakerBatch/MakerBatch.types'
import { useStyles } from './MakerBatch.styles'
import { Toolbar } from 'Common/Toolbar'
import { BatchSettingsBulkEditView } from './BulkUpdate/BulkUpdate.view'
import { CYCLE_MENU_ITEMS } from 'Features/PlanTemplate/BatchSettings/BatchSettingsForm/BatchSettings.constants'
import {
  mapDropdownMenuItemsInLabel,
  mapDropdownMenuItemsInName,
} from 'Common/Utils/utils'
import { BatchSettingsParametersType } from './BulkUpdate/BulkUpdate.types'
import { INITIAL_STATE as FORM_INITIAL_STATE } from './BulkUpdate/BulkUpdate.constant'
import { toast } from 'react-hot-toast'
import { ErrorData } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.types'

const INITIAL_STATE = {
  minimumBatchSize: '',
  minimumBatchSizeIncrement: '',
  replenishmentTypeId: '',
  minimumCycle: '',
  maximumCycle: '',
  runRate: '',
}
export const MakerBatchView = ({ dropdownOptions }: MakerBatchProps) => {
  const { snapshotId, projectId, scenarioId, planTemplateId } =
    useParams<PlanTemplatePageParams>()
  const [selectedRows, setSelectedRows] = useState<BatchSettings[] | null>([])
  const [errorData, setErrorData] = useState<null | ErrorData>(null)
  const [isIncludeUpdating, setIsIncludeUpdating] = useState(false)
  const bulkEditData = useRef(INITIAL_STATE)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formData, setFormData] =
    useState<BatchSettingsParametersType>(FORM_INITIAL_STATE)
  const classes = useStyles()

  const { data, isLoading } = useWorkcentreSettingsData(
    snapshotId,
    projectId,
    planTemplateId
  )
  const [tableData, setTableData] = useState<any>([])

  const onSuccess = (
    message: string,
    updatedData: BatchSettings[],
    updatedBatchGrouping: BatchSettings[]
  ) => {
    {
      setFormData(FORM_INITIAL_STATE)
      setErrorData(null)
      setTableData(updatedBatchGrouping)
      setIsIncludeUpdating(false)
      handleModalClose()
    }

    toast.success(message)
  }

  const onError = (errorData: ErrorData) => {
    setErrorData(errorData)
  }
  const { mutate: updateGroupSettings, isLoading: isUpdating } =
    useUpdatGroupSettings(onSuccess, onError)

  const onEditClick = () => {
    setIsModalOpen(true)
  }

  const applyCalculations = (
    editedTableData: BatchSettingsParametersType,
    field: string,
    batchGroup: number | string
  ): number => {
    let value = parseFloat(
      editedTableData[field as keyof BatchSettingsParametersType] as string
    )
    if (
      editedTableData[
        `${field}ActionType` as keyof BatchSettingsParametersType
      ] === '1'
    ) {
      value =
        tableData.find((x) => x.batchGroupingCode === batchGroup)[field] *
        (editedTableData[`${field}`] / 100 + 1)
    } else if (
      editedTableData[
        `${field}ActionType` as keyof BatchSettingsParametersType
      ] === '2'
    ) {
      value =
        tableData.find((x) => x.batchGroupingCode === batchGroup)[field] *
        ((100 - editedTableData[`${field}`]) / 100)
    }
    return parseFloat(value.toFixed(5) as string)
  }

  const handleApplyParametersClick = async (
    editedTableData: BatchSettingsParametersType
  ) => {
    let workcentreKeys = {}
    // This assumes that every sku has a single uniquesourcesite
    let sourceSiteKeys = {}
    if (selectedRows) {
      workcentreKeys = selectedRows.reduce((accumulator: any, value) => {
        if (!accumulator.hasOwnProperty(value.batchGroupingCode)) {
          accumulator[value.workcentreKey] = []
          // @ts-ignore
          sourceSiteKeys[value.workcentreKey] = value.sourceSiteKey
        }
        return accumulator
      }, {})
    }

    bulkEditData.current = editedTableData

    setErrorData(null)

    const batchGroups =
      selectedRows &&
      selectedRows.map((row: BatchSettings) => {
        const structure = {
          [row.batchGroupingCode as string]: {
            ...(editedTableData.minimumBatchSize && {
              minimumBatchSize: applyCalculations(
                editedTableData,
                'minimumBatchSize',
                row.batchGroupingCode as string | number
              ),
            }),
            ...(editedTableData.minimumBatchSizeIncrement && {
              minimumBatchSizeIncrement: applyCalculations(
                editedTableData,
                'minimumBatchSizeIncrement',
                row.batchGroupingCode as string | number
              ),
            }),

            ...(editedTableData.replenishmentTypeId && {
              replenishmentTypeId: parseInt(
                editedTableData.replenishmentTypeId as string
              ),
            }),
            ...(editedTableData.minimumCycle && {
              minimumCycle: parseInt(editedTableData.minimumCycle as string),
            }),
            ...(editedTableData.maximumCycle && {
              maximumCycle: parseInt(editedTableData.maximumCycle as string),
            }),
            ...(editedTableData.runRate && {
              runRate: applyCalculations(
                editedTableData,
                'runRate',
                row.batchGroupingCode as string | number
              ),
            }),
          },
        }
        // @ts-ignore
        workcentreKeys[row.workcentreKey].push(structure)
      })

    const fields = {
      ...(editedTableData.minimumBatchSize && {
        minimumBatchSize: parseFloat(
          editedTableData.minimumBatchSize as string
        ),
      }),
      ...(editedTableData.minimumBatchSizeIncrement && {
        minimumBatchSizeIncrement: parseFloat(
          editedTableData.minimumBatchSizeIncrement as string
        ),
      }),

      ...(editedTableData.replenishmentTypeId && {
        replenishmentTypeId: parseInt(
          editedTableData.replenishmentTypeId as string
        ),
      }),
      ...(editedTableData.minimumCycle && {
        minimumCycle: parseInt(editedTableData.minimumCycle as string),
      }),
      ...(editedTableData.maximumCycle && {
        maximumCycle: parseInt(editedTableData.maximumCycle as string),
      }),
      ...(editedTableData.runRate && {
        runRate: parseInt(editedTableData.runRate as string),
      }),
    }

    const updateMode = 'BATCH'
    updateGroupSettings({
      planTemplateId,
      snapshotId,
      projectId,
      updateMode,
      data: {
        batchGroups: workcentreKeys,
        fields,
        sourceSites: sourceSiteKeys,
      },
    })
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }
  useEffect(() => {
    if (data) {
      setTableData(data.batchGroupingSettings)
    }
  }, data)

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Box className={classes.BatchView}>
        <BatchSettingsBulkEditView
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          handleSaveClick={handleApplyParametersClick}
          selectedRows={selectedRows ?? []}
          maximumCycleTypes={CYCLE_MENU_ITEMS}
          minimumCycleTypes={CYCLE_MENU_ITEMS}
          unitOrderMeasureTypes={mapDropdownMenuItemsInName(
            dropdownOptions.unitOfMeasure
          )}
          replenishmentTypes={mapDropdownMenuItemsInLabel(
            dropdownOptions.replenishmentType
          )}
          isUpdating={isUpdating}
          hideRunRate={false}
          data={formData}
          setData={setFormData}
          //hideRunRate={templateBasedOn !== COMBINED_MAKER_AND_LINE}
        />
        <Box className={classes.toolbar}>
          <Box className={classes.bulkUpdate}>
            <Toolbar onEditClick={onEditClick} />
          </Box>
        </Box>

        <BatchTable data={tableData} setSelectedRows={setSelectedRows} />
      </Box>
    </>
  )
}
