export const INITIAL_STATE = {
  batchGroupingCode: '',
  minimumBatchSize: '',
  minimumBatchSizeIncrement: '',
  unitOfMeasure: '',
  unitOfMeasureId: '',
  replenishmentType: '',
  replenishmentTypeId: '',
  salesRate: '',
  minimumCycle: '',
  maximumCycle: '',
  salesWeeksPerMinimumBatch: 'not applicable',
}

export const CYCLE_MENU_ITEMS = [
  {
    value: 1,
    key: '1',
  },
  {
    value: 2,
    key: '2',
  },
  {
    value: 4,
    key: '4',
  },
  {
    value: 8,
    key: '8 ',
  },
]

export const START_WEEK_ITEMS = [
  { key: 'None', value: -1 },
  ...[...Array(8)].map((_, i) => ({ key: (i + 1).toString(), value: i + 1 })),
]
