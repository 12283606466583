import { useQuery } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import { Tenant, TenantListResponse } from './TenantList.types'

const fetchTenantList = async () => {
  const { data } = await request({
    url: `/user-tenants`,
  })
  return data
}

export const useTenantListData = () => {
  return useQuery(['tenants'], () => fetchTenantList(), {
    select: (data: TenantListResponse): Tenant[] => {
      return data.data
    },
  })
}
