export const mapReplenishmentType = (
  items: { id: number; label: string }[]
) => {
  return items?.map((item) => {
    return {
      key: item.label,
      value: item.label,
    }
  })
}
