import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
    minHeight: '36px',
  },
  divider: {
    height: '22px',
    alignSelf: 'center',
    background: theme.palette.common.black,
    width: '1px',
    margin: '0px 12px',
  },
}))
