import { Box } from '@material-ui/core'
import { InstructionsCardView } from './InstructionsCard/InstructionsCard.view'
import { ChangeoverListView } from './ChangeoverList/ChangeoverList.view'
import {
  BatchChangeoverProps,
  BatchGroupingToggle,
} from './BatchChangeover.types'
import { Button, ToggleButton } from 'supplyvue-ui'
import { useEffect, useState } from 'react'
import { BatchGroupChangeoversTable } from './ChangeoverMatrix/ChangeoverMatrix.table'
import { Loader } from 'Common/Loader/Loader'

export const BatchChangeoverView = ({
  downloadFile,
  uploadFile,
  isFileUploading,
  uploadFileData,
  changeoverSummaryData,
  batchGroupingChangeOverTime,
  batchGroupingChangeOverCost,
  batchGroupingChangeOverId,
  updateBatchGroupingChangeoverCells,
  isBatchChangeoverFetched,
  isBatchGroupingChangeoverSummaryLoading,
  refetchBatchChangeoverSummaryData,
}: BatchChangeoverProps) => {
  const [toggleValue, seTtoggleValue] = useState<BatchGroupingToggle>('time')
  const [
    batchGroupingChangeOverTimeTableData,
    setbatchGroupingChangeOverTimeTableData,
  ] = useState(batchGroupingChangeOverTime)
  const [
    batchGroupingChangeOverCostTableData,
    setbatchGroupingChangeOverCostTableData,
  ] = useState(batchGroupingChangeOverCost)

  useEffect(() => {
    setbatchGroupingChangeOverCostTableData(batchGroupingChangeOverCost)
    setbatchGroupingChangeOverTimeTableData(batchGroupingChangeOverTime)
  }, [batchGroupingChangeOverTime, batchGroupingChangeOverCost])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleSummaryButtonClick = () => {
    refetchBatchChangeoverSummaryData()
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleUploadFile = (file: File) => {
    uploadFile(file, 'batch')
  }

  const getTableWidth = () => window.innerWidth - 136

  const updateTableData = (
    rowIndex: string,
    columnId: string,
    value: string | number
  ) => {
    const tableData =
      toggleValue === 'time'
        ? [...batchGroupingChangeOverTimeTableData]
        : [...batchGroupingChangeOverCostTableData]

    const updatedData = tableData.map((row: any, i: number) => {
      if (i === parseInt(rowIndex)) {
        return {
          ...row,
          [columnId]: parseFloat(value as string),
        }
      } else return row
    })
    toggleValue === 'time'
      ? setbatchGroupingChangeOverTimeTableData([...updatedData])
      : setbatchGroupingChangeOverCostTableData([...updatedData])
  }

  const isTableDataAvailable =
    !!batchGroupingChangeOverTimeTableData?.length ||
    !!batchGroupingChangeOverCostTableData?.length

  if (!isBatchChangeoverFetched) {
    return <Loader />
  }

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          width="100%"
          justifyContent={isTableDataAvailable ? 'space-between' : 'flex-end'}
          alignItems="center"
        >
          {isTableDataAvailable && (
            <Box style={{ height: '30px' }}>
              <ToggleButton
                buttonOne={{ label: 'Time', value: 'time' }}
                buttonTwo={{ label: 'Cost', value: 'cost' }}
                value={toggleValue}
                handleChange={(event, value) => {
                  event.stopPropagation()
                  seTtoggleValue(value ?? 'time')
                }}
              />
            </Box>
          )}
          <Box display="flex" alignSelf="flex-end">
            {isTableDataAvailable && (
              <Button
                variant="text"
                onClick={handleSummaryButtonClick}
                style={{ marginRight: '16px' }}
              >
                Summary
              </Button>
            )}
            <InstructionsCardView
              isUploadingFile={isFileUploading}
              isChangeoverDataLoaded={isTableDataAvailable}
              handleDownloadFile={downloadFile}
              handleUploadFile={handleUploadFile}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          justifyContent={isTableDataAvailable ? 'flex-end' : 'space-between'}
          alignItems="center"
        >
          <ChangeoverListView
            isModalOpen={isModalOpen}
            handleModalClose={handleModalClose}
            changeoverCountLabel="Changeover count"
            changeoverTimeLabel="Changeover time"
            changeoverCostLabel="Changeover cost"
            changeoverData={changeoverSummaryData}
            isUploadingFile={isFileUploading}
            uploadFileData={uploadFileData}
            isLoading={isBatchGroupingChangeoverSummaryLoading}
          />
        </Box>
      </Box>
      {isTableDataAvailable && (
        <Box style={{ width: getTableWidth(), marginTop: '20px' }}>
          <BatchGroupChangeoversTable
            toggleValue={toggleValue}
            data={
              toggleValue === 'time'
                ? batchGroupingChangeOverTimeTableData
                : batchGroupingChangeOverCostTableData
            }
            batchGroupingChangeOverId={batchGroupingChangeOverId}
            isLoading={false}
            updateTableData={updateTableData}
            updateEditedCellValue={updateBatchGroupingChangeoverCells}
          />
        </Box>
      )}
    </Box>
  )
}
