import { AppProviders } from 'Features/App/AppProviders'
import { Routes } from 'Features/App/routes'
import { MuiXLicense } from 'Features/App/MuiXLicence'

export const App = () => {
  return (
    <AppProviders>
      <MuiXLicense />
      <Routes />
    </AppProviders>
  )
}
