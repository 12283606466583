import { request } from 'Features/Auth/axios-client'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  UseUpdateReplenishmentPolicyInputsParams,
  EditedReplenishmentPolicyInputParamas,
} from './ReplenishmentPolicyInputs.types'

const fetchReplenishmentPolicyInputs = async (
  replenishmentPolicyId: string
) => {
  const data = await request({
    url: `/replenishment-policies/${replenishmentPolicyId}/inputs`,
  })
  return data
}
export const useReplenishmentPolicyInputs = (replenishmentPolicyId: string) => {
  return useQuery(
    ['replenishment-policy-inputs', replenishmentPolicyId],
    () => fetchReplenishmentPolicyInputs(replenishmentPolicyId),
    {
      select: (data) => data.data?.data,
      refetchOnWindowFocus: false,
    }
  )
}

const updateReplenishmentPolicyInputs = async (
  replenishmentPolicyData: EditedReplenishmentPolicyInputParamas
) => {
  const { data } = await request({
    url: `/replenishment-policies/${replenishmentPolicyData.replenishmentPolicyId}/inputs`,
    method: 'put',
    data: replenishmentPolicyData.data,
  })
  return data
}

export const useUpdateReplenishmentPolicyInputs = ({
  onSuccess,
  onError,
}: UseUpdateReplenishmentPolicyInputsParams) => {
  const queryClient = useQueryClient()
  return useMutation(updateReplenishmentPolicyInputs, {
    onSuccess: async ({ message }) => {
      await queryClient.invalidateQueries('replenishment-policy-inputs')
      onSuccess(message)
    },
    onError: async ({ response }) => {
      await queryClient.invalidateQueries('replenishment-policy-inputs')
      onError(response.message)
    },
  })
}

// Commenting to kick start a new build.
