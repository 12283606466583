import { useMutation, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import {
  ValidateProductSettingsVariables,
  UseValidateProductSettingsParams,
  GroupSettingsUpdateParams,
  ErrorData,
} from './ProductSettingsForm.types'
import {
  BatchGroupingSettings,
  ProductSettings,
} from 'Features/PlanTemplate/StepCard/StepCard.types'

const validateProductSettings = async (
  validateSettingsVariables: ValidateProductSettingsVariables
) => {
  const { data } = await request({
    url: `/plan-template/validate-settings`,
    method: 'post',
    data: {
      ...validateSettingsVariables,
    },
  })
  return data
}

export const useProductSettingsValidation = ({
  onSuccess,
  onError,
}: UseValidateProductSettingsParams) => {
  return useMutation(validateProductSettings, {
    onSuccess: async () => {
      onSuccess()
    },
    onError: async ({ response }) => {
      onError(response.data.message)
    },
  })
}

const updateGroupSettings = async (groupDetails: GroupSettingsUpdateParams) => {
  const data = await request({
    url: `/plan-templates/${groupDetails.planTemplateId}/settings`,
    method: 'put',
    data: groupDetails.data,
  })

  return data
}

export const useUpdatGroupSettings = (
  onSuccess: (
    message: string,
    updatedData: ProductSettings[],
    updatedBatchGrouping: BatchGroupingSettings[],
    adjustedValues: unknown
  ) => void,
  onError: (errorData: ErrorData) => void
) => {
  const queryClient = useQueryClient()
  return useMutation(updateGroupSettings, {
    onSuccess: async ({ data }, variables) => {
      await queryClient.invalidateQueries([
        'workcentre-settings',
        variables.data.snapshotId,
        variables.data.projectId,
        variables.planTemplateId,
        variables.data.sourceSiteKey,
        variables.data.workcentreKey,
      ])
      onSuccess(
        data.message,
        data?.data?.productSettings,
        data?.data?.batchGroupingSettings,
        data?.data?.adjustedValues
      )
    },
    onError: async ({ response }) => {
      onError(response.data)
    },
  })
}
