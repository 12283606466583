import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
  },
  dropdown: {
    width: '220px',
  },
}))
