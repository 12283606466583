import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.grey,
    borderLeft: `3px solid ${theme.palette.error.main}`,
    height: '100%',
    padding: '8px',
    maxHeight: '480px',
    overflow: 'auto',
  },
  ruleName: {
    color: theme.palette.error.main,
    marginBottom: '12px',
  },
  errors: {
    paddingLeft: '16px',
  },
  label: {
    marginBottom: '8px',
  },
  rule: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
  },
}))
