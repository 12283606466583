import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 800,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '32px 32px',
    width: '100%',
    borderRadius: 10,
    //borderBottom: `4px solid ${theme.palette.secondary.main}`,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectionContainer: {
    marginTop: '16px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '20px',
  },
  cardContainer: {
    height: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `3px solid lightgrey`,
    borderRadius: 12,
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 4px 4px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#f0f8ff',
    },
  },
  selectedCard: {
    height: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `3px solid #007bff`,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f0f8ff',
    borderRadius: 12,
  },
  contentWrapper: {
    minWidth: '600px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  modalActionButton: {
    marginLeft: '20px',
    minWidth: '105px',
  },
  label: {
    padding: '16px 32px',
    color: 'white',
    backgroundColor: `${theme.palette.primary.main}`,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottom: `4px solid ${theme.palette.secondary.main}`,
  },
  progress: {
    marginRight: '8px',
  },
}))
