import { keys } from 'lodash'
import { sortByKeys } from './SkuGroupManagement.constants'

export const getSortKey = (sortBy: any) =>
  `${sortBy?.desc ? '-' : ''}${sortByKeys[sortBy?.id]}`

export const getFilterKey = (fieldName: string) => sortByKeys[fieldName]

export const getFilters = (inputValues: any) => {
  const keyList = keys(inputValues)
  let filter = ''
  keyList.forEach((element) => {
    const filterKey = getFilterKey(element)
    const value = inputValues[element]
    const filterValue =
      typeof value === 'string' && value ? `&${filterKey}=${value}` : ''
    filter = filter + `${filterValue}`
  })
  return filter
}
