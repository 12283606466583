import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `4px solid  ${theme.palette.secondary.main}`,
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 4px 4px',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 4px 4px',
    },
  },
  deleteIcon: {
    borderRadius: '8px',
    fontSize: 24,
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  goToIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    cursor: 'pointer',
    fontSize: 24,
    marginRight: '10px',
    marginBottom: '2px',
    color: `${theme.palette.primary.main}`,
  },
  viewFont: {
    fontWeight: 'normal',
    textDecoration: 'none',
    transition: 'fontWeight textDecoration 0.2s ease-in-out',
    '&:hover': {
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  },
  content: {
    padding: '24px 16px 16px 16px',
    minHeight: '230px',
  },
  title: {
    textTransform: 'uppercase',
    letterSpacing: '0.8px',
    marginBottom: '16px',
    color: '#666',
  },
  bold: {
    fontWeight: 600,
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
  },
  detailIcon: {
    marginRight: '16px',
    color: '#999',
  },
  detailSnapshot: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryText: { color: '#5D5D5D' },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#c5e2ec21',
    padding: '24px 16px 16px 16px',
  },
  footerDetail: {
    display: 'flex',
    alignItems: 'center',
  },
  footerDetailIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '4px',
    color: '#5D5D5D',
  },
  footerText: {
    color: '#4B5563',
  },
  timestamp: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '16px',
    borderLeft: `2px solid ${theme.palette.borders.grey}`,
  },
  publicPrivateIconContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
}))
