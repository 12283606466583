import { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Table } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { SkuTableProps } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.types'
import { Loader } from 'Common/Loader/Loader'
import { SalesHorizonTableProps } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/SalesHorizon.types'
import { useStyles } from './SalesHorizon.styles'

export const SalesHorizonTable = ({
  data,
  setSelectedRows,
}: SalesHorizonTableProps) => {
  const classes = useStyles()

  const tableData = data.map((item, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  const columns: GridColDefPremium[] = useMemo(() => {
    if (!data) {
      return []
    }

    return [
      {
        field: 'workcentreCode',
        headerName: 'Workcentre',
        minWidth: 100,
        type: 'string',
        flex: 1,
      },
      {
        field: 'sourceSiteCode',
        headerName: 'SourceCode',
        minWidth: 100,
        type: 'string',
        flex: 1,
      },
      {
        field: 'salesRateType',
        headerName: 'Sales Rate Type',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'salesRatePeriod',
        headerName: 'Sales Rate Period',
        minWidth: 150,
        type: 'number',
        flex: 1,
      },
      {
        field: 'templateBasedOn',
        headerName: 'Template Based On',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'minimumAverageBatchesPerProductionCycle',
        headerName: 'Min Avg Batches per Cycle',
        minWidth: 150,
        type: 'number',
        flex: 1,
      },
    ]
  }, [data])

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(tableData.filter((row) => selectedIds.has(row.id)))
  }

  if (!data) {
    return <Loader />
  }

  return (
    <>
      <Box className={classes.tableContainer}>
        <Table
          rows={tableData}
          checkboxSelection
          columns={columns}
          showCellVerticalBorder
          showColumnVerticalBorder
          disableRowSelectionOnClick
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
          pagination
          pageSizeOptions={[10, 20, 30, 50]}
          experimentalFeatures={{ lazyLoading: true }}
        />
      </Box>
    </>
  )
}
