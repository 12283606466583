export const SET_DEFAULT_WORKCENTRE_CAPACITY = 'SET_DEFAULT_WORKCENTRE_CAPACITY'
export const SET_WORKCENTRE_CAPACITY = 'SET_WORKCENTRE_CAPACITY'
export const SET_OPTIMIZATION = 'SET_OPTIMIZATION'
export const SET_IS_TEMPLATE_GENERATING = 'SET_IS_TEMPLATE_GENERATING'
export const SET_PLAN_TEMPLATE_GENERATION_VALIDATIONS =
  'SET_PLAN_TEMPLATE_GENERATION_VALIDATIONS'
export const SET_IS_INITIAL_CYCLE_SPEED_AND_SUMMARY_DATA_FETCHED =
  'SET_IS_INITIAL_CYCLE_SPEED_AND_SUMMARY_DATA_FETCHED'
export const SET_IS_CYCLE_SPEED_OR_SUMMARY_DATA_LOADING =
  'SET_IS_CYCLE_SPEED_OR_SUMMARY_DATA_LOADING'
export const SET_SUMMARY_DATA = 'SET_SUMMARY_DATA'
export const SET_CYCLE_SPEED_DATA = 'SET_CYCLE_SPEED_DATA'
export const SET_BUFFER = 'SET_BUFFER'
export const WORKCENTRE_CAPACITY = 'workcentreCapacity'
export const BUFFER = 'buffer'
export const BUFFER_ERROR_MESSAGE =
  'Buffer must be lesser than work centre capacity'
export const COMBINED_WORKCENTRE_CAPACITY = 'combinedWorkcentreCapacity'
export const COMBINED_BUFFER = 'combinedBuffer'
export const SET_COMBINED_WORKCENTRE_CAPACITY =
  'SET_COMBINED_WORKCENTRE_CAPACITY'
export const SET_COMBINED_BUFFER = 'SET_COMBINED_BUFFER'
