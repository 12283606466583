export const SALES_RATE_TYPE = [
  {
    value: 'forecast',
    key: 'forecast',
  },
  { value: 'historical sales', key: 'historical sales' },
]

export const TEMPLATE_BASED_ON = [
  {
    value: 'Line only',
    key: 'Line only',
  },
  {
    value: 'Combined maker and line',
    key: 'Combined maker and line',
  },
]
