import _ from 'lodash'
import { getInitialSettings } from './UpdatePlanTemplateSettingsModal.state'
import {
  PlanTemplateSettingsResponse,
  PlanTemplateSettingsState,
} from './UpdatePlanTemplateSettingsModal.types'

export const havePlanTemplateSettingsChanged = (
  data: PlanTemplateSettingsResponse,
  state: PlanTemplateSettingsState
) => {
  const initialSettings = getInitialSettings(data)
  if (!_.isEqual(initialSettings, state)) {
    return true
  }
  return false
}
