import { Box, Tab, Tabs } from '@material-ui/core'
import { useState } from 'react'
import { TabPanel } from 'supplyvue-ui'
import { TabGroupsProps } from './SummaryOfApprovedSites.types'

export const TabGroups = ({
  firstTabGraph,
  secondGraph,
  thirdGraph,
  firstTabLabel,
  secondTabLabel,
  thirdTabLabel,
  firstTabLegend,
  secondTabLegend,
  thirdTabLegend,
}: TabGroupsProps) => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setTabIndex(newValue)
  }
  return (
    <Box height="500px" margin="-24px">
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label={firstTabLabel} />
        <Tab label={secondTabLabel} />
        <Tab label={thirdTabLabel} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        {firstTabGraph && firstTabGraph}
        {firstTabLegend && firstTabLegend}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {secondGraph && secondGraph}
        {secondTabLegend && secondTabLegend}
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        {thirdGraph && thirdGraph}
        {thirdTabLegend && thirdTabLegend}
      </TabPanel>
    </Box>
  )
}
