import { ContentViewProps } from 'Features/NewPlanTemplate/WorkcentreSettings/WorkcentreSettingsContent/WorkcentreSettingsContent.types'
import { SkuView } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.view'
import { FormatView } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/Format/Format.View'
import { MakerBatchView } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/MakerBatch/MakerBatch.View'

export const ContentView = ({
  step,
  dropdownOptions,
  batchGroupingCodes,
}: ContentViewProps) => {
  switch (step) {
    case 0:
      return (
        <SkuView
          dropdownOptions={dropdownOptions}
          batchGroupingCodes={batchGroupingCodes}
        />
      )
    case 1:
      return <MakerBatchView dropdownOptions={dropdownOptions} />
  }
}
