import { useState } from 'react'
import { format } from 'date-fns'
import { Box, Typography, IconButton, Tooltip, Radio } from '@material-ui/core'
import { Button, Dialog } from 'supplyvue-ui'
import { DemandScenarioCardProps } from './DemandScenarioCard.types'
import { useStyles } from './DemandScenarioCard.styles'
import { motion } from 'framer-motion'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

export const DemandScenarioCard = ({
  viewDemandScenarioLabel,
  demandScenario,
  handleViewDemandScenarioClick,
  handleDeleteDemandScenarionClick,
  handleRadioClick,
}: DemandScenarioCardProps) => {
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)
  const animations = {
    layout: true,
    animate: 'in',
    variants: {
      in: { scaleY: 1, opacity: 1 },
    },
    transition: { type: 'spring', stiffness: 500, damping: 50, mass: 1 },
  }

  const handlePrimaryButtonClick = () => {
    setShowDialog(false)
    handleDeleteDemandScenarionClick(demandScenario.id)
  }

  return (
    <motion.div {...animations}>
      <Box className={classes.card}>
        <Box className={classes.wrapper}>
          <Box>
            <Radio
              checked={demandScenario.active}
              onChange={() => handleRadioClick(demandScenario.id)}
              value="b"
              name="radio-buttons"
              inputProps={{ 'aria-label': 'B' }}
            />
          </Box>
          <Box className={classes.nameWrapper}>
            <Typography variant="h3" className={classes.name}>
              {demandScenario.name}
            </Typography>
            <Typography variant="body2" className={classes.subTitle}>
              {format(new Date(demandScenario.createdOn), 'dd/MM/yyyy')}
            </Typography>
          </Box>

          <Box className={classes.buttonGroupWrapper}>
            <Box className={classes.buttonWrapper}>
              <Box>
                <Button
                  className={classes.textButton}
                  onClick={() =>
                    handleViewDemandScenarioClick(demandScenario.id)
                  }
                >
                  <Typography variant="h4" color="primary">
                    {viewDemandScenarioLabel}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box>
            <Tooltip title="Delete">
              <IconButton color="primary" onClick={() => setShowDialog(true)}>
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Dialog
        title="Delete Scenario?"
        content="Are you sure you want to delete the scenario?"
        primaryButtonLabel="Delete"
        secondaryButtonLabel="Cancel"
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        handlePrimaryButtonClick={handlePrimaryButtonClick}
        handleSecondaryButtonClick={() => setShowDialog(false)}
      />
    </motion.div>
  )
}
