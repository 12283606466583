import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 32px',
    borderBottom: `1px solid ${theme.palette.borders.grey}`,
  },
  nameWrapper: {
    flex: 1,
  },
  name: {
    color: '#333',
  },
  buttonGroupWrapper: {
    display: 'flex',
    flex: 3,
  },
  buttonWrapper: {
    display: 'flex',
    flex: 1,
  },
  subTitle: {
    color: '#666',
  },
  textButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))
