import { useMemo } from 'react'
import { Graph } from 'Common/Graph/Graph'
import { InventoryDurationGraphProps } from './InventoryDuration.types'

export const InventoryDurationGraph = ({
  xAxisData,
  bauDays,
}: InventoryDurationGraphProps) => {
  const hovertemplate = () => {
    return (
      ' <b>Scenario: </b>%{x}<br>' +
      ` <b>BAU (days): </b> %{y}<br>` +
      '<extra></extra>'
    )
  }

  const trace = {
    x: xAxisData,
    y: bauDays,
    name: 'Total cost',
    type: 'bar',
    textfont: { color: 'transparent' },
    width: 0.45,
    hovertemplate: hovertemplate(),
    marker: {
      color: '#4E79A7',
    },
  }

  const data = useMemo(() => [trace], [xAxisData, bauDays])

  const layout = {
    yaxis: {
      showgrid: true,
      title: {
        text: 'BAU (days)',
        standoff: 20,
      },
      automargin: true,
    },
    xaxis: {
      zeroline: true,
      nticks: 10,
    },
    margin: {
      b: 100,
    },
  }
  return <Graph data={data} layout={layout} />
}
