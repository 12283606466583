import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { useQueryClient, useIsFetching } from 'react-query'
import { Link as NavLink } from 'react-router-dom'
import {
  Paper,
  Breadcrumbs as MUIBreadcrumbs,
  Typography,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { routes } from 'Features/App/routes'
import { useStyles } from './Breadcrumbs.styles'
import {
  excludePathsFromBreadcrumbs,
  getBreadCrumbLabel,
  getBreadCrumbLabelSuffix,
} from './Breadcrumbs.utils'

export const Breadcrumbs = () => {
  const queryClient = useQueryClient()
  const isFetchingBreadcrumbs = useIsFetching('breadcrumbs')
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: excludePathsFromBreadcrumbs,
  })
  const classes = useStyles()

  if (isFetchingBreadcrumbs) {
    return null
  }

  const appLabels = queryClient.getQueryData('breadcrumbs') as Record<
    string,
    never
  >

  if (!breadcrumbs.length || !appLabels) {
    return null
  }

  return (
    <Paper className={classes.wrapper}>
      <MUIBreadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map(({ breadcrumb, match }, index) => {
          const { path } = match as any
          return breadcrumbs.length - 1 === index ? (
            <Typography key={index} color="textPrimary">
              {/** @ts-ignore  */}
              {getBreadCrumbLabel(appLabels.data, breadcrumb?.props.children)}
              {getBreadCrumbLabelSuffix(path)}
            </Typography>
          ) : (
            <NavLink key={index} to={match.url} className={classes.link}>
              {/** @ts-ignore  */}
              {getBreadCrumbLabel(appLabels.data, breadcrumb?.props.children)}
              {getBreadCrumbLabelSuffix(path)}
            </NavLink>
          )
        })}
      </MUIBreadcrumbs>
    </Paper>
  )
}
