import { Box, Typography } from '@material-ui/core'
import { useState, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { Button, Modal } from 'supplyvue-ui'
import { useStyles } from './ExportTemplateModal.styles'
import { ExportModalProps } from 'Features/PlanTemplateSummary/ExportTemplateModal/ExportTemplateModal.types'
import { CircularProgress } from '@mui/material'

export const ExportTemplateModal = ({
  isOpen,
  handleClose,
  handleExportTemplateConfirm,
  isUpdating,
}: ExportModalProps) => {
  const classes = useStyles()
  const [dateString, setDateString] = useState<string>('')
  const format = 'DD-MM-YYYY'
  const [value, setValue] = useState<Dayjs | null>(dayjs('01-01-2024', format))

  const isNotMonday = (date: Dayjs) => {
    return !dayjs(date).day(1).isSame(date, 'day')
  }

  useEffect(() => {
    if (value) {
      setDateString(value.format(format))
    }
  }, [value])

  return (
    <Box>
      <Modal
        disableBackdropClick
        header={'Export Templates'}
        open={isOpen}
        handleClose={handleClose}
        body={
          <Box className={classes.exportModal}>
            <Box className={classes.inputData}>
              <Typography variant="h5">Template Start Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                  format={format}
                  shouldDisableDate={isNotMonday}
                />
              </LocalizationProvider>
            </Box>
            <Box className={classes.buttonGroup}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleClose}
                size="small"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => handleExportTemplateConfirm(dateString)}
                disabled={isUpdating}
              >
                {isUpdating && (
                  <CircularProgress
                    color="inherit"
                    size={16}
                    sx={{ marginRight: '8px' }}
                  />
                )}
                Confirm
              </Button>
            </Box>
          </Box>
        }
      />
    </Box>
  )
}
