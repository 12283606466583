import { ListItem, ListItemText } from '@material-ui/core'
import { SummaryListItemProps } from './Summary.types'
import { useStyles } from './Summary.styles'

export const SummaryListItem = ({ listItem }: SummaryListItemProps) => {
  const classes = useStyles()
  return (
    <ListItem className={classes.listItem}>
      <ListItemText primary={listItem.label} />
      <ListItemText
        primary={listItem.value}
        classes={{ primary: classes.valueText }}
      />
    </ListItem>
  )
}
