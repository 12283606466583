/** Not in use - refere planTemplateGeneration.view instead */
import React, { useState, useEffect, useMemo } from 'react'
import { toast } from 'react-hot-toast'
import { Page } from 'Common/Page/Page'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { PageHeader } from 'Common/PageHeader/PageHeader'
import { Loader } from 'Common/Loader/Loader'
import { useStyles } from './PlanTemplateGeneration.styles'
import { SummaryList } from './Summary/SummaryList.view'
import {
  PlanTemplatePageParams,
  SourceSiteOption,
  WorkCenterOption,
  WeeklyData,
  Validation,
  EnqueueErrorResponse,
} from './PlanTemplateGeneration.types'
import { useSourceSitesData } from 'Features/PlanTemplate/PlanTemplate.data'
import { ScenarioFiltersView } from 'Features/PlanTemplate/ScenarioFilters/ScenarioFilters.view'
import { WorkcentreConstraintsView } from './WorkcentreConstraints/WorkcentreConstraints.view'
import {
  useCalculatePlanTemplate,
  useCycleSpeedGraphData,
  useSummaryData,
} from './PlanTemplateGeneration.data'
import { CycleSpeedView } from './CycleSpeed/CycleSpeed.view'
import { SummaryGraph } from './Summary/Summary.graph'
import { Optimization } from './WorkcentreConstraints/WorkcentreConstraints.types'
import { ValidationListView } from './ValidationList/ValidationList.view'
import { isInteger } from 'Common/Utils/utils'
import { SummaryItem } from './Summary/Summary.types'
import { PlanDetailsView } from './PlanDetails/PlanDetails.view'
export const PlanTemplateGenerator = () => {
  const history = useHistory()
  const classes = useStyles()
  const {
    projectId,
    scenarioId,
    sourceSiteId,
    workcentreId,
    planTemplateId,
    snapshotId,
  } = useParams<PlanTemplatePageParams>()

  useBreadcrumbsData({
    project_id: projectId,
    plan_template_id: planTemplateId,
  })

  const [defaultWorkCenterCapacity, setDefaultWorkCenterCapacity] = useState('')
  const [workcentreCapacity, setWorkcentreCapacity] = useState('')
  const [optimization, setOptimization] = useState<Optimization>('constrained')

  const [isTemplateGenerating, setIsTemplateGenerating] = useState(true)
  const [validations, setValidations] = useState<Validation[]>([])

  const [
    isInitialCycleSpeedAndSummaryDataFetched,
    setIsInitialCycleSpeedAndSummaryDataFetched,
  ] = useState(false)

  const onCycleSpeedSuccess = (
    isGenerating: boolean,
    capacity: number,
    constrained: boolean
  ) => {
    if (isGenerating) {
      setIsTemplateGenerating(true)
    } else {
      setDefaultWorkCenterCapacity(capacity?.toString() || '')
      setWorkcentreCapacity(capacity?.toString() || '')
      setOptimization(constrained ? 'constrained' : 'unconstrained')
      setIsTemplateGenerating(false)
    }
  }

  const onCycleSpeedError = () => {
    setIsTemplateGenerating(false)
  }

  const {
    isLoading: isCyceSpeedDataLoading,
    data: cycleSpeedData,
    refetch: refetchCycleSpeedData,
    isFetched: isCyceSpeedDataFetched,
  } = useCycleSpeedGraphData(
    projectId,
    planTemplateId,
    parseInt(sourceSiteId),
    parseInt(workcentreId),
    optimization === 'constrained',
    onCycleSpeedSuccess,
    onCycleSpeedError
  )

  const {
    isLoading: isSummaryDataLoading,
    data: summaryData,
    isFetched: isSummaryDataDataFetched,
  } = useSummaryData(
    planTemplateId,
    parseInt(sourceSiteId),
    parseInt(workcentreId),
    optimization
  )

  useEffect(() => {
    if (isCyceSpeedDataFetched && isSummaryDataDataFetched)
      setIsInitialCycleSpeedAndSummaryDataFetched(true)
  }, [isCyceSpeedDataFetched, isSummaryDataDataFetched])

  const [selectedSourceSite, setSelectedSourceSite] =
    useState<SourceSiteOption | null>(null)

  const [workcenters, setWorkcenters] = useState<WorkCenterOption[] | []>([])
  const [selectedWorkCenter, setSelectedWorkCenter] =
    useState<WorkCenterOption | null>(null)

  const { data: sourceSites, isLoading: isSourceSitesLoading } =
    useSourceSitesData(projectId, scenarioId)

  const onCalculatePlanTemplateSuccess = () => {
    setIsTemplateGenerating(true)
  }

  const onCalculatePlanTemplateError = (
    errorResponse: EnqueueErrorResponse
  ) => {
    toast.error(errorResponse.message)
    setValidations(errorResponse.validations)
  }

  const { mutate: calculatePlanTemplate, isLoading: isCalculating } =
    useCalculatePlanTemplate(
      onCalculatePlanTemplateSuccess,
      onCalculatePlanTemplateError
    )

  useEffect(() => {
    if (sourceSites) {
      const selectedSourceSiteFromSummaryPage =
        sourceSites.baseSourceSites.filter(
          (sourceSite: SourceSiteOption) =>
            sourceSite.value === parseInt(sourceSiteId)
        )[0]
      const selectedWorkcentreFromSummaryPage =
        selectedSourceSiteFromSummaryPage.workcentres.filter(
          (workcenter: WorkCenterOption) =>
            workcenter.key === parseInt(workcentreId)
        )[0]
      setSelectedSourceSite(selectedSourceSiteFromSummaryPage)
      setWorkcenters(selectedSourceSiteFromSummaryPage.workcentres)
      setSelectedWorkCenter(selectedWorkcentreFromSummaryPage)
    }
  }, [sourceSites, sourceSiteId, workcentreId])

  const handleSourceSiteChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    option: SourceSiteOption | null
  ) => {
    setWorkcentreCapacity('')
    setValidations([])
    if (option?.value) {
      setSelectedSourceSite(option)
      setWorkcenters(option.workcentres)
      setSelectedWorkCenter(null)
    } else {
      setSelectedSourceSite(null)
      setSelectedWorkCenter(null)
    }
  }

  const handleWorkcenterChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    option: WorkCenterOption | null
  ) => {
    setWorkcentreCapacity('')
    setValidations([])
    if (option) {
      setSelectedWorkCenter(option)
      history.push(
        `/projects/${projectId}/snapshots/${snapshotId}/scenarios/${scenarioId}/summary/${planTemplateId}/${selectedSourceSite?.value}/${option.key}/generate`
      )
      refetchCycleSpeedData()
    } else {
      setSelectedWorkCenter(null)
    }
  }

  const handleWorkcentreCapacityChange = (value: string) => {
    setWorkcentreCapacity(value)
  }

  const handleCalculatePlanTemplate = () => {
    setValidations([])
    calculatePlanTemplate({
      planTemplateId,
      sourceSiteId: parseInt(sourceSiteId),
      workcentreId: parseInt(workcentreId),
      workcentreCapacity: parseInt(
        optimization === 'constrained'
          ? workcentreCapacity
          : defaultWorkCenterCapacity
      ),
      constrained: optimization === 'constrained',
    })
  }

  const getAverageUtilisationHours = () => {
    return summaryData?.summary.filter(
      (summary: SummaryItem) => summary.label === 'Average utilised hours'
    )[0].value
  }

  const summaryGraphXAxisData = useMemo(
    () =>
      summaryData?.graphData?.weeklyData.map((data: WeeklyData) => data.weekNo),
    [summaryData]
  )

  const summaryGraphYAxisDataOne = useMemo(
    () =>
      summaryData?.graphData?.weeklyData.map(
        (data: WeeklyData) => data.totalProductionTime
      ),
    [summaryData]
  )

  const summaryGraphYAxisDataTwo = useMemo(
    () =>
      summaryData?.graphData?.weeklyData.map(
        (data: WeeklyData) => data.totalChangeoverTime
      ),
    [summaryData]
  )

  const getAverageUtilisation = () => {
    const utilisation = summaryData?.graphData?.averageUtilisation
    return isInteger(utilisation) ? utilisation : utilisation?.toFixed(1)
  }

  const NoData = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      mt={-3}
    >
      <Typography>
        Press the &apos;Calculate&apos; button to generate the plan template
        details
      </Typography>
    </Box>
  )

  const handlePlanDetailsClick = () => {
    history.push(
      `/projects/${projectId}/snapshots/${snapshotId}/scenarios/${scenarioId}/summary/${planTemplateId}/${selectedSourceSite?.value}/${selectedWorkCenter?.key}/generate/plan-details?optimization=${optimization}`
    )
  }

  const isCycleSpeedOrSummaryDataLoading =
    isInitialCycleSpeedAndSummaryDataFetched &&
    (isCyceSpeedDataLoading || isSummaryDataLoading)

  if (
    !isInitialCycleSpeedAndSummaryDataFetched &&
    (isCyceSpeedDataLoading || isSourceSitesLoading || isSummaryDataLoading)
  ) {
    return <Loader />
  }

  return (
    <Page>
      <Box display="flex" flexDirection="column" flex="auto">
        <Box className={classes.wrapper}>
          <PageHeader title="Plan template generator" />
          <ScenarioFiltersView
            sourceSiteList={sourceSites?.baseSourceSites}
            workcenterList={workcenters}
            selectedSourceSite={selectedSourceSite}
            selectedWorkcenter={selectedWorkCenter}
            handleSourceSiteChange={handleSourceSiteChange}
            handleWorkcenterChange={handleWorkcenterChange}
          />
        </Box>

        {isTemplateGenerating ? (
          <Paper className={classes.templateGeneratingCard}>
            <Box>
              <Loader />
            </Box>
            <Box mb={1}>
              <Typography variant="h2">Calculating plan template</Typography>
            </Box>
            <Typography variant="h4">
              This may take a while. You can return to this screen after a few
              minutes.
            </Typography>
          </Paper>
        ) : isInitialCycleSpeedAndSummaryDataFetched ? (
          selectedSourceSite &&
          selectedWorkCenter && (
            <>
              <Grid container spacing={2} className={classes.grid}>
                <Grid item md={6}>
                  <WorkcentreConstraintsView
                    title="Calculate plan template"
                    defaultWorkCenterCapacity={defaultWorkCenterCapacity}
                    workcentreCapacity={workcentreCapacity}
                    handleWorkcentreCapacityChange={
                      handleWorkcentreCapacityChange
                    }
                    handleOptimizationChange={(e, newValue) => {
                      setOptimization(
                        newValue === 0
                          ? 'constrained'
                          : newValue == 1
                          ? 'unconstrained'
                          : newValue == 2
                          ? 'cost'
                          : 'carbon'
                      )
                    }}
                    tabIndex={
                      optimization === 'constrained'
                        ? 0
                        : optimization === 'unconstrained'
                        ? 1
                        : optimization === 'cost'
                        ? 2
                        : 3
                    }
                    handlePrimaryButtonClick={handleCalculatePlanTemplate}
                    isPrimaryButtonDisabled={isCalculating}
                  />

                  {!isCycleSpeedOrSummaryDataLoading &&
                    (cycleSpeedData?.templateGenerationStatus === 'UNSTARTED' ||
                      cycleSpeedData?.templateGenerationStatus ===
                        'COMPLETED') &&
                    !Array.isArray(cycleSpeedData?.data) && (
                      <CycleSpeedView
                        data={cycleSpeedData.data}
                        optimization={
                          cycleSpeedData?.constrained
                            ? 'constrained'
                            : 'unconstrained'
                        }
                      />
                    )}
                </Grid>

                <Grid item md={6}>
                  {!isCycleSpeedOrSummaryDataLoading && (
                    <Paper className={classes.paper}>
                      <Box
                        pt="16px"
                        pb="24px"
                        px={3}
                        display="flex"
                        flexDirection="column"
                        height="100%"
                      >
                        <PlanDetailsView
                          handlePlanDetailsClick={handlePlanDetailsClick}
                          isDisabled={
                            cycleSpeedData?.templateGenerationStatus !==
                            'COMPLETED'
                          }
                        />
                        {!!validations?.length && (
                          <Box>
                            <Typography variant="h3">
                              Invalid plan template inputs
                            </Typography>
                          </Box>
                        )}

                        {!!validations?.length ? (
                          <ValidationListView validations={validations} />
                        ) : Array.isArray(summaryData) &&
                          summaryData?.length === 0 ? (
                          <NoData />
                        ) : (
                          <>
                            <SummaryList
                              summaryListData={summaryData?.summary}
                            />
                            <Box className={classes.summaryGraphWrapper}>
                              {summaryData?.graphData && (
                                <SummaryGraph
                                  traceOneName={'Total production time'}
                                  traceTwoName={'Total changeover time'}
                                  xAxisData={summaryGraphXAxisData}
                                  yAxisDataOne={summaryGraphYAxisDataOne}
                                  yAxisDataTwo={summaryGraphYAxisDataTwo}
                                  capacityReferenceValue={
                                    summaryData?.graphData?.workcentreCapacity
                                  }
                                  averageUtilisationPercentage={getAverageUtilisation()}
                                  averageUtilisationHours={getAverageUtilisationHours()}
                                  tooltipText={summaryData?.graphData?.weeklyData.map(
                                    (data: WeeklyData) =>
                                      data.percentageOfUtilisation.toFixed(1)
                                  )}
                                />
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Paper>
                  )}
                </Grid>
              </Grid>
              {isCycleSpeedOrSummaryDataLoading && <Loader />}
            </>
          )
        ) : (
          <Loader />
        )}
      </Box>
    </Page>
  )
}
