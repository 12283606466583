import { Graph, hovertemplateSKUGroup } from 'Common/Graph/Graph'
import { has } from 'lodash'
import { SegmentGraphProps } from './SegmentAnalysis.types'
const color = {
  Unclassified: 'rgb(211,201,201)',
  '1_Erratic': 'rgb(78,121,167)',
  '1_Erratic and Intermittent': 'rgb(160,203,232)',
  '1_Intermittent': 'rgb(242,142,43)',
  '1_Smooth': 'rgb(255,190,125)',
  '2_Erratic': 'rgb(89,161,79)',
  '2_Erratic and Intermittent': 'rgb(140,209,125)',
  '2_Intermittent': 'rgb(182,153,45)',
  '2_Smooth': 'rgb(241,206,99)',
  '3_Erratic': 'rgb(73,152,148)',
  '3_Erratic and Intermittent': 'rgb(134,188,182)',
  '3_Intermittent': 'rgb(225,87,89)',
  '3_Smooth': 'rgb(255,157,154)',
}

export const DifferenceGraph = ({
  traceData,
  yAxisLabel,
  showLegend = true,
}: SegmentGraphProps) => {
  const legendGrouping: { [key: string]: boolean } = {}

  const groupLegend = (legendName: string) => {
    if (legendGrouping[legendName]) {
      return false
    }
    legendGrouping[legendName] = true
    return true
  }

  const traces = traceData.map(
    (
      mapData: { segment: string; volume: number; label: string },
      index: number
    ) => {
      return {
        x: [index],
        y: [mapData.volume],
        hovertemplate: hovertemplateSKUGroup(
          'Segment',
          'Volume',
          mapData.segment,
          mapData.label
        ),
        marker: {
          color:
            color[
              has(color, mapData.segment) ? mapData.segment : 'Unclassified'
            ],
          line: {
            color: 'rgb(0,0,0)',
            width: 0.5,
          },
        },
        showlegend: showLegend ? groupLegend(mapData.segment) : false,
        name: mapData.segment,
        type: 'bar',
        width: 1,
      }
    }
  )

  const layout = {
    height: 430,
    yaxis: {
      showgrid: true,
      title: {
        text: yAxisLabel,
      },
      automargin: true,
    },
    xaxis: {
      zeroline: true,
      nticks: 13,
      visible: false,
    },
    bargroupgap: 0.1,
    legend: { orientation: 'v', x: 1, y: 1 },
  }

  return <Graph data={traces} layout={layout} />
}
