import { useQuery, useMutation } from 'react-query'
import { request, requestV2 } from 'Features/Auth/axios-client'
import { format } from 'date-fns'
import { UpdateNetworkReplenishmentPolicyOutputParams } from './NetworkReplenishmentSettingsOutput.types'

const fetchReplenishmentSettingsOutput = async (
  replenishmentPolicyId: string
) => {
  const data = await request({
    url: `/replenishment-policies/${replenishmentPolicyId}/network-replenishment-settings-output`,
  })
  return data
}
export const useReplenishmentSettingsOutput = (
  replenishmentPolicyId: string
) => {
  return useQuery(
    ['network-replenishment-settings-output', replenishmentPolicyId],
    () => fetchReplenishmentSettingsOutput(replenishmentPolicyId),
    {
      select: (data) => data?.data.data,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

const fetchExportReplenishmentSettingsOutput = async (
  replenishmentPolicyId: string
) => {
  const { data } = await requestV2({
    responseType: 'blob',
    url: `/replenishment-policies/${replenishmentPolicyId}/network-replenishment-settings-output/export`,
  })

  const fileName = `Network_replenishment_settings_output__${format(
    new Date(),
    'yyyyMMdd'
  )}`
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  return data
}
export const useExportReplenishmentSettingsOutput = () => {
  return useMutation(fetchExportReplenishmentSettingsOutput, {})
}

const updateReplenishmentSettingsOutput = async ({
  replenishmentPolicyId,
  editedDataArray,
}: UpdateNetworkReplenishmentPolicyOutputParams) => {
  const { data } = await request({
    url: `/replenishment-policies/${replenishmentPolicyId}/network-replenishment-settings-output`,
    method: 'put',
    data: { data: editedDataArray },
  })
  return data
}

export const useSaveReplenishmentSettingsOutput = (
  onSuccess: (msg: string) => void,
  onError: (msg: string) => void
) => {
  return useMutation(updateReplenishmentSettingsOutput, {
    onSuccess: (data) => onSuccess(data.message),
    onError: ({ response }) => onError(response.data.message),
  })
}
