import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  input: {
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
      marginRight: '0px',
      lineHeight: '15px',
    },
  },
})
