import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: '5px',
  },
  BatchView: {
    display: 'flex',
    flexDirection: 'column',
  },
  bulkUpdate: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
  },
}))
