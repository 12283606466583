import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Shell } from 'Common/Layout/Layout'
import { ReplenishmentPolicyPage } from 'Features/ReplenishmentPolicy/ReplenishmentPolicy.page'
import { TenantListPage } from 'Features/Tenant/TenantList.page'
import { PlanTemplatePage } from 'Features/PlanTemplate/PlanTemplate.page'
import { ProjectListPage } from 'Features/Project/ProjectList/ProjectList.page'
import { ProjectDetailsPage } from 'Features/Project/ProjectDetails/ProjectDetails.page'
import { PlanTemplateSummary } from 'Features/PlanTemplateSummary/PlanTemplateSummary.page'
import { PlanTemplateGenerator } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.page'
import { ViewScenarioPage } from 'Features/ViewScenario/ViewScenario.page'
import { InventoryAnalysisPage } from 'Features/InventoryAnalysis/InventoryAnalysis.page'
import { ScenarioOverviewPage } from 'Features/ScenarioOverview/ScenarioOverview.page'
import { PlanDetailsPage } from 'Features/PlanDetails/PlanDetails.page'
import { InventoryHoldingCostPage } from 'Features/InventoryHoldingCost/InventoryHolidingCost.page'
import { DemadScenarioPage } from 'Features/DemandScenario/DemandScenario.page'
import { ReplenishmentPolicyInputsPage } from 'Features/ReplenishmentPolicyInputs/ReplenishmentPolicyInputs.page'
import { NewDemandScenario } from 'Features/DemandScenario/NewDemandScenario/NewDemandScenario.page'
import { ViewDemandScenario } from 'Features/DemandScenario/ViewDemandScenario/ViewDemandScenario.page'
import { SkuGroupInventoryAnalysis } from 'Features/SkuGroupInventoryAnalysis/SkuGroupInventoryAnalysis.page'
import { SegmentAnalysisPage } from 'Features/SegmentAnalysis/SegmentAnalysis.page'
import { NetworkReplenishmentSettingsOutput } from 'Features/NetworkReplenishmentSettingsOutput/NetworkReplenishmentSettingsOutput.page'
import { SkuGroupManagementPage } from 'Features/Project/SkuGroupManagement/SkuGroupManagement.page'
import { NewPlanTemplatePage } from 'Features/NewPlanTemplate/NewPlanTemplate.page'
export const routes = [
  {
    path: '/tenants',
    component: TenantListPage,
    exact: true,
  },
  {
    path: '/select-tenant',
    component: TenantListPage,
    exact: true,
    autoRedirect: false,
  },
  {
    path: '/',
    component: ProjectListPage,
    exact: true,
  },
  {
    path: '/projects',
    component: ProjectListPage,
    redirectUrl: '/',
    exact: true,
  },
  {
    path: '/projects/sku-to-sku-group-management',
    component: SkuGroupManagementPage,
    exact: true,
    breadcrumb: 'SKU to SKU group management',
  },
  {
    path: '/projects/:projectId',
    component: ProjectDetailsPage,
    exact: true,
  },
  {
    path: '/projects/:projectId/compare-all-scenarios/view-scenario/:scenarioId',
    component: ViewScenarioPage,
    exact: true,
  },
  {
    path: '/projects/:projectId/snapshots/:snapshotId/summary/:planTemplateId/scenarios/:scenarioId',
    component: PlanTemplateSummary,
    exact: true,
  },
  {
    path: '/projects/:projectId/snapshots/:snapshotId/edit/:planTemplateId/:replenishmentPolicyId/scenarios/:scenarioId',
    component: NewPlanTemplatePage,
    exact: true,
    breadcrumb: 'Edit Settings',
  },
  {
    path: '/projects/:projectId/snapshots/:snapshotId/summary/:planTemplateId/scenarios/:scenarioId/:sourceSiteId/:workcentreId',
    component: PlanTemplatePage,
    exact: true,
  },
  {
    path: '/projects/:projectId/snapshots/:snapshotId/scenarios/:scenarioId/summary/:planTemplateId/:sourceSiteId/:workcentreId/generate',
    component: PlanTemplateGenerator,
    exact: true,
  },
  {
    path: '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId/network-replenishment-settings/:sourceSiteId?',
    component: ReplenishmentPolicyPage,
    exact: true,
  },
  {
    path: '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId/network-replenishment-settings/:sourceSiteId?/network-replenishment-settings-output',
    component: NetworkReplenishmentSettingsOutput,
    exact: true,
  },
  {
    path: '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId/network-replenishment-settings/inventory-analysis/:sourceSiteId/:siteKey',
    component: InventoryAnalysisPage,
    exact: true,
    breadcrumb: 'Inventory modelling analysis',
  },
  {
    path: '/projects/:projectId/compare-all-scenarios',
    component: ScenarioOverviewPage,
    exact: true,
    breadcrumb: 'Compare all scenarios',
  },
  {
    path: '/projects/:projectId/snapshots/:snapshotId/summary/:planTemplateId/scenarios/:scenarioId/:sourceSiteId/:workcentreId/plan-details',
    component: PlanDetailsPage,
    exact: true,
  },

  {
    path: '/projects/:projectId/snapshots/:snapshotId/scenarios/:scenarioId/demand-scenario',
    component: DemadScenarioPage,
    exact: true,
  },
  {
    path: '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId',
    component: ReplenishmentPolicyInputsPage,
    exact: true,
    breadcrumb: 'Network capacity settings',
  },
  {
    path: '/projects/:projectId/snapshots/:snapshotId/scenarios/:scenarioId/demand-scenario/details',
    component: NewDemandScenario,
    exact: true,
  },
  {
    path: '/projects/:projectId/snapshots/:snapshotId/scenarios/:scenarioId/demand-scenario/:demandScenarioId',
    component: ViewDemandScenario,
    exact: true,
  },
  {
    path: '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId/network-replenishment-settings/inventory-analysis/:sourceSiteId/:siteKey/inventory-holding-cost',
    component: InventoryHoldingCostPage,
    exact: true,
  },
  {
    path: '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId/network-replenishment-settings/inventory-analysis/:sourceSiteId/:siteKey/sku-group-inventory-analysis/:skuGroupKey',
    component: SkuGroupInventoryAnalysis,
    exact: true,
  },
  {
    path: '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId/network-replenishment-settings/inventory-analysis/:sourceSiteId/:siteKey/segment-analysis',
    component: SegmentAnalysisPage,
    exact: true,
    breadcrumb: 'Segment analysis',
  },
]

export const Routes = () => (
  <Router>
    <Shell>
      <Switch>
        {routes.map(
          ({ path, exact, component: Component, redirectUrl, ...rest }, i) => (
            <Route key={i} path={path} exact={exact}>
              {redirectUrl ? (
                <Redirect to={redirectUrl} />
              ) : (
                <Component {...rest} />
              )}
            </Route>
          )
        )}
      </Switch>
    </Shell>
  </Router>
)
