import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { Button, PageHeader } from 'supplyvue-ui'
import { Page } from 'Common/Page/Page'
import { Loader } from 'Common/Loader/Loader'
import { ProjectCardView } from 'Features/Project/ProjectCard/ProjectCard.view'
import { CreateProjectModal } from 'Features/Project/CreateProjectModal/CreateProjectModal.view'
import {
  useProjectListData,
  useCreateProjectData,
  useDeleteProject,
  useUpdateProjectAccess,
} from './ProjectList.data'
import AddIcon from 'Assets/icons/add.svg'
import EmptyIcon from 'Assets/icons/empty.svg'
import { Project } from './ProjectList.types'
import { toast } from 'react-hot-toast'
import GlobalSettings from 'Assets/icons/globalsettingsborder.svg'
import { DeleteProjectModal } from 'Features/Project/DeleteProjectModal/DeleteProjectModal.view'
import { ProjectAccessSelect } from 'Common/Components/ProjectView.components'
import { ShowPlansOptionType } from 'Common/Components/Common.types'
import { PROJECT_ACCESS_OPTIONS } from 'Common/Constants/Constants'
import { SelectChangeEvent } from '@mui/material'
import { getUserEmail } from 'Features/Auth/auth'
import { generatedBySelect } from 'Common/Utils/utils'
import { projectAccessType } from '../ProjectCard/ProjectCard.types'
import { ScenarioAccessModal } from '../ScenarioAccessModal/ScenarioAccess.Modal'

export const ProjectListPage = () => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [projectId, setProjectId] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const [showProjectsOption, setShowProjectsOption] = useState(
    PROJECT_ACCESS_OPTIONS['Generated by me'] as ShowPlansOptionType
  )
  const [userEmail, setUserEmail] = useState<null | string>(null)
  const [selectedProjectData, setSelectedProjectData] = useState<Project[]>([])
  const [scenarioAccessModalOpen, setScenarioAccessModalOpen] = useState(false)
  const [scenarioAccessConfig, setScenarioAccessConfig] = useState<
    Record<string, 'public' | 'private'>
  >({})
  const handleSettingsMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if (userEmail === null) {
      getUserEmail().then((email: string) => {
        setUserEmail(email ? email.toLocaleLowerCase() : '')
      })
    }
    return () => {
      setUserEmail(null)
    }
  }, [])

  const handleShowMyProjectsDropdown = (event: SelectChangeEvent<string>) => {
    const { value } = event.target
    setShowProjectsOption(value as ShowPlansOptionType)
  }

  const handleSettingsMenuClose = () => {
    setAnchorEl(null)
  }

  const onCreateProjectSuccess = (projectId: string, message: string) => {
    handleModalClose()
    history.push(`projects/${projectId}`)
    toast.success(message)
  }
  const {
    isLoading: isCreatingProject,
    mutate: createProject,
  } = useCreateProjectData({ onCreateProjectSuccess })

  const onDeleteSuccess = () => {
    console.log('Deleted successfully')
  }
  const { mutate: deleteProject } = useDeleteProject({ onDeleteSuccess })

  const {
    isLoading: isProjectListLoading,
    data: projectListData = [],
  } = useProjectListData()

  useEffect(() => {
    if (projectListData && projectListData?.length > 0) {
      generatedBySelect(
        showProjectsOption,
        projectListData,
        userEmail,
        setSelectedProjectData
      )
    }
  }, [showProjectsOption, projectListData, userEmail])

  const handleNewProjectClick = () => {
    setIsModalOpen(true)
  }

  const handleSkuToSkuGropManagementClick = () => {
    history.push(`/projects/sku-to-sku-group-management`)
    handleSettingsMenuClose()
  }

  const handleDeleteModalClose = () => {
    setProjectId(null)
    setProjectName('')
    setIsDeleteModalOpen(false)
  }

  const handleModalClose = () => {
    setProjectName('')
    setIsModalOpen(false)
    setErrorMessage('')
  }

  const handleProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProjectName(event.target.value)
  }

  const handleCreateProject = () => {
    if (
      projectListData.find(
        (project: Project) =>
          project.projectName.toLowerCase() === projectName.toLowerCase()
      )
    ) {
      setErrorMessage('Project name already exists')
    } else {
      setErrorMessage('')
      createProject({ projectName })
    }
  }

  const handleDelete = (projectId: string, projectName: string) => {
    setIsDeleteModalOpen(true)
    setProjectName(projectName)
    setProjectId(projectId)
  }

  const handleDeleteConfirm = () => {
    if (projectId) {
      deleteProject({ projectId })
    }
    handleDeleteModalClose()
  }

  const handleProjectCardClick = (projectId: string) => {
    history.push(`projects/${projectId}`)
  }

  /***** Project access change (public/private .....) */
  const onProjectAccessUpdateSuccess = () => {
    toast.success('Project access updated successfully')
  }

  const onProjectAccessUpdateFail = () => {
    toast.error('Failed to update project access')
  }

  const {
    mutate: updateProjectAccess,
    isLoading: isProjectAccessUpdating,
  } = useUpdateProjectAccess(
    onProjectAccessUpdateSuccess,
    onProjectAccessUpdateFail
  )

  const handlePlanAccessChangeClick = (
    projectId: string,
    projectAccess: projectAccessType
  ) => {
    if (projectAccess.projectAccess.visibility === 'public') {
      setScenarioAccessConfig(
        projectAccess.scenarioAccess as Record<string, 'public' | 'private'>
      )
      setProjectId(projectId)
      setScenarioAccessModalOpen(true)
    } else {
      updateProjectAccess({ projectId, projectAccess })
    }
  }

  const handleScenarioAccessApply = (
    scenarioConfig: Record<string, 'public' | 'private'>
  ) => {
    const projectAccess = {
      projectAccess: {
        visibility: 'public' as 'public' | 'private',
      },
      scenarioAccess: { ...scenarioConfig },
    }
    updateProjectAccess({
      projectId: projectId as string,
      projectAccess: projectAccess,
    })
    setScenarioAccessModalOpen(false)
  }
  /***** End project access */

  if (isProjectListLoading) {
    return <Loader />
  }

  return (
    <Page>
      <Box display="flex" flexDirection="column" flex={1}>
        <PageHeader
          title="Projects"
          rightElement={
            <Box>
              {projectListData.length && (
                <Button startIcon={<AddIcon />} onClick={handleNewProjectClick}>
                  New project
                </Button>
              )}
              <IconButton onClick={handleSettingsMenuClick} color="primary">
                <GlobalSettings />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleSettingsMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={handleSkuToSkuGropManagementClick}>
                  SKU to SKU group management
                </MenuItem>
              </Menu>
            </Box>
          }
        />
        <Box mb={2}>
          <ProjectAccessSelect
            showProjectsOption={showProjectsOption}
            handleShowMyProjectsDropdown={handleShowMyProjectsDropdown}
          />
        </Box>
        {selectedProjectData.length === 0 && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            flexGrow={1}
          >
            <EmptyIcon />
            <Box my={4}>
              <Typography variant="h4" color="textSecondary">
                {`You haven't created any projects yet.`}
              </Typography>
            </Box>
          </Box>
        )}
        {!projectListData.length ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            flexGrow={1}
          >
            <EmptyIcon />
            <Box my={4}>
              <Typography variant="h4" color="textSecondary">
                Create your first project and start modelling your app
              </Typography>
            </Box>
            <Button variant="contained" onClick={handleNewProjectClick}>
              Create project
            </Button>
          </Box>
        ) : (
          <Grid container spacing={6}>
            {selectedProjectData.map((project: Project) => {
              return (
                <Grid item md={4} key={project.projectId}>
                  <ProjectCardView
                    projectId={project.projectId}
                    projectName={project.projectName}
                    snapshotName={project?.snapshotDetails?.snapshotName}
                    snapshotLength={project?.snapshotDetails?.snapshotLength}
                    snapshotEndDate={project?.snapshotDetails?.snapshotEndDate}
                    workcentres={project.workcentresCount}
                    scenarios={project.scenariosCount}
                    createdAt={project.createdOn}
                    createdBy={project.createdBy}
                    handleClick={handleProjectCardClick}
                    handleDelete={handleDelete}
                    userEmail={userEmail as string}
                    projectOwnerEmail={project.email}
                    projectAccessType={{ ...project.projectAccess }}
                    handlePlanAccessChangeClick={handlePlanAccessChangeClick}
                    isProjectAccessUpdating={isProjectAccessUpdating}
                  />
                </Grid>
              )
            })}
          </Grid>
        )}
      </Box>
      <CreateProjectModal
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        heading="Create project"
        projectNameLabel="Enter project name"
        projectName={projectName}
        handleProjectNameChange={handleProjectNameChange}
        actionButtonLabel="Create"
        handleActionClick={handleCreateProject}
        isActionDisabled={!projectName || isCreatingProject}
        isLoading={isCreatingProject}
        errorMessage={errorMessage}
      />
      <DeleteProjectModal
        isModalOpen={isDeleteModalOpen}
        handleModalClose={handleDeleteModalClose}
        projectName={projectName}
        handleConfirmClick={handleDeleteConfirm}
      />
      <ScenarioAccessModal
        isModalOpen={scenarioAccessModalOpen}
        handleModalClose={() => setScenarioAccessModalOpen(false)}
        heading="Scenarios"
        isLoading={false}
        projectId={projectId as string}
        handleScenarioAccessApply={handleScenarioAccessApply}
        scenarioAccessConfig={scenarioAccessConfig}
      />
    </Page>
  )
}
