import { useMutation, useQuery } from 'react-query'
import { requestV2 } from 'Features/Auth/axios-client'
import {
  FetchMasterSkuDataParams,
  UpdateMasterSkuDataVariables,
  UpdateMasterSkusProps,
} from './SkuGroupManagement.types'

const fetchMasterSkus = async ({
  pageIndex,
  pageSize,
  sortBy,
  filterValues,
}: FetchMasterSkuDataParams) => {
  const sort = sortBy ? `&sort=${sortBy}` : ''
  const filter = filterValues ? `${filterValues}` : ''
  const { data } = await requestV2({
    url: `/master/skus?page=${pageIndex}&page_size=${pageSize}${sort}${filter}`,
  })
  return data
}

export const useMasterSkuData = ({
  pageSize,
  pageIndex,
  sortBy,
  filterValues,
}: FetchMasterSkuDataParams) => {
  return useQuery(
    ['master-skus', pageIndex, pageSize, sortBy, filterValues],
    () => fetchMasterSkus({ pageIndex, pageSize, sortBy, filterValues }),
    {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      keepPreviousData: true,
      enabled: !!pageIndex,
    }
  )
}

const updateMasterSkus = async (
  updateMasterSkuVariables: UpdateMasterSkuDataVariables
) => {
  const { data } = await requestV2({
    url: `/master/skus`,
    method: 'put',
    data: {
      ...updateMasterSkuVariables,
    },
  })
  return data
}

export const useUpdateMasterSkus = ({ onError }: UpdateMasterSkusProps) => {
  return useMutation(updateMasterSkus, {
    onError: async ({ response }) => {
      onError(response.data.message)
    },
  })
}
