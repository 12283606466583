import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Page } from 'Common/Page/Page'
import { Box, Tabs, Tab, Paper } from '@material-ui/core'
import { PageHeader, TabPanel } from 'supplyvue-ui'
import { ScenarioSummary } from './DetailedAnalysis/ScenarioSummary'
import { CostAnalysisView } from './CostAnalysis/CostAnalysis.view'
import { InventoryAnalysisView } from './InventoryAnalysis/InventoryAnalysis.view'
import { UtilisationAnalysisView } from './UtilisationAnalysis/UtilsationAnalysis.view'
import {
  useCostAnalysis,
  useDetailedScenarioAnalysis,
  useInventoryAnalysis,
  useUtilisationAnalysis,
} from './ScenarioOverview.data'
import { Loader } from 'Common/Loader/Loader'
import { toast } from 'react-hot-toast'
import { useCompareScenariosData } from 'Features/CompareScenarios/CompareScenarios.data'
import {
  CompareScenariosTableData,
  HandleCompareScenariosRowClick,
  UniqueIdentifier,
} from 'Features/CompareScenarios/CompareScenarios.types'
import { ViewScenarioPage } from "Features/ViewScenario/ViewScenario.page";
import { useCompareScenarioData } from "Features/ViewScenario/ViewScenario.data";
import { useBreadcrumbsData } from "Common/Breadcrumbs/Breadcrumbs.data";

export const ScenarioOverviewPage = () => {
  const { projectId } = useParams<{ projectId: string }>()
  useBreadcrumbsData({
    project_id: projectId,
  })
  const [tabIndex, setTabIndex] = useState(0)

  const onErrorHandler = (message: string) => {
    toast.error(message)
  }

  const {
    isLoading: isDetailedScenarioAnalysisLoading,
    data: detailedScenarioAnalysis,
  } = useDetailedScenarioAnalysis(projectId, onErrorHandler)

  const { isLoading: isInventoryAnalysisLoading, data: inventoryAnalysisData } =
    useInventoryAnalysis(projectId, onErrorHandler)

  const {
    isLoading: isUtilisationAnalysisLoading,
    data: utilisationAnalysisData,
  } = useUtilisationAnalysis(projectId, onErrorHandler)

  const { isLoading: isCostAnalysisLoading, data: costAnalysisData } =
    useCostAnalysis(projectId, onErrorHandler)

  const { data: compareScenariosData, isLoading: isCompareScenariosLoading } =
    useCompareScenariosData(projectId)

  const [disabledTableRows, setDisabledTableRows] =
    useState<UniqueIdentifier<CompareScenariosTableData>[]>()

  useEffect(() => {
    const disabledRows = compareScenariosData?.data
      ?.filter((row: CompareScenariosTableData) => row.planTemplateId === null)
      .map((row: CompareScenariosTableData) => {
        return {
          scenarioId: row.scenarioId,
        }
      })
    setDisabledTableRows(disabledRows)
  }, [compareScenariosData])

  const history = useHistory()

  const handleRowClick: HandleCompareScenariosRowClick = (data) => {
    !Array.isArray(data) &&
      history.push(
        `/projects/${projectId}/compare-all-scenarios/view-scenario/${data.scenarioId}`
      )
  }

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setTabIndex(newValue)
  }

  const { data: scenarioData, isLoading: isScenarioDataLoading } = useCompareScenarioData(
    projectId,
  )

  if (isDetailedScenarioAnalysisLoading || isCompareScenariosLoading || isScenarioDataLoading  ) {
    return <Loader />
  }

  return (
    <Page>
      <Box display="flex" flexDirection="column" flex="auto">
        <PageHeader title="Compare all scenarios" />
        <Paper>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="Compare all scenarios tabs"
          >
            <Tab label="Scenario Summary" />
            <Tab label="Detailed Analysis" />
            <Tab label="Inventory analysis" />
            {
              /*
            <Tab label="Cost analysis" />
            <Tab label="Utilisation analysis" />
              * */
            }
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <ScenarioSummary
              totalCostGraph={detailedScenarioAnalysis.costGraph}
              compareScenariosData={scenarioData?.aggregatedData ?? []}
              handleRowClick={handleRowClick}
              disabledTableRows={disabledTableRows ? disabledTableRows : []}
              costOutputs={costAnalysisData?.costOutputs}
              inventoryOutputs={inventoryAnalysisData?.inventoryOutputs}
            />
          </TabPanel>
          <TabPanel index={tabIndex} value={1}>
            <ViewScenarioPage data={scenarioData?.data} isLoading={isScenarioDataLoading}/>
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            {isInventoryAnalysisLoading ? (
              <Loader />
            ) : (
              <InventoryAnalysisView
                inventoryAnalysis={inventoryAnalysisData?.inventoryAnalysis ?? []}
              />
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={3}>
            {isCostAnalysisLoading ? (
              <Loader />
            ) : (
              <CostAnalysisView
                costGraph={costAnalysisData?.costGraph}
                costDrivers={costAnalysisData?.costDrivers}
                costOutputs={costAnalysisData?.costOutputs}
              />
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={4}>
            {isUtilisationAnalysisLoading ? (
              <Loader />
            ) : (
              <UtilisationAnalysisView
                utilisationDrivers={utilisationAnalysisData.utilisationDrivers}
                utilisationOutputs={utilisationAnalysisData.utilisationOutputs}
                assetUtilisationGraph={
                  utilisationAnalysisData.assetUtilisationGraph
                }
              />
            )}
          </TabPanel>
        </Paper>
      </Box>
    </Page>
  )
}
