export const WORKCENTER_STEPS = {
  SETTINGS: 'settings',
  PRODUCT_CHANGEOVERS: 'productChangeOvers',
  PRODUCT_SETTINGS: 'productSettings',
  BATCH_GROUPING_SETTINGS: 'batchGroupingSettings',
  BATCH_GROUPING_CHANGEOVERS: 'batchGroupingChangeOvers',
  GENERATE_TEMPLATE: 'generateTemplate',
} as const

export const DEFAULT_STEP_NUMBER = 5 // step number for generateTemplate
