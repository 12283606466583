import { useMutation, useQuery, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import { MultiBulkUpdate } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/BulkUpdate/BulkUpdate.types'

const fetchWorkcentreSettings = async (
  snapshotId: string,
  projectId: string,
  planTemplateId: string
) => {
  const { data } = await request({
    url: `/plan-template/workcentre-settings`,
    method: 'post',
    data: {
      snapshotId,
      projectId,
      planTemplateId,
    },
  })
  return data
}

export const useWorkcentreSettingsData = (
  snapshotId: string,
  projectId: string,
  planTemplateId: string
) => {
  return useQuery(
    ['workcentre-settings-all', snapshotId, projectId, planTemplateId],
    () => fetchWorkcentreSettings(snapshotId, projectId, planTemplateId),
    {
      select: (data) => {
        return data.data
      },
      refetchOnWindowFocus: false,
    }
  )
}

const updateGroupSettings = async (groupDetails: MultiBulkUpdate) => {
  const data = await request({
    url: `/plan-templates/${groupDetails.planTemplateId}/bulk-settings`,
    method: 'put',
    data: groupDetails,
  })
  return data
}

export const useUpdatGroupSettings = (
  onSuccess: (message: string) => void,
  onError: () => void
) => {
  const queryClient = useQueryClient()
  return useMutation(updateGroupSettings, {
    onSuccess: async ({ data }, variables) => {
      await queryClient.invalidateQueries([
        'workcentre-settings-all',
        variables.snapshotId,
        variables.projectId,
        variables.planTemplateId,
      ])
      onSuccess(data.message)
    },
    onError: async () => {
      onError()
    },
  })
}
