import {
  ReplenishmentSiteParams,
  ReplenishmentSiteParamsResponse,
  ReplenishmentSiteParamsTableData,
  ReplenishmentUploadParams,
} from './ReplenishmentSettings.types'
import { request } from 'Features/Auth/axios-client'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  UpdateReplenishmentSiteParams,
  ReplenishmentDownloadParams,
} from './ReplenishmentSettings.types'

const fetchMultiData = async (
  replenishmentSiteParams: ReplenishmentSiteParams
) => {
  const { data } = await request({
    url: `/multi-site-skugroups`,
    method: 'post',
    data: {
      ...replenishmentSiteParams,
    },
  })

  return data
}

export const useMultiData = (
  replenishmentSiteParams: ReplenishmentSiteParams
) => {
  return useQuery(
    [
      'replenishment-site-params-multi',
      replenishmentSiteParams.projectId,
      replenishmentSiteParams.scenarioId,
      replenishmentSiteParams.replenishmentPolicyId,
    ],
    () => fetchMultiData(replenishmentSiteParams),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (
        data: ReplenishmentSiteParamsResponse
      ): ReplenishmentSiteParamsTableData[] => {
        return data.data.map((item) => ({
          ...item,
          serviceLevel: item.serviceLevel && item.serviceLevel + '%',
          replenishmentType: item.replenishmentType ?? '',
        }))
      },
    }
  )
}

const updateReplenishmentParametersData = async (
  updateReplenishmentSiteParams: UpdateReplenishmentSiteParams
) => {
  const { data } = await request({
    url: `/multi-site-skugroups-inventory`,
    method: 'put',
    data: {
      ...updateReplenishmentSiteParams,
    },
  })
  return data
}

export const useUpdateReplenishmentParametersData = (onSuccess: () => void) => {
  const queryClient = useQueryClient()

  return useMutation(updateReplenishmentParametersData, {
    onSuccess: () => {
      queryClient.invalidateQueries('replenishment-site-params-multi')
      onSuccess()
    },
  })
}

const getDownloadData = async (downloadParams: ReplenishmentDownloadParams) => {
  const { data } = await request({
    method: 'post',
    data: downloadParams,
    url: `/replenishment-settings-download`,
  })
  return data
}

export const useDownloadData = (
  onSuccess: (data: any) => void,
  onError: () => void
) => {
  return useMutation(getDownloadData, {
    onSuccess: (data) => {
      onSuccess(data)
    },
    onError: () => {
      onError()
    },
  })
}

const uploadInventoryFile = async (uploadParams: ReplenishmentUploadParams) => {
  const { planTemplateId, replenishmentPolicyId, uploadType, file } =
    uploadParams

  const formData = new FormData()

  formData.append('planTemplateId', planTemplateId)
  formData.append('replenishmentPolicyId', replenishmentPolicyId)
  formData.append('upload_type', uploadType)
  formData.append('settings_file', file)

  const { data } = await request({
    method: 'post',
    url: `/plan-templates/${planTemplateId}/replenishment-settings-upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
  return data
}

export const useUploadInventoryFile = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient()
  return useMutation(uploadInventoryFile, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('replenishment-site-params-multi')
      onSuccess()
    },
    onError: () => {
      onError()
    },
  })
}
