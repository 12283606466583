import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '87vh',
  },
})
