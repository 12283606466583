import { CloneScenarioModalProps } from 'Features/Project/Scenario/CloneScenarioModal/CloneScenarioModal.types'
import { useStyles } from './CloneScenarioModal.styles'
import { Box, Typography } from '@mui/material'
import { Input, Button, Modal } from 'supplyvue-ui'
import { CircularProgress } from '@material-ui/core'

export const CloneScenarioModal = ({
  isModalOpen,
  handleModalClose,
  isLoading,
  handleConfirmClick,
  scenarioName,
  handleScenarioNameChange,
}: CloneScenarioModalProps) => {
  const classes = useStyles()
  const body = (
    <Box className={classes.contentWrapper}>
      <label htmlFor="scenario-name">
        <Typography className={classes.label}>Scenario Name</Typography>
        <Input
          fullWidth
          value={scenarioName}
          onChange={(event) => handleScenarioNameChange(event.target.value)}
          disabled={isLoading}
          ariaLabel="Scenario Name"
        />
      </label>
    </Box>
  )
  const footer = (
    <Box className={classes.footer}>
      <Button
        onClick={handleConfirmClick}
        className={classes.modalActionButton}
        label="Clone"
        disabled={isLoading}
        variant="contained"
      >
        {isLoading && (
          <CircularProgress
            color="inherit"
            size={16}
            className={classes.progress}
          />
        )}
        Clone
      </Button>
    </Box>
  )

  return (
    <Modal
      open={isModalOpen}
      header={'Clone Scenario'}
      handleClose={handleModalClose}
      body={body}
      footer={footer}
    />
  )
}
