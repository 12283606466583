import { useReducer } from 'react'
import {
  PlanTemplateSettingsState,
  PlanTemplateSettingStateAction,
  PlanTemplateSettingsResponse,
} from './UpdatePlanTemplateSettingsModal.types'

export const getInitialSettings = (
  planTemplateSettingsData: PlanTemplateSettingsResponse
) => {
  const { settings } = planTemplateSettingsData
  const salesRateType = settings.salesRateType ?? 'forecast'
  const salesRatePeriod = settings.salesRatePeriod ?? ''
  const minimumAverageBatchesPerProductionCycle =
    settings.minimumAverageBatchesPerProductionCycle ?? 1
  const templateBasedOn = settings.templateBasedOn ?? 'Line only'
  return {
    salesRateType,
    salesRatePeriod,
    minimumAverageBatchesPerProductionCycle,
    templateBasedOn,
  }
}

const reducer = (
  state: PlanTemplateSettingsState,
  action: PlanTemplateSettingStateAction
) => {
  if (action.type === 'SET_SALES_RATE_TYPE') {
    return {
      ...state,
      salesRateType: action.payload as string,
    }
  }
  if (action.type === 'SET_SALES_RATE_PERIOD') {
    return {
      ...state,
      salesRatePeriod: action.payload as number,
    }
  }
  if (action.type === 'SET_MIN_AVG_BATCH_PER_CYCLE') {
    return {
      ...state,
      minimumAverageBatchesPerProductionCycle: action.payload as number,
    }
  }
  if (action.type === 'SET_PLANTEMPLATE_BASED_ON') {
    return {
      ...state,
      templateBasedOn: action.payload as string,
    }
  }
  if (action.type === 'RESET_PLANTEMPLATE_SETTINGS') {
    return getInitialSettings(action.payload as PlanTemplateSettingsResponse)
  }
  return state
}

export const usePlanTemplateSettingsState = (
  initialSettings: PlanTemplateSettingsResponse
) => {
  return useReducer(reducer, initialSettings, getInitialSettings)
}
