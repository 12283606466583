import { Box, CircularProgress, Typography } from '@material-ui/core'
import { Button } from 'supplyvue-ui'
import { useStyles } from './SiteParametersEditForm.styles'
import { SiteParametersEditFormProps } from './SiteParametersEditForm.types'
import { DropDownParameter } from './DropDownParameter/DropDownParameter.view'
import { InputParameter } from './InputParameter/InputParameter.view'
import { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import {
  dropDownvaluesInitialState,
  inputValuesInitialState,
} from '../SiteParameters.constants'

export const SiteParametersEditForm = (props: SiteParametersEditFormProps) => {
  const classes = useStyles()
  const [isIncludeSelected, setIsIncludeSelected] = useState(false)
  const {
    inputValues,
    dropdownValues,
    handleDropdownChange,
    handleInputChange,
    isApplyParametersDisabled,
    handleUpdateReplenishmentSiteParams,
    supplyTypeOptions,
    replenishmentTypeOptions,
    isDataUpdating,
    includeTypeOptions,
    handleClearButtonClick,
    isCalculating,
  } = props

  useEffect(() => {
    if (dropdownValues.includeType !== '') {
      setIsIncludeSelected(true)
    } else {
      setIsIncludeSelected(false)
    }
  }, [dropdownValues])

  return (
    <Box className={classes.contents}>
      <Box mb={3}>
        <Typography>
          Enter one or more replenishment parameters and click &apos;Apply
          parameters&apos; to update the selected rows. Only valid data will be
          applied, no changes will be shown if invalid data is provided
        </Typography>
      </Box>
      <Box className={classes.parametersContainer}>
        <Box className={classes.section}>
          <DropDownParameter
            name={Object.keys(dropdownValues)[0]}
            handleDropdownChange={handleDropdownChange}
            defaultLabel="None"
            label="Supply type"
            value={dropdownValues.supplyType}
            options={supplyTypeOptions}
            isDisabled={isDataUpdating || isIncludeSelected || isCalculating}
          />
          <InputParameter
            name={Object.keys(inputValues)[0]}
            label={'Replenishment cycle'}
            value={inputValues.replenishmentCycle}
            handleInputChange={handleInputChange}
            className={classes.inputLabel}
            suffix={'weeks'}
            isDisabled={isDataUpdating || isIncludeSelected || isCalculating}
          />

          <InputParameter
            name={Object.keys(inputValues)[4]}
            label={'Service level'}
            value={inputValues.serviceLevel}
            handleInputChange={handleInputChange}
            className={classes.inputLabel}
            suffix={'%'}
            isDisabled={isDataUpdating || isIncludeSelected || isCalculating}
          />
        </Box>

        <Box className={`${classes.section} ${classes.middleSection} `}>
          <DropDownParameter
            name={Object.keys(dropdownValues)[1]}
            handleDropdownChange={handleDropdownChange}
            defaultLabel="None"
            label="Replenishment type"
            value={dropdownValues.replenishmentType}
            options={replenishmentTypeOptions}
            isDisabled={isDataUpdating || isIncludeSelected || isCalculating}
          />
          <InputParameter
            name={Object.keys(inputValues)[3]}
            label={'MOQ'}
            value={inputValues.MOQ}
            handleInputChange={handleInputChange}
            className={classes.inputLabel}
            isDisabled={isDataUpdating || isIncludeSelected || isCalculating}
          />
          <DropDownParameter
            name={Object.keys(dropdownValues)[2]}
            handleDropdownChange={(event) => {
              handleDropdownChange(event)
            }}
            defaultLabel="None"
            label="Include"
            value={dropdownValues.includeType}
            options={includeTypeOptions}
            isDisabled={
              !isApplyParametersDisabled || isDataUpdating || isCalculating
            }
          />
        </Box>

        <Box className={`${classes.section} ${classes.lastSection}`}>
          <InputParameter
            name={Object.keys(inputValues)[1]}
            label={'Deployment lead time'}
            value={inputValues.deploymentLeadTime}
            handleInputChange={handleInputChange}
            className={classes.sectionTwolabel}
            suffix={'days'}
            isDisabled={isDataUpdating || isIncludeSelected || isCalculating}
          />
          <Box className={classes.moiWrapper}>
            <InputParameter
              name={Object.keys(inputValues)[2]}
              label={'MOI'}
              value={inputValues.MOI}
              handleInputChange={handleInputChange}
              className={`${classes.inputLabel} ${classes.moi}`}
              isDisabled={isDataUpdating || isIncludeSelected || isCalculating}
            />
          </Box>
          <Box className={classes.moiWrapper}>
            <InputParameter
              name={Object.keys(inputValues)[5]}
              label={'Planning period'}
              value={inputValues.planningPeriod}
              handleInputChange={handleInputChange}
              className={`${classes.inputLabel} ${classes.moi}`}
              isDisabled={isDataUpdating || isIncludeSelected || isCalculating}
            />
          </Box>
          <Box className={classes.buttonGroup}>
            <Button
              className={classes.clearButton}
              color="primary"
              variant="contained"
              onClick={handleClearButtonClick}
              disabled={
                isEqual(inputValues, inputValuesInitialState) &&
                isEqual(dropdownValues, dropDownvaluesInitialState)
              }
            >
              Clear
            </Button>
            <Button
              variant="contained"
              disabled={
                (isApplyParametersDisabled || isDataUpdating) &&
                !isIncludeSelected
              }
              onClick={handleUpdateReplenishmentSiteParams}
            >
              {isDataUpdating && (
                <CircularProgress
                  color="inherit"
                  size={16}
                  className={classes.progress}
                />
              )}
              Apply parameters
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
