import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  replenishmentView: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    marginTop: '5px',
  },
  buttonGroup: {
    display: 'flex',
    gap: 5,
  },
  button: {
    borderRadius: 0,
  },
  buttonPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clearButton: {
    marginRight: '15px',
  },
  bulkUpdate: {
    marginBottom: '5px',
    backgroundColor: theme.palette.background.paper,
  },
}))
