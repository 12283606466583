import { useState, useEffect, useRef } from 'react'
import { Box, Drawer } from '@material-ui/core'
import { DrawerControls } from 'supplyvue-ui'
import { Toolbar } from 'Common/Toolbar'
import { ProductSettingsProps } from './ProductSettings.types'
import {
  BatchGroupingSettings,
  ProductSettings,
} from 'Features/PlanTemplate/StepCard/StepCard.types'
import { ProductSettingsTable } from './ProductSettings.table'
import { useStyles } from './ProductSettings.styles'
import { ProductSettingsForm } from './ProductSettingsForm/ProductSettingsForm.view'
import { CYCLE_MENU_ITEMS } from './ProductSettings.constants'
import {
  ErrorData,
  PlanTemplateEditInputsParams,
  ProductSettingsFormData,
  ProductSettingsParametersType,
} from './ProductSettingsForm/ProductSettingsForm.types'
import { WORKCENTER_STEPS } from 'Features/PlanTemplate/StepCard/StepCard.constants'
import { getUpdatedProductSettings } from './ProductSettings.utils'
import {
  mapDropdownMenuItemsInLabel,
  mapDropdownMenuItemsInName,
} from 'Common/Utils/utils'

import {
  ProductSettingsFormBulkUpdateView,
  INITIAL_STATE as FORM_INITIAL_STATE,
} from './ProductSettingsForm/ProductSettingsFormBulkUpdate.view'
import { useUpdatGroupSettings } from './ProductSettingsForm/ProductSettingsForm.data'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { SKUGroupSettingsError } from './ProductSettings.error.view'
import { InstructionsCardView } from './InstructionsCard/InstructionsCard.view'
import {
  useDownloadSkuSettingsData,
  useUploadProductFile,
} from './ProductSettings.data'
import { FileUploadErrorView } from './FileUploadErrorView/FileUploadError.view'
import { UploadFileData } from './FileUploadErrorView/FileUploadErrorView.types'
import { Loader } from 'Common/Loader/Loader'
import { useQueryClient } from 'react-query'

const INITIAL_STATE = {
  batchGroupingCode: '',
  salesRate: '',
  runRate: '',
  minimumOrderQuantity: '',
  minimumOrderIncrement: '',
  minimumCycle: '',
  maximumCycle: '',
  salesWeeksPerMOQ: '',
  replenishmentTypeId: '',
  productionUnitOfMeasure: '',
  runRateUnitOfMeasure: '',
  replenishmentType: '',
  include: '',
  fixedStartWeek: '',
  fixedCycleTime: '',
  keepCurrentStartWeek: '',
  keepCurrentCycleTime: '',
}

export const ProductSettingsView = ({
  productSettingsTabledata,
  batchGroupingSettingsData,
  updateWorkcentreSettingsState,
  dropdownOptions,
  batchGroupingCodes,
  settingsOption,
}: ProductSettingsProps) => {
  const {
    snapshotId,
    projectId,
    planTemplateId,
    sourceSiteId,
    workcentreId,
    scenarioId,
  } = useParams<PlanTemplateEditInputsParams>()
  const [formData, setFormData] =
    useState<ProductSettingsFormData>(FORM_INITIAL_STATE)
  const classes = useStyles()
  const [selectedProductBatchMinCycle, setSelectedProductBatchMinCycle] =
    useState<number | null>(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedRows, setSelectedRows] = useState<ProductSettings[]>()

  const [tableData, setTableData] = useState<any>(
    productSettingsTabledata.map((item: ProductSettings, idx: number) => {
      return {
        ...item,
        id: idx,
      }
    })
  )

  const [isIncludeUpdating, setIsIncludeUpdating] = useState(false)
  const { replenishmentType, unitOfMeasure } = dropdownOptions

  const uniqueBatchGroupingCodes = [...new Set(batchGroupingCodes)]
  const batchGroupingCodeOptions = uniqueBatchGroupingCodes.map((code) => ({
    key: code,
    value: code,
  }))

  const bulkEditData = useRef(INITIAL_STATE as ProductSettingsParametersType)
  const [errorData, setErrorData] = useState<null | ErrorData>(null)
  const [fileUploadResponse, setFileUploadResponse] =
    useState<null | UploadFileData>(null)
  /*** FILE download and upload */
  // const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  // const [validFile, setValidFile] = useState(false)

  const {
    mutate: uploadSkuGroupData,
    isLoading: isFileUploading,
    data: fileUploadData,
  } = useUploadProductFile()

  useEffect(() => {
    setFileUploadResponse(fileUploadData?.data)
  }, [fileUploadData])

  const handleUploadFile = (file: File) => {
    uploadSkuGroupData(
      {
        planTemplateId,
        sourceSiteId,
        workcentreId,
        projectId,
        snapshotId,
        uploadType: 'product',
        file,
      },
      {
        onSuccess: (data) => {
          if (data?.data.valid) {
            const { updatedData } = data.data
            if ('productSettings' in updatedData) {
              setTableData(updatedData['productSettings'])
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.PRODUCT_SETTINGS,
                value: updatedData['productSettings'],
              })
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.BATCH_GROUPING_SETTINGS,
                value: updatedData['batchGroupingSettings'],
              })
            }
            setAdjustedValues({})
            toast.success('File uploaded and data saved successfully.')
          } else {
            toast.error(
              'File uploaded with error(s), please correct in your file and re-upload.'
            )
          }
        },
        onError: () => {
          toast.error('Could not complete the request, Please try again.')
        },
      }
    )
  }

  const queryClient = useQueryClient()

  const query_data = queryClient.getQueryData('breadcrumbs')

  const projectCode = query_data.data[projectId]
  const workcentreCode = query_data.data[workcentreId]
  const scenarioCode = query_data.data[scenarioId]

  const { isLoading: isDownloadingFile, mutate: downloadSkuGroupSettingsData } =
    useDownloadSkuSettingsData()

  /*** End FILE download and upload */

  useEffect(() => {
    setTableData(
      productSettingsTabledata.map((item: ProductSettings, idx: number) => {
        return {
          ...item,
          id: idx,
        }
      })
    )
    setFileUploadResponse(null)
  }, [productSettingsTabledata])

  useEffect(() => {
    if (selectedRows?.length === 1) {
      const selectedBatchSettingsData = batchGroupingSettingsData.filter(
        (data) => data.batchGroupingCode === selectedRows[0]?.batchGroupingCode
      )
      setSelectedProductBatchMinCycle(
        selectedBatchSettingsData[0]?.minimumCycle
      )
    } else {
      setSelectedProductBatchMinCycle(null)
    }
  }, [selectedRows])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const getSelectedProductIds = () => {
    return selectedRows && selectedRows.map((row) => row.productCode)
  }

  const handleSaveClick = (editedTableData: ProductSettingsParametersType) => {
    const selectedProductIds = getSelectedProductIds()
    const updatedTableData = tableData.map((data) => {
      if (
        selectedProductIds &&
        selectedProductIds?.includes(data.productCode)
      ) {
        return getUpdatedProductSettings(editedTableData, data)
      } else return data
    })
    setTableData(
      updatedTableData.map((item: any, idx: number) => {
        return {
          ...item,
          id: idx,
        }
      })
    )
    updateWorkcentreSettingsState({
      step: WORKCENTER_STEPS.PRODUCT_SETTINGS,
      value: updatedTableData,
    })
    setOpenDrawer(false)
  }

  const [adjustedValues, setAdjustedValues] = useState<unknown>({})

  const onSuccess = (
    message: string,
    updatedData: ProductSettings[],
    updatedBatchGrouping: BatchGroupingSettings[],
    newAdjustedValues: unknown
  ) => {
    setFormData(FORM_INITIAL_STATE)
    setAdjustedValues(newAdjustedValues)
    setErrorData(null)
    setTableData(
      updatedData.map((item: any, idx: number) => {
        return {
          ...item,
          id: idx,
        }
      })
    )
    setIsIncludeUpdating(false)
    handleModalClose()
    updateWorkcentreSettingsState({
      step: WORKCENTER_STEPS.PRODUCT_SETTINGS,
      value: updatedData,
    })
    updateWorkcentreSettingsState({
      step: WORKCENTER_STEPS.BATCH_GROUPING_SETTINGS,
      value: updatedBatchGrouping,
    })
    toast.success(message)
  }

  const onError = (errorData: ErrorData) => {
    toast.error('Failed to Update SKU settings')
  }

  const { mutate: updateGroupSettings, isLoading: isUpdating } =
    useUpdatGroupSettings(onSuccess, onError)

  const applyCalculations = (
    editedTableData: ProductSettingsParametersType,
    field: string,
    SKUGroup: number
  ): number => {
    let value = parseFloat(editedTableData[`${field}`] as string)
    if (editedTableData[`${field}ActionType`] === '1') {
      value =
        tableData.find((x) => x.skuGroupKey === SKUGroup)[field] *
        (editedTableData[`${field}`] / 100 + 1)
    }
    if (editedTableData[`${field}ActionType`] === '2') {
      value =
        tableData.find((x) => x.skuGroupKey === SKUGroup)[field] *
        ((100 - editedTableData[`${field}`]) / 100)
    }
    return parseFloat(value.toFixed(5) as string)
  }

  const handleApplychangesClick = async (
    editedTableData: ProductSettingsParametersType
  ) => {
    bulkEditData.current = editedTableData

    setErrorData(null)
    const SKUGroups =
      selectedRows &&
      selectedRows.map((row) => {
        const structure = {
          [row.skuGroupKey]: [
            {
              ...(editedTableData.batchGroupingCode && {
                batchGroupingCode: editedTableData.batchGroupingCode,
              }),
              ...(editedTableData.replenishmentTypeId && {
                replenishmentTypeId:
                  editedTableData.replenishmentTypeId as number,
              }),
              ...(editedTableData.salesRate && {
                salesRate: applyCalculations(
                  editedTableData,
                  'salesRate',
                  row.skuGroupKey
                ),
              }),
              ...(editedTableData.runRate && {
                runRate: applyCalculations(
                  editedTableData,
                  'runRate',
                  row.skuGroupKey
                ),
              }),
              ...(editedTableData.minimumOrderQuantity && {
                minimumOrderQuantity: applyCalculations(
                  editedTableData,
                  'minimumOrderQuantity',
                  row.skuGroupKey
                ),
              }),
              ...(editedTableData.minimumOrderIncrement && {
                minimumOrderIncrement: applyCalculations(
                  editedTableData,
                  'minimumOrderIncrement',
                  row.skuGroupKey
                ),
              }),
              ...(editedTableData.minimumCycle && {
                minimumCycle: editedTableData.minimumCycle as number,
              }),
              ...(editedTableData.maximumCycle && {
                maximumCycle: editedTableData.maximumCycle as number,
              }),
              ...(editedTableData.include !== null && {
                include: editedTableData.include as boolean,
              }),
              ...(editedTableData.fixedStartWeek !== null && {
                fixedStartWeek: editedTableData.fixedStartWeek as number,
              }),
              ...(editedTableData.fixedCycleTime !== null && {
                fixedCycleTime: editedTableData.fixedCycleTime as number,
              }),
              ...(editedTableData.keepCurrentStartWeek !== null && {
                keepCurrentStartWeek:
                  editedTableData.keepCurrentStartWeek as boolean,
              }),
              ...(editedTableData.keepCurrentCycleTime !== null && {
                keepCurrentCycleTime:
                  editedTableData.keepCurrentCycleTime as boolean,
              }),
            },
          ],
        }
        return structure
      })

    const fields = {
      ...(editedTableData.batchGroupingCode && {
        batchGroupingCode: editedTableData.batchGroupingCode,
      }),
      ...(editedTableData.replenishmentTypeId && {
        replenishmentTypeId: editedTableData.replenishmentTypeId as number,
      }),
      ...(editedTableData.salesRate && {
        salesRate: parseFloat(editedTableData.salesRate as string),
      }),
      ...(editedTableData.runRate && {
        runRate: parseFloat(editedTableData.runRate as string),
      }),
      ...(editedTableData.minimumOrderQuantity && {
        minimumOrderQuantity: parseFloat(
          editedTableData.minimumOrderQuantity as string
        ),
      }),
      ...(editedTableData.minimumOrderIncrement && {
        minimumOrderIncrement: parseFloat(
          editedTableData.minimumOrderIncrement as string
        ),
      }),
      ...(editedTableData.minimumCycle && {
        minimumCycle: editedTableData.minimumCycle as number,
      }),
      ...(editedTableData.maximumCycle && {
        maximumCycle: editedTableData.maximumCycle as number,
      }),
      ...(editedTableData.include &&
        editedTableData.include !== '' && {
          include: editedTableData.include as boolean,
        }),
      ...(editedTableData.fixedStartWeek !== null && {
        fixedStartWeek: editedTableData.fixedStartWeek as number,
      }),
      ...(editedTableData.fixedCycleTime !== null && {
        fixedCycleTime: editedTableData.fixedCycleTime as number,
      }),
      ...(editedTableData.keepCurrentStartWeek &&
        editedTableData.keepCurrentStartWeek !== '' && {
          keepCurrentStartWeek: editedTableData.keepCurrentStartWeek as boolean,
        }),
      ...(editedTableData.keepCurrentCycleTime &&
        editedTableData.keepCurrentCycleTime !== '' && {
          keepCurrentCycleTime: editedTableData.keepCurrentCycleTime as boolean,
        }),
    }

    const SKUGroupKeys =
      selectedRows && selectedRows.map((row) => row.skuGroupKey)

    updateGroupSettings({
      planTemplateId,
      data: {
        snapshotId,
        projectId,
        sourceSiteKey: parseInt(sourceSiteId),
        workcentreKey: parseInt(workcentreId),
        skuGroups: SKUGroups,
        skuGroupKeys: SKUGroupKeys,
        fields,
      },
    })
  }

  const handleDrawerClose = () => {
    setOpenDrawer(false)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  if (isFileUploading) {
    return <Loader />
  }
  return (
    <>
      <ProductSettingsFormBulkUpdateView
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        replenishmentTypes={mapDropdownMenuItemsInLabel(replenishmentType)}
        selectedProductBatchMinCycle={selectedProductBatchMinCycle}
        maximumCycleTypes={CYCLE_MENU_ITEMS}
        minimumCycleTypes={CYCLE_MENU_ITEMS}
        rateUnitMeasureTypes={mapDropdownMenuItemsInName(unitOfMeasure)}
        orderUnitMeasureTypes={mapDropdownMenuItemsInName(unitOfMeasure)}
        batchGroupingCodeOptions={batchGroupingCodeOptions}
        selectedRows={selectedRows ?? []}
        handleSaveClick={handleApplychangesClick}
        isUpdating={isUpdating && isIncludeUpdating === false}
        data={formData}
        setData={setFormData}
      />
      {fileUploadResponse && (
        <FileUploadErrorView
          uploadFileData={fileUploadResponse}
          isLoading={isFileUploading}
        />
      )}
      {errorData !== null && (
        <Box mt={2}>
          <SKUGroupSettingsError data={errorData} groupType="SKU" />
        </Box>
      )}
      <Box display="flex">
        <Box flexDirection="row">
          <Toolbar onEditClick={handleModalOpen} />
        </Box>
        <Box
          flexDirection="row"
          display="flex"
          alignSelf="flex-end"
          justifyContent="flex-end"
          flexGrow={1}
          mb={3}
        >
          <InstructionsCardView
            isUploadingFile={isFileUploading}
            isChangeoverDataLoaded={tableData ? true : false}
            handleDownloadFile={() =>
              downloadSkuGroupSettingsData({
                planTemplateId,
                sourceSiteId,
                workcentreId,
                projectId,
                projectCode,
                workcentreCode,
                scenarioCode,
              })
            }
            handleUploadFile={handleUploadFile}
            isDownloadingFile={isDownloadingFile}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <ProductSettingsTable
          unitOfMeasure={unitOfMeasure}
          data={tableData}
          setData={setTableData}
          setSelectedRows={setSelectedRows}
          adjustedValues={adjustedValues}
          settingsOption={settingsOption}
        />
      </Box>
      {Array.isArray(selectedRows) && selectedRows.length > 0 && (
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={handleDrawerClose}
          transitionDuration={500}
        >
          <Box className={classes.drawerContainer}>
            <Box display="flex" justifyContent="flex-end">
              <DrawerControls onClose={handleDrawerClose} />
            </Box>
            <ProductSettingsForm
              replenishmentTypes={mapDropdownMenuItemsInLabel(
                replenishmentType
              )}
              selectedProductBatchMinCycle={selectedProductBatchMinCycle}
              maximumCycleTypes={CYCLE_MENU_ITEMS}
              minimumCycleTypes={CYCLE_MENU_ITEMS}
              rateUnitMeasureTypes={mapDropdownMenuItemsInName(unitOfMeasure)}
              orderUnitMeasureTypes={mapDropdownMenuItemsInName(unitOfMeasure)}
              batchGroupingCodeOptions={batchGroupingCodeOptions}
              selectedRows={selectedRows}
              handleSaveClick={handleSaveClick}
            />
          </Box>
        </Drawer>
      )}
    </>
  )
}
