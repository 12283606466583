import { SourceSiteDetails } from 'Features/Project/WorkcentreModal/WorkcentreModal.types'
import { COST_OF_CAPITAL_MINIMUM_VALUE } from './ProjectDetails.constants'
import { Scenario } from './ProjectDetails.types'

export const getSelectedWorkcentres = (
  sourceSiteDetails: SourceSiteDetails[]
) => {
  return sourceSiteDetails
    .filter((sourceSite) => sourceSite.isSelected)
    .map((sourceSite) => {
      const workcentres = sourceSite.siteWorkcentres
        .filter((workcentre) => workcentre.isSelected)
        .map((workcentre) => workcentre.key)
      return {
        siteKey: sourceSite.siteKey,
        siteWorkcentres: workcentres,
      }
    })
}

export const getSelctedWorkcentresCount = (
  sourceSiteDetails: SourceSiteDetails[]
) => {
  return sourceSiteDetails.reduce((total, sourceSite) => {
    const selectedSourceSites = sourceSite.siteWorkcentres.filter(
      (workcentre) => workcentre.isSelected
    )
    return total + selectedSourceSites.length
  }, 0)
}

export const parseDateForTriggerAPI = (dateString: string): string => {
  const snapshotEndDate: Date = new Date(dateString)

  const date = ('0' + snapshotEndDate.getDate()).slice(-2)

  const month = ('0' + (snapshotEndDate.getMonth() + 1)).slice(-2)

  const year = snapshotEndDate.getFullYear()

  return `${year}${month}${date}`
}

export const handleCostOfCapitalValidation = (value: string | number) => {
  const valueAsNumber = parseFloat(value as string)
  const VALID_NUMBER_PATTERN = /^[+]?((\.\d{0,1})|(\d+(\.\d{0,1})?)|(\d+\.))$/
  const isValid = value.toString().match(VALID_NUMBER_PATTERN)
  return isValid && valueAsNumber >= COST_OF_CAPITAL_MINIMUM_VALUE
    ? ''
    : 'error'
}

export const emptyScenario: Scenario = {
  scenarioId: '',
  scenarioName: '',
  createdOn: '',
  planTemplateId: '',
  planTemplateName: '',
  replenishmentPolicyId: '',
  replenishmentPolicyName: '',
  templateGenerationComplete: 0,
  templateGenerationPending: 0,
  safetyStockCalculated: '',
}
