import { Table } from 'supplyvue-ui'
import { Column } from 'react-table'
import {
  InventoryHoldingCostTableProps,
  InventoryHoldingCostColumns,
} from './InventoryHoldingCost.types'
import { Loader } from 'Common/Loader/Loader'

export const InventoryHoldingCostTable = ({
  data,
  isLoading,
}: InventoryHoldingCostTableProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 300
    const availableHeight = window.innerHeight - 480
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  const columns: Column<InventoryHoldingCostColumns>[] = [
    {
      Header: 'SKU group code',
      accessor: 'skuGroup',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Current BAU stock volume',
      accessor: 'currentBAUStockVolume',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Hybrid BAU stock volume',
      accessor: 'hybridBAUStockVolume',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Current BAU number of pallets',
      accessor: 'currentBAUNumberOfPallets',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Hybrid BAU number of pallets',
      accessor: 'hybridBAUNumberOfPallets',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Cost per pallet',
      accessor: 'costPerPallet',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Current occupancy cost',
      accessor: 'currentOccupancyCost',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Hybrid occupancy cost',
      accessor: 'hybridOccupancyCost',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Cost change',
      accessor: 'costChange',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
  ]
  if (isLoading) {
    return <Loader />
  }
  return (
    <Table
      data={data}
      height={getTableHeight()}
      columns={columns}
      stickyHeader={true}
      cellPadding={'5px 5px'}
    />
  )
}
