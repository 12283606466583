import { useQuery } from 'react-query'
import { format } from 'date-fns'
import { requestV2 } from 'Features/Auth/axios-client'

const downloadLineData = async (
  planTemplateId: string,
  sourceSiteKey: string,
  workcentreKey: string
) => {
  const { data } = await requestV2({
    url: `/plan-templates/${planTemplateId}/changeovers/download?source_site_key=${sourceSiteKey}&workcentre_key=${workcentreKey}&download_type=product`,
  })
  const fileName = `line_data__${format(new Date(), 'yyyyMMdd')}`
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  return data
}

export const useDownloadLineData = (
  planTemplateId: string,
  sourceSiteKey: string,
  workcentreKey: string
) => {
  return useQuery(
    ['download-line-data', planTemplateId],
    () => downloadLineData(planTemplateId, sourceSiteKey, workcentreKey),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      enabled: false,
    }
  )
}
