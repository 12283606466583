import { Box } from '@material-ui/core'
import { Page } from 'Common/Page/Page'
import { PageHeader } from 'supplyvue-ui'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { useParams } from 'react-router-dom'
import { InventoryHoldingCostParamTypes } from './InventoryHoldingCost.types'
import { useInventoryHoldingCost } from './InventoryHolidingCost.data'
import { InventoryHoldingCostTable } from './InventoryHolidingCost.table'

export const InventoryHoldingCostPage = () => {
  const {
    projectId,
    scenarioId,
    replenishmentPolicyId,
    sourceSiteId,
    siteKey,
  } = useParams<InventoryHoldingCostParamTypes>()

  useBreadcrumbsData({
    project_id: projectId,
    scenario_id: scenarioId,
    replenishment_policy_id: replenishmentPolicyId,
    base_source_site_id: sourceSiteId,
    site_id: siteKey,
  })

  const { data: costData, isLoading } = useInventoryHoldingCost(
    replenishmentPolicyId,
    sourceSiteId,
    parseInt(siteKey)
  )
  return (
    <Page>
      <Box display="flex" flexDirection="column" flex={1}>
        <PageHeader title="Inventory holding cost" />
        <InventoryHoldingCostTable isLoading={isLoading} data={costData} />
      </Box>
    </Page>
  )
}
