import * as React from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material/'
import { ScenarioAccessToggleProps } from './ScenarioAccess.types'

export const ScenarioAccessToggle = ({
  access = 'public',
  scenarioId,
  handleScenarioAccessChange,
}: ScenarioAccessToggleProps) => {
  const [alignment, setAlignment] = React.useState(access)

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: 'public' | 'private'
  ) => {
    if (newAlignment === null) return // If we click on an already selected button, then newAlignment will be null
    setAlignment(newAlignment)
    handleScenarioAccessChange(scenarioId, newAlignment)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Scenario Access Toggle Button Group"
      size="small"
    >
      <ToggleButton value="public">Public</ToggleButton>
      <ToggleButton value="private">Private</ToggleButton>
    </ToggleButtonGroup>
  )
}
