import { Box, Collapse } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { SKUGroupSettingsErrorProps } from './ProductSettingsForm/ProductSettingsForm.types'

export const SKUGroupSettingsError = ({
  data,
  groupType,
}: SKUGroupSettingsErrorProps) => {
  const displayError = () => {
    return Object.keys(data?.validationErrors).map((errorKey) => {
      return (
        <span key={errorKey}>
          {errorKey} for
          {` ${groupType} group code${
            data.validationErrors[errorKey].length > 1 ? 's' : ''
          }`}{' '}
          {data.validationErrors[errorKey].join(',')}
        </span>
      )
    })
  }
  return (
    <Box width="100%">
      <Collapse in={true}>
        <Alert severity="error">
          <AlertTitle>{data.message}</AlertTitle>
          <strong>Please correct all the errors.</strong> <br />
          {displayError()}
        </Alert>
      </Collapse>
    </Box>
  )
}
