import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { SkuGroupManagementFormProps } from './SkuGroupManagementForm.types'
import { booleanDropdownOptions } from '../SkuGroupManagement.constants'
import { useStyles } from './SkuGroupManagementForm.styles'
import { Button, DropDown, Input } from 'supplyvue-ui'

export const SkuGroupManagementForm = ({
  inputValues,
  handleDropdownChange,
  handleInputChange,
  handleSearchClick,
  handleResetFiltersClick,
  disableSearch,
}: SkuGroupManagementFormProps) => {
  const classes = useStyles()

  return (
    <Accordion elevation={0} defaultExpanded className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="search-accordion-content"
        id="search-accordion-header"
      >
        <Typography variant="h4">Search</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Box className={classes.contents}>
          <Box className={classes.parametersContainer}>
            <Box className={classes.section}>
              <Box className={classes.parameter}>
                <Typography className={classes.dropDownLabel}>
                  SKU group code:
                </Typography>

                <Input
                  ariaLabel="SKU group code"
                  value={inputValues.skuGroupCode}
                  onChange={handleInputChange}
                  name="skuGroupCode"
                  className={classes.formElement}
                />
              </Box>
              <Box className={classes.parameter}>
                <Typography className={classes.dropDownLabel}>
                  SKU code:
                </Typography>
                <Input
                  ariaLabel="SKU code"
                  value={inputValues.skuCode}
                  onChange={handleInputChange}
                  name="skuCode"
                  className={classes.formElement}
                />
              </Box>
              <Box className={classes.parameter}>
                <Typography className={classes.dropDownLabel}>
                  Primary workcentre:
                </Typography>
                <Input
                  ariaLabel="Primary workcentre code"
                  value={inputValues.primaryWorkCentreCode}
                  onChange={handleInputChange}
                  name="primaryWorkCentreCode"
                  className={classes.formElement}
                />
              </Box>
            </Box>
            <Box className={`${classes.section} ${classes.middleSection} `}>
              <Box className={classes.parameter}>
                <Typography className={classes.dropDownLabel}>
                  SKU group description:
                </Typography>
                <Input
                  ariaLabel="SKU group description"
                  value={inputValues.skuGroupDescription}
                  onChange={handleInputChange}
                  name="skuGroupDescription"
                  className={classes.formElement}
                />
              </Box>
              <Box className={classes.parameter}>
                <Typography className={classes.dropDownLabel}>
                  SKU description:
                </Typography>
                <Input
                  ariaLabel="SKU description"
                  value={inputValues.skuDescription}
                  onChange={handleInputChange}
                  name="skuDescription"
                  className={classes.formElement}
                />
              </Box>
            </Box>
            <Box className={`${classes.section} ${classes.lastSection}`}>
              <Box className={classes.parameter}>
                <Typography className={classes.dropDownLabel}>
                  Batch group code:
                </Typography>
                <Input
                  ariaLabel="Batch group code"
                  value={inputValues.batchGroupCode}
                  onChange={handleInputChange}
                  name="batchGroupCode"
                  className={classes.formElement}
                />
              </Box>
              <Box className={classes.parameter}>
                <Typography className={classes.dropDownLabel}>
                  Master SKU:
                </Typography>
                <DropDown
                  name="masterSku"
                  onChange={(event: any) => {
                    handleDropdownChange(event)
                  }}
                  defaultLabel="None"
                  label="Master SKU"
                  value={inputValues.masterSku}
                  menuItems={booleanDropdownOptions}
                  className={classes.formElement}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>

      <AccordionActions>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleResetFiltersClick}
          disabled={disableSearch}
        >
          Clear filters
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          disabled={disableSearch}
          onClick={handleSearchClick}
        >
          Search
        </Button>
      </AccordionActions>
    </Accordion>
  )
}
