import { Box, Tabs, Tab } from '@material-ui/core'
import { ChangeEvent, useState } from 'react'
import { TabPanel } from 'supplyvue-ui'
import { useStyles } from './Summary.styles'
import { SummaryGraphToggleViewPropTypes } from './Summary.types'
export const SummaryGraphToggleView = ({
  TotalledGraphView,
  SequencedGraphView,
}: SummaryGraphToggleViewPropTypes) => {
  const classes = useStyles()
  const [tabIndex, setTabIndex] = useState<number>(0)
  const onTabChange = (
    event: ChangeEvent<Record<string, unknown>>,
    value: number
  ) => {
    setTabIndex(value)
  }
  return (
    <Box height="800px">
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={onTabChange}
        aria-label="Totalled or Sequenced"
        className={classes.tabs}
      >
        <Tab
          label="Sequenced"
          className={tabIndex === 0 ? classes.activeTab : ''}
        />
        <Tab
          label="Totalled"
          className={tabIndex === 1 ? classes.activeTab : ''}
        />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Box>{SequencedGraphView}</Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Box>{TotalledGraphView}</Box>
      </TabPanel>
    </Box>
  )
}
