export const SITE_PARAMETER_TYPES = {
  SUPPLY_TYPE: 'supplyType',
  REPLENISHMENT_TYPE: 'replenishmentType',
}

export const inputValuesInitialState = {
  replenishmentCycle: '',
  deploymentLeadTime: '',
  MOI: '',
  MOQ: '',
  serviceLevel: '',
  planningPeriod: '',
}

export const dropDownvaluesInitialState = {
  supplyType: '',
  replenishmentType: '',
  includeType: '',
}

export const includeDropdownIptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false },
]
