export const getStepsLabel = () => {
  return ['Workcentre Settings', 'Inventory Settings']

  /*

  return [
    'Stock Flow Settings',
    'Workcentre Settings',
    'Inventory Settings',
    'Costing',
  ]

   */
}

export const getStepByIndex = (index: number) => {
  const steps = ['workcentreSettings', 'inventorySettings']
  /*
  const steps = [
    'stockFlowSettings',
    'workcentreSettings',
    'inventorySettings',
    'costing',
  ]

   */

  return steps[index]
}
