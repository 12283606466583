import { Box } from '@material-ui/core'
import { PageProps } from './Page.types'
import { useStyles } from './Page.styles'

export const Page = (props: PageProps) => {
  const { children } = props
  const classes = useStyles(props)

  return <Box className={classes.wrapper}>{children}</Box>
}
