import { useState } from 'react'
import {
  Typography,
  CircularProgress,
  Modal,
  Card,
  Paper,
  Checkbox,
} from '@material-ui/core'
import { Button } from 'supplyvue-ui'
import { useStyles } from './CreateScenarioModal.styles'
import { CreateScenarioModalProps } from './CreateScenarioModal.types'
import {
  RadioButtonUncheckedRounded,
  RadioButtonCheckedRounded,
} from '@material-ui/icons'
import InputBase from '@mui/material/InputBase'
import {
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import {
  ProjectDataType,
  ScenarioResponse,
  TemplateSelectionOption,
} from 'Features/Project/ProjectDetails/ProjectDetails.types'

export const CreateScenarioModal = ({
  isModalOpen,
  handleModalClose,
  heading,
  scenarioNameLabel,
  scenarioName,
  handleScenarioNameChange,
  actionButtonLabel,
  handleActionClick,
  isActionDisabled,
  isLoading,
  selectionOption,
  updateSelection,
  existingProjects,
}: CreateScenarioModalProps) => {
  const classes = useStyles()

  const [selectedProjectId, setSelectedProjectId] = useState<string>('')
  const [selectedScenarioName, setSelectedScenarioName] = useState<string>('')

  const scenarios =
    existingProjects.find(
      (project: ProjectDataType) => project.projectId === selectedProjectId
    )?.scenarios || []

  type CircularCheckboxProps = {
    selectionOptionNumber: number
    checked: boolean
  }

  const handleCardClick = (selectionOptionNumber: number) => {
    const templateSelectionOption: TemplateSelectionOption = {
      option: selectionOptionNumber,
      projectId: selectionOptionNumber < 2 ? null : selectionOption.projectId,
      scenarioId: selectionOptionNumber < 2 ? null : selectionOption.scenarioId,
    }

    updateSelection(templateSelectionOption)
  }

  const CircularCheckbox = ({
    selectionOptionNumber,
    checked,
  }: CircularCheckboxProps) => {
    const templateSelectionOption: TemplateSelectionOption = {
      option: selectionOptionNumber,
      projectId: selectionOptionNumber < 2 ? null : selectionOption.projectId,
      scenarioId: selectionOptionNumber < 2 ? null : selectionOption.scenarioId,
    }

    return (
      <Checkbox
        style={{ color: '#007bff' }}
        checked={checked}
        onChange={(event) => updateSelection(templateSelectionOption)}
        icon={<RadioButtonUncheckedRounded style={{ color: 'lightgrey' }} />}
        checkedIcon={<RadioButtonCheckedRounded />}
        disableRipple={true}
      />
    )
  }

  const CardOption = ({
    option,
    title,
    subtitle,
  }: {
    option: number
    title: string
    subtitle: string
  }) => {
    return (
      <Card
        className={
          selectionOption.option === option
            ? classes.selectedCard
            : classes.cardContainer
        }
        onClick={() => {
          handleCardClick(option)
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <CircularCheckbox
            selectionOptionNumber={option}
            checked={selectionOption.option === option}
          />
          <Typography variant="h4">{title}</Typography>
        </div>
        <div style={{ marginLeft: '42px' }}>
          <Typography variant="subtitle1">{subtitle}</Typography>
        </div>
      </Card>
    )
  }

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleActionClick()
    }
  }

  const SelectDropdown = () => {
    const handleProjectChange = (event: SelectChangeEvent) => {
      setSelectedProjectId(event.target.value)
      setSelectedScenarioName('')

      const newTemplateOption: TemplateSelectionOption = {
        option: 2,
        scenarioId: null,
        projectId: event.target.value,
      }

      updateSelection(newTemplateOption)
    }

    const handleScenarioChange = (event: SelectChangeEvent) => {
      const scenarioName = event.target.value
      setSelectedScenarioName(scenarioName)

      const scenario: ScenarioResponse | undefined = scenarios.find(
        (scenario: ScenarioResponse) => scenario.scenarioName === scenarioName
      )

      if (scenario) {
        const newTemplateOption: TemplateSelectionOption = {
          option: 2,
          scenarioId: scenario.scenarioId,
          projectId: scenario.projectId,
        }

        updateSelection(newTemplateOption)
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          width: '100%', // Full width of parent container
        }}
      >
        {/* Headers Section with 30%-70% Split */}
        <div style={{ display: 'flex', width: '100%', gap: '50px' }}>
          <Typography variant="h5" style={{ flex: 0.3, textAlign: 'left' }}>
            Project
          </Typography>
          <Typography variant="h5" style={{ flex: 0.7, textAlign: 'left' }}>
            Scenario
          </Typography>
        </div>

        {/* Dropdown Section with Slash Separator */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '20px',
          }}
        >
          {/* First Dropdown - 30% width */}
          <div style={{ flex: 0.3 }}>
            <Paper elevation={0}>
              <FormControl fullWidth>
                <Select
                  sx={{
                    height: '50px',
                    borderRadius: '6px',
                    border: '1px solid lightgrey',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                  }}
                  value={selectedProjectId}
                  onChange={handleProjectChange}
                >
                  <MenuItem value="" disabled>
                    Select a project
                  </MenuItem>
                  {existingProjects.map((project: ProjectDataType) => (
                    <MenuItem key={project.projectId} value={project.projectId}>
                      {project.projectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>
          </div>

          {/* Slash Separator */}
          <Typography variant="subtitle1" style={{ fontSize: '1.5rem' }}>
            /
          </Typography>

          {/* Second Dropdown - 70% width */}
          <div style={{ flex: 0.7 }}>
            <Paper elevation={0}>
              <FormControl fullWidth>
                <Select
                  sx={{
                    height: '50px',
                    borderRadius: '6px',
                    border: '1px solid lightgrey',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                  }}
                  value={selectedScenarioName}
                  onChange={handleScenarioChange}
                  renderValue={(selected) => {
                    const selectedScenario = scenarios.find(
                      (s) => s.scenarioName === selected
                    )
                    return selectedScenario ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Typography variant="body1">
                          {selectedScenario.scenarioName}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ color: 'lightgrey' }}
                        >
                          {new Date(
                            selectedScenario.createdOn
                          ).toLocaleDateString()}
                        </Typography>
                      </div>
                    ) : (
                      <Typography style={{ color: 'lightgrey' }}>
                        Select a scenario
                      </Typography>
                    )
                  }}
                >
                  <MenuItem value="" disabled>
                    Select a scenario
                  </MenuItem>
                  {scenarios.map((scenario: ScenarioResponse) => (
                    <MenuItem
                      key={scenario.scenarioName}
                      value={scenario.scenarioName}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="body1">
                        {scenario.scenarioName}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ color: 'lightgrey' }}
                      >
                        {new Date(scenario.createdOn).toLocaleDateString()}{' '}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal
      aria-labelledby={heading}
      open={isModalOpen}
      onClose={handleModalClose}
    >
      <div className={classes.modalWrapper}>
        <Paper elevation={2} className={classes.paper}>
          <Typography className={classes.label} variant="h2">
            {scenarioNameLabel}
          </Typography>
          <div className={classes.modalContent}>
            <div className={classes.body}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper
                  elevation={0}
                  component="form"
                  style={{
                    borderRadius: 8,
                    border: '1px solid lightgrey',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    height: '48px',
                  }}
                >
                  <InputBase
                    value={scenarioName}
                    onChange={handleScenarioNameChange}
                    onKeyDown={handleOnKeyDown}
                    disabled={isLoading}
                    placeholder="Enter Scenario Name"
                    fullWidth
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      minHeight: '100%',
                      padding: 0,
                      '& .MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        sx={{
                          height: '100%',
                          minHeight: '100%',
                          paddingLeft: '12px',
                          paddingRight: '12px',
                          marginRight: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: 'grey.100',
                          borderTopLeftRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderRight: '1px solid lightgrey',
                        }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            color: 'grey.700',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                        >
                          Scenario Name
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </Paper>
              </div>
            </div>
            <div style={{ marginTop: '32px' }}>
              <div className={classes.description}>
                <Typography variant="h3">Base Template</Typography>
                <Typography variant="subtitle1" style={{ color: 'grey' }}>
                  Choose a base template or create brand new template
                </Typography>
              </div>
              <div className={classes.selectionContainer}>
                <CardOption
                  option={0}
                  title={'New Template'}
                  subtitle={'Create a new scenario that has no base template'}
                />
                <CardOption
                  option={1}
                  title={'System of Record'}
                  subtitle={
                    'Create a new scenario with a base template from the system of record'
                  }
                />
                <CardOption
                  option={2}
                  title={'Existing Project'}
                  subtitle={
                    'Create a new scenario from an existing scenario from any project'
                  }
                />
              </div>
            </div>
            {selectionOption.option === 2 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '4px',
                }}
              >
                <SelectDropdown />
              </div>
            )}
            <div className={classes.footer}>
              <Button
                onClick={handleActionClick}
                className={classes.modalActionButton}
                label={actionButtonLabel}
                disabled={isActionDisabled}
                variant="contained"
              >
                {isLoading && (
                  <CircularProgress
                    color="inherit"
                    size={16}
                    className={classes.progress}
                  />
                )}
                {actionButtonLabel}
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    </Modal>
  )
}
