import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { modellingTheme } from 'supplyvue-ui'

export const AppThemeProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const theme = createMuiTheme(modellingTheme)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
