import {
  Box,
  Typography,
  Tabs,
  Tab,
  Popover,
  IconButton,
  Divider,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Input, Button, TabPanel } from 'supplyvue-ui'
import { useStyles } from './WorkcentreConstraints.styles'
import {
  FieldName,
  WorkcentreConstraintsProps,
} from './WorkcentreConstraints.types'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import {
  BUFFER,
  BUFFER_ERROR_MESSAGE,
  WORKCENTRE_CAPACITY,
  COMBINED_BUFFER,
  COMBINED_WORKCENTRE_CAPACITY,
} from '../PlanTemplateGeneration.constants'

export const WorkcentreConstraintsView = ({
  buffer,
  workcentreCapacity,
  updateInputField,
  handleOptimizationChange,
  handlePrimaryButtonClick,
  isPrimaryButtonDisabled,
  tabIndex,
  planTemplateBasedOn,
  combinedBuffer,
  combinedWorkcentreCapacity,
}: WorkcentreConstraintsProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [bufferError, setBufferError] = useState(false)
  const [combinedBufferError, setCombinedBufferError] = useState(false)
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const isPopoverOpen = Boolean(anchorEl)
  const popoverId = isPopoverOpen ? 'info-popover' : undefined

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: FieldName
  ) => {
    const value = event.target.value
    const numberRegex = /^([0-9]){0,8}$/i
    const isBlank = value === ''
    const isNumeric = numberRegex.test(value)
    const isGreaterThanZero = parseInt(value) > 0
    const isGreaterThanOrEqualToZero = parseInt(value) >= 0
    if (
      fieldName === WORKCENTRE_CAPACITY ||
      fieldName === COMBINED_WORKCENTRE_CAPACITY
    ) {
      if (isBlank || (isNumeric && isGreaterThanZero)) {
        updateInputField(event.target.value, fieldName)
      }
    } else {
      if (isBlank || (isNumeric && isGreaterThanOrEqualToZero)) {
        updateInputField(event.target.value, fieldName)
      }
    }
  }

  useEffect(() => {
    setBufferError(parseInt(buffer) >= parseInt(workcentreCapacity))
  }, [buffer, workcentreCapacity])

  useEffect(() => {
    setCombinedBufferError(
      parseInt(combinedBuffer) >= parseInt(combinedWorkcentreCapacity)
    )
  }, [combinedBuffer, combinedWorkcentreCapacity])

  return (
    <>
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleOptimizationChange}
        aria-label="Constrained,Unconstrained, costOptimized, carbonCost tabs"
        className={classes.tabs}
      >
        <Tab
          label="Constrained"
          className={tabIndex === 0 ? classes.activeTab : ''}
        />
        <Tab
          label="Unconstrained"
          className={tabIndex === 1 ? classes.activeTab : ''}
        />
        <Tab
          label="Cost Optimized"
          className={tabIndex === 2 ? classes.activeTab : ''}
        />
        <Tab
          label="Campaign"
          className={tabIndex === 3 ? classes.activeTab : ''}
        />
        {/**
           <Tab
           label="Alternative Constrained"
           className={tabIndex === 4 ? classes.activeTab : ''}
           />
           <Tab
           label="Alternative Unconstrained"
           className={tabIndex === 5 ? classes.activeTab : ''}
           />
           **/}
        <Box className={classes.iconButton}>
          <IconButton className={classes.helpIcon} onClick={handlePopoverOpen}>
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Box>
              <Typography className={classes.label} variant="h4">
                Product line
              </Typography>
            </Box>
            <Box display="flex" mt={2}>
              <Box className={classes.formControl}>
                <label htmlFor="buffer">
                  <Typography className={classes.label} variant="h4">
                    Buffer
                  </Typography>
                </label>
                <Input
                  id="buffer"
                  value={buffer}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(event, BUFFER)
                  }
                  className={classes.input}
                  size="small"
                  error={bufferError}
                />
              </Box>
              <Box className={classes.formControl}>
                <label htmlFor="total workcentre capacity">
                  <Typography className={classes.label} variant="h4">
                    Total work centre capacity
                  </Typography>
                </label>
                <Input
                  id="total workcentre capacity"
                  value={workcentreCapacity}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(event, WORKCENTRE_CAPACITY)
                  }
                  className={classes.input}
                  size="small"
                />
              </Box>
            </Box>
          </Box>

          {/****** Maker */}
          {planTemplateBasedOn === 'Combined maker and line' && (
            <Box display="flex">
              <Divider orientation="vertical" />
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                ml={2}
              >
                <Box>
                  <Typography className={classes.label} variant="h4">
                    Maker line
                  </Typography>
                </Box>
                <Box display="flex" mt={2}>
                  <Box className={classes.formControl}>
                    <label htmlFor="buffer">
                      <Typography className={classes.label} variant="h4">
                        Buffer
                      </Typography>
                    </label>
                    <Input
                      id="combined buffer"
                      value={combinedBuffer}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(event, COMBINED_BUFFER)
                      }
                      className={classes.input}
                      size="small"
                      error={combinedBufferError}
                    />
                  </Box>
                  <Box className={classes.formControl}>
                    <label htmlFor="total workcentre capacity">
                      <Typography className={classes.label} variant="h4">
                        Total work centre capacity
                      </Typography>
                    </label>
                    <Input
                      id="total workcentre capacity"
                      value={combinedWorkcentreCapacity}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(event, COMBINED_WORKCENTRE_CAPACITY)
                      }
                      className={classes.input}
                      size="small"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {/****** End maker */}
          <Box className={classes.calculateButtonConstrained}>
            <Button
              className={classes.calculateButton}
              color="primary"
              variant="contained"
              onClick={handlePrimaryButtonClick}
              disabled={isPrimaryButtonDisabled || !workcentreCapacity}
            >
              Calculate
            </Button>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography className={classes.errorMessage}>
            {bufferError || combinedBufferError ? BUFFER_ERROR_MESSAGE : ''}
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Box className={classes.calculateButtonUnconstrained}>
          <Button
            className={classes.calculateButton}
            color="primary"
            variant="contained"
            onClick={handlePrimaryButtonClick}
            disabled={isPrimaryButtonDisabled}
          >
            Calculate
          </Button>
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <Box className={classes.calculateButtonUnconstrained}>
          <Button
            className={classes.calculateButton}
            color="primary"
            variant="contained"
            onClick={handlePrimaryButtonClick}
            disabled={isPrimaryButtonDisabled}
          >
            Calculate
          </Button>
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <Box className={classes.calculateButtonUnconstrained}>
          <Button
            className={classes.calculateButton}
            color="primary"
            variant="contained"
            onClick={handlePrimaryButtonClick}
            disabled={isPrimaryButtonDisabled}
          >
            Calculate
          </Button>
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <Box display="flex" justifyContent="flex-start" flexDirection="column">
          <Box>
            <Typography className={classes.label} variant="h4">
              Product line
            </Typography>
          </Box>
          <Box display="flex" mt={2}>
            <Box className={classes.formControl}>
              <label htmlFor="buffer">
                <Typography className={classes.label} variant="h4">
                  Buffer
                </Typography>
              </label>
              <Input
                id="buffer"
                value={buffer}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(event, BUFFER)
                }
                className={classes.input}
                size="small"
                error={bufferError}
              />
            </Box>
            <Box className={classes.formControl}>
              <label htmlFor="total workcentre capacity">
                <Typography className={classes.label} variant="h4">
                  Total work centre capacity
                </Typography>
              </label>
              <Input
                id="total workcentre capacity"
                value={workcentreCapacity}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(event, WORKCENTRE_CAPACITY)
                }
                className={classes.input}
                size="small"
              />
            </Box>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={5}>
        <Box className={classes.calculateButtonUnconstrained}>
          <Button
            className={classes.calculateButton}
            color="primary"
            variant="contained"
            onClick={handlePrimaryButtonClick}
          >
            Calculate
          </Button>
        </Box>
      </TabPanel>
      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box maxWidth="300px" p={2}>
          <Typography variant="body1">
            <b>Constrained</b> - Generate the best plan template that meets the
            work centre capacity constraint
          </Typography>
          <br />
          <Typography variant="body1">
            <b>Unconstrained</b> - Generate a plan template by applying the
            minimum cycle for each product, without taking account of work
            centre capacity
          </Typography>
        </Box>
      </Popover>
    </>
  )
}
