import { useState } from 'react'
import { Box, Tabs, Tab } from '@material-ui/core'
import { useStyles } from './Parameters.styles'
import { ContentView } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/ParametersContent.View'
import { ParametersProps } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/Parameters.types'

export const ParametersView = ({
  dropdownOptions,
  batchGroupingCodes,
}: ParametersProps) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState<number>(0)
  const steps = ['Sku', 'Maker Batch']

  return (
    <>
      <Tabs
        value={activeStep}
        onChange={(e, value) => {
          setActiveStep(value)
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        classes={{ root: classes.tabs }}
      >
        {steps.map((label: string) => {
          return <Tab key={label} label={label} />
        })}
      </Tabs>
      <Box className={classes.stepContentView}>
        <ContentView
          step={activeStep}
          dropdownOptions={dropdownOptions}
          batchGroupingCodes={batchGroupingCodes}
        />
      </Box>
    </>
  )
}
