import { Box, Typography, IconButton, Tooltip } from '@material-ui/core'
import { Button } from 'supplyvue-ui'
import { PageHeaderProps } from './PageHeader.types'
import { useStyles } from './PageHeader.styles'

export const PageHeader = ({
  title,
  actionIcon,
  actionLabel,
  actionHandler,
  actionTooltip,
}: PageHeaderProps) => {
  const classes = useStyles()
  return (
    <Box className={classes.wrapper}>
      <Typography variant="h2">{title}</Typography>
      {!!actionLabel && (
        <Button startIcon={actionIcon} onClick={actionHandler}>
          {actionLabel}
        </Button>
      )}
      {!actionLabel && !!actionTooltip && (
        <Tooltip title={actionTooltip}>
          <span>
            <IconButton
              color="primary"
              onClick={actionHandler}
              disabled={false}
            >
              {actionIcon}
            </IconButton>
          </span>
        </Tooltip>
      )}
    </Box>
  )
}
