import React from 'react'
import Plot from 'react-plotly.js'

import { GraphProps } from './Graph.types'
import { useStyles } from './Graph.styles'

export const hovertemplate = (
  xAxisName: string,
  traceName: string,
  xAxisUnit?: string,
  yAxisUnit?: string
) =>
  `   <b>${xAxisName}:  </b>%{x}${xAxisUnit ? xAxisUnit : ''}<br>` +
  `   <b>${traceName}:  </b>%{y}${yAxisUnit ? yAxisUnit : ''}<br><br>` +
  '<extra></extra>'

export const hovertemplateSKUGroup = (
  xAxisName: string,
  traceName: string,
  segmentName?: string,
  grpCode?: string
) =>
  `   <b>${xAxisName}:  </b>${segmentName ? segmentName : ''}<br>` +
  `   <b>${'SKU Grp code'}:  </b>${grpCode ? grpCode : ''}<br>` +
  `   <b>${traceName}:  </b>%{y}<br><br>` +
  '<extra></extra>'

export const Graph: React.FC<GraphProps> = ({
  onHover,
  onUnhover,
  revision,
  responsive = true,
  data,
  config,
  layout,
  className,
  title,
}: GraphProps) => {
  const classes = useStyles()

  const defaultConfig = {
    displaylogo: false,
    responsive: true,
  }

  const styles = {
    plotBackgroundColor: '#fff',
    lineColor: '#E7E7E7',
    fontColor: '#000',
    hoverBorderColor: '#000',
    hoverBackgroundColor: '#FFF',
  }

  const properties = React.useMemo<any>(() => {
    return {
      data: data,
      layout: {
        title: {
          text: `<b>${title ? title : ''}<b>`,
          font: {
            family: 'proxima-nova',
            size: 16,
            color: styles.fontColor,
          },
        },
        hovermode: 'closest',
        hoverlabel: {
          bordercolor: styles.hoverBorderColor,
          bgcolor: styles.hoverBackgroundColor,
          font: { color: styles.fontColor, family: 'proxima-nova' },
          style: {
            padding: '20px',
          },
        },
        margin: {
          l: 50,
          r: 50,
          b: 50,
          t: 50,
        },
        padding: {
          l: 50,
        },
        plotBackground: styles.plotBackgroundColor,
        ...layout,
        xaxis: {
          showgrid: false,
          nticks: 8,
          linewidth: 2,
          zeroline: true,
          showline: true,
          linecolor: styles.lineColor,
          tickfont: {
            family: 'proxima-nova',
            size: 14,
            color: styles.fontColor,
          },
          ...layout?.xaxis,
        },
        yaxis: {
          showgrid: false,
          showline: true,
          linewidth: 2,
          zeroline: false,
          linecolor: styles.lineColor,
          tickfont: {
            family: 'proxima-nova',
            size: 14,
            color: styles.fontColor,
          },
          ...layout?.yaxis,
        },
      },
      config: {
        ...defaultConfig,
        ...config,
      },
    }
  }, [data])

  return (
    <Plot
      className={`${classes.plot} ${className}`}
      data={properties.data}
      config={properties.config}
      layout={properties.layout}
      useResizeHandler={responsive}
      onHover={onHover}
      onUnhover={onUnhover}
      revision={revision}
    />
  )
}
