import { Box } from '@material-ui/core'
import { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PlanTemplatePageParams } from 'Features/NewPlanTemplate/NewPlanTemplate.types'
import { useWorkcentreSettingsData } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.data'
import { Loader } from 'Common/Loader/Loader'
import { useStyles } from './SalesHorizon.styles'
import { Toolbar } from 'Common/Toolbar'
import { SalesHorizonTable } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/SalesHorizon.table'
import { SalesHorizonBulkUpdate } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/BulkUpdate/BulkUpdate.view'
import { INITIAL_STATE as FORM_INITIAL_STATE } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/BulkUpdate/BulkUpdate.view'
import { SalesHorizonFormData } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/BulkUpdate/BulkUpdate.types'
import { toast } from 'react-hot-toast'
import { useUpdateGroupSettings } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/SalesHorizon.data'
import { WorkcentreSettings } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/SalesHorizon.types'

export const SalesHorizonView = () => {
  const classes = useStyles()
  const { snapshotId, projectId, scenarioId, planTemplateId } =
    useParams<PlanTemplatePageParams>()

  const { data } = useWorkcentreSettingsData(
    snapshotId,
    projectId,
    planTemplateId
  )

  const [tableData, setTableData] = useState<WorkcentreSettings[]>([])
  const [selectedRows, setSelectedRows] = useState<WorkcentreSettings[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [formData, setFormData] =
    useState<SalesHorizonFormData>(FORM_INITIAL_STATE)

  const onSuccess = (updatedData: WorkcentreSettings[]) => {
    setFormData(FORM_INITIAL_STATE)
    setTableData(updatedData)
    handleModalClose()
    toast.success('Succesfully updated workcentre settings')
  }

  const onError = () => {
    handleModalClose()
    toast.error('Updating workcentre failed')
  }
  const { mutate: updateGroupSettings, isLoading: isUpdating } =
    useUpdateGroupSettings(onSuccess, onError)

  useEffect(() => {
    if (data) {
      setTableData(data.settings)
    }
  }, data)

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleApplyChanges = (data: SalesHorizonFormData) => {
    const updateMode = 'SETTINGS'

    const updatedData = selectedRows.map((item: WorkcentreSettings) => {
      let newItem = {
        ...item,
        salesRatePeriod:
          data.salesRatePeriod !== ''
            ? data.salesRatePeriod
            : item.salesRatePeriod,
        salesRateType:
          data.salesRateType != '' ? data.salesRateType : item.salesRateType,
        templateBasedOn:
          data.templateBasedOn != ''
            ? data.templateBasedOn
            : item.templateBasedOn,
      }

      delete newItem['id']
      return newItem
    })

    updateGroupSettings({
      planTemplateId,
      snapshotId,
      projectId,
      updateMode,
      data: updatedData,
    })
  }

  const onEditClick = () => {
    handleModalOpen()
  }

  if (!tableData) {
    return <Loader />
  }

  return (
    <>
      <Box className={classes.salesHorizonView}>
        <SalesHorizonBulkUpdate
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          isUpdating={isUpdating}
          data={formData}
          setData={setFormData}
          handleSaveClick={handleApplyChanges}
        />

        <Box className={classes.toolbar}>
          <Box className={classes.bulkUpdate}>
            <Toolbar onEditClick={onEditClick} />
          </Box>
        </Box>
        <SalesHorizonTable data={tableData} setSelectedRows={setSelectedRows} />
      </Box>
    </>
  )
}
