import { useReducer } from 'react'
import { ScenarioSettingsResponse } from 'Features/PlanTemplate/TemplateSettings/TemplateSettings.types'
import {
  ScenarioSettingsState,
  ScenarioSettingStateAction,
} from './ScenarioSettings.types'

export const getInitialSettings = (
  scenarioSettingsData: ScenarioSettingsResponse
) => {
  const { settings } = scenarioSettingsData
  const salesRateType = settings.salesRateType ?? 'forecast'
  const salesRatePeriod = settings.salesRatePeriod ?? ''
  const minimumAverageBatchesPerProductionCycle =
    settings.minimumAverageBatchesPerProductionCycle ?? 1
  const templateBasedOn = settings.templateBasedOn
  return {
    salesRateType,
    salesRatePeriod,
    minimumAverageBatchesPerProductionCycle,
    templateBasedOn,
  }
}

const reducer = (
  state: ScenarioSettingsState,
  action: ScenarioSettingStateAction
) => {
  if (action.type === 'SET_SALES_RATE_TYPE') {
    return {
      ...state,
      salesRateType: action.payload as string,
    }
  }
  if (action.type === 'SET_SALES_RATE_PERIOD') {
    return {
      ...state,
      salesRatePeriod: action.payload as number,
    }
  }
  if (action.type === 'SET_MIN_AVG_BATCH_PER_CYCLE') {
    return {
      ...state,
      minimumAverageBatchesPerProductionCycle: action.payload as number,
    }
  }
  if (action.type === 'SET_PLANTEMPLATE_BASED_ON') {
    return {
      ...state,
      templateBasedOn: action.payload as string,
    }
  }
  if (action.type === 'RESET_SCENARIO_SETTINGS') {
    return getInitialSettings(action.payload as ScenarioSettingsResponse)
  }
  return state
}

export const useScenarioSettingsState = (
  initialSettings: ScenarioSettingsResponse
) => {
  return useReducer(reducer, initialSettings, getInitialSettings)
}
