import { Table } from 'supplyvue-ui'
import { Column, TableInstance } from 'react-table'
import { CostOutputsTableProps, CostOutputsColumn } from './CostOutputs.types'
import { Loader } from 'Common/Loader/Loader'

export const CostOutputsTable = ({
  data,
  isLoading,
}: CostOutputsTableProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 300
    const availableHeight = window.innerHeight - 690
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  const cellRenderer = (props: TableInstance) =>
    props.value ? props.value.toLocaleString('en-US') : null

  const columns: Column<CostOutputsColumn>[] = [
    {
      Header: 'Scenario',
      accessor: 'scenario',
      disableFilters: true,
      disableSortBy: true,
      columnWidth: 120,
    },
    {
      Header: 'Crew cost',
      accessor: 'crewCost',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },
    {
      Header: 'Incremental cost',
      accessor: 'incrementalCost',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },
    {
      Header: 'Change over cost',
      accessor: 'changeoverCost',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },
    {
      Header: 'Total work center cost',
      accessor: 'totalWorkcentreCost',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },
    {
      Header: 'Cost of capital times the inventory value',
      accessor: 'costOfCapitalTimesInvValue',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },
    {
      Header: 'Warehouse holding cost',
      accessor: 'warehouseHoldingCost',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },
    {
      Header: 'Total inventory cost',
      accessor: 'totalInventoryCost',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },
    {
      Header: 'Total scenario cost',
      accessor: 'totalScenarioCost',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },
  ]
  if (isLoading) {
    return <Loader />
  }
  return (
    <Table
      data={data}
      height={getTableHeight()}
      columns={columns}
      cellPadding="5px 5px"
    />
  )
}
