import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  stepper: {
    padding: '18px 24px',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  stepContentView: {
    marginTop: '15px',
    height: '100%',
  },
}))
