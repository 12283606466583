import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  contentWrapper: {
    minWidth: '600px',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    gap: '3px',
  },
})
