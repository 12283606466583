import { Box } from '@material-ui/core'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { Loader } from 'Common/Loader/Loader'
import { Page } from 'Common/Page/Page'
import { useParams } from 'react-router-dom'
import { PageHeader } from 'supplyvue-ui'
import { useDemandScenarioTableData } from '../DemandScenario.data'
import { DemandScenarioParams } from '../DemandScenario.types'
import { ViewDemandScenarioTable } from './ViewDemandScenario.table'
export const ViewDemandScenario = () => {
  const {
    demandScenarioId,
    projectId,
    snapshotId,
  } = useParams<DemandScenarioParams>()
  useBreadcrumbsData({
    project_id: projectId,
    snapshot_id: snapshotId,
    demand_scenario_id: demandScenarioId,
  })
  const {
    data: demandScenarioTableData,
    isLoading,
  } = useDemandScenarioTableData(demandScenarioId)

  if (isLoading) {
    return <Loader />
  }
  return (
    <Page>
      <Box display="flex" flexDirection="column" flex="auto">
        <PageHeader title="View demand scenario"></PageHeader>
        <Box>
          <ViewDemandScenarioTable
            data={demandScenarioTableData}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Page>
  )
}
