import { Box, Typography } from '@material-ui/core'
import { Button } from 'supplyvue-ui'
import { EmptyStateProps } from './EmptyState.types'

export const EmptyState = ({
  icon,
  text,
  actionText,
  handleClick,
}: EmptyStateProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      flexGrow={1}
    >
      <Box color="#A2BFC9">{icon}</Box>
      <Box mb={4} maxWidth="400px" color="#5D5D5D">
        <Typography variant="h4" color="inherit" align="center">
          {text}
        </Typography>
      </Box>
      <Button variant="contained" onClick={handleClick}>
        {actionText}
      </Button>
    </Box>
  )
}
