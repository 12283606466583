import { PlanDetailsOptimizationDropDownProps } from './PlanDetails.types'
import { DropDown } from 'supplyvue-ui'
import { ChangeEvent } from 'react'
import { useStyles } from './PlanDetails.styles'
import { Box, Typography } from '@material-ui/core'

export const PlanDetailsOptimizationDropDown = ({
  label,
  menuItems,
  value,
  handleOptimizationChange,
}: PlanDetailsOptimizationDropDownProps) => {
  const classes = useStyles()
  return (
    <Box display={'flex'}>
      <Box className={classes.labelContainer}>
        <Typography>{label}</Typography>
      </Box>
      <Box>
        <DropDown
          className={classes.select}
          label={label}
          menuItems={menuItems}
          value={value}
          onChange={(
            e: ChangeEvent<{
              name?: string | undefined
              value: unknown
            }>
          ) => handleOptimizationChange(e.target.value as string)}
        />
      </Box>
    </Box>
  )
}
