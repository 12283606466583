import { Graph, hovertemplate } from 'Common/Graph/Graph'
import { useMemo } from 'react'
import { SummaryOfApprovedSitesGraphProps } from '../SummaryOfApprovedSites/SummaryOfApprovedSites.types'

export const SummaryOfApprovedSitesGraph = ({
  xAxisData,
  yAxisDataOne,
  yAxisDataTwo,
  xAxisLabel,
  yAxisLabel,
}: SummaryOfApprovedSitesGraphProps) => {
  const data = useMemo(
    () => [
      {
        x: xAxisData,
        y: yAxisDataOne,
        hovertemplate: hovertemplate('Source site ', 'Current inventory '),
        marker: {
          color: '#59A14F',
        },
        name: 'Current inventory',
        type: 'bar',
        width: 0.2,
        showlegend: true,
      },
      {
        x: xAxisData,
        y: yAxisDataTwo,
        hovertemplate: hovertemplate('Source site ', 'Proposed inventory '),
        marker: {
          color: '#2E7E9B',
        },
        name: 'Proposed inventory',
        type: 'bar',
        width: 0.2,
        showlegend: true,
      },
    ],
    [xAxisData, yAxisDataOne, yAxisDataTwo]
  )

  const layout = {
    height: 350,
    yaxis: {
      showgrid: true,
      title: {
        text: yAxisLabel,
      },
      automargin: true,
    },
    xaxis: {
      zeroline: true,
      nticks: 13,
      title: {
        text: xAxisLabel,
        standoff: 1,
      },
    },
    barmode: 'group',
    legend: { orientation: 'h', x: 0, y: -0.8 },
  }

  return <Graph data={data} layout={layout} />
}
