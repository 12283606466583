import { useState } from 'react'
import { Box, Typography, CircularProgress } from '@material-ui/core'
import { Input, CalendarInput, DropDown, Button, Modal } from 'supplyvue-ui'
import {
  CONSTANTS,
  SNAPSHOT_LENGTH_MENU_ITEMS,
} from './SnapshotModal.constants'
import {
  getLastSunday,
  getSelectSnapshotDropdownMenuItems,
  isValidDate,
} from './SnapshotModal.utils'
import { SnapshotDetails, SnapshotModalProps } from './SnapshotModal.types'
import { useStyles } from './SnapshotModal.styles'

export const SnapshotModal = ({
  snapshotData,
  isModalOpen,
  handleModalClose,
  importStatus,
  handleOkButtonClick,
}: SnapshotModalProps) => {
  const classes = useStyles()
  const DEFAULT_SNAPSHOT_LENGTH = 52
  const [endDateError, setEndDateError] = useState(false)
  const [selectedSnapshot, setSelectedSnapshot] = useState('')
  const [snapshotName, setSnapshotName] = useState('')
  const [endDate, setEndDate] = useState(getLastSunday())
  const [snapshotLengthValue, setSnapshotLengthValue] = useState(
    DEFAULT_SNAPSHOT_LENGTH
  )
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false)

  const handleSnapshotNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSnapshotName(event.target.value)
  }

  const handleEndDateChange = (date: Date | null) => {
    if (isValidDate(date) && date) {
      setEndDate(date)
      setEndDateError(false)
    } else {
      setEndDateError(true)
    }
  }

  const getSelectedSnapshot = (snapshotId: string) => {
    return snapshotData.filter(
      (snapshot: SnapshotDetails) => snapshot.snapshotId === snapshotId
    )[0]
  }

  const handleSnapshotLengthChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSnapshotLengthValue(event.target.value as number)
  }

  const handleSelectedSnapshotChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value !== '') {
      const selectedSnapshot: SnapshotDetails = getSelectedSnapshot(
        event.target.value as string
      )
      const endDate = new Date(selectedSnapshot.snapshotEndDate)
      setSnapshotName(selectedSnapshot.snapshotName)
      setEndDate(endDate)
      setSnapshotLengthValue(selectedSnapshot.snapshotLength)
      setIsFieldsDisabled(true)
    } else {
      setSnapshotName('')
      setEndDate(getLastSunday())
      setSnapshotLengthValue(DEFAULT_SNAPSHOT_LENGTH)
      setIsFieldsDisabled(false)
    }
    setSelectedSnapshot(event.target.value as string)
  }

  const disableWeekdays = (date: Date) => {
    return date.getDay() !== 0
  }

  const Body = (
    <Box>
      <Typography className={classes.label} variant="h4">
        Select snapshot
      </Typography>
      <DropDown
        label="Select snapshot"
        className={classes.selectSnapshot}
        defaultLabel="Import new snapshot"
        menuItems={getSelectSnapshotDropdownMenuItems(snapshotData)}
        value={selectedSnapshot}
        onChange={handleSelectedSnapshotChange}
        disabled={importStatus === CONSTANTS.IMPORT_STATUS.INPROGRESS}
        MenuProps={{
          classes: { list: classes.list },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      />

      <Box className={classes.snapshotName}>
        <Typography className={classes.label} variant="h4">
          Snapshot name
        </Typography>
        <Input
          placeholder="Enter snapshot name"
          fullWidth
          value={snapshotName}
          onChange={handleSnapshotNameChange}
          disabled={
            isFieldsDisabled ||
            importStatus === CONSTANTS.IMPORT_STATUS.INPROGRESS
          }
          ariaLabel="Snapshot name"
        />
      </Box>
      <Box className={classes.periodSelector}>
        <Box>
          <Typography className={classes.label} variant="h4">
            End date
          </Typography>
          <CalendarInput
            format="dd/MM/yyyy"
            inputVariant="outlined"
            className={classes.calendar}
            iconLabel="End date"
            value={endDate}
            maxDate={new Date()}
            onChange={handleEndDateChange}
            disabled={
              isFieldsDisabled ||
              importStatus === CONSTANTS.IMPORT_STATUS.INPROGRESS
            }
            shouldDisableDate={disableWeekdays}
          />
        </Box>
        <Box>
          <Typography className={classes.label} variant="h4">
            Snapshot length
          </Typography>
          <DropDown
            label="Snapshot length"
            className={classes.select}
            menuItems={SNAPSHOT_LENGTH_MENU_ITEMS}
            value={snapshotLengthValue}
            onChange={handleSnapshotLengthChange}
            disabled={
              isFieldsDisabled ||
              importStatus === CONSTANTS.IMPORT_STATUS.INPROGRESS
            }
          />
        </Box>
      </Box>
    </Box>
  )

  const Footer = (
    <Box className={classes.footer}>
      <Button
        onClick={handleModalClose}
        className={classes.modalActionButton}
        variant="outlined"
        label="Cancel"
        disabled={importStatus === CONSTANTS.IMPORT_STATUS.INPROGRESS}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        disabled={
          importStatus === CONSTANTS.IMPORT_STATUS.INPROGRESS ||
          (snapshotName === '' && selectedSnapshot === '') ||
          endDateError
        }
        onClick={() =>
          handleOkButtonClick(
            snapshotName,
            endDate,
            snapshotLengthValue,
            getSelectedSnapshot(selectedSnapshot)
          )
        }
        className={classes.modalActionButton}
        label="Ok"
      >
        {importStatus === CONSTANTS.IMPORT_STATUS.INPROGRESS && (
          <CircularProgress
            color="inherit"
            size={16}
            className={classes.progress}
          />
        )}
        Ok
      </Button>
    </Box>
  )

  return (
    <Modal
      disableBackdropClick
      aria-labelledby="Select snapshot"
      open={isModalOpen}
      handleClose={handleModalClose}
      header="Select snapshot"
      body={Body}
      footer={Footer}
      isHeaderCloseButtonDisabled={
        importStatus === CONSTANTS.IMPORT_STATUS.INPROGRESS
      }
    />
  )
}
