import { useMemo } from 'react'

import { Graph } from 'Common/Graph/Graph'
import { CycleSpeedGraphProps } from './CycleSpeed.types'

export const CycleSpeedGraph = ({
  xAxisTitle,
  xAxisData,
  yAxisData,
  xAxisHoverLabel,
}: CycleSpeedGraphProps) => {
  const colors = {
    barColor: '#00A3E0',
    lineColor: '#E7E7E7',
    fontColor: '#000',
    hoverBorderColor: '#000',
    hoverBackgroundColor: '#FFF',
  }

  const data = useMemo(() => {
    const hovertemplate =
      `   <b>${xAxisHoverLabel}:  </b>%{x}<br>` +
      '   <b>Cycle:  </b>%{y}<br><br>' +
      '<extra></extra>'

    return [
      {
        type: 'bar',
        x: xAxisData,
        y: [1, 2, 3, 4, 5],
        orientation: 'h',
        width: 0.5,
        hovertemplate: hovertemplate,
        marker: {
          width: 0,
          color: colors.barColor,
        },
      },
    ]
  }, [xAxisData])

  const layout = useMemo(
    () => ({
      height: 275,
      hoverlabel: {
        bordercolor: colors.hoverBorderColor,
        bgcolor: colors.hoverBackgroundColor,
        font: { color: colors.fontColor, family: 'proxima-nova' },
        style: {
          padding: '20px',
        },
      },
      yaxis: {
        ticktext: yAxisData,
        tickvals: [1, 2, 3, 4, 5],
        tickmode: 'array',
        linewidth: 1,
        linecolor: colors.lineColor,
        showline: true,
        mirror: 'ticks',
        tickfont: {
          family: 'proxima-nova',
          size: 16,
          color: colors.fontColor,
        },
      },
      xaxis: {
        zeroline: true,
        showline: true,
        showgrid: true,
        linewidth: 1,
        mirror: 'ticks',
        linecolor: colors.lineColor,
        title: {
          text: `<b>${xAxisTitle ? xAxisTitle : ''}</b>`,
          font: {
            family: 'proxima-nova',
            size: 16,
            color: colors.fontColor,
          },
        },
        tickfont: {
          family: 'proxima-nova',
          size: 16,
          color: colors.fontColor,
        },
      },
    }),
    [yAxisData, xAxisTitle]
  )

  return <Graph data={data} layout={layout} />
}
