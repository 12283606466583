import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography, CircularProgress } from '@material-ui/core'
import { toast } from 'react-hot-toast'
import { Button } from 'supplyvue-ui'
import {
  ProductSettingsFormProps,
  ProductSettingsParametersType,
  PlanTemplateEditInputsParams,
} from './ProductSettingsForm.types'
import { useStyles } from './ProductSettingsForm.styles'
import { useCycleValidation } from 'Common/hooks/useCycleValidation.hook'
import { useOrderValidation } from 'Common/hooks/useOrderValidation.hook'
import { InputField } from 'Features/PlanTemplate/ProductSettings/InputField/InputField.view'
import { DropdownField } from 'Features/PlanTemplate/ProductSettings/DropdownField/DropdownField.view'
import { ProductSettingsParameter } from 'Features/PlanTemplate/ProductSettings/ProductSettingsParameter/ProductSettingsParameter.view'
import { ProductInformationItem } from 'Features/PlanTemplate/ProductSettings/ProductInformationItem/ProductInformationItem.view'
import {
  SALES_PER_MOQ_INITIAL_VALUE,
  INITIAL_STATE,
} from './ProductSettingsForm.constants'
import {
  getSettingsData,
  getUpdatedProductSettingsForValidation,
  mandatoryErrorStatusInitialState,
} from './ProductSettingsForm.utils'
import { useProductSettingsValidation } from './ProductSettingsForm.data'
import { FOC, FOQ } from 'Common/Constants/Constants'

export const ProductSettingsForm = (props: ProductSettingsFormProps) => {
  const classes = useStyles()
  const {
    selectedRows,
    handleSaveClick,
    replenishmentTypes,
    minimumCycleTypes,
    maximumCycleTypes,
    selectedProductBatchMinCycle,
    batchGroupingCodeOptions,
  } = props

  const { snapshotId, projectId, planTemplateId, sourceSiteId, workcentreId } =
    useParams<PlanTemplateEditInputsParams>()

  const [batchMinCycleError, setBatchMinCycleError] = useState('')
  const isMultipleRowsSelected = selectedRows.length > 1 ? true : false
  const [data, setData] = useState<ProductSettingsParametersType>(
    isMultipleRowsSelected ? INITIAL_STATE : getSettingsData(selectedRows[0])
  )

  const mandatoryFieldErrorStatus = useRef(mandatoryErrorStatusInitialState)
  const [isMandatoryError, setIsMandatoryError] = useState(false)

  const [salesRatePerMOQ, setSalesRatePerMOQ] = useState<string>(
    SALES_PER_MOQ_INITIAL_VALUE
  )

  const onSuccess = () => {
    handleSaveClick(data)
  }

  const onError = (message: string) => {
    toast.error(message)
  }

  const { isLoading: isValidating, mutate: validateProductSetttings } =
    useProductSettingsValidation({ onSuccess, onError })

  const {
    minCycleError,
    maxCycleError,
    replenishmentTypeError,
    setMaximumCycle,
    setMinimumCycle,
    setSalesweeksPerMinimumQuantity,
    setReplenishmentTypename,
  } = useCycleValidation(
    data.minimumCycle,
    data.maximumCycle,
    data.salesWeeksPerMOQ,
    data.replenishmentType,
    'sales weeks per MOQ'
  )

  const {
    minQuantityError,
    minIncrementError,
    setMinimumIncrement,
    setMinimumQuantity,
  } = useOrderValidation(
    data.minimumOrderQuantity,
    data.minimumOrderIncrement
    //'MOQ',
    //'MOI'
  )

  const getMandatoryErrorStatus = (error: string, name: string) => {
    mandatoryFieldErrorStatus.current = {
      ...mandatoryFieldErrorStatus.current,
      [name]: error,
    }
    setIsMandatoryError(isMandatoryFieldErrorPresent())
  }

  const isMandatoryFieldErrorPresent = () => {
    let errorStatus = false
    for (const name in mandatoryFieldErrorStatus.current) {
      if (
        mandatoryFieldErrorStatus.current[
          name as keyof typeof mandatoryFieldErrorStatus.current
        ] !== ''
      ) {
        errorStatus = true
        break
      }
    }
    return errorStatus
  }

  useEffect(() => {
    setMinimumCycle(data.minimumCycle)
    if (
      data.replenishmentType === FOC &&
      selectedProductBatchMinCycle &&
      data.minimumCycle &&
      selectedProductBatchMinCycle > parseInt(data.minimumCycle as string)
    ) {
      setBatchMinCycleError(
        'product minimum cycle should be greater than or equal to batch minimum cycle'
      )
    } else {
      setBatchMinCycleError('')
    }
  }, [data.minimumCycle, data.replenishmentTypeId])

  useEffect(() => {
    setMaximumCycle(data.maximumCycle)
  }, [data.maximumCycle])

  useEffect(() => {
    setMinimumIncrement(data.minimumOrderIncrement)
  }, [data.minimumOrderIncrement])

  useEffect(() => {
    setMinimumQuantity(data.minimumOrderQuantity)
  }, [data.minimumOrderQuantity])

  useEffect(() => {
    setReplenishmentTypename(data.replenishmentType)
  }, [data.replenishmentType])

  useEffect(() => {
    if (
      !isMultipleRowsSelected &&
      data.salesRate !== '' &&
      data.salesRate !== '0' &&
      parseFloat(data.salesRate as string) !== 0 &&
      data.salesRate !== null
    ) {
      const minimumOrderQuantity = data.minimumOrderQuantity as number
      const salesRate = data.salesRate as number
      const value = (minimumOrderQuantity / salesRate).toFixed(2).toString()
      setSalesRatePerMOQ(value)
      setSalesweeksPerMinimumQuantity(value)
    } else {
      setSalesRatePerMOQ('-')
      setSalesweeksPerMinimumQuantity('-')
    }
  }, [data.minimumOrderQuantity, data.salesRate])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value
    if (name !== 'batchGroupingCode') {
      const VALID_NUMBER_PATTERN =
        /^[+]?((\.\d{0,2})|(\d+(\.\d{0,2})?)|(\d+\.))$/
      if (event.target.value === '' || value.match(VALID_NUMBER_PATTERN)) {
        setData({
          ...data,
          [name]: value,
        })
      }
    } else {
      setData({
        ...data,
        [name]: value,
      })
    }
  }

  const isValidInputs = () => {
    return isMultipleRowsSelected
      ? true
      : data.minimumOrderIncrement &&
          data.minimumOrderQuantity &&
          data.salesRate &&
          data.runRate &&
          data.batchGroupingCode &&
          data.replenishmentTypeId &&
          data.minimumCycle &&
          data.maximumCycle
  }

  const handleSaveButtonClick = async () => {
    if (isMultipleRowsSelected) {
      validateProductSetttings({
        snapshotId,
        projectId,
        planTemplateId,
        sourceSiteKey: parseInt(sourceSiteId),
        workcentreKey: parseInt(workcentreId),
        productSettings: getUpdatedProductSettingsForValidation(
          data,
          selectedRows
        ),
      })
    } else {
      !minQuantityError &&
        !minIncrementError &&
        !minCycleError &&
        !maxCycleError &&
        !replenishmentTypeError &&
        !batchMinCycleError &&
        !isMandatoryError &&
        isValidInputs() &&
        handleSaveClick(data)
    }
  }

  const handleDropdownChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof data
    if (name === 'replenishmentTypeId') {
      const replenishmentType = replenishmentTypes.filter(
        (type) => type.value === event.target.value
      )
      setData({
        ...data,
        replenishmentType:
          replenishmentType.length > 0 ? replenishmentType[0].key : '',
        [name]: event.target.value as string,
      })
    } else {
      setData({
        ...data,
        [name]: event.target.value as string,
      })
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box
        className={classes.settingsHeader}
        justifyContent={isMultipleRowsSelected ? 'flex-start' : 'space-between'}
      >
        <ProductInformationItem
          label={isMultipleRowsSelected ? 'SKU group codes' : 'SKU group code'}
          value={
            isMultipleRowsSelected
              ? selectedRows.map((row) => row.productCode).join(', ')
              : selectedRows[0].productCode
              ? selectedRows[0].productCode.toString()
              : '-'
          }
        />
        {!isMultipleRowsSelected && (
          <>
            <ProductInformationItem
              label="Description"
              value={selectedRows[0].description ?? '-'}
            />
            <ProductInformationItem
              label="SKU group format"
              value={selectedRows[0].productFormat ?? '-'}
            />
          </>
        )}
      </Box>

      <Box className={`${classes.evenRow} ${classes.row}`}>
        <ProductSettingsParameter label="Batch group code">
          <DropdownField
            getErrorStatus={getMandatoryErrorStatus}
            // hasError={
            //   !!replenishmentTypeError ||
            //   (!isMultipleRowsSelected && data.replenishmentTypeId === 0)
            // }
            isMandatoryField={!isMultipleRowsSelected}
            label="Batch group code"
            name="batchGroupingCode"
            handleChange={handleDropdownChange}
            value={!data.batchGroupingCode ? '' : data.batchGroupingCode}
            defaultLabel={''}
            menuItems={batchGroupingCodeOptions}
          />
        </ProductSettingsParameter>
        <ProductSettingsParameter label="Replenishment type">
          <DropdownField
            getErrorStatus={getMandatoryErrorStatus}
            errorMessage={replenishmentTypeError}
            hasError={
              !!replenishmentTypeError ||
              (!isMultipleRowsSelected && data.replenishmentTypeId === 0)
            }
            isMandatoryField={!isMultipleRowsSelected}
            label="Replenishment type"
            name="replenishmentTypeId"
            handleChange={handleDropdownChange}
            value={!data.replenishmentTypeId ? '' : data.replenishmentTypeId}
            defaultLabel={
              isMultipleRowsSelected || !data.replenishmentTypeId ? 'None' : ''
            }
            menuItems={replenishmentTypes}
          />
        </ProductSettingsParameter>
        <Box width="33.33%"></Box>
      </Box>

      <Box className={classes.row}>
        <ProductSettingsParameter label="Sales rate">
          <InputField
            isMandatoryField={!isMultipleRowsSelected}
            label="Sales rate"
            name="salesRate"
            handleChange={handleInputChange}
            value={data.salesRate ?? ''}
            isNumber={true}
            getErrorStatus={getMandatoryErrorStatus}
          />
        </ProductSettingsParameter>
        <ProductSettingsParameter label="Run rate">
          <InputField
            isMandatoryField={!isMultipleRowsSelected}
            label="Run rate"
            name="runRate"
            handleChange={handleInputChange}
            value={data.runRate ?? ''}
            isNumber={true}
            getErrorStatus={getMandatoryErrorStatus}
          />
        </ProductSettingsParameter>

        <ProductSettingsParameter label="Unit of measure">
          <Typography className={classes.value}>
            {isMultipleRowsSelected
              ? selectedRows[0].runRateUnitOfMeasure
              : data.runRateUnitOfMeasure}
          </Typography>
        </ProductSettingsParameter>
      </Box>

      <Box className={`${classes.evenRow} ${classes.row}`}>
        <ProductSettingsParameter label="Minimum order quantity">
          <InputField
            isMandatoryField={!isMultipleRowsSelected}
            label="Minimum order quantity"
            name="minimumOrderQuantity"
            handleChange={handleInputChange}
            value={data.minimumOrderQuantity ?? ''}
            errorMessage={minQuantityError}
            getErrorStatus={getMandatoryErrorStatus}
          />
        </ProductSettingsParameter>
        <ProductSettingsParameter label="Minimum order increment">
          <InputField
            isMandatoryField={!isMultipleRowsSelected}
            label="Minimum order increment"
            name="minimumOrderIncrement"
            handleChange={handleInputChange}
            value={data.minimumOrderIncrement ?? ''}
            errorMessage={minIncrementError}
            getErrorStatus={getMandatoryErrorStatus}
          />
        </ProductSettingsParameter>
        <ProductSettingsParameter label="Unit of measure">
          <Typography className={classes.value}>
            {isMultipleRowsSelected
              ? selectedRows[0].productionUnitOfMeasure
              : data.productionUnitOfMeasure}
          </Typography>
        </ProductSettingsParameter>
      </Box>
      <Box className={classes.row}>
        <ProductSettingsParameter label="Sales weeks per MOQ">
          <Typography className={classes.value}>
            {salesRatePerMOQ === 'NaN' ? 0 : salesRatePerMOQ}
          </Typography>
        </ProductSettingsParameter>
        <ProductSettingsParameter label="Minimum cycle">
          {data.replenishmentType === FOQ ? (
            <InputField
              isMandatoryField={!isMultipleRowsSelected}
              label="Minimum cycle"
              name="minimumCycle"
              handleChange={handleInputChange}
              value={data.minimumCycle ?? ''}
              errorMessage={minCycleError}
              getErrorStatus={getMandatoryErrorStatus}
            />
          ) : (
            <DropdownField
              getErrorStatus={getMandatoryErrorStatus}
              errorMessage={minCycleError || batchMinCycleError}
              hasError={
                !!minCycleError ||
                !!batchMinCycleError ||
                (!isMultipleRowsSelected && data.minimumCycle === null)
              }
              isMandatoryField={!isMultipleRowsSelected}
              label="Minimum cycle"
              name="minimumCycle"
              handleChange={handleDropdownChange}
              value={
                minimumCycleTypes.find(
                  (option) => option.value === data.minimumCycle
                )?.value ?? ''
              }
              defaultLabel={
                isMultipleRowsSelected || data.minimumCycle === null
                  ? 'None'
                  : ''
              }
              menuItems={minimumCycleTypes}
            />
          )}
        </ProductSettingsParameter>
        <ProductSettingsParameter label="Maximum cycle">
          {data.replenishmentType === FOQ ? (
            <InputField
              isMandatoryField={!isMultipleRowsSelected}
              label="Maximum cycle"
              name="maximumCycle"
              handleChange={handleInputChange}
              value={data.maximumCycle ?? ''}
              errorMessage={maxCycleError}
              getErrorStatus={getMandatoryErrorStatus}
            />
          ) : (
            <DropdownField
              getErrorStatus={getMandatoryErrorStatus}
              errorMessage={maxCycleError}
              hasError={
                !!maxCycleError ||
                (!isMultipleRowsSelected && data.maximumCycle === null)
              }
              isMandatoryField={!isMultipleRowsSelected}
              label="Maximum cycle"
              name="maximumCycle"
              handleChange={handleDropdownChange}
              value={
                maximumCycleTypes.find(
                  (option) => option.value === data.maximumCycle
                )?.value ?? ''
              }
              defaultLabel={
                isMultipleRowsSelected || data.maximumCycle === null
                  ? 'None'
                  : ''
              }
              menuItems={maximumCycleTypes}
            />
          )}
        </ProductSettingsParameter>
      </Box>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={handleSaveButtonClick}
        disabled={isValidating}
      >
        {isValidating && (
          <CircularProgress
            color="inherit"
            size={16}
            className={classes.progress}
          />
        )}
        Apply changes
      </Button>
    </Box>
  )
}
