import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  paper: {
    flex: 1,
  },
  wrapper: {
    width: '100%',
    padding: '16px 24px',
  },
  title: {
    marginBottom: '24px',
  },
  fullHeight: {
    height: '100%',
  },
  dropdownsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  label: {
    marginRight: '8px',
  },
}))
