import { Box, Paper } from '@material-ui/core'
import { Page } from 'Common/Page/Page'
import { useStyles } from './SegmentAnalysis.styles'
import { DifferenceGraph } from './SegmentAnalysis.difference.graph'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { InventoryAnalysisPageParams } from 'Features/InventoryAnalysis/InventoryAnalysis.types'
import { useParams, useLocation } from 'react-router-dom'
import { useSegmentGraphData } from './SegmentAnalysis.data'
import { mapReplenishmentType } from 'Features/InventoryAnalysis/InventoryAnaylsis.utils'
import { useEffect, useState } from 'react'
import { useDropdownOptionsData } from 'Features/InventoryAnalysis/InventoryAnalysis.data'
import { Loader } from 'Common/Loader/Loader'
import { useSourceSitesData } from 'Features/ReplenishmentPolicy/ReplenishmentPolicy.data'
import { SegmentAnalysisFiltersView } from './SegmentAnalysisFilter.View'
import { PageHeader } from 'supplyvue-ui'

export const SegmentAnalysisPage = () => {
  const classes = useStyles()
  const {
    projectId,
    replenishmentPolicyId,
    sourceSiteId,
    siteKey,
    scenarioId,
  } = useParams<InventoryAnalysisPageParams>()

  const { search } = useLocation()
  const query = new URLSearchParams(search)

  useBreadcrumbsData({
    project_id: projectId,
    replenishment_policy_id: replenishmentPolicyId,
    base_source_site_id: sourceSiteId,
    site_id: siteKey,
  })

  const snapshotId = query.get('snapshotId')
  const method = query.get('method') ?? 'forecast_error'

  const [replenishmentTypeFilter, setReplenishmentTypeFilter] = useState('')
  const [cycleTimeFilter, setCycleTimeFilter] = useState<'' | number>('')
  const [workcentre, setWorkcentre] = useState<number | ''>('')
  const [workcentrerOptions, setWorkcentreOptions] = useState()

  const { data: sourceSiteData } = useSourceSitesData(projectId, scenarioId)

  const { data: graphData, isLoading: graphDataLoading } = useSegmentGraphData(
    replenishmentPolicyId,
    sourceSiteId,
    replenishmentTypeFilter,
    cycleTimeFilter,
    workcentre ?? '',
    Number(siteKey),
    method
  )

  useEffect(() => {
    if (sourceSiteData) {
      const currentSourceSite = sourceSiteData?.baseSourceSites.filter(
        (site: { value: number }) => site.value.toString() === sourceSiteId
      )
      const options = currentSourceSite[0]?.workcentres?.map(
        (workcentre: { code: string; key: number }) => {
          return {
            key: workcentre.code,
            value: workcentre.key,
          }
        }
      )
      setWorkcentreOptions(options)
    }
  }, [sourceSiteData, sourceSiteId])
  const {
    isLoading: isDropdownOptionsLoading,
    data: dropdownOptions,
  } = useDropdownOptionsData(snapshotId ?? sourceSiteData?.snapshotId)

  if (isDropdownOptionsLoading || graphDataLoading) {
    return <Loader />
  }
  return (
    <Page>
      <Box className={classes.wrapper}>
        <PageHeader
          title="Segment analysis"
          rightElement={
            isDropdownOptionsLoading ? null : (
              <>
                <SegmentAnalysisFiltersView
                  replenishmentTypeMenuItems={mapReplenishmentType(
                    dropdownOptions?.replenishmentType
                  )}
                  replenishmentType={replenishmentTypeFilter}
                  cycleTime={cycleTimeFilter}
                  handleReplenishmentTypeChange={(e) =>
                    setReplenishmentTypeFilter(e.target.value as string)
                  }
                  handleCycleTimeChange={(e) =>
                    setCycleTimeFilter(e.target.value as number)
                  }
                  handleWorkcentreChange={(e) =>
                    setWorkcentre(e.target.value as number)
                  }
                  workcenter={workcentre ?? ''}
                  workcentreOptions={workcentrerOptions ?? []}
                />
              </>
            )
          }
        />
        <Box>
          <Paper>
            <Box width="100%" padding={2} height={890}>
              <Box>
                <DifferenceGraph
                  yAxisLabel="Forecast error stock volume"
                  traceData={graphData?.differenceGraph ?? []}
                />
              </Box>
              <Box>
                <DifferenceGraph
                  yAxisLabel="Current BAU stock volume"
                  traceData={graphData?.currentBAUGraph ?? []}
                  showLegend={false}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Page>
  )
}
