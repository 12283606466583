import { FlowElement } from 'react-flow-renderer'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory'
import { SiteConnections, ApprovalStatus } from './ReplenishmentPolicy.types'
import { Box } from '@material-ui/core'
import { stockDurationText } from './ReplenishmentPolicy.styles'

export const nodePosition = { x: 0, y: 0 }

export const nodeStyle = {
  cursor: 'pointer',
}

export const approvedNodeStyle = {
  ...nodeStyle,
  border: '1px dashed #729f41',
}

export const invalidNodeStyle = {
  ...nodeStyle,
  border: '2px solid #ff0000',
}

const processingNodeStyle = {
  ...nodeStyle,
  border: '1px dashed #FFBF00',
}

const getNodeStyle = (approvalStatus: ApprovalStatus) => {
  const nodeStyles = {
    pending: nodeStyle,
    processing: processingNodeStyle,
    valid: nodeStyle,
    invalid: invalidNodeStyle,
    approved: approvedNodeStyle,
    failed: nodeStyle,
  }
  return nodeStyles[approvalStatus.toLowerCase() as Lowercase<ApprovalStatus>]
}

export const edgeType = 'smoothstep'

export const edgeStyle = {
  strokeWidth: 2,
}

/** Data Transformation helpers */

export const getGraphData = (
  siteConnections: SiteConnections,
  selectedSourceSiteId: string
): FlowElement<any>[] => {
  const nodes = siteConnections.nodes.map((node) => ({
    id: node.id.toString(),
    data: {
      label: (
        <>
          <Box
            display="flex"
            alignItems="center"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {!!node.isEdited && (
              <ChangeHistoryIcon
                style={{ fontSize: '10px', marginRight: '2px' }}
              />
            )}
            <small>{node.label}</small>
          </Box>
          {!!node.stockDuration && (
            <small style={stockDurationText}>
              Stock duration - {node.stockDuration} days
            </small>
          )}
        </>
      ),
      approvalStatus: node.approvalStatus,
      name: node.label,
    },
    position: nodePosition,
    style: getNodeStyle(node.approvalStatus),
    draggable: node.id !== parseInt(selectedSourceSiteId),
  }))

  const edges = siteConnections.connections.map((connection) => ({
    id: connection.id.toString(),
    source: connection.source,
    target: connection.target,
    type: edgeType,
    style: edgeStyle,
    data: {
      nodes: siteConnections.nodes,
    },
  }))

  return [...nodes, ...edges]
}
