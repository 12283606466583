import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
  },
  row: {
    display: 'flex',
    marginBottom: '24px',
  },
  progress: {
    marginRight: '8px',
  },
}))
