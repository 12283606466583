import { Paper, Box, Typography } from '@material-ui/core'
import { DropDown } from 'supplyvue-ui'
import { useStyles } from './AnalysisGraph.styles'
import { SegmentGraph } from './Segment.graph'
import { INVENTORY_MEASURE, STOCK_TYPE } from './AnalysisGraph.constants'
import { AnalysisGraphViewProps } from './AnalysisGraph.types'
import { AnalysisMeasure, StockType } from '../InventoryAnalysis.types'

// This is left for reference.
// const gData = [
//   {
//     currentInventory: 0,
//     proposedInventory: 0,
//     segment: '1_Eratic',
//   },
//   {
//     currentInventory: 47024,
//     proposedInventory: 24169,
//     segment: '1_Eratic and in.',
//   },
//   {
//     currentInventory: 87024,
//     proposedInventory: 24169,
//     segment: '1_intermittent',
//   },
//   {
//     currentInventory: 17024,
//     proposedInventory: 34169,
//     segment: '1_Smooth',
//   },
//   {
//     currentInventory: 27024,
//     proposedInventory: 44169,
//     segment: '2_Eratic',
//   },
//   {
//     currentInventory: 67024,
//     proposedInventory: 64169,
//     segment: '2_Eratic and in.',
//   },
//   {
//     currentInventory: 97024,
//     proposedInventory: 14169,
//     segment: '2_intermittent',
//   },
//   {
//     currentInventory: 57024,
//     proposedInventory: 54169,
//     segment: '2_Smooth',
//   },
//   {
//     currentInventory: 17024,
//     proposedInventory: 14169,
//     segment: '3_Eratic',
//   },
//   {
//     currentInventory: 27024,
//     proposedInventory: 84169,
//     segment: '3_Eratic and in',
//   },
//   {
//     currentInventory: 67024,
//     proposedInventory: 24169,
//     segment: '3_intermittent',
//   },
//   {
//     currentInventory: 77024,
//     proposedInventory: 34169,
//     segment: '3_Smooth',
//   },
//   {
//     currentInventory: 37024,
//     proposedInventory: 74169,
//     segment: 'Other',
//   },
// ]
export const AnalysisGraphView = ({
  graphData,
  analysisMeasure,
  stockType,
  handleAnalysisMeasureChange,
  handleStockTypeChange,
}: AnalysisGraphViewProps) => {
  const classes = useStyles()

  const xAxisData = graphData?.map((item) => {
    return item.segment
  })

  const proposedInventoryData = graphData?.map((item) => {
    return item.proposedInventory
  })

  const currentInventoryData = graphData?.map((item) => {
    return item.currentInventory
  })

  const selectedMeasure = INVENTORY_MEASURE.find(
    (obj) => obj.value === analysisMeasure
  )

  return (
    <Paper className={classes.paper}>
      <Box className={classes.wrapper}>
        <Box className={classes.dropdownsWrapper}>
          <Box component="span">
            <Typography variant="h4" component="span" className={classes.label}>
              Inventory measure
            </Typography>
            <DropDown
              label="Inventory measure"
              menuItems={INVENTORY_MEASURE}
              value={analysisMeasure}
              onChange={(e) =>
                handleAnalysisMeasureChange(e.target.value as AnalysisMeasure)
              }
            />
          </Box>
          <Box component="span" ml={4}>
            <Typography variant="h4" component="span" className={classes.label}>
              Stock type
            </Typography>
            <DropDown
              label="Stock type"
              menuItems={STOCK_TYPE}
              value={stockType}
              onChange={(e) =>
                handleStockTypeChange(e.target.value as StockType)
              }
            />
          </Box>
        </Box>
        <SegmentGraph
          xAxisData={xAxisData}
          proposedInventory={proposedInventoryData}
          currentInventory={currentInventoryData}
          yAxisLabel={selectedMeasure?.key || ''}
        />
      </Box>
    </Paper>
  )
}
