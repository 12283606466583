import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  costOfCapital: {
    paddingTop: '5px',
  },
  textBox: {
    width: '40px',
    backgroundColor: theme.palette.background.paper,
  },
}))
