import { Table, EditableCell } from 'supplyvue-ui'
import { Loader } from 'Common/Loader/Loader'
import { Column, TableInstance } from 'react-table'
import {
  ReplenishmentPolicyInputsProps,
  ReplenishmentPolicyInputsColumns,
} from './ReplenishmentPolicyInputs.types'

export const ReplenishmentPolicyInputsTable = ({
  data,
  isLoading,
  updateTableData,
  updateEditedCellValue,
  loadingStatus,
}: ReplenishmentPolicyInputsProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 300
    const availableHeight = window.innerHeight - 480
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  const handleValidation = (value: string | number) => {
    const VALID_NUMBER_PATTERN = /^[+]?((\.\d{0,1})|(\d+(\.\d{0,1})?)|(\d+\.))$/
    const isValid = value.toString().match(VALID_NUMBER_PATTERN)
    return isValid ? '' : 'error'
  }

  const updateData = (
    rowId: string,
    columnId: string,
    value: string | number
  ) => {
    let siteKey = null
    const updatedData = data.map((row, i) => {
      if (i === parseInt(rowId)) {
        siteKey = row.siteKey
        return {
          ...row,
          [columnId]: parseFloat(value as string),
        }
      } else return row
    })
    updateTableData(updatedData)
    updateEditedCellValue(siteKey, columnId, parseFloat(value as string))
  }

  const getEditableCellProps = (props: TableInstance) => {
    const { siteKey, columnId, isLoading } = props.tableCellLoadingStatus

    const isCellDataLoading =
      isLoading &&
      columnId === props.column.id &&
      siteKey === props.row.original.siteKey

    const isDisabled =
      isLoading &&
      !(columnId === props.column.id && siteKey === props.row.original.siteKey)

    return [isCellDataLoading, isDisabled]
  }

  const columns: Column<ReplenishmentPolicyInputsColumns>[] = [
    {
      Header: 'Site',
      accessor: 'siteLabel',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Capacity (Pallets)',
      accessor: 'capacity',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: (props: TableInstance) => {
        const [isLoading, isDisabled] = getEditableCellProps(props)
        return (
          <>
            <EditableCell
              isEditDisabled={isDisabled}
              isLoading={isLoading}
              value={props.value ? props.value : 0}
              handleValidation={props.handleValidation}
              updateData={(value) =>
                props.updateData(props.row.id, props.column.id, value)
              }
            />
          </>
        )
      },
    },
    {
      Header: 'Holding cost per pallet per week',
      accessor: 'holdingCostPerPalletPerWeek',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: (props: TableInstance) => {
        const [isLoading, isDisabled] = getEditableCellProps(props)
        return (
          <>
            <EditableCell
              isEditDisabled={isDisabled}
              isLoading={isLoading}
              value={props.value ?? 0}
              handleValidation={props.handleValidation}
              updateData={(value) =>
                props.updateData(props.row.id, props.column.id, value)
              }
            />
          </>
        )
      },
    },
  ]
  if (isLoading) {
    return <Loader />
  }
  return (
    <Table
      data={data}
      height={getTableHeight()}
      columns={columns}
      stickyHeader={true}
      updateData={updateData}
      handleValidation={handleValidation}
      tableCellLoadingStatus={loadingStatus}
    />
  )
}
