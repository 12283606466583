import { useState } from 'react'
import { Modal, Dialog, Button } from 'supplyvue-ui'
import { Box, CircularProgress } from '@material-ui/core'
import { UpdatePlanTemplateModalProps } from './UpdatePlanTemplateSettingsModal.types'
import { RESET_PLANTEMPLATE_SETTINGS } from './PlanTemplateSettings.constants'
import { PlanTemplateSettingsView } from './PlanTemplateSettings.view'
import { usePlanTemplateSettingsState } from './UpdatePlanTemplateSettingsModal.state'
import { havePlanTemplateSettingsChanged } from './UpdatePlanTemplateSettingsModal.util'
import { toast } from 'react-hot-toast'
import { useUpdatePlanTemplateSettingsData } from './UpdatePlanTemplateSettingsModal.data'
import { useStyles } from './UpdatePlanTemplateSettingsModal.styles'

export const UpdatePlanTemplateModal = ({
  isModalOpen,
  heading,
  handleModalClose,
  planTemplateSettings,
  projectId,
  planTemplateId,
  primaryButtonLabel,
  secondaryButtonLabel,
  salesRateTypeLabel,
  salesRatePeriodLabel,
  minBatchesPerCycleLabel,
  planTemplateBasedOnLabel,
}: UpdatePlanTemplateModalProps) => {
  const classes = useStyles()

  const [
    planTemplateSettingsState,
    updatePlanTemplateSettingsState,
  ] = usePlanTemplateSettingsState(planTemplateSettings)

  const [showDialog, setShowDialog] = useState(false)

  const onUpdatePlanTemplateSettingsSuccess = (message: string) => {
    handleModalClose()
    toast.success(message)
  }

  const onUpdatePlanTemplateSettingsError = (message: string) => {
    toast.error(message)
  }

  const {
    isLoading,
    mutate: updatePlanTemplateSettings,
  } = useUpdatePlanTemplateSettingsData({
    onSuccess: onUpdatePlanTemplateSettingsSuccess,
    onError: onUpdatePlanTemplateSettingsError,
  })

  const handlePlanTemplateSettingsSave = () => {
    updatePlanTemplateSettings({
      projectId,
      planTemplateId,
      settings: {
        ...planTemplateSettingsState,
      },
    })
  }

  const handlePlanTemplateSettingsClose = () => {
    if (
      havePlanTemplateSettingsChanged(
        planTemplateSettings,
        planTemplateSettingsState
      )
    ) {
      setShowDialog(true)
    } else {
      handleModalClose()
    }
  }

  const handleDiscardChanges = () => {
    updatePlanTemplateSettingsState({
      type: RESET_PLANTEMPLATE_SETTINGS,
      payload: planTemplateSettings,
    })
    setShowDialog(false)
    handleModalClose()
    toast('Changes have been discarded')
  }

  const handleKeepChanges = () => {
    setShowDialog(false)
  }

  const Body = (
    <>
      <Box className={classes.contentWrapper}>
        <PlanTemplateSettingsView
          salesRateTypeLabel={salesRateTypeLabel}
          salesRatePeriodLabel={salesRatePeriodLabel}
          minBatchesPerCycleLabel={minBatchesPerCycleLabel}
          planTemplateBasedOnLabel={planTemplateBasedOnLabel}
          salesRateType={planTemplateSettingsState.salesRateType}
          salesRatePeriod={planTemplateSettingsState.salesRatePeriod}
          minimumAverageBatchesPerProductionCycle={
            planTemplateSettingsState.minimumAverageBatchesPerProductionCycle
          }
          updatePlanTemplateSettingsState={updatePlanTemplateSettingsState}
          templateBasedOn={planTemplateSettingsState.templateBasedOn}
        />
      </Box>
    </>
  )

  const Footer = (
    <Box className={classes.footer}>
      <Button
        onClick={handlePlanTemplateSettingsClose}
        className={classes.modalActionButton}
        label={secondaryButtonLabel}
        disabled={isLoading}
        variant="outlined"
      >
        {secondaryButtonLabel}
      </Button>

      <Button
        onClick={() => handlePlanTemplateSettingsSave()}
        className={classes.modalActionButton}
        label={primaryButtonLabel}
        disabled={isLoading}
        variant="contained"
      >
        {isLoading && (
          <CircularProgress
            color="inherit"
            size={16}
            className={classes.progress}
          />
        )}
        {primaryButtonLabel}
      </Button>
    </Box>
  )
  return (
    <>
      <Modal
        aria-labelledby={heading}
        header={heading}
        open={isModalOpen}
        handleClose={handlePlanTemplateSettingsClose}
        body={Body}
        footer={Footer}
      ></Modal>
      <Dialog
        title="Unsaved changes will be lost"
        content="Are you sure you want to discard the changes?"
        primaryButtonLabel="Discard"
        secondaryButtonLabel="Cancel"
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        handlePrimaryButtonClick={handleDiscardChanges}
        handleSecondaryButtonClick={handleKeepChanges}
      />
    </>
  )
}
