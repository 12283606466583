import { makeStyles, Theme } from '@material-ui/core'
import { InstructionsCardStyleProps } from './InstructionsCard.types'

export const useStyles = makeStyles<Theme, InstructionsCardStyleProps>(
  (theme) => ({
    wrapper: (props) => {
      return props?.isChangeoverDataLoaded
        ? {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }
        : {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.grey,
            padding: '16px',
            justifyContent: 'flex-end',
          }
    },
    instructionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.grey,
      padding: '16px',
      minWidth: '500px',
      justifyContent: 'flex-end',
    },
    instruction: {
      display: 'flex',
      margin: '4px 0',
    },
    buttonMargin: {
      marginRight: '16px',
    },
    textbutton: {
      padding: '0px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
)
