import { useEffect } from 'react'
import { Box } from '@material-ui/core'
import { SwitchTenantCard } from 'supplyvue-ui'
import { Loader } from 'Common/Loader/Loader'
import { useTenantListData } from './TenantList.data'
import { Page } from 'Common/Page/Page'
import SwitchIcon from 'Assets/icons/switch.svg'
import { TenantListPageProps } from './TenantList.types'

export const TenantListPage = ({
  autoRedirect = true,
}: TenantListPageProps) => {
  const { isLoading, data } = useTenantListData()
  const tenant = localStorage.getItem('tenantId') || ''

  useEffect(() => {
    if (autoRedirect) {
      localStorage.removeItem('tenantId')
      localStorage.removeItem('tenantsCount')
      localStorage.removeItem('tenantName')
    }
  }, [autoRedirect])

  useEffect(() => {
    if (autoRedirect && !isLoading && data?.length === 1) {
      const tenantId = data[0].id
      const tenantName = data[0].name
      const tenantsCount = data?.length.toString()
      localStorage.setItem('tenantId', tenantId as string)
      localStorage.setItem('tenantName', tenantName as string)
      localStorage.setItem('tenantsCount', tenantsCount as string)
      window.location.href = window.location.origin
    }
  }, [autoRedirect, isLoading, data])

  const handleSwitchTenantClick = (tenantId: string | number) => {
    const selectedTenant = data?.find((tenant) => tenant.id === tenantId)
    const tenantsCount = data?.length.toString()
    localStorage.setItem('tenantId', selectedTenant?.id as string)
    localStorage.setItem('tenantName', selectedTenant?.name as string)
    localStorage.setItem('tenantsCount', tenantsCount as string)
    /** Redirecting user than re-routing as header component updation needs global state
     * like redux which is unnecessary at this moment. If in the future, redux has been
     * integrated, please maintain tenantName in redux store and use that in the Header.
     * Replace location.href with history.push() */
    window.location.href = window.location.origin
  }

  if (isLoading || !data || (autoRedirect && data.length === 1)) {
    return <Loader />
  }
  return (
    <Page center>
      <Box width="80%">
        <SwitchTenantCard
          title="Select a tenant"
          initialTenantId={tenant}
          tenantList={data}
          switchTenantIcon={<SwitchIcon width="24px" height="24px" />}
          primaryButtonLabel="Ok"
          secondaryButtonLabel="Cancel"
          showSecondaryButton={!autoRedirect}
          handlePrimaryButtonClick={handleSwitchTenantClick}
          handleSecondaryButtonClick={() =>
            (window.location.href = window.location.origin)
          }
        />
      </Box>
    </Page>
  )
}
