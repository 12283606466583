import { useState, useEffect } from 'react'
import { Modal, Button } from 'supplyvue-ui'
import { Box, CircularProgress } from '@material-ui/core'
import _ from 'lodash'

import { Loader } from 'Common/Loader/Loader'
import { Page } from 'Common/Page/Page'
import { useStyles } from './WorkcentreModal.styles'
import { Accordions } from './Accordions/Accordions.view'
import { WorkcentreModalProps } from './WorkcentreModal.types'
import { SourceSiteSelectionControl } from './SourceSiteSelectionControl/SourceSiteSelectionControl.view'
import {
  isAnyWorkCentresSelected,
  isAnySourceSitesSelected,
} from './WorkcentreModal.utils'

export const WorkcentreModal = ({
  isModalOpen,
  handleModalClose,
  sourceSiteData,
  handleOkbuttonClick,
  readOnly,
  isUpdating,
}: WorkcentreModalProps) => {
  const classes = useStyles({ readOnly })
  const [sourceSiteDetails, setSourceSiteDetails] = useState(sourceSiteData)

  useEffect(() => {
    setSourceSiteDetails(sourceSiteData)
  }, [sourceSiteData])

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    sourceSiteName?: string,
    workcentreName?: string
  ) => {
    const updatedData = sourceSiteDetails.map((sourcesite) => {
      if (sourceSiteName === sourcesite.siteLabel || !sourceSiteName) {
        const workcentres = sourcesite.siteWorkcentres.map((workcentre) => {
          if (
            (workcentreName && workcentre.code === workcentreName) ||
            !workcentreName ||
            !sourceSiteName
          ) {
            return {
              ...workcentre,
              isSelected:
                workcentre.productCount > 0 ? event.target.checked : false,
            }
          } else return workcentre
        })
        return {
          ...sourcesite,
          isSelected: isAnyWorkCentresSelected(workcentres),
          siteWorkcentres: workcentres,
        }
      } else return sourcesite
    })
    setSourceSiteDetails(updatedData)
  }

  const Body = (
    <Box className={classes.modalBody}>
      {!readOnly && (
        <SourceSiteSelectionControl
          sourceSiteDetails={sourceSiteDetails}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
      <Box className={classes.accordionsWrapper}>
        <Accordions
          readOnly={readOnly}
          sourceSiteDetails={sourceSiteDetails}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Box>
    </Box>
  )

  const isSourceSiteDetailsEdited = () => {
    return !_.isEqual(sourceSiteDetails, sourceSiteData)
  }

  const Loading = (
    <Box className={classes.modalBody}>
      <Page center>
        <Loader />
      </Page>
    </Box>
  )

  const Footer = (
    <Box className={classes.footer}>
      <Button
        onClick={handleModalClose}
        className={classes.modalActionButton}
        variant="outlined"
        label="Cancel"
        disabled={isUpdating}
      >
        Cancel
      </Button>
      <Button
        disabled={
          !isAnySourceSitesSelected(sourceSiteDetails) ||
          isUpdating ||
          !isSourceSiteDetailsEdited()
        }
        variant="contained"
        onClick={() => handleOkbuttonClick(sourceSiteDetails)}
        className={classes.modalActionButton}
        label="Ok"
      >
        {isUpdating && (
          <CircularProgress
            color="inherit"
            size={16}
            className={classes.progress}
          />
        )}
        Ok
      </Button>
    </Box>
  )

  return (
    <Modal
      disableBackdropClick
      aria-labelledby={readOnly ? 'View work centres' : 'Select work centres'}
      open={isModalOpen}
      handleClose={handleModalClose}
      header={readOnly ? 'View work centres' : 'Select work centres'}
      body={sourceSiteData.length > 0 ? Body : Loading}
      footer={!readOnly && Footer}
      className={classes.modal}
      isHeaderCloseButtonDisabled={isUpdating}
    />
  )
}
