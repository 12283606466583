import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import { Edge, Node } from 'react-flow-renderer'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Page } from 'Common/Page/Page'
import { Loader } from 'Common/Loader/Loader'
import { Button } from 'supplyvue-ui'
import {
  useReplenishmentParametersData,
  useUpdateReplenishmentParametersData,
  useParameterTypeOptionsData,
  useDownloadSiteSkusFile,
  useUpdateIncludeColumnData,
  useApproveSingleSite,
  useCalculationStatus,
} from './SiteParameters.data'
import {
  SiteParametersPageParams,
  OverlayProps,
  UniqueIdentifier,
  LoadingStatusType,
  SelectedSiteOptionType,
} from './SiteParameters.types'
import {
  inputValuesInitialState,
  dropDownvaluesInitialState,
  includeDropdownIptions,
} from './SiteParameters.constants'
import { useStyles } from './SiteParameters.styles'
import { SiteParameterTable } from './SiteParametersVirtualTable/SiteParameters.table'
import { SiteParametersEditForm } from './SiteParametersEditForm/SiteParametersEditForm.view'
import { mapDropdownMenuItemsInLabel } from 'Common/Utils/utils'
import { getEdgeLabel } from './SiteParameters.utils'
import { toast } from 'react-hot-toast'
import { FilteredRows } from 'supplyvue-ui/dist/types/components/Table/Table.types'
import { ReplenishmentSiteParamsTableData } from './SiteParametersVirtualTable/SiteParameterTable.types'
import { SiteParametersDropdowns } from './SiteParametersDropdowns.view'
import { ButtonGroup } from '@mui/material'

const LoadingCompoenent = () => {
  return (
    <Box width="90vw">
      <Page center>
        <Loader />
      </Page>
    </Box>
  )
}

export const SiteParameters = ({
  sourceSiteId,
  snapshotId,
  replenishmentPolicyId,
  overlayElement,
  siteLabel,
  onSuccessCb,
}: OverlayProps) => {
  const { projectId, scenarioId } = useParams<SiteParametersPageParams>()
  const classes = useStyles()
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [dropdownValues, setDropdownValues] = useState(
    dropDownvaluesInitialState
  )
  const [tableData, setTableData] = useState<
    ReplenishmentSiteParamsTableData[]
  >([])
  const [filteredSkuGroupKeys, setFilteredSkuGroupKeys] = useState<number[]>([])
  const [currentlyUpdatingRowKeys, setCurrentlyUpdatingRowKeys] = useState<
    number[]
  >([]) // stores skuGroupKey
  const [disabledRows, setDisabledRows] = useState<
    UniqueIdentifier<ReplenishmentSiteParamsTableData>[]
  >([])
  const { type } = overlayElement
  const destinationId =
    (overlayElement as Edge).target === undefined
      ? overlayElement.id
      : (overlayElement as Edge).target

  const siteName =
    (overlayElement as Node).position !== undefined
      ? (overlayElement as Node).data?.name
      : getEdgeLabel(overlayElement)

  /**** Code for approving single site*/
  const currentSite = {
    label: overlayElement?.data?.name ? overlayElement?.data?.name : siteLabel,
    approvalStatus: overlayElement?.data?.approvalStatus,
    id: overlayElement?.id ? parseInt(overlayElement?.id) : null,
  }
  const workcenterRef = useRef<number | null | undefined>()
  const siteRef = useRef<number | null | undefined>()
  const [selectedSite, setSelectedSite] = useState<
    SelectedSiteOptionType | null | undefined
  >(currentSite)

  // const [currentTarget, setCurrentTarget] = useState<number>(
  //   parseInt(destinationId)
  // )

  const [selectedSourceSite, setSelectedSourceSite] = useState<number | null>(
    sourceSiteId
  )
  const [isSafetyStockCalculating, setSafetyStockcalculating] = useState(false)

  const [periodType, setPeriodType] = useState(1)

  const handleSourceSiteChange = (code: number | null) => {
    setSelectedSourceSite(code)
  }

  const handleWorkcenterChange = (code: number | null) => {
    workcenterRef.current = code
  }

  const handleSiteChange = (option: SelectedSiteOptionType | null) => {
    siteRef.current = option?.id
    setSelectedSite(option)
  }

  const onapproveInitiateSuccess = (message: string) => {
    toast.success(message)
  }
  const onapproveInitiateError = (message: string) => {
    setSafetyStockcalculating(false)
    toast.error(message)
  }

  const approveSuccess = () => {
    setSafetyStockcalculating(false)
    toast.success('Approved')
  }

  const approveError = () => {
    setSafetyStockcalculating(false)
    toast.error('Approval failed')
  }

  const { triggerApproveSingleSite } = useApproveSingleSite(
    onapproveInitiateSuccess,
    onapproveInitiateError,
    approveSuccess,
    approveError
  )

  const handleApproveSite = () => {
    const siteKey = selectedSite?.id ? selectedSite.id : siteRef.current
    const dataLoad = {
      projectId: projectId,
      snapshotId: snapshotId,
      scenarioId: scenarioId,
      baseSourceSiteKey: selectedSourceSite
        ? parseInt(selectedSourceSite.toString())
        : parseInt(sourceSiteId.toString()),
      ...(siteKey && {
        siteKey: siteKey,
      }),
      ...(workcenterRef.current && {
        workcentreKey: workcenterRef.current,
      }),
      destinationSiteKey: selectedSite?.id ? selectedSite.id : siteRef.current,
      replenishmentPolicyId: replenishmentPolicyId,
      periodType: periodType,
    }
    triggerApproveSingleSite(dataLoad)
    setSafetyStockcalculating(true)
  }

  /**** */

  /*** Code to check the status of site calculation */
  const prepareData = () => {
    const siteKey = selectedSite?.id ? selectedSite.id : siteRef.current
    const dataLoad = {
      projectId: projectId,
      snapshotId: snapshotId,
      scenarioId: scenarioId,
      baseSourceSiteKey: selectedSourceSite
        ? parseInt(selectedSourceSite.toString())
        : parseInt(sourceSiteId.toString()),
      ...(siteKey && {
        siteKey: siteKey,
      }),
      ...(workcenterRef.current && {
        workcentreKey: workcenterRef.current,
      }),
      destinationSiteKey: selectedSite?.id ? selectedSite.id : siteRef.current,
      replenishmentPolicyId: replenishmentPolicyId,
    }
    return dataLoad
  }

  const { calculationStatusData } = useCalculationStatus(prepareData())
  const isSiteCalculating =
    calculationStatusData?.pollData === false ? false : true
  /*** End calculation check status*/

  const { isLoading, data, isFetching } = useReplenishmentParametersData({
    projectId,
    scenarioId,
    replenishmentPolicyId,
    baseSourceSiteKey: selectedSourceSite
      ? parseInt(selectedSourceSite.toString())
      : parseInt(sourceSiteId.toString()),
    ...(type === 'smoothstep' && {
      sourceSiteKey: selectedSite?.id
        ? selectedSite.id
        : siteRef.current ?? undefined,
    }),
    destinationSiteKey: selectedSite?.id ? selectedSite.id : siteRef.current,
  })

  const InitialLoadingStatus = {
    isLoading: false,
    columnId: '',
    skuGroupKey: null,
  }
  const [loadingStatus, setLoadingStatus] =
    useState<LoadingStatusType>(InitialLoadingStatus)

  // Needed for api implemntation of planning period.
  // const onSuccess = (message: string) => {
  //   toast.success(message)
  //   setLoadingStatus(InitialLoadingStatus)
  // }

  // const onError = (message: string) => {
  //   toast.error(message)
  //   setLoadingStatus(InitialLoadingStatus)
  // }

  const { isLoading: isTypeOptionsloading, data: typeOptionsData } =
    useParameterTypeOptionsData(snapshotId)

  const onSuccess = () => {
    setInputValues(inputValuesInitialState)
    setDropdownValues(dropDownvaluesInitialState)
    onSuccessCb && onSuccessCb()
  }

  const {
    isLoading: isupdatingReplenishmentSiteParams,
    mutate: updateReplenishmentSiteParams,
  } = useUpdateReplenishmentParametersData(onSuccess)

  const handleClearButtonClick = () => {
    setInputValues(inputValuesInitialState)
    setDropdownValues(dropDownvaluesInitialState)
  }

  const updateIncludeColumn = (skuGroupKey: number) => {
    setTableData((oldTableData) =>
      oldTableData.map((item) => {
        if (item.skuGroupKey === skuGroupKey) {
          setCurrentlyUpdatingRowKeys((oldCurrentlyUpdatingRowKeys) => {
            const newCurrentlyUpdatingRowKeys =
              oldCurrentlyUpdatingRowKeys.filter(
                (rowKey: number) => rowKey !== skuGroupKey
              )
            return newCurrentlyUpdatingRowKeys
          })

          return {
            ...item,
            include: !item.include,
          }
        } else return item
      })
    )
  }

  const onHandleUpdateError = (message: string, skuGroupKey: number) => {
    toast.error(message)
    updateIncludeColumn(skuGroupKey)
  }

  const { mutate: updateIncludeColumnData } =
    useUpdateIncludeColumnData(onHandleUpdateError)

  const { refetch: downloadCSVFile } = useDownloadSiteSkusFile({
    projectId,
    scenarioId,
    replenishmentPolicyId,
    baseSourceSiteKey: selectedSourceSite
      ? parseInt(selectedSourceSite.toString())
      : parseInt(sourceSiteId.toString()),
    ...(type === 'smoothstep' && {
      sourceSiteKey: selectedSite?.id
        ? selectedSite.id
        : siteRef.current ?? undefined,
    }),
    destinationSiteKey: selectedSite?.id ? selectedSite.id : siteRef.current,
  })

  useEffect(() => {
    if (data) {
      setTableData(data)
    }
  }, [data])

  useEffect(() => {
    setDisabledRows(getDisabledRows(tableData))
  }, [tableData])

  const handleDropdownChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof dropdownValues
    setDropdownValues({
      ...dropdownValues,
      [name]: event.target.value as string,
    })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'serviceLevel') {
      const value = parseFloat(event.target.value)
      if ((value >= 0 && value < 100) || event.target.value === '') {
        setInputValues({
          ...inputValues,
          [event.target.name]: event.target.value,
        })
      }
    } else if (
      event.target.name === 'replenishmentCycle' ||
      event.target.name === 'deploymentLeadTime'
    ) {
      const regex = /^([0-9]){0,4}$/i
      if (event.target.value === '' || regex.test(event.target.value)) {
        setInputValues({
          ...inputValues,
          [event.target.name]: event.target.value,
        })
      }
    } else if (event.target.name === 'MOI' || event.target.name === 'MOQ') {
      const regex = /^([0-9]){0,15}$/i
      if (event.target.value === '' || regex.test(event.target.value)) {
        setInputValues({
          ...inputValues,
          [event.target.name]: event.target.value,
        })
      }
    } else if (event.target.name === 'planningPeriod') {
      const regex = /^([0-9]){0,15}$/i
      if (event.target.value === '' || regex.test(event.target.value)) {
        setInputValues({
          ...inputValues,
          [event.target.name]: event.target.value,
        })
      }
    }
  }

  const isApplyParametersDisabled = (): boolean => {
    return (
      (dropdownValues.supplyType === '' &&
        dropdownValues.replenishmentType === '' &&
        inputValues.replenishmentCycle === '' &&
        inputValues.deploymentLeadTime === '' &&
        inputValues.MOI === '' &&
        inputValues.MOQ === '' &&
        inputValues.planningPeriod === '' &&
        inputValues.serviceLevel === '') ||
      parseFloat(inputValues.serviceLevel) < 50 ||
      parseFloat(inputValues.serviceLevel) > 99.99
    )
  }

  const handleUpdateReplenishmentSiteParams = () => {
    updateReplenishmentSiteParams({
      projectId,
      replenishmentPolicyId,
      baseSourceSiteKey: selectedSourceSite
        ? parseInt(selectedSourceSite.toString())
        : parseInt(sourceSiteId.toString()),
      ...(type === 'smoothstep' && {
        sourceSiteKey: selectedSite?.id
          ? selectedSite.id
          : siteRef.current ?? undefined,
      }),
      destinationSiteKey: selectedSite?.id ? selectedSite.id : siteRef.current,
      skuGroupKeys: filteredSkuGroupKeys,
      fields: {
        ...(dropdownValues.supplyType && {
          supplyTypeId: parseInt(dropdownValues.supplyType),
        }),
        ...(dropdownValues.replenishmentType && {
          replenishmentTypeId: parseInt(dropdownValues.replenishmentType),
        }),
        ...(dropdownValues.includeType !== '' && {
          include: dropdownValues.includeType,
        }),
        ...(inputValues.replenishmentCycle && {
          replenishmentCycle: parseInt(inputValues.replenishmentCycle),
        }),
        ...(inputValues.deploymentLeadTime && {
          deploymentLeadTime: parseInt(inputValues.deploymentLeadTime),
        }),
        ...(inputValues.MOI && {
          minimumOrderIncrement: parseInt(inputValues.MOI),
        }),
        ...(inputValues.MOQ && {
          minimumOrderQuantity: parseInt(inputValues.MOQ),
        }),
        ...(inputValues.serviceLevel && {
          serviceLevel: parseFloat(inputValues.serviceLevel),
        }),
        ...(inputValues.planningPeriod && {
          planningPeriod: parseInt(inputValues.planningPeriod),
        }),
      },
    })
  }

  const handleDownload = () => {
    downloadCSVFile()
  }

  const getDisabledRows = (data: ReplenishmentSiteParamsTableData[]) =>
    data
      .filter((item) => !item.include)
      .map((item) => {
        return { skuGroupCode: item.skuGroupCode }
      })

  const handleToggleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    value: boolean | null,
    skuGroupKey: number
  ) => {
    event.stopPropagation()
    updateIncludeColumnData({
      replenishmentPolicyId,
      baseSourceSiteKey: parseInt(sourceSiteId.toString()),
      destinationSiteKey: parseInt(destinationId),
      skuGroupKey: skuGroupKey,
      include: !!value,
    })
    updateIncludeColumn(skuGroupKey)
  }

  const getFilteredRows = (
    filteredRows: FilteredRows<ReplenishmentSiteParamsTableData>
  ) => {
    setFilteredSkuGroupKeys(
      filteredRows?.map((row) => row?.original?.skuGroupKey)
    )
  }

  const updateEditedCellValue = () => {
    toast.success('Updated')
    setLoadingStatus(InitialLoadingStatus)
  }

  if (isLoading || isTypeOptionsloading) {
    return <LoadingCompoenent />
  }

  if (!data) {
    return (
      <Box width="90vw">
        <Page center>
          <Typography>No data found</Typography>
        </Page>
      </Box>
    )
  }

  return (
    <Box width="90vw" padding={2}>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h3" className={classes.overlayHeading}>
              {selectedSite?.label
                ? selectedSite.label
                : siteName
                ? siteName
                : siteLabel}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <SiteParametersDropdowns
              handleSourceSiteChange={handleSourceSiteChange}
              handleWorkcenterChange={handleWorkcenterChange}
              handleSiteChange={handleSiteChange}
              selectedSite={selectedSite}
              sourceSiteId={selectedSourceSite as number}
              // setCurrentTarget={(newTarget) => setCurrentTarget(newTarget)}
            />
          </Box>
        </Box>
        <SiteParametersEditForm
          key={isSiteCalculating ? Date.now() : null}
          inputValues={inputValues}
          dropdownValues={dropdownValues}
          handleDropdownChange={handleDropdownChange}
          handleInputChange={handleInputChange}
          isDataUpdating={isupdatingReplenishmentSiteParams || isFetching}
          isApplyParametersDisabled={isApplyParametersDisabled()}
          handleUpdateReplenishmentSiteParams={
            handleUpdateReplenishmentSiteParams
          }
          supplyTypeOptions={mapDropdownMenuItemsInLabel(
            typeOptionsData.data.supplyType
          )}
          replenishmentTypeOptions={mapDropdownMenuItemsInLabel(
            typeOptionsData.data.replenishmentType
          )}
          includeTypeOptions={includeDropdownIptions}
          handleClearButtonClick={handleClearButtonClick}
          isCalculating={isSiteCalculating}
        />

        <Box className={classes.filterContainer}>
          <Tooltip title="Download CSV">
            <IconButton
              className={
                tableData?.length
                  ? classes.iconButton
                  : classes.iconButtonDisabled
              }
              color="primary"
              aria-label="Download CSV"
              disabled={
                isupdatingReplenishmentSiteParams ||
                isFetching ||
                !tableData?.length
              }
              onClick={handleDownload}
            >
              <GetAppIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        {tableData && (
          <SiteParameterTable
            data={tableData}
            handleToggleButtonChange={handleToggleButtonChange}
            disabledRows={disabledRows}
            updatingRowKeys={currentlyUpdatingRowKeys}
            getFilteredRows={getFilteredRows}
            updateTableData={setTableData}
            updateEditedCellValue={updateEditedCellValue}
            loadingStatus={loadingStatus}
          />
        )}
        <Box className={classes.buttonRow}>
          <ButtonGroup size="small" className={classes.button}>
            <Button
              variant={periodType ? 'outlined' : 'contained'}
              onClick={() => setPeriodType(0)}
              size="small"
              className={classes.button}
            >
              Months
            </Button>
            <Button
              variant={!periodType ? 'outlined' : 'contained'}
              size="small"
              onClick={() => setPeriodType(1)}
              className={classes.button}
            >
              Weeks
            </Button>
          </ButtonGroup>

          <Button
            onClick={handleApproveSite}
            variant="contained"
            disabled={
              isSafetyStockCalculating ||
              !selectedSourceSite ||
              !tableData?.length ||
              isSiteCalculating
            }
          >
            {(isSafetyStockCalculating || isSiteCalculating) && (
              <CircularProgress
                color="inherit"
                size={16}
                className={classes.progress}
              />
            )}
            Approve
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
