import { useMutation } from 'react-query'
import { format } from 'date-fns'
import { request } from 'Features/Auth/axios-client'
import {
  BulkUpdateParams,
  DownloadSkuGroupSettingsParamsType,
} from './ProductSettings.types'

const downloadSkuSettingsData = async ({
  planTemplateId,
  sourceSiteId,
  workcentreId,
  projectId,
  projectCode,
  workcentreCode,
  scenarioCode,
}: DownloadSkuGroupSettingsParamsType) => {
  const { data } = await request({
    method: 'post',
    url: `/plan-templates/${planTemplateId}/sku-group-settings/download`,
    data: {
      sourceSiteId,
      workcentreId,
      projectId,
    },
  })

  const fileName =
    `sku_group_data__${projectCode}__${scenarioCode}__${workcentreCode}__${format(
      new Date(),
      'yyyyMMdd'
    )}`.substring(0, 256)

  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  return data
}

export const useDownloadSkuSettingsData = () => {
  return useMutation(downloadSkuSettingsData)
}

const uploadProductBulkUpdateFile = async (
  bulkUpdateParams: BulkUpdateParams
) => {
  const {
    planTemplateId,
    sourceSiteId = 0,
    workcentreId = 0,
    projectId = '',
    snapshotId = '',
    uploadType,
    file,
  } = bulkUpdateParams
  const formData = new FormData()
  formData.append('source_site_key', sourceSiteId.toString())
  formData.append('workcentre_key', workcentreId.toString())
  formData.append('snapshot_id', snapshotId.toString())
  formData.append('project_id', projectId.toString())
  formData.append('upload_type', uploadType)
  formData.append('settings_file', file)
  const { data } = await request({
    method: 'post',
    url: `/plan-templates/${planTemplateId}/sku-group-settings/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
  return data
}

export const useUploadProductFile = () => {
  return useMutation(uploadProductBulkUpdateFile)
}
