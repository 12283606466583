import { Input } from 'supplyvue-ui'

import { useMandatoryFieldValidation } from 'Common/hooks/useMandatoryFieldValidation.hook'
import { InputFieldProps } from './InputField.types'
import { useEffect } from 'react'
import { useStyles } from './InputField.styles'

export const InputField = ({
  isMandatoryField = false,
  label,
  name,
  handleChange,
  value,
  errorMessage,
  isNumber = false,
  isDisabled = false,
  getErrorStatus,
}: InputFieldProps) => {
  const { error, setFieldValue } = useMandatoryFieldValidation(
    value,
    isNumber,
    label
  )
  const classes = useStyles()
  useEffect(() => {
    isMandatoryField && setFieldValue(value)
  }, [value])

  useEffect(() => {
    getErrorStatus(error, name)
  }, [error])

  return (
    <Input
      error={isMandatoryField ? !!error || !!errorMessage : false}
      helperText={isMandatoryField ? errorMessage || error : false}
      ariaLabel={label}
      value={value}
      onChange={handleChange}
      name={name}
      className={classes.input}
      disabled={isDisabled}
    />
  )
}
