import { Box, Divider, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { ToolbarProps } from './Toolbar.types'
import { useStyles } from './Toolbar.styles'

export const Toolbar = ({ onEditClick }: ToolbarProps) => {
  const classes = useStyles()
  return (
    <Box className={classes.toolbar}>
      <EditIcon onClick={onEditClick} />
      <Typography variant="h4" onClick={onEditClick}>
        Bulk update
      </Typography>
      <Divider orientation="vertical" flexItem />
    </Box>
  )
}
