import { Box, Paper, Typography } from '@material-ui/core'
import { Page } from 'Common/Page/Page'
import { PageHeader } from 'Common/PageHeader/PageHeader'
import { SkuGroupInventoryAnalysisDropdowns } from './SkuGroupInventoryAnalysisDropdowns.view'
import { SkuGroupInventoryAnalysisTables } from './SkuGroupInventoryAnalysis.tables'
import { SkuGroupInventoryGraphView } from './SkuGroupInventoryGraph.view'
import { useHistory, useParams } from 'react-router-dom'
import { InventoryAnalysisParams } from './SkuGroupInventoryAnalysis.types'
import {
  useInventoryAnalysisGraphData,
  useInventoryAnalysisTableData,
} from './SkuGroupInventoryAnalysis.data'
import { Loader } from 'Common/Loader/Loader'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { useEffect, useState } from 'react'
import {
  useSiteConnectionsData,
  useSourceSitesData,
} from 'Features/ReplenishmentPolicy/ReplenishmentPolicy.data'
import { SiteCodeOption } from 'Features/ReplenishmentPolicy/ReplenishmentPolicy.types'
import { useInventoryModellingData } from 'Features/ViewInventory/ViewInventory.data'
const historyOptions = Array.from({ length: 12 }, (_, i) => ({
  key: `${i + 1} weeks`,
  value: i + 1,
}))

export const SkuGroupInventoryAnalysis = () => {
  const history = useHistory()
  const {
    projectId,
    scenarioId,
    replenishmentPolicyId,
    siteKey,
    skuGroupKey,
    sourceSiteId,
  } = useParams<InventoryAnalysisParams>()

  useBreadcrumbsData({
    project_id: projectId,
    scenario_id: scenarioId,
    replenishment_policy_id: replenishmentPolicyId,
    base_source_site_id: sourceSiteId,
    site_id: siteKey,
    //sku_group_key: skuGroupKey,
  })

  const [
    selectedSiteCode,
    setSelectedSiteCode,
  ] = useState<SiteCodeOption | null>(null)

  const [
    selectedSourceSite,
    setSelectedSourceSite,
  ] = useState<SiteCodeOption | null>(null)
  const [selectedSKUgroup, setSelectedSKUGroup] = useState<string | null>(
    skuGroupKey
  )
  const [isApprovedSiteAvailable, setApprovedSiteAvailable] = useState(false)
  const [sourceSiteCodeOptions, setSourceSiteCodeOptions] = useState<
    SiteCodeOption[] | null
  >(null)
  const [historyLength, setHistoryLength] = useState<number>(12)

  const { data: summaryData, isLoading } = useInventoryAnalysisTableData(
    replenishmentPolicyId,
    selectedSourceSite?.id ? selectedSourceSite?.id.toString() : sourceSiteId,
    siteKey,
    skuGroupKey
  )

  const {
    data: graphData,
    isLoading: graphDataLoading,
  } = useInventoryAnalysisGraphData(
    projectId,
    replenishmentPolicyId,
    selectedSourceSite?.id ? selectedSourceSite?.id.toString() : sourceSiteId,
    siteKey,
    skuGroupKey,
    historyLength
  )
  const { data: sourceSites } = useSourceSitesData(projectId, scenarioId)
  const { data: siteConnections } = useSiteConnectionsData(
    projectId,
    sourceSites?.snapshotId,
    scenarioId,
    replenishmentPolicyId,
    parseInt(
      selectedSourceSite?.id ? selectedSourceSite?.id.toString() : sourceSiteId
    ),
    undefined,
    () => null,
    () => null
  )

  const {
    data: inventoryModellingData,
    isLoading: isInventoryModellingLoading,
  } = useInventoryModellingData(
    replenishmentPolicyId,
    selectedSourceSite?.id ? selectedSourceSite?.id.toString() : sourceSiteId,
    siteKey,
    'demand_variability'
  )

  useEffect(() => {
    if (siteConnections?.data) {
      const defaultSiteCode = siteConnections.data?.nodes.find(
        (node: SiteCodeOption) =>
          node.id ===
            parseInt(
              selectedSiteCode?.id ? selectedSiteCode.id.toString() : siteKey
            ) && node.approvalStatus === 'APPROVED'
      )
      setSelectedSiteCode(defaultSiteCode)

      const options = siteConnections?.data?.nodes.filter(
        (node: SiteCodeOption) => node.approvalStatus === 'APPROVED'
      )
      setSourceSiteCodeOptions(options)

      if (options.length > 0) {
        setApprovedSiteAvailable(true)
      } else {
        setApprovedSiteAvailable(false)
      }
    }
  }, [siteConnections])

  useEffect(() => {
    if (sourceSites?.baseSourceSites?.length > 0) {
      const defaultSiteCode = sourceSites.baseSourceSites?.find(
        (sourceSite: { value: number }) =>
          sourceSite.value ===
          parseInt(
            selectedSourceSite?.id
              ? selectedSourceSite?.id.toString()
              : sourceSiteId
          )
      )

      setSelectedSourceSite({
        id: defaultSiteCode.value,
        label: defaultSiteCode.label,
      } as SiteCodeOption)
    }
  }, [sourceSites])

  const handleSourceSiteChange = (newSite: SiteCodeOption | null) => {
    if (newSite) {
      setSelectedSiteCode(null)
      setSelectedSKUGroup(null)
      setSelectedSourceSite(newSite)
    }
  }

  const handleReplenishmentSiteChange = (
    newReplenishmentSite: SiteCodeOption | null
  ) => {
    if (newReplenishmentSite) {
      setSelectedSiteCode(newReplenishmentSite)
      setSelectedSKUGroup(null)
      history.push(
        `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings/inventory-analysis/${selectedSourceSite?.id}/${newReplenishmentSite.id}/sku-group-inventory-analysis/${skuGroupKey}`
      )
    }
  }

  const handleSkuChange = (newSkuKey: number) => {
    if (Number.isInteger(newSkuKey)) {
      setSelectedSKUGroup(newSkuKey.toString())
      history.push(
        `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings/inventory-analysis/${selectedSourceSite?.id}/${selectedSiteCode?.id}/sku-group-inventory-analysis/${newSkuKey}`
      )
    }
  }

  if (isLoading || graphDataLoading || isInventoryModellingLoading) {
    return <Loader />
  }
  return (
    <Page>
      <Box display="flex" flexDirection="column" flex={1}>
        <PageHeader title="SKU group inventory analysis" />
        <Box display="flex" flexDirection="column">
          <Box>
            <Paper>
              <Box padding={2}>
                <SkuGroupInventoryAnalysisDropdowns
                  skuGroupOptions={
                    inventoryModellingData?.map(
                      (SKU: { skuGroup: string; skuGroupKey: number }) => ({
                        key: SKU.skuGroup,
                        value: SKU.skuGroupKey,
                      })
                    ) ?? []
                  }
                  skuGroupValue={selectedSKUgroup?.toString() ?? ''}
                  sourceSiteCodeOptions={sourceSites?.baseSourceSites.map(
                    (site: { label: string; value: number }) => ({
                      label: site.label,
                      id: site.value,
                    })
                  )}
                  baseSourceSiteValue={selectedSourceSite}
                  replenishmentSiteCodeOptions={sourceSiteCodeOptions ?? []}
                  replenishmentSiteValue={selectedSiteCode}
                  handleSkuChange={handleSkuChange}
                  handleSourceSiteChange={handleSourceSiteChange}
                  handleReplenishmentSiteChange={handleReplenishmentSiteChange}
                />
              </Box>
            </Paper>
          </Box>

          <Box display="flex" marginTop={2} justifyContent="space-between">
            {!isApprovedSiteAvailable ? (
              <Typography>
                No available replenishment sites for this source site.
              </Typography>
            ) : selectedSKUgroup && selectedSiteCode && selectedSourceSite ? (
              <>
                <Box width="58%">
                  <Paper>
                    <Box padding={2} minHeight="800px">
                      {summaryData?.data?.forecastError && (
                        <SkuGroupInventoryAnalysisTables
                          summaryData={summaryData?.data}
                          reportingUnitOfMeasure="hl"
                        />
                      )}
                    </Box>
                  </Paper>
                </Box>
                <Box width="40%">
                  <Paper>
                    <Box padding={2} minHeight="800px">
                      {graphData && (
                        <SkuGroupInventoryGraphView
                          data={graphData.data}
                          historyLength={historyLength}
                          historyOptions={historyOptions}
                          handleHistoryLengthChange={setHistoryLength}
                          isLoading={graphDataLoading}
                        />
                      )}
                    </Box>
                  </Paper>
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Page>
  )
}
