import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  contentWrapper: {
    minWidth: '600px',
  },
  modalActionButton: {
    marginLeft: '20px',
    minWidth: '105px',
  },
  label: {
    marginBottom: '8px',
  },
  progress: {
    marginRight: '8px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
})
