import { Box, CircularProgress } from '@material-ui/core'
import { Page } from 'Common/Page/Page'
import { useParams } from 'react-router-dom'
import { Button, PageHeader } from 'supplyvue-ui'
import {
  useReplenishmentSettingsOutput,
  useExportReplenishmentSettingsOutput,
  useSaveReplenishmentSettingsOutput,
} from './NetworkReplenishmentSettingsOutput.data'
import {
  Edited,
  NetworkReplenishmentPolicyOutputTableColumns,
  OutputParams,
} from './NetworkReplenishmentSettingsOutput.types'
import { NetworkReplenishmentPolicyOutputTable } from './NetworkReplenishmentPolicyOutput.table'
import { useStyles } from './NetworkReplenishmentPolicyOutput.styles'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'

export const NetworkReplenishmentSettingsOutput = () => {
  const classes = useStyles()
  const { replenishmentPolicyId, projectId } = useParams<OutputParams>()
  const [tableData, setTableData] = useState<
    NetworkReplenishmentPolicyOutputTableColumns[]
  >([])

  const editedData = useRef<{ [key: number]: Edited }>({})
  useBreadcrumbsData({
    project_id: projectId,
    replenishment_policy_id: replenishmentPolicyId,
  })
  const { data, isLoading } = useReplenishmentSettingsOutput(
    replenishmentPolicyId
  )

  useEffect(() => {
    setTableData(data)
  }, [data])

  const {
    mutate: exportData,
    isLoading: isDownloading,
  } = useExportReplenishmentSettingsOutput()

  const onSuccess = (msg: string) => {
    toast.success(msg)
    editedData.current = {}
  }

  const onError = (msg: string) => {
    toast.error(msg)
  }
  const {
    mutate: saveData,
    isLoading: isSaving,
  } = useSaveReplenishmentSettingsOutput(onSuccess, onError)
  const handleExportClick = () => {
    exportData(replenishmentPolicyId)
  }

  const handleSaveClick = () => {
    const editedDataArray = []
    for (const key in editedData.current) {
      editedDataArray.push(editedData.current[key])
    }
    saveData({
      replenishmentPolicyId,
      editedDataArray,
    })
  }

  const updateField = (
    row: NetworkReplenishmentPolicyOutputTableColumns,
    selectedValue: string,
    field: keyof NetworkReplenishmentPolicyOutputTableColumns
  ) => {
    const siteKey = row?.siteKey
    const updatedData = tableData.map(
      (data: NetworkReplenishmentPolicyOutputTableColumns) => {
        if (data.siteKey === siteKey) {
          data[field] = selectedValue
        }
        return data
      }
    )

    setTableData(updatedData)

    let fieldValue: boolean | string | number = row[field]
    if (field === 'export') {
      fieldValue = row[field] === 'Include' ? true : false
    }
    const editedObj = {
      siteKey: row.siteKey,
      sourceSiteKey: row.baseSourceSiteKey,
      [field]: fieldValue,
    }

    if (!editedData?.current[row.siteKey]) {
      editedData.current = {
        ...editedData.current,
        ...{ [row.siteKey]: editedObj },
      }
    } else {
      editedData.current[row.siteKey] = {
        ...editedData.current[row.siteKey],
        ...editedObj,
      }
    }
  }

  return (
    <Page>
      <Box className={classes.wrapper}>
        <PageHeader title="Network replenishment settings output" />
        <Box>
          {tableData?.length > 0 && (
            <NetworkReplenishmentPolicyOutputTable
              data={tableData ?? []}
              isLoading={isLoading}
              updateField={updateField}
            />
          )}
        </Box>
        <Box mt={2} display="flex" flexDirection="row-reverse">
          <Box>
            <Button variant="contained" onClick={handleExportClick}>
              {isDownloading && (
                <CircularProgress
                  color="inherit"
                  size={16}
                  className={classes.progress}
                />
              )}
              Export
            </Button>
          </Box>
          <Box mr={2}>
            <Button variant="contained" onClick={handleSaveClick}>
              {isSaving && (
                <CircularProgress
                  color="inherit"
                  size={16}
                  className={classes.progress}
                />
              )}
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Page>
  )
}
