import {
  ColumnFilterForMatrixTable,
  EditableCell,
  multiSelectFilter,
  Table,
} from 'supplyvue-ui'
import { Column, TableInstance } from 'react-table'

import { Loader } from 'Common/Loader/Loader'
import { useMemo } from 'react'
import { keys } from 'lodash'
import { ProductChangeoversMatrix } from './ProductChangeoverMatrix.types'

const handleValidation = (value: string | number) => {
  const valueAsNumber = parseFloat(value as string)
  const VALID_NUMBER_PATTERN = /^[+]?((\.\d{0,2})|(\d+(\.\d{0,2})?)|(\d+\.))$/
  const isValid = value.toString().match(VALID_NUMBER_PATTERN)
  return isValid && valueAsNumber >= 0 ? '' : 'error'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getId = (header: string, productCode: string, productIds: any) => {
  const id = productIds?.find((item: any) => item?.productCode === productCode)[
    header
  ]
  return id
}

export const ProductChangeoversTable = ({
  toggleValue,
  data,
  updateTableData,
  isLoading,
  productChangeOverId,
  updateEditedCellValue,
}: ProductChangeoversMatrix) => {
  const columns: Column<any>[] = useMemo(() => {
    const defaultColumns: any = [
      {
        Header: '',
        accessor: 'productCode',
        disableSortBy: true,
        disableFilters: false,
        Filter: ColumnFilterForMatrixTable,
        filter: multiSelectFilter,
        enableRowSpan: false,
        maxWidth: 300,
      },
    ]
    const columnKeys = keys(data?.length ? data[0] : {})
    columnKeys.forEach((element) => {
      if (element !== 'productCode')
        defaultColumns?.push({
          Header: element,
          accessor: element,
          disableSortBy: true,
          disableFilters: true,
          enableRowSpan: false,
          columnWidth: 50,
          maxWidth: 50,
          Cell: (props: TableInstance) => {
            const shouldDisable =
              props.column.Header === props.row.cells[0].value

            return (
              <EditableCell
                isLoading={false}
                value={props.value}
                updateData={(value) => {
                  props.updateData(props.row.id, props.column.id, value)

                  const id = getId(
                    props.column.Header,
                    props.row.cells[0].value,
                    productChangeOverId
                  )

                  updateEditedCellValue(value, 'product', toggleValue, id)
                }}
                handleValidation={handleValidation}
                isEditDisabled={shouldDisable}
                isNumeric
              />
            )
          },
        })
    })

    return defaultColumns
  }, [data, updateEditedCellValue, toggleValue])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Table
      data={data as any}
      columns={columns}
      isRowClickable={true}
      hightlightClickedRow={true}
      shouldHighlightRowOnHover={true}
      isMatrix
      pageSizeOptions={[5, 10, 15, { label: 'All', value: data?.length }]}
      defaultPageSize={5}
      isTablePaginated={true}
      headerHeight={150}
      updateData={updateTableData}
      cellPadding="10px 5px"
    />
  )
}
