import { Page } from 'Common/Page/Page'
import { useEffect, useState } from 'react'
import { SkuGroupManagementTable } from './SkuGroupManagement.table'
import { SkuGroupManagementTableData } from './SkuGroupManagement.types'
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  inputValuesInitialState,
} from './SkuGroupManagement.constants'
import { SkuGroupManagementForm } from './SkuGroupManagamentSearch/SkuGroupManagementForm'
import { Box } from '@material-ui/core'
import {
  useMasterSkuData,
  useUpdateMasterSkus,
} from './SkuGroupManagement.data'
import { getFilters, getSortKey } from './SkuGroupManagement.utils'
import { toast } from 'react-hot-toast'
import { Loader } from 'Common/Loader/Loader'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'

export const SkuGroupManagementPage = () => {
  useBreadcrumbsData({})
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [filterValues, setFilterValues] = useState('')
  const [sortBy, setSortBy] = useState<string>('')
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)

  const {
    data: masterSkuData,
    isLoading,
    isFetching: isMasterSkuDataFetching,
  } = useMasterSkuData({
    pageIndex: pageIndex ? pageIndex + 1 : 1,
    pageSize,
    sortBy,
    filterValues,
  })

  const [tableData, setTableData] = useState<SkuGroupManagementTableData[]>([])

  useEffect(() => {
    setTableData(masterSkuData?.records ?? [])
  }, [masterSkuData])

  const onError = (message: string) => {
    toast.error(message)
  }
  const { mutate: updateMasterSkus } = useUpdateMasterSkus({ onError })

  const updateIncludeColumn = (
    skuKey: string,
    skuGroupKey: string,
    masterSku: boolean
  ) => {
    updateMasterSkus({
      skuKey: parseInt(skuKey),
      skuGroupKey: parseInt(skuGroupKey),
      masterSku,
    })

    setTableData((oldTableData) =>
      oldTableData.map((item) => {
        if (item.skuKey === skuKey) {
          return {
            ...item,
            masterSku: !item.masterSku,
          }
        } else if (item.skuGroupKey === skuGroupKey && item.masterSku) {
          return {
            ...item,
            masterSku: false,
          }
        } else return item
      })
    )
  }

  const handleToggleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    skuKey: string,
    skuGroupKey: string,
    masterSku: boolean
  ) => {
    event.stopPropagation()

    updateIncludeColumn(skuKey, skuGroupKey, masterSku)
  }

  const handleDropdownChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof inputValues

    setInputValues({
      ...inputValues,
      [name]: event.target.value,
    })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    })
  }

  const onSortByChange = (currentSortBy: any) => {
    if (currentSortBy?.length) {
      const sortKey = getSortKey(currentSortBy[0])
      setSortBy(sortKey)
    } else {
      setSortBy('')
    }
  }
  const onPageSizeChange = (currentPageSize: number) => {
    setPageSize(currentPageSize)
  }
  const onPageIndexChange = (currentPageIndex: number) => {
    setPageIndex(currentPageIndex)
  }

  const updateTableData = (updatedTableData: SkuGroupManagementTableData[]) => {
    setTableData(updatedTableData)
  }
  const handleSearchClick = () => {
    const filters = getFilters(inputValues)
    setPageIndex(0)
    setFilterValues(filters)
  }
  const handleResetFiltersClick = () => {
    setInputValues(inputValuesInitialState)
    setFilterValues('')
  }

  if (isLoading) return <Loader />

  return (
    <Page>
      <Box width="100%">
        <SkuGroupManagementForm
          inputValues={inputValues}
          handleDropdownChange={handleDropdownChange}
          handleInputChange={handleInputChange}
          handleSearchClick={handleSearchClick}
          handleResetFiltersClick={handleResetFiltersClick}
          disableSearch={isMasterSkuDataFetching}
        />
        <Box mt={1}>
          <SkuGroupManagementTable
            data={tableData}
            handleToggleButtonChange={handleToggleButtonChange}
            updateTableData={updateTableData}
            paginationDetails={masterSkuData?.pagination}
            onSortByChange={onSortByChange}
            onPageSizeChange={onPageSizeChange}
            onPageIndexChange={onPageIndexChange}
            pageSize={pageSize}
          />
        </Box>
      </Box>
    </Page>
  )
}
