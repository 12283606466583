import { useMemo } from 'react'
import { Table as MUITable } from 'Common/MUITable/MUITable'
import { useStyles } from './PlanDetails.styles'
import {
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
} from '@mui/x-data-grid-premium'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'
import { Typography, Tooltip } from '@material-ui/core'
import { AddTaskOutlined } from '@mui/icons-material'
import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  Check,
} from '@mui/icons-material'

type CurrentComparisonData = {
  currentCycleTime: number
  currentStartWeek: number
  cycle: number
  description: string | null
  productCode: string
  startWeek: number
}

type CurrentComparisonTableProps = {
  data: CurrentComparisonData[]
}

export const CurrentComparisonTable = ({
  data,
}: CurrentComparisonTableProps) => {
  const classes = useStyles()

  const tableData = data.map((item: any, idx: number) => {
    return {
      ...item,
      id: idx,
    }
  })

  type RenderStatusProps = {
    currentCycleTime: number
    cycle: number
  }

  const RenderStatus = ({ currentCycleTime, cycle }: RenderStatusProps) => {
    if (currentCycleTime !== -1) {
      if (cycle === currentCycleTime) {
        return (
          <Tooltip title="Cycle Unchanged">
            <Check sx={{ color: 'green' }}></Check>
          </Tooltip>
        )
      } else if (currentCycleTime < cycle) {
        return (
          <Tooltip title="Cycle Decreased">
            <KeyboardDoubleArrowDown
              sx={{ color: 'red' }}
            ></KeyboardDoubleArrowDown>
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title="Cycle Increased">
            <KeyboardDoubleArrowUp
              sx={{ color: 'green' }}
            ></KeyboardDoubleArrowUp>
          </Tooltip>
        )
      }
    } else {
      return (
        <Tooltip title="New Sku">
          <AddTaskOutlined sx={{ color: '#44a6c6' }}></AddTaskOutlined>
        </Tooltip>
      )
    }
  }

  const skuCodes = [
    ...new Set(
      data.map((item: CurrentComparisonData) => {
        return item.productCode
      })
    ),
  ]

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'None',
        headerName: 'Status',
        align: 'center',
        width: 75,
        renderCell: (params: GridCellParams) => {
          const { cycle, currentCycleTime } = params.row
          return (
            <RenderStatus currentCycleTime={currentCycleTime} cycle={cycle} />
          )
        },
      },
      {
        field: 'productCode',
        headerName: 'SKU group code',
        sortable: true,
        filterable: true,
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuCodes,
              label: 'Sku codes',
            },
          },
        ],
        minWidth: 300,
        flex: 3,
      },
      {
        field: 'description',
        headerName: 'Description',
        renderCell: (params: any) => {
          const { description } = params.row
          if (description === 'nan') {
            return <div></div>
          } else {
            return <div>{description}</div>
          }
        },
        type: 'string',
        minWidth: 500,
        flex: 3,
      },

      {
        field: 'cycle',
        headerName: 'Cycle',
        type: 'number',
        flex: 1,
      },
      {
        field: 'startWeek',
        headerName: 'Start Week',
        type: 'number',
        flex: 1,
      },
      {
        field: 'currentCycleTime',
        headerName: 'Current Cycle',
        type: 'number',
        flex: 1,
      },
      {
        field: 'currentStartWeek',
        headerName: 'Current Start Week',
        type: 'number',
        flex: 1,
      },
    ]
  }, [data])

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'SKU',
      children: [
        { field: 'productCode' },
        { field: 'description' },
        { field: 'None' },
      ],
    },
    {
      groupId: 'Current',
      children: [{ field: 'currentCycleTime' }, { field: 'currentStartWeek' }],
    },
    {
      groupId: 'Proposed',
      children: [{ field: 'cycle' }, { field: 'startWeek' }],
    },
  ]

  return (
    <div className={classes.comparisonTable}>
      <MUITable
        rows={tableData}
        columns={columns}
        rowHeight={40}
        showCellVerticalBorder
        showColumnVerticalBorder
        columnGroupingModel={columnGroupingModel}
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pagination
        pageSizeOptions={[20, 30, 50]}
        disableRowSelectionOnClick
        experimentalFeatures={{ columnGrouping: true }}
        columnHeaderHeight={40}
      />
    </div>
  )
}
