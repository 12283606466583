import { Typography } from '@material-ui/core'
import { TotalCostGraph } from './TotalCost/TotalCost.graph'
import { DetailedAnalysisViewProps } from './DetailedAnalysis.types'
import { CompareScenariosTable } from 'Features/CompareScenarios/CompareScenarios.table'
import { InventoryDurationGraph } from './InventoryDuration/InventoryDuration.graph'
import { useStyles } from "../../ViewScenario/ViewScenario.styles"


export const ScenarioSummary = ({
  totalCostGraph,
  compareScenariosData,
  disabledTableRows,
  handleRowClick,
  costOutputs,
  inventoryOutputs,
}: DetailedAnalysisViewProps) => {
  // Decided to use total scenario cost from cost outputs data for total cost graph
  const classes = useStyles()
  const totalCostData = costOutputs?.map((item) =>
    item?.totalScenarioCost ? item?.totalScenarioCost : 0
  )

  if (totalCostData?.length <= 8) {
    for (let i = totalCostData?.length; i <= 8; i++) {
      totalCostData.push(0)
    }
  }

  const scenarioNames = totalCostGraph?.map((item) =>
    item?.label ? item?.label : ''
  )

  const bauDays = inventoryOutputs?.map((item) =>
    item?.bauDays ? item?.bauDays : 0
  )
  if (bauDays?.length <= 8) {
    for (let i = bauDays?.length; i <= 8; i++) {
      bauDays.push(0)
    }
  }

  return (
    <div className={classes.summaryContainer}>
      <div className={classes.summaryCharts}>
        <div style={{width:"50%"}}>
          <Typography variant="h4">Total cost</Typography>
          <div>
            <TotalCostGraph
              xAxisData={scenarioNames}
              totalCost={totalCostData}
            />
          </div>
        </div>
        <div style={{width:"50%"}}>
          <Typography variant="h4">Inventory duration(BAU)</Typography>
          <div>
            <InventoryDurationGraph
              xAxisData={scenarioNames}
              bauDays={bauDays}
            />
          </div>
        </div>
      </div>

      <div className={classes.summaryTable}>
        <CompareScenariosTable
          data={compareScenariosData}
        />
      </div>
    </div>
  )
}
