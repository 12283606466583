import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  select: {
    marginLeft: '20px',
    backgroundColor: 'white',
    width: '200px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '16px',
    marginLeft: '30px',
  },
  downloadButton: {
    backgroundColor: theme.palette.common.white,
  },
  iconButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
  },
  tableWrapper: {
    width: '70%',
    paddingRight: '20px',
  },
  graphWrapper: {
    width: '30%',
  },
  iconButtonDisabled: {
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.common.white,
  },
  sequenceBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  '@keyframes flash': {
    '0%': { backgroundColor: theme.palette.secondary.main },
    '100%': { backgroundColor: 'white' },
  },
  flash: {
    animation: '$flash 1.5s',
  },
  saveButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: '5px',
  },
  tabsIndicator: {
    display: 'none',
  },
  tabContainer: {
    borderRadius: '4px',
    display: 'inline-flex',
    marginBottom: '2px',
    height: '30px',
    padding: '3px',
  },
  tab: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    paddingTop: '10px',
    transition: 'transform 0.3s ease',
    transform: 'translateY(3px)',
    '&.Mui-selected': {
      backgroundColor: 'white',
      borderTop: `3px solid ${theme.palette.primary.main}`,
      borderBottom: 'none !important',
      boxShadow: '0px -1px 5px rgba(0,0,0,0.2)',
      transform: 'translateY(0)',
    },
  },
  runTimeTable: {
    minWidth: 600,
    '& .table.zero': {
      backgroundColor: 'lightGreen',
      backgroundImage: `linear-gradient(45deg, rgba(255,255,255,0.2) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, transparent 75%, transparent)`,
      backgroundSize: '10px 10px',
    },
    '& .table.foq': {
      backgroundColor: 'lightPink',
      backgroundImage: `linear-gradient(45deg, rgba(255,255,255,0.2) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, transparent 75%, transparent)`,
      backgroundSize: '10px 10px',
    },
    '& .table.aggregatedCell': {
      backgroundColor: 'white',
      fontWeight: 'bold',
    },
  },
  sequenceTable: {
    height: '100%',
    minWidth: 600,
  },
  comparisonTable: {
    height: '100%',
    minWidth: 1200,
  },
  aggregatedCell: {
    fontWeight: 'bold',
    color: 'blue',
  },
  paperContent: {
    display: 'flex',
    height: '100%',
    minHeight: '100%',
  },
}))
