export const CYCLE_MENU_ITEMS = [
  {
    value: 1,
    key: '1',
  },
  {
    value: 2,
    key: '2',
  },
  {
    value: 4,
    key: '4 ',
  },
  {
    value: 8,
    key: '8 ',
  },
]
