import { useState } from 'react'
import { StepCardProps } from 'Features/NewPlanTemplate/StepCard/StepCard.types'
import { Paper, Tabs, Box, Tab } from '@material-ui/core'
import { useStyles } from './StepCard.styles'
import { getStepsLabel } from 'Features/NewPlanTemplate/StepCard/StepCard.utils'
import { StepContentView } from 'Features/NewPlanTemplate/StepContentView/StepContentView'

export const StepCardView = ({
  dropdownOptions,
  workcentreSettingsData,
}: StepCardProps) => {
  const [activeStep, setActiveStep] = useState(0)

  const steps = getStepsLabel()

  const classes = useStyles()
  return (
    <>
      <Paper elevation={0} square className={classes.wrapper}>
        <Box>
          <Tabs
            value={activeStep}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => {
              setActiveStep(value)
            }}
            variant="fullWidth"
            classes={{ root: classes.tabs }}
          >
            {steps.map((label: string) => {
              return <Tab key={label} label={label} />
            })}
          </Tabs>
        </Box>
        <Box className={classes.stepContentView}>
          <StepContentView
            step={activeStep}
            dropdownOptions={dropdownOptions}
            workcentreSettingsData={workcentreSettingsData}
          />
        </Box>
      </Paper>
    </>
  )
}
