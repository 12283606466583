import { MsalAuthProvider, LoginType } from 'react-aad-msal'
const tenantID: string = process.env.TENANT_ID as string
const applicationID: string = process.env.APP_ID as string
const redirectUri: string = `${window.location.origin}/tenants` as string
const signInAuthority = `https://login.microsoftonline.com/${tenantID}`

// Msal Configurations
const config = {
  auth: {
    authority: signInAuthority,
    clientId: applicationID,
    redirectUri: redirectUri,
    validateAuthority: false,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  } as const,
}

//Authentication Parameters
const authenticationParameters = {
  scopes: [`${applicationID}/.default`],
}

// Options
const options = {
  loginType: LoginType.Redirect,
  // tokenRefreshUri: window.location.origin + "/",
}

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
)

export const getBearerToken = async () => {
  return await authProvider.getIdToken()
}

export const getUserId = async () => {
  return await authProvider.getAccount().accountIdentifier
}

export const getUserEmail = async () => {
  return await authProvider.getAccount().userName
}

export const getUsername = async () => {
  return await authProvider.getAccount().name
}

export const sessionLogout = async () => {
  await authProvider.logout()
}
