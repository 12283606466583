export const excludePathsFromBreadcrumbs = [
  '/',
  '/tenants',
  '/select-tenant',
  '/projects/:projectId/snapshots/',
  '/projects/:projectId/snapshots/:snapshotId',
  '/projects/:projectId/snapshots/:snapshotId/scenarios',
  '/projects/:projectId/snapshots/:snapshotId/summary/',
  '/projects/:projectId/snapshots/:snapshotId/summary/:planTemplateId',
  '/projects/:projectId/scenarios/:scenarioId/',
  '/projects/:projectId/snapshots/:snapshotId/summary/:planTemplateId/scenarios',
  '/projects/:projectId/snapshots/:snapshotId/summary/:planTemplateId/scenarios/:scenarioId/:sourceSiteId/',
  '/projects/:projectId/scenarios/',
  '/projects/:projectId/compare-all-scenarios/view-scenario',
  '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/',
  '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId/network-replenishment-settings/:sourceSiteId',
  '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId/network-replenishment-settings/inventory-analysis/',
  '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId/network-replenishment-settings/inventory-analysis/:sourceSiteId/',
  '/projects/:projectId/scenarios/:scenarioId/replenishment-policy-inputs/:replenishmentPolicyId/network-replenishment-settings/inventory-analysis/:sourceSiteId/:siteKey/sku-group-inventory-analysis',
  '/projects/:projectId/snapshots/:snapshotId/edit/:planTemplateId/:replenishmentPolicyId/scenarios/',
  '/projects/:projectId/snapshots/:snapshotId/edit/:planTemplateId/:replenishmentPolicyId/',
  '/projects/:projectId/snapshots/:snapshotId/edit/:planTemplateId/',
  '/projects/:projectId/snapshots/:snapshotId/edit/',
]

function lowerFirstLetter(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1)
}

export const getBreadCrumbLabel = (
  labels: Record<string, unknown>,
  id: string
) => {
  if (labels[id]) {
    return labels[id]
  } else if (labels[lowerFirstLetter(id)]) {
    return labels[lowerFirstLetter(id)]
  } else {
    return id
  }
}

export const getBreadCrumbLabelSuffix = (path: string) => {
  return path ===
    '/projects/:projectId/snapshots/:snapshotId/summary/:planTemplateId/scenarios/:scenarioId' ||
    path ===
      '/projects/:projectId/snapshots/:snapshotId/summary/:planTemplateId/scenarios/:scenarioId/:sourceSiteId/:workcentreId'
    ? ' - Plan Template'
    : ''
}
