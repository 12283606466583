import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  tableContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: window.outerWidth + 500,
    minHeight: window.innerHeight - 580 > 300 ? window.innerHeight - 580 : 300,
  },
}))
