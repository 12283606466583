import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'
import { Page } from 'Common/Page/Page'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { PageHeader } from 'supplyvue-ui'
import { useStyles } from './PlanTemplate.styles'
import { ScenarioFiltersView } from './ScenarioFilters/ScenarioFilters.view'
import { PlanTemplatePageParams } from './PlanTemplate.types'
import {
  useSourceSitesData,
  useScenarioSettingsData,
  useWorkcentreSettingsData,
  useDropdownOptionsData,
} from './PlanTemplate.data'
import { Loader } from 'Common/Loader/Loader'
import {
  SourceSiteOption,
  WorkCenterOption,
} from './ScenarioFilters/ScenarioFilters.types'
import { TemplateSettingsView } from './TemplateSettings/TemplateSettings.view'
import {
  useFetchPreferredMethod,
  useTemplateSummary,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.data'
import { usePlanTemplateGenerationState } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.state'
import {
  SET_IS_TEMPLATE_GENERATING,
  SET_WORKCENTRE_CAPACITY,
  SET_DEFAULT_WORKCENTRE_CAPACITY,
  SET_OPTIMIZATION,
  SET_CYCLE_SPEED_DATA,
  SET_SUMMARY_DATA,
  SET_IS_INITIAL_CYCLE_SPEED_AND_SUMMARY_DATA_FETCHED,
  SET_IS_CYCLE_SPEED_OR_SUMMARY_DATA_LOADING,
  SET_PLAN_TEMPLATE_GENERATION_VALIDATIONS,
  SET_BUFFER,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.constants'
import { LINE_ONLY } from './BatchSettings/BatchSettings.constants'
import { mapSummaryObjectToArray } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.utils'

export const PlanTemplatePage = () => {
  const {
    snapshotId,
    projectId,
    scenarioId,
    planTemplateId,
    sourceSiteId,
    workcentreId,
  } = useParams<PlanTemplatePageParams>()

  const { refetch: refetchBreadcrumbsData } = useBreadcrumbsData({
    project_id: projectId,
    work_center_id: workcentreId,
    scenario_id: scenarioId,
    plan_template_id: planTemplateId,
    base_source_site_id: sourceSiteId,
  })
  const history = useHistory()
  const [selectedSourceSite, setSelectedSourceSite] =
    useState<SourceSiteOption | null>(null)
  const [workcenters, setWorkcenters] = useState<WorkCenterOption[] | []>([])
  const [selectedWorkCenter, setSelectedWorkCenter] =
    useState<WorkCenterOption | null>(null)
  const [workcentreCapacity, setWorkcentreCapacity] = useState<number | string>(
    ''
  )

  // Get the preferredMethod optimization here and use it to set the initial state

  const { data: initialOptimization } = useFetchPreferredMethod(
    planTemplateId,
    sourceSiteId,
    workcentreId
  )

  useEffect(() => {
    updatePlanTemplateGenerationState({
      type: SET_OPTIMIZATION,
      payload: initialOptimization,
    })
  }, [initialOptimization])

  const [planTemplateGenerationState, updatePlanTemplateGenerationState] =
    usePlanTemplateGenerationState({
      buffer: '0',
      defaultWorkCenterCapacity: '',
      workcentreCapacity: '',
      combinedBuffer: '0',
      combinedWorkcentreCapacity: '',
      optimization: initialOptimization,
      // Should this be set to true or false?
      isTemplateGenerating: false,
      planTemplateGererationValidations: [],
      isInitialCycleSpeedAndSummaryDataFetched: false,
      isCycleSpeedOrSummaryDataLoading: false,
    })

  const { optimization } = planTemplateGenerationState

  const [graphData, setGraphData] = useState([])
  const { data: dropdownOptions } = useDropdownOptionsData(snapshotId)

  const { data: sourceSites, isLoading: isSourceSitesLoading } =
    useSourceSitesData(projectId, scenarioId)

  useEffect(() => {
    if (sourceSites) {
      const selectedSourceSiteFromSummaryPage =
        sourceSites.baseSourceSites.filter(
          (sourceSite: SourceSiteOption) =>
            sourceSite.value === parseInt(sourceSiteId)
        )[0]
      const selectedWorkcentreFromSummaryPage =
        selectedSourceSiteFromSummaryPage.workcentres.filter(
          (workcenter: WorkCenterOption) =>
            workcenter.key === parseInt(workcentreId)
        )[0]
      setSelectedSourceSite(selectedSourceSiteFromSummaryPage)
      setWorkcenters(selectedSourceSiteFromSummaryPage.workcentres)
      setSelectedWorkCenter(selectedWorkcentreFromSummaryPage)
    }
  }, [sourceSites, sourceSiteId, workcentreId])

  const {
    data: scenarioSettings,
    isLoading: isScenarioSettingsLoading,
    isFetchedAfterMount: isScenarioSettingsFetched,
  } = useScenarioSettingsData(projectId, planTemplateId)

  const {
    isLoading: isWorkcentreSettingsLoading,
    data: workcentreSettings,
    refetch,
    isFetching: isWorkcentreSettingsFetching,
    isFetchedAfterMount: isWorkcenterSettingsFetched,
  } = useWorkcentreSettingsData(
    snapshotId,
    projectId,
    planTemplateId,
    selectedSourceSite?.value,
    selectedWorkCenter?.key
  )

  useEffect(() => {
    if (workcentreSettings) {
      setWorkcentreCapacity(workcentreSettings?.workcentreCapacity)

      updatePlanTemplateGenerationState({
        type: SET_WORKCENTRE_CAPACITY,
        payload: workcentreSettings.workcentreCapacity,
      })
    }
  }, [workcentreSettings])

  const templateBasedOn = workcentreSettings?.settings?.templateBasedOn

  useEffect(() => {
    if (selectedWorkCenter?.key) {
      refetch()
      refetchBreadcrumbsData()
    }
  }, [refetch, selectedWorkCenter?.key, refetchBreadcrumbsData])

  const onMergeSuccess = (
    isGenerating: boolean,
    capacity: number,
    bufferValue: number,
    optimization: string
  ) => {
    if (isGenerating) {
      updatePlanTemplateGenerationState({
        type: SET_IS_TEMPLATE_GENERATING,
        payload: true,
      })
    } else {
      updatePlanTemplateGenerationState({
        type: SET_OPTIMIZATION,
        payload: optimization,
      })
      updatePlanTemplateGenerationState({
        type: SET_IS_TEMPLATE_GENERATING,
        payload: false,
      })
      updatePlanTemplateGenerationState({
        type: SET_BUFFER,
        payload: bufferValue,
      })
    }
  }

  const onMergeError = () => {
    updatePlanTemplateGenerationState({
      type: SET_IS_TEMPLATE_GENERATING,
      payload: false,
    })
  }

  const {
    data: mergeData,
    isLoading: isMergeLoading,
    isFetched: isMergeDataFetched,
  } = useTemplateSummary(
    projectId,
    planTemplateId,
    parseInt(sourceSiteId),
    parseInt(workcentreId),
    optimization,
    templateBasedOn === LINE_ONLY,
    onMergeSuccess,
    onMergeError
  )

  useEffect(() => {
    if (mergeData) {
      if (mergeData?.templateGenerationStatus == 'COMPLETED') {
        const summary = mapSummaryObjectToArray(
          mergeData.weeklySummary?.summary
        )

        let weeklySummary = { ...mergeData.weeklySummary, summary }
        updatePlanTemplateGenerationState({
          type: SET_SUMMARY_DATA,
          payload: weeklySummary,
        })

        setGraphData(mergeData.detailsGraph?.data)
        let cycleData = mergeData.cycles
        updatePlanTemplateGenerationState({
          type: SET_CYCLE_SPEED_DATA,
          payload: cycleData,
        })
      } else {
        updatePlanTemplateGenerationState({
          type: SET_SUMMARY_DATA,
          payload: [],
        })

        setGraphData([])
      }
    }
  }, [mergeData])

  useEffect(() => {
    if (isMergeLoading && updatePlanTemplateGenerationState)
      updatePlanTemplateGenerationState({
        type: SET_IS_CYCLE_SPEED_OR_SUMMARY_DATA_LOADING,
        payload: true,
      })
    else {
      updatePlanTemplateGenerationState({
        type: SET_IS_CYCLE_SPEED_OR_SUMMARY_DATA_LOADING,
        payload: false,
      })
    }
  }, [isMergeLoading, updatePlanTemplateGenerationState])

  useEffect(() => {
    if (isMergeDataFetched && updatePlanTemplateGenerationState)
      updatePlanTemplateGenerationState({
        type: SET_IS_INITIAL_CYCLE_SPEED_AND_SUMMARY_DATA_FETCHED,
        payload: true,
      })
  }, [isMergeDataFetched, updatePlanTemplateGenerationState])

  useEffect(() => {
    if (isMergeLoading && updatePlanTemplateGenerationState)
      updatePlanTemplateGenerationState({
        type: SET_IS_CYCLE_SPEED_OR_SUMMARY_DATA_LOADING,
        payload: true,
      })
    else {
      updatePlanTemplateGenerationState({
        type: SET_IS_CYCLE_SPEED_OR_SUMMARY_DATA_LOADING,
        payload: false,
      })
    }
  }, [isMergeLoading, updatePlanTemplateGenerationState])

  const classes = useStyles()

  const handleSourceSiteChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    option: SourceSiteOption | null
  ) => {
    updatePlanTemplateGenerationState({
      type: SET_WORKCENTRE_CAPACITY,
      payload: workcentreCapacity,
    })
    updatePlanTemplateGenerationState({
      type: SET_PLAN_TEMPLATE_GENERATION_VALIDATIONS,
      payload: [],
    })
    if (option?.value) {
      setSelectedSourceSite(option)
      setWorkcenters(option.workcentres)
      setSelectedWorkCenter(null)
    } else {
      setSelectedSourceSite(null)
      setSelectedWorkCenter(null)
    }
  }

  const handleWorkcenterChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    option: WorkCenterOption | null
  ) => {
    updatePlanTemplateGenerationState({
      type: SET_WORKCENTRE_CAPACITY,
      payload: workcentreCapacity,
    })
    updatePlanTemplateGenerationState({
      type: SET_PLAN_TEMPLATE_GENERATION_VALIDATIONS,
      payload: [],
    })
    if (option) {
      setSelectedWorkCenter(option)
      history.push(
        `/projects/${projectId}/snapshots/${snapshotId}/summary/${planTemplateId}/scenarios/${scenarioId}/${selectedSourceSite?.value}/${option.key}`
      )
    } else {
      setSelectedWorkCenter(null)
    }
  }

  const isSettingsLoading = () => {
    return (
      (!isScenarioSettingsFetched && isScenarioSettingsLoading) ||
      (!isWorkcenterSettingsFetched &&
        (isWorkcentreSettingsLoading || isWorkcentreSettingsFetching))
    )
  }

  const isSettingsNotFound = () => {
    return (
      workcentreSettings !== undefined &&
      selectedWorkCenter &&
      _.isEmpty(workcentreSettings)
    )
  }

  if (isSourceSitesLoading) {
    return <Loader />
  }

  return (
    <Page>
      <Box display="flex" flexDirection="column" flex="auto">
        <Box className={classes.wrapper}>
          <PageHeader
            title="Plan template"
            rightElement={
              <ScenarioFiltersView
                sourceSiteList={sourceSites?.baseSourceSites}
                workcenterList={workcenters}
                selectedSourceSite={selectedSourceSite}
                selectedWorkcenter={selectedWorkCenter}
                handleSourceSiteChange={handleSourceSiteChange}
                handleWorkcenterChange={handleWorkcenterChange}
              />
            }
          />
        </Box>
        {isSettingsLoading() ? (
          <Loader />
        ) : isSettingsNotFound() ? (
          <Typography>No work centre settings found</Typography>
        ) : (
          <TemplateSettingsView
            snapshotId={snapshotId}
            projectId={projectId}
            scenarioId={scenarioId}
            planTemplateId={planTemplateId}
            sourceSiteId={selectedSourceSite?.value}
            workcentreId={selectedWorkCenter?.key}
            isWorkcenterSelected={!!selectedWorkCenter}
            scenarioSettingsData={scenarioSettings}
            workcentreSettingsData={workcentreSettings}
            dropdownOptions={dropdownOptions}
            planTemplateGenerationState={planTemplateGenerationState}
            updatePlanTemplateGenerationState={
              updatePlanTemplateGenerationState
            }
            graphData={graphData}
          />
        )}
      </Box>
    </Page>
  )
}
