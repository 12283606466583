import { requestV2 } from 'Features/Auth/axios-client'
import { useQuery } from 'react-query'

const fetchSegmentGraphData = async (
  replenishmentPolicyId: string,
  sourceSiteKey: string,
  replenishmentTypeFilter: string,
  cycleTimeFilter: number | '',
  workcentreKey: number | '',
  siteKey: number,
  method: string
) => {
  const replenishment_cycle_filter = cycleTimeFilter
    ? `&replenishment_cycle_filter=${cycleTimeFilter}`
    : ''
  const replenishment_type_filter = replenishmentTypeFilter
    ? `&replenishment_type_filter=${replenishmentTypeFilter}`
    : ''
  const workcentre_key = workcentreKey ? `&workcentre_key=${workcentreKey}` : ''
  const data = await requestV2({
    url: `/replenishment-policies/${replenishmentPolicyId}/source-sites/${sourceSiteKey}/segment-analysis?site=${siteKey}&method=${method}${workcentre_key}${replenishment_type_filter}${replenishment_cycle_filter}`,
  })

  return data
}
export const useSegmentGraphData = (
  replenishmentPolicyId: string,
  sourceSiteKey: string,
  replenishmentTypeFilter: string,
  cycleTimeFilter: number | '',
  workcentreKey: number | '',
  siteKey: number,
  method: string
) => {
  return useQuery(
    [
      'segment-difference-graph-data',
      replenishmentPolicyId,
      sourceSiteKey,
      siteKey,
      workcentreKey,
      replenishmentTypeFilter,
      cycleTimeFilter,
      method,
    ],

    () =>
      fetchSegmentGraphData(
        replenishmentPolicyId,
        sourceSiteKey,
        replenishmentTypeFilter,
        cycleTimeFilter,
        workcentreKey,
        siteKey,
        method
      ),
    {
      select: (data) => data?.data?.data,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  )
}
