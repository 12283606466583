import React, { useState, useEffect, useRef } from 'react'
import { Paper, MenuList, MenuItem, ListItemText, Box } from '@material-ui/core'
import { useStyles } from './ScenarioCard.styles'
import MoreHorizeIcon from '@material-ui/icons/MoreHoriz'
import {
  UserMenuItem,
  MenuPropTypes,
  ContextMenuPropsType,
} from './ScenarioCard.types'

export const MenuButton = ({ menuData }: ContextMenuPropsType) => {
  const classes = useStyles()
  const [showMenuStatus, setShowMenuStatus] = useState(false)
  const timerRef = useRef<null | NodeJS.Timeout>(null)

  const menuClickAction = () => {
    const status = !showMenuStatus
    setShowMenuStatus(status)

    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
  }

  const handleContextMenuClick = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault()
    event.stopPropagation()
    window.dispatchEvent(new Event('usercontextmenuintable'))
    timerRef.current = setTimeout(() => menuClickAction(), 10)
    menuClickAction()
  }
  return (
    <>
      <Box className={classes.menuContainer}>
        {!showMenuStatus && (
          <MoreHorizeIcon
            onClick={handleContextMenuClick}
            name="HorizontalIcon"
            data-type-attr="HorizontalIcon"
            aria-label="HorizontalIcon"
            id="HorizontalIcon"
          />
        )}
      </Box>
      {showMenuStatus && (
        <Menu setShowMenuStatus={setShowMenuStatus} menuItems={menuData} />
      )}
    </>
  )
}

const Menu = ({ setShowMenuStatus, menuItems }: MenuPropTypes) => {
  const classes = useStyles()

  const processClick = () => {
    setShowMenuStatus(false)
  }

  useEffect(() => {
    window.addEventListener('usercontextmenuintable', processClick)
    window.addEventListener('click', processClick)
    window.addEventListener('wheel', processClick)
    window.addEventListener('scroll', processClick)
    return () => {
      window.removeEventListener('click', processClick)
      window.removeEventListener('scroll', processClick)
      window.removeEventListener('wheel', processClick)
      window.removeEventListener('usercontextmenuintable', processClick)
    }
  }, [])

  return (
    <Paper className={classes.paperStyle}>
      {menuItems && (
        <MenuList>
          {menuItems.map((menuItem: UserMenuItem, index: number) => {
            return (
              <MenuItem key={`#menuItem${index}`}>
                <ListItemText
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.preventDefault()
                    event.stopPropagation()
                    processClick()
                    menuItem.action(menuItem.data)
                  }}
                >
                  {menuItem.text}
                </ListItemText>
              </MenuItem>
            )
          })}
        </MenuList>
      )}
    </Paper>
  )
}
