import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@material-ui/core'
import { ProductChangeoverListProps } from './FileUploadErrorView.types'
import { useStyles } from './FileUploadErrorView.styles'

export const FileUploadErrorView = ({
  uploadFileData,
}: ProductChangeoverListProps) => {
  const classes = useStyles()

  if (uploadFileData) {
    const { valid, rowSummary } = uploadFileData
    if (!valid) {
      return (
        <Box width="100%" mr={2}>
          <List className={classes.listWrapper} subheader={<li />}>
            {Object.keys(rowSummary).map((row: string | number) => (
              <li key={`section-${row}`} className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader className={classes.listText}>
                    {`Row ${row}`}
                  </ListSubheader>
                  {rowSummary[row].map((item: string) => (
                    <ListItem key={`item-${item}`}>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </ul>
              </li>
            ))}
          </List>
        </Box>
      )
    }
  }

  /** Render the list of changeovers */
  return <></>
}
