import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  footer: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  select: {
    marginLeft: '20px',
    backgroundColor: 'white',
    width: '200px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  handlingCostButton: {
    marginRight: '16px',
  },
  chosenMethodDropdown: {
    backgroundColor: 'white',
    width: '60px',
    height: '30px',
  },
  safetyStockDropdown: {
    backgroundColor: 'white',
    width: '160px',
    height: '30px',
  },
  progress: {
    marginRight: '8px',
  },
}))
