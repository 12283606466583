export const SALES_PER_MOQ_INITIAL_VALUE = 'not applicable'

export const INITIAL_STATE = {
  batchGroupingCode: '',
  salesRate: '',
  runRate: '',
  minimumOrderQuantity: '',
  minimumOrderIncrement: '',
  minimumCycle: '',
  maximumCycle: '',
  salesWeeksPerMOQ: '',
  replenishmentTypeId: '',
  productionUnitOfMeasure: '',
  runRateUnitOfMeasure: '',
  replenishmentType: '',
  include: false,
}
