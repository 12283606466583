import { Box } from '@material-ui/core'
import { KPITable } from './KPI.table'
import { useStyles } from './KPI.styles'
import { KPIViewProps } from './KPI.types'
export const KPIView = ({ tableData }: KPIViewProps) => {
  const classes = useStyles({ innerWidth: window.innerWidth })

  return (
    <Box className={classes.tableWrapper}>
      <KPITable data={tableData} />
    </Box>
  )
}
