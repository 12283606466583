import { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ErrorBoundary } from 'Features/Error/ErrorBoundary'
import { Header, Footer } from 'supplyvue-ui'
import { ShellProps } from './Layout.types'
import { brandLogoStyles, useStyles } from './Layout.styles'
import brandLogo from 'Assets/images/modelling-brand-logo.png'
import SwitchIcon from 'Assets/icons/switch.svg'
import { getUsername } from 'Features/Auth/auth'
import { Breadcrumbs } from 'Common/Breadcrumbs/Breadcrumbs'

export const Shell = ({ children }: ShellProps) => {
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const tenantName = localStorage.getItem('tenantName') || ''
  const [loadingAuthData, setLoadingAuthData] = useState(true)
  const [userName, setUserName] = useState('')

  useEffect(() => {
    const fetchUserName = async () => {
      const userName = await getUsername()
      setUserName(userName)
      setLoadingAuthData(false)
    }
    fetchUserName()
  }, [])

  useEffect(() => {
    if (!tenantName && history.location.pathname !== '/tenants')
      window.location.href = `${window.location.origin}/tenants`
    setLoading(false)
  }, [])

  useEffect(() => {
    if (window.location.pathname === '/tenants') {
      localStorage.removeItem('tenantId')
      localStorage.removeItem('tenantsCount')
      localStorage.removeItem('tenantName')
    }
  }, [])

  if (loadingAuthData || loading) {
    return null
  }
  const isTenantSwitchEnabled = (): boolean => {
    const count = localStorage.getItem('tenantsCount') as string
    const tenantsCount = parseInt(count)
    return tenantsCount > 1
  }

  return (
    <Box className={classes.app}>
      <Header
        brandLogo={brandLogo}
        brandLogoStyles={brandLogoStyles}
        profileText={`Welcome ${userName}`}
        tenantName={tenantName}
        switchIcon={<SwitchIcon />}
        handleBrandLogoClick={() => tenantName && history.push('/')}
        handleTenantSwitchClick={() => history.push('/select-tenant')}
        isClickable={isTenantSwitchEnabled()}
      />
      <ErrorBoundary>
        <Box component="main" className={classes.main}>
          <Breadcrumbs />
          {children}
        </Box>
      </ErrorBoundary>

      <Footer
        copyrightText="All rights reserved"
        privacyText="Privacy Notice"
        cookiesText="Cookies"
        handlePrivacyClick={() =>
          window.open('https://www.supplyvue.com/privacy-notice/')
        }
        handleCookiesClick={() =>
          window.open('https://www.supplyvue.com/cookies/')
        }
      />
    </Box>
  )
}
