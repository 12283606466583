import { Box, Typography } from '@material-ui/core'
import { ProductInformationItemProps } from './ProductInformationItem.types'

export const ProductInformationItem = ({
  label,
  value,
}: ProductInformationItemProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h4">{label}</Typography>
      <Typography>{value}</Typography>
    </Box>
  )
}
