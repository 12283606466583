import { useState, useEffect } from 'react'

export const useMandatoryFieldValidation = (
  value: string | number,
  isNumber = false,
  fieldName: string
) => {
  const [fieldValue, setFieldValue] = useState(value)
  const [error, setError] = useState('')

  const MANDATORY_ERROR_MESSAGE = '* mandatory field'
  const ZERO_VALUE_ERROR_MESSAGE = `${fieldName} should be greater than 0`

  useEffect(() => {
    if (fieldValue === '') setError(MANDATORY_ERROR_MESSAGE)
    if (isNumber && parseFloat(fieldValue as string) === 0)
      setError(ZERO_VALUE_ERROR_MESSAGE)
    if (
      isNumber &&
      error &&
      fieldValue !== '' &&
      fieldValue !== '0' &&
      parseFloat(fieldValue as string) !== 0
    )
      setError('')
    if (!isNumber && error && fieldValue !== '') setError('')
  }, [fieldValue])

  return {
    error,
    setFieldValue,
  }
}
