import { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import { EditableCell } from 'supplyvue-ui'
import { handleCostOfCapitalValidation } from '../ProjectDetails/ProjectDetails.utils'
import { useStyles } from './CostOfCapital.style'
import { CostOfCapitalProps } from './CostOfCapital.types'

export const CostOfCapital = ({
  snapshotId,
  costOfCapital,
  handleCostOfCapitalUpdate,
  isCostOfCapitalLoading,
}: CostOfCapitalProps) => {
  const [
    costOfCapitalFieldValue,
    setCostOfCapitalFieldValue,
  ] = useState<number>(costOfCapital)

  useEffect(() => {
    if (snapshotId && costOfCapitalFieldValue !== costOfCapital) {
      handleCostOfCapitalUpdate(snapshotId, costOfCapitalFieldValue)
    }
  }, [costOfCapitalFieldValue, snapshotId])

  const updateCostOfCapitalField = (value: number | string) => {
    const newValue = typeof value === 'string' ? parseInt(value) : value
    setCostOfCapitalFieldValue(newValue)
  }

  const classes = useStyles()

  return (
    <>
      <Typography variant="h4" className={classes.costOfCapital}>
        Cost of capital:
      </Typography>
      <EditableCell
        value={isCostOfCapitalLoading ? costOfCapitalFieldValue : costOfCapital}
        isLoading={isCostOfCapitalLoading}
        updateData={updateCostOfCapitalField}
        isNumeric
        inputClassName={classes.textBox}
        suffix="%"
        handleValidation={handleCostOfCapitalValidation}
      />
    </>
  )
}
