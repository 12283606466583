import { Table, ColumnFilter, multiSelectFilter } from 'supplyvue-ui'
import { Column } from 'react-table'
import { Loader } from 'Common/Loader/Loader'
import {
  NewDemandScenarioTableColumns,
  NewDemandScenarioTableProps,
} from './NewDemandScenarioTable.types'

export const NewDemandScenarioTable = ({
  isLoading,
  data = [],
  getFilteredRows,
  allFilteredRowsLength,
}: NewDemandScenarioTableProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 300
    const availableHeight = window.innerHeight - 480
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }
  const columns: Column<NewDemandScenarioTableColumns>[] = [
    {
      Header: 'SKU group code',
      accessor: 'skuGroup',
      disableSortBy: true,
      Filter: ColumnFilter,
      filter: multiSelectFilter,
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      disableSortBy: true,
      Filter: ColumnFilter,
      filter: multiSelectFilter,
    },
    {
      Header: 'Segment',
      accessor: 'segment',
      disableSortBy: true,
      Filter: ColumnFilter,
      filter: multiSelectFilter,
    },
    {
      Header: 'Profit centre',
      accessor: 'profitCentre',
      disableSortBy: true,
      Filter: ColumnFilter,
      filter: multiSelectFilter,
    },
    {
      Header: 'Y1 Q1',
      accessor: 'Y1Q1',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Y1 Q2',
      accessor: 'Y1Q2',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Y1Q3',
      accessor: 'Y1Q3',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Y1 Q4',
      accessor: 'Y1Q4',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Y2 Q1',
      accessor: 'Y2Q1',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Y2 Q2',
      accessor: 'Y2Q2',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Y2 Q3',
      accessor: 'Y2Q3',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Y2 Q4',
      accessor: 'Y2Q4',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Y3 Q1',
      accessor: 'Y3Q1',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
  ]
  if (isLoading) {
    return <Loader />
  }

  return (
    <Table
      data={data}
      height={getTableHeight()}
      columns={columns}
      stickyHeader={true}
      cellPadding={'5px 5px'}
      getFilteredRows={getFilteredRows}
      pageSizeOptions={[
        10,
        15,
        20,
        { label: 'All', value: allFilteredRowsLength },
      ]}
      defaultPageSize={10}
      isTablePaginated={true}
      autoResetFilters={false}
    />
  )
}
