import React from 'react'
import { useParams } from 'react-router-dom'
import { Page } from 'Common/Page/Page'
import { Box } from '@material-ui/core'
import { PageHeader } from 'supplyvue-ui'
import { PlanTemplatePageParams } from 'Features/NewPlanTemplate/NewPlanTemplate.types'
import { StepCardView } from 'Features/NewPlanTemplate/StepCard/StepCard.view'
import { useStyles } from './NewPlanTemplate.styles'
import {
  useDropdownOptionsData,
  useWorkcentreSettingsData,
} from 'Features/NewPlanTemplate/NewPlanTemplate.data'
import { Loader } from 'Common/Loader/Loader'

export const NewPlanTemplatePage = () => {
  const { snapshotId, projectId, planTemplateId } =
    useParams<PlanTemplatePageParams>()

  const { data: dropdownOptions, isLoading: isdropdownLoading } =
    useDropdownOptionsData(snapshotId)
  const { data: workcentreSettingsData, isLoading: isWcLoading } =
    useWorkcentreSettingsData(snapshotId, projectId, planTemplateId)

  const classes = useStyles()

  return (
    <Page>
      <Box className={classes.content}>
        <PageHeader title="Edit Settings" />
        {isdropdownLoading ||
        isWcLoading ||
        workcentreSettingsData.batchGroupingCodes ? (
          <Loader />
        ) : (
          <StepCardView
            dropdownOptions={dropdownOptions}
            workcentreSettingsData={workcentreSettingsData}
          />
        )}
      </Box>
    </Page>
  )
}
