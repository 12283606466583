import { Box } from '@material-ui/core'
import {
  ProductChangeoverProps,
  ProductChangeOverParams,
} from './ProductChangeover.types'
import { useDownloadLineData } from './ProductChangeOver.data'
import { useParams } from 'react-router-dom'
import { ProductChangeoverListView } from './ChangeoverList/ProductChangeoverList.view'
import { useEffect, useMemo, useState } from 'react'
import { Button, ToggleButton } from 'supplyvue-ui'
import { BatchGroupingToggle } from '../BatchChangeover/BatchChangeover.types'
import { ProductChangeoversTable } from './ProductChangeoverMatrix/ProductChangeoverMatrix.table'
import { InstructionsCardView } from '../BatchChangeover/InstructionsCard/InstructionsCard.view'
import { Loader } from 'Common/Loader/Loader'

export const ProductChangeoverView = ({
  uploadFile,
  isFileUploading,
  uploadFileData,
  changeoverCountLabel,
  changeoverTimeLabel,
  changeoverCostLabel,
  productChangeoverData,
  productChangeOverTime,
  productChangeOverCost,
  productChangeOverCarbonCost,
  productChangeOverId,
  updateProductChangeoverCells,
  isProductChangeoverFetched,
  isProductChangeoverSummaryLoading,
  refetchProductChangeoverSummaryData,
}: ProductChangeoverProps) => {
  const {
    planTemplateId,
    sourceSiteId,
    workcentreId,
  } = useParams<ProductChangeOverParams>()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [toggleValue, seTtoggleValue] = useState<BatchGroupingToggle>('time')
  const [
    productChangeOverTimeTableData,
    setProductChangeOverTimeTableData,
  ] = useState(productChangeOverTime)
  const [
    productChangeOverCostTableData,
    setProductChangeOverCostTableData,
  ] = useState(productChangeOverCost)

  const [
    productChangeOverCarbonCostTableData,
    setProductChangeOverCarbonCostTableData,
  ] = useState(productChangeOverCarbonCost)

  useEffect(() => {
    setProductChangeOverTimeTableData(productChangeOverTime)
    setProductChangeOverCostTableData(productChangeOverCost)
    setProductChangeOverCarbonCostTableData(productChangeOverCarbonCost)
  }, [
    productChangeOverTime,
    productChangeOverCost,
    productChangeOverCarbonCost,
  ])

  const handleSummaryButtonClick = () => {
    refetchProductChangeoverSummaryData()
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const getTableWidth = () => {
    return window.innerWidth - 136
  }

  const { refetch: downloadLineData } = useDownloadLineData(
    planTemplateId,
    sourceSiteId,
    workcentreId
  )

  const handleUploadFile = (file: File) => {
    uploadFile(file, 'product')
  }

  const updateTableData = (
    rowIndex: string,
    columnId: string,
    value: string | number
  ) => {
    let tableData: unknown[] = []
    if (toggleValue === 'time') {
      tableData = [...productChangeOverTimeTableData]
    } else if (toggleValue === 'cost') {
      tableData = [...productChangeOverCostTableData]
    } else if (toggleValue === 'carbon') {
      tableData = [...productChangeOverCarbonCostTableData]
    }

    const updatedData = tableData.map((row: any, i: number) => {
      if (i === parseInt(rowIndex)) {
        return {
          ...row,
          [columnId]: parseFloat(value as string),
        }
      } else return row
    })

    if (toggleValue === 'time') {
      setProductChangeOverTimeTableData([...updatedData])
    } else if (toggleValue === 'cost') {
      setProductChangeOverCostTableData([...updatedData])
    } else if (toggleValue === 'carbon') {
      setProductChangeOverCarbonCostTableData([...updatedData])
    }
  }

  const isTableDataAvailable =
    !!productChangeOverTimeTableData?.length ||
    !!productChangeOverCostTableData?.length ||
    !!productChangeOverCarbonCostTableData?.length

  const tableData = useMemo(() => {
    if (toggleValue === 'time') {
      return productChangeOverTimeTableData
    }
    if (toggleValue === 'cost') {
      return productChangeOverCostTableData
    }
    if (toggleValue === 'carbon') {
      return productChangeOverCarbonCostTableData
    }
  }, [
    toggleValue,
    productChangeOverCarbonCostTableData,
    productChangeOverCostTableData,
    productChangeOverTimeTableData,
  ])

  if (!isProductChangeoverFetched) {
    return <Loader />
  }

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          justifyContent={isTableDataAvailable ? 'space-between' : 'flex-end'}
          alignItems="center"
        >
          {isTableDataAvailable && (
            <Box style={{ height: '30px' }}>
              <ToggleButton
                buttonOne={{ label: 'Time', value: 'time' }}
                buttonTwo={{ label: 'Cost', value: 'cost' }}
                buttonThree={{ label: 'Carbon', value: 'carbon' }}
                value={toggleValue}
                handleChange={(event, value) => {
                  event.stopPropagation()
                  seTtoggleValue(value ?? 'time')
                }}
              />
            </Box>
          )}
          <Box display="flex" alignSelf="flex-end">
            {isTableDataAvailable && (
              <Button
                variant="text"
                onClick={handleSummaryButtonClick}
                style={{ marginRight: '16px' }}
              >
                Summary
              </Button>
            )}
            <InstructionsCardView
              isUploadingFile={isFileUploading}
              isChangeoverDataLoaded={isTableDataAvailable}
              handleDownloadFile={() => downloadLineData()}
              handleUploadFile={handleUploadFile}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          justifyContent={isTableDataAvailable ? 'flex-end' : 'space-between'}
          alignItems="center"
        >
          <ProductChangeoverListView
            isModalOpen={isModalOpen}
            handleModalClose={handleModalClose}
            changeoverCountLabel={changeoverCountLabel}
            changeoverTimeLabel={changeoverTimeLabel}
            changeoverCostLabel={changeoverCostLabel}
            changeoverData={productChangeoverData}
            uploadFileData={uploadFileData}
            isLoading={isProductChangeoverSummaryLoading}
          />
        </Box>
      </Box>
      {isTableDataAvailable && (
        <Box style={{ width: getTableWidth(), marginTop: '20px' }}>
          <ProductChangeoversTable
            toggleValue={toggleValue}
            data={tableData as undefined[]}
            productChangeOverId={productChangeOverId}
            isLoading={false}
            updateTableData={updateTableData}
            updateEditedCellValue={updateProductChangeoverCells}
          />
        </Box>
      )}
    </Box>
  )
}
