import { Box, Typography, Divider } from '@material-ui/core'
import { Button } from 'supplyvue-ui'
import { PlanDetailsViewProps } from './PlanDetailsView.types'
export const PlanDetailsView = ({
  handlePlanDetailsClick,
  isDisabled,
}: PlanDetailsViewProps) => {
  return (
    <Box marginBottom="10px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="10px"
      >
        <Box>
          <Typography variant="h3">Plan template summary</Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={handlePlanDetailsClick}
            disabled={isDisabled}
          >
            Template details
          </Button>
        </Box>
      </Box>
      <Divider />
    </Box>
  )
}
