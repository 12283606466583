import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '500px',
    maxHeight: '700px',
    height: '100%',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '64px',
    alignItems: 'flex-end',
    padding: '16px',
  },
  sidebarBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  clearFilters: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    paddingTop: '16px',
  },
  infoHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  divider: {
    height: '1px',
    background: '#006286',
    width: '100%',
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    //boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    width: 350,
    marginRight: '16px',
    marginBottom: '4px',
    backgroundColor: theme.palette.background.grey,
  },
  sideBarTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '32px',
  },
  sideBarFilters: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '16px',
    flex: 1,
    width: '100%',
    paddingTop: '32px',
    paddingRight: '24px',
  },
  skuContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  infoRowBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  roundDropdown: {
    width: '248px',
    marginTop: '5px',
    backgroundColor: 'white',
    borderRadius: 25,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  dropdown: {
    width: '248px',
    marginTop: '5px',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  autocomplete: {
    width: '248px',
    marginTop: '5px',
    backgroundColor: 'white',
    color: `${theme.palette.primary.main}`,
  },
  textField: {
    color: `${theme.palette.primary.main}`,
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '16px',
    marginBottom: '8px',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    padding: '16px',
    gap: '4px',
  },
  borderRight: {
    borderRight: `10px solid white`,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 900,
  },
}))
