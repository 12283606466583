import { useState, useEffect /*useMemo */ } from 'react'
export const useOrderValidation = (
  minQuantity: string | number | null,
  minIncrement: string | number | null
  //parameterOneName: string,
  //parameterTwoName: string
) => {
  const [minimumQuantity, setMinimumQuantity] = useState<
    string | number | null
  >(minQuantity)
  const [minimumIncrement, setMinimumIncrement] = useState<
    string | number | null
  >(minIncrement)
  const [minQuantityError, setMinQuantityError] = useState('')
  const [minIncrementError, setMinIncrementError] = useState('')
  // Commenting out code, because MOI and MOQ validations could be re-introduced.
  //const MIN_QUANTITY_ERROR_MESSAGE = `${parameterOneName} should be a multiple of ${parameterTwoName}`
  //const MIN_INCREMENT_ERROR_MESSAGE = `${parameterTwoName} should be a factor of ${parameterOneName}`
  const MIN_QUANTITY_MANDATORY_ERROR_MESSAGE = '* mandatory field'
  const MIN_INCREMENT_MANDATORY_ERROR_MESSAGE = '* mandatory field'

  // const isValuesInvalid = useMemo(() => {
  //   const minQuantityFloatValue = parseFloat(minimumQuantity as string)
  //   const minIncrementFloatValue = parseFloat(minimumIncrement as string)
  //   if (minQuantityFloatValue !== 0 || minIncrementFloatValue !== 0) {
  //     return minQuantityFloatValue === 0 && minIncrementFloatValue !== 0
  //       ? true
  //       : (minimumQuantity as number) % (minimumIncrement as number) !== 0 &&
  //           minimumQuantity !== '' &&
  //           minimumIncrement !== ''
  //   } else {
  //     return false
  //   }
  // }, [minimumQuantity, minimumIncrement])

  useEffect(() => {
    // if (isValuesInvalid) {
    //   setMinQuantityError(MIN_QUANTITY_ERROR_MESSAGE)
    // } else
    if (minimumQuantity === '' && minimumIncrement !== '') {
      setMinQuantityError(MIN_QUANTITY_MANDATORY_ERROR_MESSAGE)
    } else if (minimumQuantity !== '' && minimumIncrement === '') {
      setMinIncrementError(MIN_INCREMENT_MANDATORY_ERROR_MESSAGE)
    } else {
      setMinQuantityError('')
      setMinIncrementError('')
    }
  }, [
    minimumQuantity,
    //MIN_QUANTITY_ERROR_MESSAGE,
    MIN_QUANTITY_MANDATORY_ERROR_MESSAGE,
    MIN_INCREMENT_MANDATORY_ERROR_MESSAGE,
  ])

  useEffect(() => {
    if (minimumIncrement === '' && minimumQuantity !== '') {
      setMinIncrementError(MIN_INCREMENT_MANDATORY_ERROR_MESSAGE)
    } else if (minimumIncrement !== '' && minimumQuantity === '') {
      setMinQuantityError(MIN_QUANTITY_MANDATORY_ERROR_MESSAGE)
    } else {
      setMinQuantityError('')
      setMinIncrementError('')
    }
  }, [
    minimumIncrement,
    //MIN_INCREMENT_ERROR_MESSAGE,
    MIN_INCREMENT_MANDATORY_ERROR_MESSAGE,
    MIN_QUANTITY_MANDATORY_ERROR_MESSAGE,
  ])

  return {
    minQuantityError,
    minIncrementError,
    setMinimumIncrement,
    setMinimumQuantity,
  }
}
