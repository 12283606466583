import { DropDown } from 'supplyvue-ui'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from './DropDownParameter.styles'
import { DropDownParameterProps } from './DropDownParameter.types'

export const DropDownParameter = (props: DropDownParameterProps) => {
  const {
    name,
    handleDropdownChange,
    value,
    defaultLabel,
    label,
    options,
    isDisabled = false,
  } = props

  const classes = useStyles()
  return (
    <Box className={classes.parameter}>
      <Typography className={classes.dropDownLabel}>{label}:</Typography>
      <DropDown
        value={value}
        className={classes.formElement}
        menuItems={options}
        label={label}
        defaultLabel={defaultLabel}
        onChange={handleDropdownChange}
        name={name}
        disabled={isDisabled}
      />
    </Box>
  )
}
