import { Box, Typography } from '@material-ui/core'
import { Button, Modal } from 'supplyvue-ui'
import { useStyles } from './ScenarioAccess.styles'
import { ScenarioAccessModalProps } from './ScenarioAccess.types'
import { useScenarios } from './ScenarioAccess.data'
import { Scenario } from '../ProjectDetails/ProjectDetails.types'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
} from '@mui/material'
import { ScenarioAccessToggle } from './ScenarioAccessToggle.view'
import { useEffect, useState } from 'react'

export const ScenarioAccessModal = ({
  isModalOpen,
  handleModalClose,
  heading,
  projectId,
  isLoading,
  handleScenarioAccessApply,
  scenarioAccessConfig,
}: ScenarioAccessModalProps) => {
  console.log('scenarioAccessConfiguration', scenarioAccessConfig)
  const classes = useStyles()
  const [
    scenarioAccessConfiguration,
    setScenarioAccessConfiguration,
  ] = useState<{ [key: string]: 'public' | 'private' }>(scenarioAccessConfig)
  const { data: scenarios, isLoading: isScenarioDataLoading } = useScenarios(
    projectId
  )

  useEffect(() => {
    if (isModalOpen) {
      setScenarioAccessConfiguration(scenarioAccessConfig)
    } else {
      setScenarioAccessConfiguration({})
    }
  }, [isModalOpen, projectId])

  const handleScenarioAccessChange = (
    scenarioId: string,
    access: 'public' | 'private'
  ) => {
    setScenarioAccessConfiguration({
      ...scenarioAccessConfiguration,
      [scenarioId]: access,
    })
  }

  const handeleApply = () => {
    handleScenarioAccessApply(scenarioAccessConfiguration)
  }

  const Body = (
    <Box className={classes.contentWrapper}>
      <Typography className={classes.label} variant="h4">
        Please configure scenario access when the project becomes public.
      </Typography>
      <Box>
        {isScenarioDataLoading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Scenario</TableCell>
                  <TableCell align="center">Access</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scenarios?.map((row: Scenario) => (
                  <TableRow
                    key={row.scenarioId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.scenarioName}
                    </TableCell>
                    <TableCell align="center">
                      <ScenarioAccessToggle
                        access={
                          scenarioAccessConfig[row.scenarioId] ?? 'public'
                        }
                        scenarioId={row.scenarioId}
                        handleScenarioAccessChange={handleScenarioAccessChange}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  )

  const Footer = (
    <Box className={classes.footer}>
      <Button
        onClick={() => {
          handeleApply()
        }}
        className={classes.modalActionButton}
        label={isLoading ? 'Applying...' : 'Apply'}
        variant="contained"
        disabled={Object.keys(scenarioAccessConfiguration).length === 0}
      >
        {isLoading && (
          <CircularProgress
            color="inherit"
            size={16}
            className={classes.progress}
          />
        )}
        Apply
      </Button>
    </Box>
  )

  return (
    <Modal
      aria-labelledby={heading}
      header={heading}
      open={isModalOpen}
      handleClose={handleModalClose}
      body={Body}
      footer={Footer}
    />
  )
}
