import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  overlayHeading: {
    padding: '15px 0px',
  },
  iconButton: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  iconButtonDisabled: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0px',
    justifyContent: 'flex-end',
  },
  progress: {
    marginRight: '8px',
  },
  button: {
    display: 'flex',
    borderRadius: 0,
  },
  buttonRow: {
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '16px',
  },
  buttonGroup: {
    display: 'flex',
  },
}))
