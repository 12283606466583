import { useQuery } from 'react-query'
import { request, requestV2 } from 'Features/Auth/axios-client'
import { DROPDOWN_OPTIONS } from './PlanTemplate.constants'

/** Begin - Fetch source sites */
const fetchSourceSites = async (projectId: string, scenarioId: string) => {
  const { data } = await request({
    url: `/projects/${projectId}/scenarios/${scenarioId}/source-sites?with-workcentres=true`,
  })
  return data
}

export const useSourceSitesData = (projectId: string, scenarioId: string) => {
  return useQuery(
    ['source-sites', projectId, scenarioId],
    () => fetchSourceSites(projectId, scenarioId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  )
}
/** End - Fetch source sites */

/** Begin - Fetch Scenario Settings */

const fetchScenarioSettings = async (
  projectId: string,
  planTemplateId: string
) => {
  const { data } = await request({
    url: `/projects/${projectId}/plan-templates/${planTemplateId}/settings`,
  })
  return data
}

export const useScenarioSettingsData = (
  projectId: string,
  planTemplateId: string
) => {
  return useQuery(
    ['scenario-settings', projectId, planTemplateId],
    () => fetchScenarioSettings(projectId, planTemplateId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  )
}
/** End - Fetch Scenario Settings */

/** Begin - Fetch all settings */

const fetchWorkcentreSettings = async (
  snapshotId: string,
  projectId: string,
  planTemplateId: string,
  sourceSiteKey: number,
  workcentreKey: number
) => {
  const { data } = await request({
    url: `/plan-template/workcentre-settings`,
    method: 'post',
    data: {
      snapshotId,
      projectId,
      planTemplateId,
      sourceSiteKey,
      workcentreKey,
    },
  })

  return data
}

export const useWorkcentreSettingsData = (
  snapshotId: string,
  projectId: string,
  planTemplateId: string,
  sourceSiteKey = 0,
  workcentreKey = 0
) => {
  return useQuery(
    [
      'workcentre-settings',
      snapshotId,
      projectId,
      planTemplateId,
      sourceSiteKey,
      workcentreKey,
    ],
    () =>
      fetchWorkcentreSettings(
        snapshotId,
        projectId,
        planTemplateId,
        sourceSiteKey,
        workcentreKey
      ),
    {
      enabled: false,
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  )
}

/** End - Fetch all settings */

const fetchDropdownOptionsData = async (snapshotId: string) => {
  const { REPLENISHMENT_TYPE, SUPPLY_TYPE, UNIT_OF_MEASURE } = DROPDOWN_OPTIONS
  const { data } = await request({
    url: `/snapshots/${snapshotId}/mappings?option=${REPLENISHMENT_TYPE}&config=PlanTemplate&option=${SUPPLY_TYPE}&option=${UNIT_OF_MEASURE}`,
  })
  return data
}

export const useDropdownOptionsData = (snapshotId: string) => {
  return useQuery(
    ['dropdown-options', snapshotId],
    () => fetchDropdownOptionsData(snapshotId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  )
}

const fetchChangeOverData = async (
  planTemplateId: string,
  sourceSiteKey: number,
  workcentreKey: number,
  entity: string,
  action: boolean
) => {
  const data = await requestV2({
    url: `/plan-templates/changeovers`,
    method: 'post',
    data: {
      planTemplateId,
      sourceSiteKey,
      workcentreKey,
      entity,
      ...(action === true && { action: 'summary' }),
    },
  })
  return data
}

export const useProductChangeOverData = (
  planTemplateId: string,
  sourceSiteKey: number,
  workcentreKey: number
) => {
  return useQuery(
    ['product-change-over-data', planTemplateId, sourceSiteKey, workcentreKey],
    () =>
      fetchChangeOverData(
        planTemplateId,
        sourceSiteKey,
        workcentreKey,
        'product',
        false
      ),
    {
      select: (data) => data.data?.data,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  )
}

export const useBatchChangeOverData = (
  planTemplateId: string,
  sourceSiteKey: number,
  workcentreKey: number
) => {
  return useQuery(
    ['batch-change-over-data', planTemplateId, sourceSiteKey, workcentreKey],
    () =>
      fetchChangeOverData(
        planTemplateId,
        sourceSiteKey,
        workcentreKey,
        'batch',
        false
      ),
    {
      select: (data) => data.data?.data,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  )
}

export const useProductChangeOverSummaryData = (
  planTemplateId: string,
  sourceSiteKey: number,
  workcentreKey: number
) => {
  return useQuery(
    [
      'product-change-over-summary-data',
      planTemplateId,
      sourceSiteKey,
      workcentreKey,
    ],
    () =>
      fetchChangeOverData(
        planTemplateId,
        sourceSiteKey,
        workcentreKey,
        'product',
        true
      ),
    {
      select: (data) => data.data?.data?.productChangeOvers,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  )
}

export const useBatchChangeOverSummaryData = (
  planTemplateId: string,
  sourceSiteKey: number,
  workcentreKey: number
) => {
  return useQuery(
    [
      'batch-change-over-summary-data',
      planTemplateId,
      sourceSiteKey,
      workcentreKey,
    ],
    () =>
      fetchChangeOverData(
        planTemplateId,
        sourceSiteKey,
        workcentreKey,
        'batch',
        true
      ),
    {
      select: (data) => data.data?.data?.batchGroupingChangeOvers,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  )
}
