import { AzureAD } from 'react-aad-msal'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { authProvider } from 'Features/Auth/auth'
import { AppInsightsProvider } from 'Features/Error/AppInsightsProvider'
import { AppThemeProvider } from 'Features/App/AppThemeProvider'
import { Toaster } from 'supplyvue-ui'

export const AppProviders = ({ children }: { children: React.ReactNode }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  })

  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      <QueryClientProvider client={queryClient}>
        <AppThemeProvider>
          <Toaster />
          <AppInsightsProvider>{children}</AppInsightsProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </AppThemeProvider>
      </QueryClientProvider>
    </AzureAD>
  )
}
