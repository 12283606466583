import { useMemo, useState } from "react";
import { Table as MUITable } from 'Common/MUITable/MUITable'
import {
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowSelectionModel
} from "@mui/x-data-grid-premium";
import { BatchSettingsTableProps } from './BatchSettings.types'
import { BatchGroupingSettings } from 'Features/PlanTemplate/StepCard/StepCard.types'
import { UnitOfMeasureLabel } from 'Features/PlanTemplate/ProductSettings/UnitOfMeasureLabel/UnitOfMeasureLabel.view'
import { TEMPLATE_FOR_COMBINED_MAKER_AND_LINE } from './BatchSettings.constants'
import { Loader } from 'Common/Loader/Loader'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'
import { Button } from "supplyvue-ui"
import { CheckCircleOutlined, RadioButtonUnchecked, SettingsOutlined } from "@material-ui/icons"
import { round } from "lodash";
import { Tooltip, Typography } from "@material-ui/core";
import { AddTaskOutlined, PublishedWithChanges, RemoveCircleOutline, TaskAlt } from "@mui/icons-material";

export const BatchSettingsTable = ({
  data,
  setData,
  setSelectedRows,
  unitOfMeasure,
  templateBasedOn,
}: BatchSettingsTableProps) => {

  if (!data) {
    return <Loader />
  }

  const batchCodes = [
    ...new Set(
      data.map((item: BatchGroupingSettings) => {
        return item.batchGroupingCode
      })
    ),
  ]

  const repl_types = [
    ...new Set(
      data.map((item: BatchGroupingSettings) => {
        return item.replenishmentType
      })
    ),
  ]

  type keepColumn = 'keepCurrentStartWeek' | 'keepCurrentCycleTime'

  const toggleSelection = (id: number, field: keepColumn) => {
    setData((prevRows) =>
      prevRows.map((row: BatchGroupingSettings) => {
        if (row.id === id) {
          if (field === 'keepCurrentCycleTime') {
            return {
              ...row,
              [field]: !row[field],
              fixedCycleTime: !row[field] ? row.currentBatchCycleTime : -1
            }
          }

          return { ...row, [field]: !row[field] }
        }
        return row
      })
    );
  };

  type RenderStatusProps = {
    salesRate: number
    currentCycle: number
    minimumBatchSize: number
  }

  const RenderStatus = ({salesRate, currentCycle, minimumBatchSize} :RenderStatusProps) => {

    if (salesRate > 0) {
      if (currentCycle !== -1) {
        const naturalCycle = round(minimumBatchSize / salesRate , 1)
        if (naturalCycle > currentCycle){
          return (
            <Tooltip title="SKU has been slowed to FOQ">
              <PublishedWithChanges  sx={{color: 'orange'}} />
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title="Unchanged">
              <TaskAlt sx={{color: 'green'}}></TaskAlt>
            </Tooltip>
          )
        }
      } else {
        return (
          <Tooltip title="New Sku">
            <AddTaskOutlined sx={{color: '#44a6c6'}}></AddTaskOutlined>
          </Tooltip>
        )
      }
    } else {
      if (currentCycle === -1) {
        return (
          <Tooltip title="Unchanged">
            <TaskAlt sx={{color: 'green'}}></TaskAlt>
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title="Removed from template">
            <RemoveCircleOutline sx={{color: 'red'}}/>
          </Tooltip>
        )
      }
    }
  }

  const renderCheckBox = (value: boolean, id: number, field: keepColumn) => {
    return (
      <div>
        {value ? (
          <CheckCircleOutlined
            style={{
              cursor: 'pointer',
            }}
            color={"primary"}
            onClick={() => toggleSelection(id, field)}
          />
        ) : (
          <RadioButtonUnchecked
            style={{
              cursor: 'pointer',
            }}
            color={"primary"}
            onClick={() => toggleSelection(id, field)}
          />
        )}
      </div>
    )
  }

  const toggleColumns = [
    'currentBatchCycleTime',
    'currentBatchStartWeek',
    'fixedBatchCycleTime',
    'fixedBatchStartWeek'
  ]

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    currentBatchCycleTime: false,
    currentBatchStartWeek: false,
    fixedBatchCycleTime: false,
    fixedBatchStartWeek: false,
  })

  const [settingsStatus, setSettingsStatus] = useState<boolean>(false)

  const handleToggleColumns = () => {
    const allVisible = toggleColumns.every((col) => columnVisibilityModel[col]);
    setSettingsStatus(!settingsStatus)

    const newVisibilityModel = toggleColumns.reduce((acc: object, col) => {
      acc[col] = !allVisible
      return acc
    }, {})

    setColumnVisibilityModel((prev) => ({
      ...prev,
      ...newVisibilityModel,
    }))
  }
  const MUIColumns: GridColDef[] | any[] = useMemo(() => {
    if (!data) {
      return []
    }

    const defaultColumns = [
      {
        headerName: 'Batch group code',
        field: 'batchGroupingCode',
        minWidth: 100,
        width: 350,
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: batchCodes,
              label: 'Search',
            },
          },
        ],
        renderCell: (params: GridCellParams) => {
          const { currentBatchCycleTime, minimumBatchSize, salesRate, batchGroupingCode } = params.row

          return (
            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
              <div style={{display: 'flex', flexDirection: 'column', width: '95%'}}>
                <Typography variant='h4'>{batchGroupingCode}</Typography>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', width: '5%', justifyContent: 'center', alignItems: 'center'}}>
               <RenderStatus salesRate={salesRate} currentCycle={currentBatchCycleTime} minimumBatchSize={minimumBatchSize} />
              </div>
            </div>
          )

        }
      },
      {
        headerName: 'Replenishment type',
        field: 'replenishmentType',
        minWidth: 100,
        type: 'string',
        flex: 1,
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: repl_types,
              label: 'Search',
            },
          },
        ],
      },
      {
        headerName: 'Sales rate',
        field: 'salesRate',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        field: 'naturalCycle',
        headerName: 'Natural Cycle',
        type: 'number',
        renderCell: (params: GridCellParams) => {
          const { minimumBatchSize, salesRate } = params.row
          return salesRate > 0 ? round( minimumBatchSize / salesRate , 1) : 0
        }
      },
      {
        headerName: 'Run rate',
        field: 'runRate',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'UoM',
        field: 'unitOfMeasure',
        minWidth: 100,
        type: 'string',
        renderCell: (params: any) => (
          <UnitOfMeasureLabel
            id={params.row.unitOfMeasureId}
            label={params.row.unitOfMeasure}
            unitOfMeasure={unitOfMeasure}
          />
        ),
        flex: 1,
      },
      {
        headerName: 'Minimum batch size',
        field: 'minimumBatchSize',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Minimum batch increment',
        field: 'minimumBatchSizeIncrement',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'UoM',
        field: 'unitOfMeasure2',
        minWidth: 100,
        type: 'string',
        renderCell: (params: any) => (
          <UnitOfMeasureLabel
            id={params.row.unitOfMeasureId}
            label={params.row.unitOfMeasure}
            unitOfMeasure={unitOfMeasure}
          />
        ),
        flex: 1,
      },
      {
        headerName: 'Keep Current Cycle',
        field: 'keepCurrentCycleTime',
        minWidth: 100,
        type: 'boolean',
        renderCell: (params: any) => {
          return renderCheckBox(params.value as boolean, params.id as number, params.field as keepColumn)
        },
        flex: 1,
      },
      {
        headerName: 'Keep Current Start Week',
        field: 'keepCurrentStartWeek',
        minWidth: 100,
        type: 'boolean',
        renderCell: (params: any) => {
          return renderCheckBox(params.value as boolean, params.id as number, params.field as keepColumn)
        },
        flex: 1,
      },
      {
        headerName: 'Current Batch Cycle',
        field: 'currentBatchCycleTime',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Current Batch Start Week',
        field: 'currentBatchStartWeek',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Fixed Batch Cycle',
        field: 'fixedBatchCycleTime',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Fixed Batch Start Week',
        field: 'fixedBatchStartWeek',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Min cycle',
        field: 'minimumCycle',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Max cycle',
        field: 'maximumCycle',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
    ]

    if (templateBasedOn === TEMPLATE_FOR_COMBINED_MAKER_AND_LINE) {
      return defaultColumns
    }

    return defaultColumns.filter((item) => item.field !== 'runRate')
  }, [data, templateBasedOn])

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(data.filter((row) => selectedIds.has(row.id)))
  }

  if (!data) {
    return <Loader />
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      maxWidth: '120%',
      marginBottom: '32px'
    }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '3px'
        }}
      >
      <Button
        color="primary"
        variant={settingsStatus ? "outlined" : "contained"}
        onClick={handleToggleColumns}
        icon={<SettingsOutlined />}
      >
        Advanced Settings
      </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <MUITable
          rows={data}
          checkboxSelection
          style={{ flexGrow: 1, height: '100%' }}
          columns={MUIColumns.map((col: GridColDef) => ({
            ...col,
            cellClassName: toggleColumns.includes(col.field) ? 'toggle-column' : ''
          }))}
          showCellVerticalBorder
          showColumnVerticalBorder
          columnVisibilityModel={columnVisibilityModel}
          initialState={{
            pagination: { paginationModel: { pageSize: 15}},
          }}
          isCellEditable={(params: GridCellParams) => {
            return !params.row.keepCurrentCycle && params.row.include
          }}
          pageSizeOptions={[15, 30, 50]}
          onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
          disableRowSelectionOnClick
          pagination
          sx={{
            '& .toggle-column': {
              backgroundColor: 'rgba(208, 231, 255, 0.5)',
            }
          }}
        />
      </div>

    </div>
  )
}
