import { Box } from '@material-ui/core'
import { DropDown } from 'supplyvue-ui'
import { CYCLE_TIME_MENU_ITEMS } from '../InventoryAnalysis/InventoryAnalysisFilters/InventoryAnalysisFilters.constants'
import { SegmentAnalysisFiltersViewProps } from './SegmentAnalysis.types'
import { useStyles } from '../InventoryAnalysis/InventoryAnalysisFilters/InventoryAnalysisFilters.styles'

export const SegmentAnalysisFiltersView = ({
  replenishmentTypeMenuItems,
  replenishmentType,
  cycleTime,
  handleReplenishmentTypeChange,
  handleCycleTimeChange,
  handleWorkcentreChange,
  workcentreOptions,
  workcenter,
}: SegmentAnalysisFiltersViewProps) => {
  const classes = useStyles()
  return (
    <Box display="flex">
      <Box mr={2} minWidth="190px">
        <DropDown
          label="Replenishment type"
          onChange={handleReplenishmentTypeChange}
          value={replenishmentType}
          defaultLabel="Replenishment type"
          menuItems={replenishmentTypeMenuItems}
          className={classes.background}
          fullWidth
        />
      </Box>
      <Box mr={2} minWidth="120px">
        <DropDown
          label="Cycle time"
          onChange={handleCycleTimeChange}
          value={cycleTime}
          defaultLabel="Cycle time"
          menuItems={CYCLE_TIME_MENU_ITEMS}
          className={classes.background}
          fullWidth
        />
      </Box>
      <Box minWidth="190px">
        <DropDown
          label="Work centre"
          onChange={handleWorkcentreChange}
          value={workcenter}
          defaultLabel="Work centre"
          menuItems={workcentreOptions ?? []}
          className={classes.background}
          fullWidth
        />
      </Box>
    </Box>
  )
}
