import { DROPDOWN_OPTIONS } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/MakerBatch/MakerBatch.constants'
import { request } from 'Features/Auth/axios-client'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ErrorData } from 'Features/PlanTemplate/ProductSettings/ProductSettingsForm/ProductSettingsForm.types'
import { MultiBulkUpdate } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/MakerBatch/BulkUpdate/BulkUpdate.types'
import { BatchSettings } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/MakerBatch/MakerBatch.types'

const fetchDropdownOptionsData = async (snapshotId: string) => {
  const { REPLENISHMENT_TYPE, SUPPLY_TYPE, UNIT_OF_MEASURE } = DROPDOWN_OPTIONS
  const { data } = await request({
    url: `/snapshots/${snapshotId}/mappings?option=${REPLENISHMENT_TYPE}&config=PlanTemplate&option=${SUPPLY_TYPE}&option=${UNIT_OF_MEASURE}`,
  })
  return data
}

export const useDropdownOptionsData = (snapshotId: string) => {
  return useQuery(
    ['dropdown-options', snapshotId],
    () => fetchDropdownOptionsData(snapshotId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  )
}

const updateGroupSettings = async (groupDetails: MultiBulkUpdate) => {
  const data = await request({
    url: `/plan-templates/${groupDetails.planTemplateId}/bulk-settings`,
    method: 'put',
    data: groupDetails,
  })
  return data
}

export const useUpdatGroupSettings = (
  onSuccess: (
    message: string,
    updatedData: BatchSettings[] | any,
    updatedBatchGrouping: BatchSettings[] | any
  ) => void,
  onError: (errorData: ErrorData) => void
) => {
  const queryClient = useQueryClient()
  return useMutation(updateGroupSettings, {
    onSuccess: async ({ data }, variables) => {
      await queryClient.invalidateQueries([
        'workcentre-settings-all',
        variables.snapshotId,
        variables.projectId,
        variables.planTemplateId,
      ])
      onSuccess(
        data.message,
        data?.data?.batchGroupingSettings,
        data?.data?.batchGroupingSettings
      )
    },
    onError: async ({ response }) => {
      onError(response.data)
    },
  })
}
