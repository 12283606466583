import { InventoryAnalysisViewProps } from './InventoryAnalysis.types'
import { Table as MUITable } from 'Common/MUITable/MUITable'
import React, { useMemo } from "react";
import { useStyles } from "../../ViewScenario/ViewScenario.styles"
import { Typography } from "@material-ui/core";
import {inventoryAnalysisData } from "./InventoryAnalysis.types"
import { searchTextOperator } from "Common/MUITable/SearchText.operator";
import { GridToolbarColumnsButton, GridToolbarExport, GridToolbarContainer } from "@mui/x-data-grid-premium";

export const InventoryAnalysisView = ({
  inventoryAnalysis
}: InventoryAnalysisViewProps) => {
  const classes = useStyles()

  const tableData = inventoryAnalysis.map((item: inventoryAnalysisData, idx: number) => {
    return {
      ...item,
      id: idx,
    }
  })

  const scenarios = [
    ...new Set(
      inventoryAnalysis.map((item: inventoryAnalysisData) => item.scenario)
    )
  ]

  const siteCodes = [
    ...new Set(
      inventoryAnalysis.map((item: inventoryAnalysisData) => item.siteCode)
    )
  ]

  const skuGroupCodes = [
    ...new Set(
      inventoryAnalysis.map((item: inventoryAnalysisData) => item.skuGroupCode)
    )
  ]

  const segments = [
    ...new Set(
      inventoryAnalysis.map((item: inventoryAnalysisData) => item.segment)
    )
  ]

  const supplyTypes = [
    ...new Set(
      inventoryAnalysis.map((item: inventoryAnalysisData) => item.supplyType)
    )
  ]

  const MUIColumns: any[] = useMemo(() => {
    if (!inventoryAnalysis) {
      return []
    }

    return [
      {
        field: 'scenario',
        headerName: 'Scenario',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: scenarios,
              label: 'scenario',
            }
          }
        ],
        type: 'string',
        flex: 1
      },
      {
        field: 'siteCode',
        headerName: 'Site Code',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: siteCodes,
              label: 'site code',
            }
          }
        ],
        type: 'string',
        flex: 1
      },
      {
        field: 'skuGroupCode',
        headerName: 'SKU Group Code',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuGroupCodes,
              label: 'sku group code',
            }
          }
        ],
        type: 'string',
        flex: 1
      },
      {
        field: 'segment',
        headerName: 'Segment',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: segments,
              label: 'segment',
            }
          }
        ],
        type: 'string',
        flex: 1
      },
      {
        field: 'supplyType',
        headerName: 'Supply Type',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: supplyTypes,
              label: 'suply type',
            }
          }
        ],
        type: 'string',
        flex: 1
      },
      {
        field: 'actualBAUVolume',
        headerName: 'Actual BAU Volume',
        type: 'number',
        flex: 1
      },
      {
        field: 'actualCycleStockVolume',
        headerName: 'Actual Cycle Stock Volume',
        type: 'number',
        flex: 1
      },
      {
        field: 'actualInventoryValue',
        headerName: 'Actual Inventory Value',
        type: 'number',
        flex: 1
      },
      {
        field: 'actualSafetyStockVolume',
        headerName: 'Actual Safety Stock Volume',
        type: 'number',
        flex: 1
      },
      {
        field: 'chosenMethod',
        headerName: 'Chosen Method',
        type: 'string',
        flex: 1
      },
      {
        field: 'customBAUDuration',
        headerName: 'Custom BAU Duration',
        type: 'number',
        flex: 1
      },
      {
        field: 'customBAUVolume',
        headerName: 'Custom BAU Volume',
        type: 'number',
        flex: 1
      },
      {
        field: 'cycleStockVolume',
        headerName: 'Cycle Stock Volume',
        type: 'number',
        flex: 1
      },
      {
        field: 'dailySalesRate',
        headerName: 'Daily Sales Rate',
        type: 'number',
        flex: 1
      },
      {
        field: "forecastAccuracy",
        headerName: 'Forecast Accuracy',
        type: 'number',
        flex: 1
      },
      {
        field: "forecastAccuracyByDemandVariability",
        headerName: 'Forecast Accuracy By Demand Variability',
        type: 'number',
        flex: 1
      },
      {
        field: "forecastAccuracyByForecastError",
        headerName: 'Forecast Accuracy By Forecast Error',
        type: 'number',
        flex: 1
      },
      {
        field: "hybridSafetyStockVolume",
        headerName: 'Hybrid Safety Stock Volume',
        type: 'number',
        flex: 1
      },
      {
        field: "replenishmentCycle",
        headerName: 'Replenishment Cycle',
        type: 'number',
        flex: 1
      },
      {
        field: "safetyStockType",
        headerName: 'Safety Stock Type',
        type: 'string',
        flex: 1
      },
      {
        field: "serviceLevel",
        headerName: 'Service Level',
        type: 'number',
        flex: 1
      },
      {
        field: "ssVolumeByDemandVariability",
        headerName: 'SS Volume By Demand Variability',
        type: 'number',
        flex: 1
      },
      {
        field: "ssVolumeByForecastError",
        headerName: 'SS Volume By Forecast Error',
        type: 'number',
        flex: 1
      },
      {
        field: "stockPeriod",
        headerName: 'Stock Period',
        type: 'number',
        flex: 1
      },
      {
        field: "supplyType",
        headerName: 'Supply Type',
        type: 'string',
        flex: 1
      },
      {
        field: "unusedInventory",
        headerName: 'Unused Inventory',
        type: 'number',
        flex: 1
      } ,
      {
        field: "weeklySalesRateByDemandVariability",
        headerName: 'Weekly Sales Rate By Demand Variability',
        type: 'number',
        flex: 1
      },
      {
        field: "weeklySalesRateByForecastError",
        headerName: 'Weekly Sales Rate By Forecast Error',
        type: 'number',
        flex: 1
      },

  ]

  }, [inventoryAnalysis])


  const toolBar = () => {
    return (
        <GridToolbarContainer className={classes.toolbar}>
          <GridToolbarColumnsButton  sx = {{ color: '#006286' }}/>
          <GridToolbarExport sx = {{ color: '#006286' }}/>
        </GridToolbarContainer>
    )
  }


  return (
    <div className={classes.tabContainer}>
      <div className={classes.header}>
        <Typography variant="h4">
          Inventory Analysis
        </Typography>

      </div>
      <div className={inventoryAnalysis ? classes.tableContainer : classes.emptyTable}>
        <MUITable
          sx={{ border: 'none', boxShadow: 'none'}}
          rows={tableData}
          disableRowSelectionOnClick
          columns={MUIColumns}
          experimentalFeatures={{ lazyLoading: true }}
          hideFooter={true}
          components={{ Toolbar: toolBar }}
        />
      </div>
    </div>

  )

}
