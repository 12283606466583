import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    minWidth: '700px',
    border: `1px solid ${theme.palette.borders.grey}`,
    borderRadius: '3px',
    borderBottom: 'none',
    boxShadow: 'none',
    marginBottom: '10px',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: '10px 0px',
    },
  },
}))
