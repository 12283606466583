import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  contents: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    padding: '10px',
    width: '100%',
    background: theme.palette.background.grey,
    overflowX: 'scroll',
  },
  parametersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  section: {
    height: '100%',
    width: '32%',
    padding: '0px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minWidth: '330px',
    boxSizing: 'border-box',
  },
  middleSection: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    minWidth: '320px',
  },
  lastSection: {
    width: '36%',
    minWidth: '360px',
    alignItems: 'flex-end',
  },
  moq: {
    minWidth: '150px',
  },
  inputLabel: {
    paddingRight: '10px',
    minWidth: '160px',
    textAlign: 'right',
  },
  moi: {
    minWidth: '170px',
  },
  moiWrapper: {
    marginRight: '42px',
  },
  label: {
    textAlign: 'right',
  },
  buttonGroup: {
    marginTop: '20px',
    alignSelf: 'flex-end',
  },
  clearButton: {
    marginRight: '15px',
  },
  sectionTwolabel: {
    minWidth: '170px',
  },
  progress: {
    marginRight: '8px',
  },
}))
