import { AnalysisSummaryTableProps } from './AnalysisSummary.types'
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'

import { useStyles } from './AnalysisSummary.styles'
const CURRENCY = '£' // Set currncy
export const AnalysisSummaryTable = ({
  data,
  reportingUnitOfMeasure,
}: AnalysisSummaryTableProps) => {
  const classes = useStyles()
  const rows = [
    {
      name: 'Volume',
      BAUStockPolicy: data.volume?.BAUStockPolicy
        ? data.volume.BAUStockPolicy.toLocaleString('en-US') +
          ` ${reportingUnitOfMeasure}`
        : '',
      safetyStockPolicy: data.volume?.safetyStockPolicy
        ? data.volume.safetyStockPolicy.toLocaleString('en-US') +
          ` ${reportingUnitOfMeasure}`
        : '',
      cycleStockPolicy: data.volume?.cycleStockPolicy
        ? data.volume.cycleStockPolicy.toLocaleString('en-US') +
          ` ${reportingUnitOfMeasure}`
        : '',
    },
    {
      name: 'Value',
      BAUStockPolicy: data.value?.BAUStockPolicy
        ? CURRENCY +
          Math.trunc(data.value.BAUStockPolicy).toLocaleString('en-GB')
        : '',
      safetyStockPolicy: data.value?.safetyStockPolicy
        ? CURRENCY +
          Math.trunc(data.value.safetyStockPolicy).toLocaleString('en-GB')
        : '',
      cycleStockPolicy: data.value?.cycleStockPolicy
        ? CURRENCY +
          Math.trunc(data.value.cycleStockPolicy).toLocaleString('en-GB')
        : '',
    },
    {
      name: 'Duration',
      BAUStockPolicy: data.duration?.BAUStockPolicy
        ? data.duration.BAUStockPolicy + ' d'
        : '',
      safetyStockPolicy: data.duration?.safetyStockPolicy
        ? data.duration.safetyStockPolicy + ' d'
        : '',
      cycleStockPolicy: data.duration?.cycleStockPolicy
        ? data.duration.cycleStockPolicy + ' d'
        : '',
    },
  ]

  return (
    <Table
      aria-label="safety stock summary table"
      className={classes.contentWrapper}
      size="small"
    >
      <TableHead className={classes.header}>
        <TableRow>
          <TableCell className={classes.cell}></TableCell>
          <TableCell className={classes.cell} align="left" padding="none">
            <Typography className={classes.columnHeader} variant="body1">
              BAU stock policy
            </Typography>
          </TableCell>
          <TableCell className={classes.cell} align="left" padding="none">
            <Typography className={classes.columnHeader} variant="body1">
              Safety stock policy
            </Typography>
          </TableCell>
          <TableCell align="left" padding="none">
            <Typography className={classes.columnHeader} variant="body1">
              Cycle stock policy
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.name}>
            <TableCell className={classes.cell} component="th" scope="row">
              <Typography variant="body1">{row.name}</Typography>
            </TableCell>
            <TableCell className={classes.cell} align="center">
              <Typography variant="h4">{row.BAUStockPolicy}</Typography>
            </TableCell>
            <TableCell className={classes.cell} align="center">
              <Typography variant="h4">{row.safetyStockPolicy}</Typography>
            </TableCell>
            <TableCell className={classes.cell} align="center">
              <Typography variant="h4">{row.cycleStockPolicy}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
