import { useQuery } from 'react-query'
import { request, requestV2 } from 'Features/Auth/axios-client'

const fetchDetailedScenarioAnalysis = async (projectId: string) => {
  const { data } = await requestV2({
    url: `/projects/${projectId}/detailed-scenario-analysis`,
  })
  return data
}

export const useDetailedScenarioAnalysis = (
  projectId: string,
  onError: (message: string) => void
) => {
  return useQuery(
    ['detailed-scenario-analysis', projectId],
    () => fetchDetailedScenarioAnalysis(projectId),
    {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      onError: async ({ response }) => {
        onError(response.data.message)
      },
    }
  )
}

const fetchInventoryAnalysis = async (projectId: string) => {
  const { data } = await requestV2({
    url: `/projects/${projectId}/inventory-analysis`,
  })
  return data
}

export const useInventoryAnalysis = (
  projectId: string,
  onError: (message: string) => void
) => {
  return useQuery(
    ['inventory-analysis', projectId],
    () => fetchInventoryAnalysis(projectId),
    {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      onError: async ({ response }) => {
        onError(response.data.message)
      },
    }
  )
}

const fetchUtilisationAnalysis = async (projectId: string) => {
  const { data } = await request({
    url: `/projects/${projectId}/utilisation-analysis`,
  })
  return data
}

export const useUtilisationAnalysis = (
  projectId: string,
  onError: (message: string) => void
) => {
  return useQuery(
    ['utilisation-analysis', projectId],
    () => fetchUtilisationAnalysis(projectId),
    {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      onError: async ({ response }) => {
        onError(response.data.message)
      },
    }
  )
}

const fetchCostAnalysis = async (projectId: string) => {
  const { data } = await requestV2({
    url: `/projects/${projectId}/cost-analysis`,
  })
  return data
}

export const useCostAnalysis = (
  projectId: string,
  onError: (message: string) => void
) => {
  return useQuery(
    ['cost-analysis', projectId],
    () => fetchCostAnalysis(projectId),
    {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      onError: async ({ response }) => {
        onError(response.data.message)
      },
    }
  )
}
