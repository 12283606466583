import { useState } from 'react'
import { Box, Tabs, Tab, Typography } from '@material-ui/core'
import { TabPanel, ToggleButton } from 'supplyvue-ui'
import { CycleSpeedGraph } from './CycleSpeed.graph'
import { useStyles } from './CycleSpeed.styles'
import { CycleSpeedProps, AggregatedData } from './CycleSpeed.types'

export const CycleSpeedView = ({ data, optimization }: CycleSpeedProps) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [productToggleButtonValue, setProductToggleButtonValue] =
    useState('count')
  const [batchToggleButtonValue, setBatchToggleButtonValue] = useState('count')

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setTabIndex(newValue)
  }

  const getProductXAxisData = () => {
    return data?.cycleByProduct?.aggregatedData.map(
      (data) =>
        data[productToggleButtonValue as keyof Omit<AggregatedData, 'label'>]
    )
  }

  const getBatchXAxisData = () => {
    return data?.cycleByBatch?.aggregatedData.map(
      (data) =>
        data[batchToggleButtonValue as keyof Omit<AggregatedData, 'label'>]
    )
  }

  const getYAxisData = (graphName: string) => {
    return graphName === 'cyclebyProduct'
      ? data?.cycleByProduct?.aggregatedData.map((data) =>
          data.label.toString()
        )
      : data?.cycleByBatch?.aggregatedData.map((data) => data.label.toString())
  }

  const classes = useStyles()
  return (
    <Box>
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="Cycle Speed Tabs"
      >
        <Tab label="Cycle by SKU group" />
        <Tab label="Cycle by batch group" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box width="75%" height="275px">
            <CycleSpeedGraph
              xAxisHoverLabel={
                productToggleButtonValue === 'count' ? 'Count' : 'Volume'
              }
              xAxisData={getProductXAxisData()}
              yAxisData={getYAxisData('cyclebyProduct')}
            />
          </Box>
          <Box className={classes.cycleSpeedWrapper}>
            <Box
              mb={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h3">Cycle speed</Typography>
              <Typography variant="body2">(Volume Weighted)</Typography>
            </Box>
            <Box>
              <Typography variant="h1">
                {data?.cycleByProduct?.averageCycleSpeed}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <ToggleButton
            buttonOne={{ label: 'Count', value: 'count' }}
            buttonTwo={{ label: 'Volume', value: 'volume' }}
            value={productToggleButtonValue}
            handleChange={(event, value) => {
              event.stopPropagation()
              setProductToggleButtonValue(value as string)
            }}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box width="75%" height="275px">
            <CycleSpeedGraph
              xAxisHoverLabel={
                batchToggleButtonValue === 'count' ? 'Count' : 'Volume'
              }
              xAxisData={getBatchXAxisData()}
              yAxisData={getYAxisData('cycleByBatch')}
            />
          </Box>
          <Box className={classes.cycleSpeedWrapper}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={1}
            >
              <Typography variant="h3">Cycle speed</Typography>
              <Typography variant="body2">(Volume Weighted)</Typography>
            </Box>
            <Box>
              <Typography variant="h1">
                {data?.cycleByBatch?.averageCycleSpeed}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <ToggleButton
            buttonOne={{ label: 'Count', value: 'count' }}
            buttonTwo={{ label: 'Volume', value: 'volume' }}
            value={batchToggleButtonValue}
            handleChange={(event, value) => {
              event.stopPropagation()
              setBatchToggleButtonValue(value as string)
            }}
          />
        </Box>
      </TabPanel>
    </Box>
  )
}
