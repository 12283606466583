import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  accordionSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px',
  },
  accordionSummaryRoot: {
    borderBottom: `1px solid ${theme.palette.borders.grey}`,
    paddingRight: '4px',
    '& .MuiIconButton-edgeEnd': {
      marginRight: '0px',
    },
  },
  accordionSummaryContentExpanded: {
    margin: '0px !important',
    minHeight: '48px !important',
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
}))
