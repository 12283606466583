import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { PROJECT_ACCESS_OPTIONS } from 'Common/Constants/Constants'
import { ProjectAccessSelectProps } from './Common.types'

export const ProjectAccessSelect = ({
  showProjectsOption,
  handleShowMyProjectsDropdown,
}: ProjectAccessSelectProps) => (
  <FormControl fullWidth>
    <InputLabel id="project-access-type-select-label">
      Project access
    </InputLabel>
    <Select
      sx={{
        width: '200px',
      }}
      labelId="project-access-type-select-label"
      id="project-access-type-select"
      value={showProjectsOption}
      label="Project access"
      onChange={handleShowMyProjectsDropdown}
    >
      <MenuItem value={PROJECT_ACCESS_OPTIONS['Generated by me']}>
        {PROJECT_ACCESS_OPTIONS['Generated by me']}
      </MenuItem>
      <MenuItem value={PROJECT_ACCESS_OPTIONS['Not generated by me']}>
        {PROJECT_ACCESS_OPTIONS['Not generated by me']}
      </MenuItem>
      <MenuItem value={PROJECT_ACCESS_OPTIONS['Generated by anyone']}>
        {PROJECT_ACCESS_OPTIONS['Generated by anyone']}
      </MenuItem>
    </Select>
  </FormControl>
)
