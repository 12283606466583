import { Table } from 'supplyvue-ui'
import { Column, TableInstance } from 'react-table'
import { CostDriverTableProps, CostDriverColumn } from './CostDriver.types'
import { Loader } from 'Common/Loader/Loader'

export const CostDriverTable = ({ data, isLoading }: CostDriverTableProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 300
    const availableHeight = window.innerHeight - 690
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  const cellRenderer = (props: TableInstance) =>
    props.value ? props.value.toLocaleString('en-US') : null

  const columns: Column<CostDriverColumn>[] = [
    {
      Header: 'Scenario',
      accessor: 'scenario',
      disableFilters: true,
      disableSortBy: true,
      columnWidth: 120,
    },
    {
      Header: 'Average number of change overs per week',
      accessor: 'averageNumberOfChangeoversPerWeek',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },

    {
      Header: 'Average weekly change over cost for all the work centers',
      accessor: 'averageWeeklyChangeoverCost',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },
    {
      Header: 'Number of pallets',
      accessor: 'numberOfPallets',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: cellRenderer,
    },
  ]
  if (isLoading) {
    return <Loader />
  }
  return (
    <Table
      data={data}
      height={getTableHeight()}
      columns={columns}
      cellPadding="5px 5px"
    />
  )
}
