import { ProductSettingsParametersType } from './ProductSettingsForm.types'
import { ProductSettings } from 'Features/PlanTemplate/StepCard/StepCard.types'

export const mandatoryErrorStatusInitialState = {
  batchGroupingCode: '',
  salesRate: '',
  runRate: '',
  minimumOrderQuantity: '',
  minimumOrderIncrement: '',
  minimumCycle: '',
  maximumCycle: '',
  replenishmentTypeId: '',
  productionUnitOfMeasureId: '',
  runRateUnitOfMeasureId: '',
}

export const getSettingsData = (
  data: ProductSettings
): ProductSettingsParametersType => {
  return {
    batchGroupingCode: data.batchGroupingCode,
    salesRate: data.salesRate,
    runRate: data.runRate,
    minimumOrderQuantity: data.minimumOrderQuantity,
    minimumOrderIncrement: data.minimumOrderIncrement,
    minimumCycle: data.minimumCycle,
    maximumCycle: data.maximumCycle,
    salesWeeksPerMOQ:
      data.salesRate !== null
        ? ((data.minimumOrderQuantity ?? 0) / data.salesRate).toFixed(2)
        : '-',
    replenishmentTypeId: data.replenishmentTypeId,
    replenishmentType: data.replenishmentType,
    productionUnitOfMeasure: data.productionUnitOfMeasure,
    runRateUnitOfMeasure: data.runRateUnitOfMeasure,
    include: data.include ?? false,
  }
}

export const getUpdatedProductSettingsForValidation = (
  updatedData: ProductSettingsParametersType,
  originalData: ProductSettings[]
): ProductSettings[] => {
  return originalData.map((data) => {
    return {
      ...data,
      ...(updatedData.batchGroupingCode && {
        batchGroupingCode: updatedData.batchGroupingCode,
      }),
      ...(updatedData.salesRate && {
        salesRate: parseFloat(updatedData.salesRate as string),
      }),
      ...(updatedData.runRate && {
        runRate: parseFloat(updatedData.runRate as string),
      }),
      ...(updatedData.minimumOrderQuantity && {
        minimumOrderQuantity: parseFloat(
          updatedData.minimumOrderQuantity as string
        ),
      }),
      ...(updatedData.minimumOrderIncrement && {
        minimumOrderIncrement: parseFloat(
          updatedData.minimumOrderIncrement as string
        ),
      }),
      ...(updatedData.minimumCycle && {
        minimumCycle: parseInt(updatedData.minimumCycle as string),
      }),
      ...(updatedData.maximumCycle && {
        maximumCycle: parseInt(updatedData.maximumCycle as string),
      }),
      ...(updatedData.replenishmentTypeId && {
        replenishmentTypeId: parseInt(
          updatedData.replenishmentTypeId as string
        ),
      }),
      ...(updatedData.replenishmentType && {
        replenishmentType: updatedData.replenishmentType,
      }),
      include: updatedData.include,
    }
  })
}
