import { useMemo } from 'react'

import { Graph } from 'Common/Graph/Graph'
import { TotalCostGraphProps } from './TotalCost.types'

export const TotalCostGraph = ({
  xAxisData,
  totalCost,
}: TotalCostGraphProps) => {
  const hovertemplate = () => {
    return (
      ' <b>Scenario: </b>%{x}<br>' +
      ` <b>Total cost: </b> %{y}<br>` +
      '<extra></extra>'
    )
  }

  const trace = {
    x: xAxisData,
    y: totalCost,
    name: 'Total cost',
    type: 'bar',
    textfont: { color: 'transparent' },
    width: 0.45,
    hovertemplate: hovertemplate(),
    marker: {
      color: '#4E79A7',
    },
  }

  const data = useMemo(() => [trace], [xAxisData, totalCost])

  const layout = {
    yaxis: {
      showgrid: true,
      title: {
        text: 'Total cost ($)',
        standoff: 20,
      },
      automargin: true,
    },
    xaxis: {
      zeroline: true,
      nticks: 10,
    },
    margin: {
      b: 100,
    },
  }
  return <Graph data={data} layout={layout} />
}
