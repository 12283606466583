import { Table } from 'supplyvue-ui'
import { Column } from 'react-table'
import { KPIColumns, KPITableProps } from './KPI.types'
export const KPITable = ({ data }: KPITableProps) => {
  const columns: Column<KPIColumns>[] = [
    {
      Header: 'KPIs',
      accessor: 'KPIs',
      disableFilters: true,
      disableSortBy: true,
      minWidth: 200,
    },
    {
      Header: 'Total',
      accessor: 'Total',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: '1 Erratic',
      accessor: 'KPI_1E',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: '1 Erratic & Intermittent',
      accessor: 'KPI_1EandI',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: '1 Intermittent',
      accessor: 'KPI_1I',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: '1 Smooth',
      accessor: 'KPI_1S',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },

    {
      Header: '2 Erratic',
      accessor: 'KPI_2E',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: '2 Erratic & Intermittent',
      accessor: 'KPI_2EandI',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: '2 Intermittent',
      accessor: 'KPI_2I',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: '2 Smooth',
      accessor: 'KPI_2S',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },

    {
      Header: '3 Erratic',
      accessor: 'KPI_3E',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: '3 Erratic & Intermittent',
      accessor: 'KPI_3EandI',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: '3 Intermittent',
      accessor: 'KPI_3I',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: '3 Smooth',
      accessor: 'KPI_3S',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
    {
      Header: 'Other',
      accessor: 'Other',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
    },
  ]

  return <Table data={data} columns={columns} />
}
