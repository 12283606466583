import { Box, CircularProgress, Typography, Divider } from '@material-ui/core'
import { isEqual } from 'lodash'
import { Button, DropDown, Input, Modal } from 'supplyvue-ui'
import { useStyles } from './BulkUpdate.styles'
import { SalesHoizonBulkUpdateProps } from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/BulkUpdate/BulkUpdate.types'
import {
  SALES_RATE_TYPE,
  TEMPLATE_BASED_ON,
} from 'Features/NewPlanTemplate/WorkcentreSettings/SalesHorizon/BulkUpdate/BulkUpdate.constants'

export const INITIAL_STATE = {
  salesRateType: '',
  salesRatePeriod: '',
  templateBasedOn: '',
}

export const SalesHorizonBulkUpdate = ({
  isModalOpen,
  handleModalClose,
  isUpdating,
  data,
  setData,
  handleSaveClick,
}: SalesHoizonBulkUpdateProps) => {
  const classes = useStyles()
  const handleDropdownChange = (
    event: React.ChangeEvent<{ name: string; value: unknown }>
  ) => {
    const name = event.target.name as string

    setData({
      ...data,
      [name]: event.target.value,
    })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    const VALID_NUMBER_PATTERN = /^[+]?((\.\d{0,5})|(\d+(\.\d{0,5})?)|(\d+\.))$/
    if (event.target.value === '' || value.match(VALID_NUMBER_PATTERN)) {
      setData({
        ...data,
        [name]: parseInt(value),
      })
    }
  }

  const handleSaveButtonClick = async () => {
    handleSaveClick(data)
  }
  const handleClearButtonClick = () => {
    setData(INITIAL_STATE)
  }

  return (
    <Box>
      <Modal
        disableBackdropClick
        header={'Edit Sales Horizon'}
        open={isModalOpen}
        handleClose={handleModalClose}
        body={
          <Box mt={2} mb={2} display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Metric</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Action</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Value</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Divider flexItem className={classes.divider} />
            </Box>

            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Sales Rate Type</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Input
                    fullWidth={true}
                    ariaLabel="Set value to"
                    value="Set value to"
                    name="setValueTo"
                    disabled={true}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" ml={3}>
                <Box width="15rem">
                  <DropDown
                    fullWidth={true}
                    label="Sales Rate Type"
                    name="salesRateType"
                    defaultLabel="None"
                    menuItems={SALES_RATE_TYPE}
                    value={data.salesRateType}
                    onChange={handleDropdownChange}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Template Based On</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Input
                    fullWidth={true}
                    ariaLabel="Set value to"
                    value="Set value to"
                    name="setValueTo"
                    disabled={true}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" ml={3}>
                <Box width="15rem">
                  <DropDown
                    fullWidth={true}
                    label="Template Based On"
                    name="templateBasedOn"
                    defaultLabel="None"
                    menuItems={TEMPLATE_BASED_ON}
                    value={data.templateBasedOn}
                    onChange={handleDropdownChange}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Sales Rate Period</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Input
                    fullWidth={true}
                    ariaLabel="Set value to"
                    value="Set value to"
                    name="setValueTo"
                    disabled={true}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" ml={3}>
                <Box width="15rem">
                  <Input
                    fullWidth={true}
                    ariaLabel="Sales Rate Period"
                    value={data.salesRatePeriod}
                    onChange={handleInputChange}
                    name="salesRatePeriod"
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignSelf="flex-end" mt="10px">
              <Button
                className={classes.button}
                color="primary"
                variant="outlined"
                onClick={handleClearButtonClick}
                disabled={isEqual(INITIAL_STATE, data)}
              >
                Clear
              </Button>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={handleSaveButtonClick}
                disabled={isUpdating}
              >
                {isUpdating && (
                  <CircularProgress
                    color="inherit"
                    size={16}
                    className={classes.progress}
                  />
                )}
                Apply changes
              </Button>
            </Box>
          </Box>
        }
      />
    </Box>
  )
}
