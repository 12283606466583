import { GridFilterInputValueProps } from '@mui/x-data-grid-premium'
import { SyntheticEvent } from 'react'
import * as React from 'react'
import { useTheme, styled } from '@mui/material/styles'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import { ButtonBase, Checkbox, FormControlLabel } from '@mui/material'
import { Search } from '@material-ui/icons'

interface PopperComponentProps {
  anchorEl?: HTMLElement | unknown
  disablePortal?: boolean
  open: boolean
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))

function PopperComponent(props: PopperComponentProps) {
  const { ...other } = props
  return <StyledAutocompletePopper {...other} />
}

const Button = styled(ButtonBase)(({ theme }) => ({
  fontSize: 13,
  width: '100%',
  textAlign: 'left',
  paddingBottom: 8,
  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
  fontWeight: 600,
  '&:hover,&:focus': {
    color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
  },
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
}))

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}))

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${
    theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
  }`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${
      theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
    }`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light'
          ? 'rgba(3, 102, 214, 0.3)'
          : 'rgb(12, 45, 107)'
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}))

export const AutoCompleteTextBox = (
  props: GridFilterInputValueProps & { data: string[]; label: string }
) => {
  const { data, item, applyValue, label } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [pendingValue, setPendingValue] = React.useState<string[]>([
    ...(item?.value ? item?.value : data),
  ])
  const theme = useTheme()

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const handleCheckbox = (event: SyntheticEvent<Element, Event>) => {
    const { checked } = event.target
    if (checked) {
      setPendingValue([...data])
    } else {
      setPendingValue([])
    }
  }

  React.useEffect(() => {
    applyValue({
      ...item,
      value: new Set(pendingValue),
    })
  }, [pendingValue])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? `supplyvue-search-${label}` : undefined

  return (
    <React.Fragment>
      <Box
        sx={{
          width: 150,
          fontSize: 20,
          height: 48,
          display: 'flex',
          alignItems: 'center',
        }}
        id={`#bu-${label}`}
      >
        <Button disableRipple aria-describedby={id} onClick={handleClick}>
          <span>{`Search ${
            label.length > 15 ? label.slice(0, 15) + '...' : label
          }`}</span>
          <Search fontSize="large" />
        </Button>
      </Box>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              sx={{
                borderBottom: `1px solid ${
                  theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
                }`,
                padding: '8px 10px',
                fontWeight: 600,
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label="Select all"
                onChange={handleCheckbox}
                checked={data.length === item?.value?.size}
              />
            </Box>
            <Autocomplete
              open
              multiple
              disableCloseOnSelect
              clearOnBlur={false}
              onClose={(
                event: SyntheticEvent<Element, Event>,
                reason: AutocompleteCloseReason
              ) => {
                if (reason === 'escape') {
                  handleClose()
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return
                }
                setPendingValue(newValue)
              }}
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No labels"
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      '& span': {
                        color:
                          theme.palette.mode === 'light'
                            ? '#586069'
                            : '#8b949e',
                      },
                    }}
                  >
                    {option}
                  </Box>
                  <Box
                    component={CloseIcon}
                    sx={{ opacity: 0.6, width: 18, height: 18 }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                </li>
              )}
              options={[...data]}
              getOptionLabel={(option) => option}
              renderInput={(params) => {
                return (
                  <StyledInput
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    placeholder="Filter"
                  />
                )
              }}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  )
}
