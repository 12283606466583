import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  summaryGraphWrapper: {
    border: `1px solid ${theme.palette.borders.grey}`,
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
  },
  paper: {
    boxShadow: theme.shadows[2],
  },
  grid: {
    marginTop: '20px',
  },
  templateGeneratingCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    marginTop: '20px',
  },
  innerBox: {
    borderRadius: '20px',
    height: '28%',
    width: '40%',
  },
  templateBox: {
    borderRadius: '20px',
    height: 700,
  },
  templateIcon: {
    fontSize: 150,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  iconBox: {
    width: '35%',
    color: theme.palette.primary.main,
  },
}))
