import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  selectSnapshot: {
    width: '100%',
  },
  list: {
    maxHeight: '300px',
  },
  snapshotName: {
    margin: '20px 0px',
  },
  modalActionButton: {
    marginLeft: '20px',
    minWidth: '105px',
  },
  periodSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  calendar: {
    minWidth: '250px',
    marginRight: '30px',
  },
  select: {
    minWidth: '298px',
  },
  label: {
    marginBottom: '8px',
  },
  progress: {
    marginRight: '8px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
})
