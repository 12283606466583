import React, { useState } from 'react'
import { Tabs, Tab, Box } from '@material-ui/core'
import { useStyles } from './WorkcentreSettings.style'
import { ContentView } from 'Features/NewPlanTemplate/WorkcentreSettings/WorkcentreSettingsContent/WorkcentreSettingsContent.View'
import { WorkcentreSettingsProps } from 'Features/NewPlanTemplate/WorkcentreSettings/WorkcentreSettings.types'
import { Loader } from 'Common/Loader/Loader'

export const WorkcentreSettingsView = ({
  dropdownOptions,
  workcentreSettingsData,
}: WorkcentreSettingsProps) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState<number>(0)
  const steps = ['Sales Horizon', 'Parameters', 'Workcentre Routing']
  const { batchGroupingCodes } = workcentreSettingsData

  if (!(workcentreSettingsData && dropdownOptions)) {
    return <Loader />
  }

  return (
    <>
      <Tabs
        value={activeStep}
        onChange={(e, value) => {
          setActiveStep(value)
        }}
        indicatorColor="primary"
        textColor="primary"
        classes={{ root: classes.tabs }}
      >
        {steps.map((label: string) => {
          return <Tab key={label} label={label} />
        })}
      </Tabs>
      <Box className={classes.stepContentView}>
        <ContentView
          step={activeStep}
          dropdownOptions={dropdownOptions}
          batchGroupingCodes={batchGroupingCodes}
        />
      </Box>
    </>
  )
}
