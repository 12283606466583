export const SALES_RATE_TYPE = [
  { key: 'forecast', value: 'forecast' },
  { key: 'historical sales', value: 'historical' },
]
export const MIN_AVG_BATCHES_PER_CYCLE = [
  1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0,
].map((value) => ({ key: value.toString(), value }))

export const SET_SALES_RATE_TYPE = 'SET_SALES_RATE_TYPE'
export const SET_SALES_RATE_PERIOD = 'SET_SALES_RATE_PERIOD'
export const SET_MIN_AVG_BATCH_PER_CYCLE = 'SET_MIN_AVG_BATCH_PER_CYCLE'
export const RESET_SCENARIO_SETTINGS = 'RESET_SCENARIO_SETTINGS'
export const RESET_PLANTEMPLATE_SETTINGS = 'RESET_PLANTEMPLATE_SETTINGS'
export const SET_PLANTEMPLATE_BASED_ON = 'SET_PLANTEMPLATE_BASED_ON'
