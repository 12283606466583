import { Box, CircularProgress, Divider, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Button, DropDown, Input, Modal } from 'supplyvue-ui'
import { useStyles } from './BulkUpdate.styles'
import { ReplenishmentSettingsBulkUpdateProps } from 'Features/NewPlanTemplate/ReplenishmentSettings/BulkUpdate/BulkUpdate.types'
import { isEqual } from 'lodash'
import {
  dropDownvaluesInitialState,
  inputValuesInitialState,
} from 'Features/ReplenishmentPolicy/SiteParameters/SiteParameters.constants'

export const ReplenishmentSettingsBulkUpdate = ({
  isModalOpen,
  handleModalClose,
  inputValues,
  dropdownValues,
  handleDropdownChange,
  handleInputChange,
  isApplyParametersDisabled,
  handleUpdateReplenishmentSiteParams,
  supplyTypeOptions,
  replenishmentTypeOptions,
  isDataUpdating,
  includeTypeOptions,
  handleClearButtonClick,
  isCalculating,
}: ReplenishmentSettingsBulkUpdateProps) => {
  const classes = useStyles()
  const [isIncludeSelected, setIsIncludeSelected] = useState(false)

  return (
    <Box>
      <Modal
        className={classes.modal}
        disableBackdropClick
        open={isModalOpen}
        handleClose={handleModalClose}
        header={'Edit Inventory Settings'}
        body={
          <Box mt={2} mb={2} display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Metric</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Value</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Divider flexItem className={classes.divider} />
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Supply Type</Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <DropDown
                    fullWidth={true}
                    value={dropdownValues.supplyType}
                    menuItems={supplyTypeOptions}
                    label={'Supply type'}
                    defaultLabel={'None'}
                    onChange={handleDropdownChange}
                    name={Object.keys(dropdownValues)[0]}
                    disabled={
                      isDataUpdating || isIncludeSelected || isCalculating
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Replenishment Cycle</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Input
                    fullWidth={true}
                    ariaLabel={'Replenishment cycle'}
                    value={inputValues.replenishmentCycle}
                    onChange={handleInputChange}
                    name={Object.keys(inputValues)[0]}
                    disabled={
                      isDataUpdating || isIncludeSelected || isCalculating
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Service Level</Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Input
                    fullWidth={true}
                    ariaLabel={'Service level'}
                    value={inputValues.serviceLevel}
                    onChange={handleInputChange}
                    name={Object.keys(inputValues)[4]}
                    disabled={
                      isDataUpdating || isIncludeSelected || isCalculating
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Replenishment Type</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <DropDown
                    fullWidth={true}
                    value={dropdownValues.replenishmentType}
                    menuItems={replenishmentTypeOptions}
                    label={'Replenishment type'}
                    defaultLabel={'None'}
                    onChange={handleDropdownChange}
                    name={Object.keys(dropdownValues)[1]}
                    disabled={
                      isDataUpdating || isIncludeSelected || isCalculating
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">MOQ</Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Input
                    fullWidth={true}
                    ariaLabel={'MOQ'}
                    value={inputValues.MOQ}
                    onChange={handleInputChange}
                    name={Object.keys(inputValues)[3]}
                    disabled={
                      isDataUpdating || isIncludeSelected || isCalculating
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Include</Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <DropDown
                    fullWidth={true}
                    value={dropdownValues.includeType}
                    menuItems={includeTypeOptions}
                    label={'Include'}
                    defaultLabel={'None'}
                    onChange={handleDropdownChange}
                    name={Object.keys(dropdownValues)[2]}
                    disabled={
                      isDataUpdating || isIncludeSelected || isCalculating
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Deployment Lead Time</Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Input
                    fullWidth={true}
                    ariaLabel={'Deployment lead time'}
                    value={inputValues.deploymentLeadTime}
                    onChange={handleInputChange}
                    name={Object.keys(inputValues)[1]}
                    disabled={
                      isDataUpdating || isIncludeSelected || isCalculating
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">MOI</Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Input
                    fullWidth={true}
                    ariaLabel={'MOI'}
                    value={inputValues.MOI}
                    onChange={handleInputChange}
                    name={Object.keys(inputValues)[2]}
                    disabled={
                      isDataUpdating || isIncludeSelected || isCalculating
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Typography variant="h4">Planning Period</Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column">
                <Box width="15rem">
                  <Input
                    fullWidth={true}
                    ariaLabel={'Planning period'}
                    value={inputValues.planningPeriod}
                    onChange={handleInputChange}
                    name={Object.keys(inputValues)[5]}
                    disabled={
                      isDataUpdating || isIncludeSelected || isCalculating
                    }
                  />
                </Box>
              </Box>
            </Box>

            <Box className={classes.buttonGroup}>
              <Button
                className={classes.clearButton}
                color="primary"
                variant="contained"
                onClick={handleClearButtonClick}
                disabled={
                  isEqual(inputValues, inputValuesInitialState) &&
                  isEqual(dropdownValues, dropDownvaluesInitialState)
                }
              >
                Clear
              </Button>
              <Button
                variant="contained"
                disabled={
                  (isApplyParametersDisabled || isDataUpdating) &&
                  !isIncludeSelected
                }
                onClick={handleUpdateReplenishmentSiteParams}
              >
                {isDataUpdating && (
                  <CircularProgress
                    color="inherit"
                    size={16}
                    className={classes.progress}
                  />
                )}
                Apply parameters
              </Button>
            </Box>
          </Box>
        }
      />
    </Box>
  )
}
