import { StepCardView } from 'Features/PlanTemplate/StepCard/StepCard.view'
import { useScenarioSettingsState } from 'Features/PlanTemplate/ScenarioSettings/ScenarioSettings.state'
import { TemplateSettingsProps } from './TemplateSettings.types'

export const TemplateSettingsView = ({
  snapshotId,
  projectId,
  scenarioId,
  planTemplateId,
  sourceSiteId,
  workcentreId,
  isWorkcenterSelected,
  scenarioSettingsData,
  workcentreSettingsData,
  dropdownOptions,
  planTemplateGenerationState,
  updatePlanTemplateGenerationState,
  graphData,
}: TemplateSettingsProps) => {
  /** Begin - Maintain scenario settings state with useReducer */
  const [scenarioSettingsState] = useScenarioSettingsState(scenarioSettingsData)
  /** End - Maintain scenario settings state with useReducer */

  return (
    <>
      {isWorkcenterSelected && workcentreSettingsData && (
        <StepCardView
          snapshotId={snapshotId}
          projectId={projectId}
          scenarioId={scenarioId}
          planTemplateId={planTemplateId}
          sourceSiteId={sourceSiteId}
          workcentreId={workcentreId}
          scenarioSettingsState={scenarioSettingsState}
          workcentreSettingsData={workcentreSettingsData}
          dropdownOptions={dropdownOptions}
          planTemplateGenerationState={planTemplateGenerationState}
          updatePlanTemplateGenerationState={updatePlanTemplateGenerationState}
          graphData={graphData}
        />
      )}
    </>
  )
}
