import { Box, CircularProgress } from '@material-ui/core'
import { useStyles } from 'Features/ViewInventory/ViewInventory.styles'
import { ViewInventoryTable } from 'Features/ViewInventory/ViewInventory.table'
import { Button } from 'supplyvue-ui'
import { ReplenishmentSettingsOutputProps } from './ReplenishmentSettingsOutput.type'

//TODO:
// import { ViewInventoryDropDown } from 'Features/ViewInventory/ViewInventoryDropdown'
// const viewByOptions = [{ key: 'Sku group', value: 'skuGroup' }]

export const ReplenishmentSettingsOutput = ({
  tableData,
  isLoading,
  handleDownload,
  updateTableData,
  handleRowClick,
  handleInventoryHandlingCostClick,
  updatedRowValues,
  handleApproveClick,
  isApproveButtonBusy,
  getFilteredRows,
}: ReplenishmentSettingsOutputProps) => {
  const classes = useStyles()

  // const handleDropdownChange = () => {
  //   //Todo:
  // }

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {/* <PageHeader
        title=""
        rightElement={
          <></>
          //TODO:
          // <ViewInventoryDropDown
          //   label="View by"
          //   menuItems={viewByOptions}
          //   value="skuGroup"
          //   handleDropdownChange={handleDropdownChange}
          // />
        }
      /> */}
      <ViewInventoryTable
        isLoading={isLoading}
        data={tableData}
        handleRowClick={handleRowClick}
        updateTableData={updateTableData}
        updatedRowValues={updatedRowValues}
        getFilteredRows={getFilteredRows}
      />
      <Box className={classes.footer}>
        <Button
          onClick={handleApproveClick}
          variant="contained"
          className={classes.handlingCostButton}
          disabled={isApproveButtonBusy}
        >
          {isApproveButtonBusy && (
            <CircularProgress
              color="inherit"
              size={16}
              className={classes.progress}
            />
          )}
          Approve
        </Button>
        <Button
          onClick={handleInventoryHandlingCostClick}
          variant="contained"
          className={classes.handlingCostButton}
        >
          Inventory holding cost
        </Button>
        <Button onClick={handleDownload} variant="contained">
          Download
        </Button>
      </Box>
    </Box>
  )
}
