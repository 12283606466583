import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { TabPanel } from 'supplyvue-ui'
import { SummaryOfApprovedSitesGraph } from '../Graphs/SummaryOfApprovedSitesGraph.graph'
import {
  TOTAL_CURRENT_CYCLE_STOCK_VOLUME_LABEL,
  TOTAL_CURRENT_DURATION_LABEL,
  TOTAL_CURRENT_SAFETY_STOCK_VOLUME_LABEL,
  TOTAL_CURRENT_VALUE_LABEL,
  TOTAL_CURRENT_VOLUME_LABEL,
  TOTAL_PROPOSED_DURATION_LABEL,
  TOTAL_PROPOSED_SAFETY_STOCK_VOLUME_LABEL,
  TOTAL_PROPOSED_VALUE_LABEL,
  TOTAL_PROPOSED_VOLUME_LABEL,
  TOTAL_UNUSED_INVENTORY_LABEL,
} from './SummaryOfApprovedSites.constants'
import { useStyles } from './SummaryOfApprovedSites.styles'
import { SummaryOfApprovedSitesProps } from './SummaryOfApprovedSites.types'
import { TabGroups } from './TabGroups.view'
export type graphItem = {
  currentInventory: number
  label: string
  proposedInventory: number
}
export const SummaryOfApprovedSites = ({
  graphData,
}: SummaryOfApprovedSitesProps) => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setTabIndex(newValue)
  }

  const {
    totalCurrentDuration,
    totalCurrentSafetyStockVolume,
    totalCurrentValue,
    totalCurrentVolume,
    totalCycleStockVolume,
    totalProposedDuration,
    totalProposedSafetyStockVolume,
    totalProposedValue,
    totalProposedVolume,
    totalUnusedInventory,
  } = graphData

  const xAxisData = useMemo(() => {
    if (graphData) {
      return graphData?.duration?.map((item: graphItem) => item.label)
    }
    return []
  }, [graphData])

  const yAxisData = useMemo(() => {
    const yAxisDataOneForCycleStockVolume: number[] = []
    const yAxisDataTwoForCycleStockVolume: number[] = []
    const yAxisDataOneForDuration: number[] = []
    const yAxisDataTwoForDuration: number[] = []
    const yAxisDataOneForSafetyStockVolume: number[] = []
    const yAxisDataTwoForSafetyStockVolume: number[] = []
    const yAxisDataOneForUnusedInventoryVolume: number[] = []
    const yAxisDataTwoForUnusedInventoryVolume: number[] = []
    const yAxisDataOneForValue: number[] = []
    const yAxisDataTwoForValue: number[] = []
    const yAxisDataOneForVolume: number[] = []
    const yAxisDataTwoForVolume: number[] = []

    if (graphData) {
      graphData?.cycleStockVolume?.forEach((item: graphItem) =>
        yAxisDataOneForCycleStockVolume.push(item.currentInventory)
      )
      graphData?.duration?.forEach((item: graphItem) => {
        yAxisDataOneForDuration.push(item.currentInventory)
        yAxisDataTwoForDuration.push(item.proposedInventory)
      })
      graphData?.safetyStockVolume?.forEach((item: graphItem) => {
        yAxisDataOneForSafetyStockVolume.push(item.currentInventory)
        yAxisDataTwoForSafetyStockVolume.push(item.proposedInventory)
      })
      graphData?.unusedInventoryVolume?.forEach((item: graphItem) => {
        yAxisDataOneForUnusedInventoryVolume.push(item.currentInventory)
      })
      graphData?.value?.forEach((item: graphItem) => {
        yAxisDataOneForValue.push(item.currentInventory)
        yAxisDataTwoForValue.push(item.proposedInventory)
      })
      graphData?.volume?.forEach((item: graphItem) => {
        yAxisDataOneForVolume.push(item.currentInventory)
        yAxisDataTwoForVolume.push(item.proposedInventory)
      })
    }

    return {
      yAxisDataOneForCycleStockVolume,
      yAxisDataTwoForCycleStockVolume,
      yAxisDataOneForDuration,
      yAxisDataTwoForDuration,
      yAxisDataOneForSafetyStockVolume,
      yAxisDataTwoForSafetyStockVolume,
      yAxisDataOneForUnusedInventoryVolume,
      yAxisDataTwoForUnusedInventoryVolume,
      yAxisDataOneForValue,
      yAxisDataTwoForValue,
      yAxisDataOneForVolume,
      yAxisDataTwoForVolume,
    }
  }, [graphData])

  const commonGraphProps = {
    xAxisData,
    xAxisLabel: 'Site',
  }

  const Legend = ({ labelOne, labelTwo }: any) => {
    return (
      <Box>
        {labelOne && <Typography>{labelOne}</Typography>}
        {labelTwo && <Typography>{labelTwo}</Typography>}
      </Box>
    )
  }
  const classes = useStyles()

  const getLegendValue = (value: string | number | null) =>
    value ? value.toLocaleString('en-US') : 0

  return (
    <Box className={classes.wrapper} height="93%" width="100%">
      <Typography variant="h3">Summary of approved sites</Typography>
      <Paper className={classes.paper}>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="fullWidth"
        >
          <Tab label="By" />
          <Tab label="Stock type" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <TabGroups
            firstTabLabel="Duration"
            secondTabLabel="Volume"
            thirdTabLabel="Value"
            firstTabLegend={
              <Legend
                labelOne={`${TOTAL_CURRENT_DURATION_LABEL} : ${
                  totalCurrentDuration ?? 0
                } d`}
                labelTwo={`${TOTAL_PROPOSED_DURATION_LABEL} : ${
                  totalProposedDuration ?? 0
                } d`}
              />
            }
            secondTabLegend={
              <Legend
                labelOne={`${TOTAL_CURRENT_VOLUME_LABEL} : ${getLegendValue(
                  totalCurrentVolume
                )} `}
                labelTwo={`${TOTAL_PROPOSED_VOLUME_LABEL} : ${getLegendValue(
                  totalProposedVolume
                )} `}
              />
            }
            thirdTabLegend={
              <Legend
                labelOne={`${TOTAL_CURRENT_VALUE_LABEL} : £ ${getLegendValue(
                  totalCurrentValue
                )}`}
                labelTwo={`${TOTAL_PROPOSED_VALUE_LABEL} : £ ${getLegendValue(
                  totalProposedValue
                )}`}
              />
            }
            firstTabGraph={
              <SummaryOfApprovedSitesGraph
                yAxisDataOne={yAxisData.yAxisDataOneForDuration}
                yAxisDataTwo={yAxisData.yAxisDataTwoForDuration}
                yAxisLabel="Duration"
                {...commonGraphProps}
              />
            }
            secondGraph={
              <SummaryOfApprovedSitesGraph
                yAxisDataOne={yAxisData.yAxisDataOneForVolume}
                yAxisDataTwo={yAxisData.yAxisDataTwoForVolume}
                yAxisLabel="Volume"
                {...commonGraphProps}
              />
            }
            thirdGraph={
              <SummaryOfApprovedSitesGraph
                yAxisDataOne={yAxisData.yAxisDataOneForValue}
                yAxisDataTwo={yAxisData.yAxisDataTwoForValue}
                yAxisLabel="Value"
                {...commonGraphProps}
              />
            }
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <TabGroups
            firstTabLabel="Unused inventory"
            secondTabLabel="Safety stock"
            thirdTabLabel="Cycle stock"
            firstTabLegend={
              <Legend
                labelOne={`${TOTAL_UNUSED_INVENTORY_LABEL} : ${getLegendValue(
                  totalUnusedInventory
                )}`}
              />
            }
            secondTabLegend={
              <Legend
                labelOne={`${TOTAL_CURRENT_SAFETY_STOCK_VOLUME_LABEL} : ${getLegendValue(
                  totalCurrentSafetyStockVolume
                )} `}
                labelTwo={`${TOTAL_PROPOSED_SAFETY_STOCK_VOLUME_LABEL} : ${getLegendValue(
                  totalProposedSafetyStockVolume
                )} `}
              />
            }
            thirdTabLegend={
              <Legend
                labelOne={`${TOTAL_CURRENT_CYCLE_STOCK_VOLUME_LABEL} : ${getLegendValue(
                  totalCycleStockVolume
                )} `}
              />
            }
            firstTabGraph={
              <SummaryOfApprovedSitesGraph
                yAxisDataOne={yAxisData.yAxisDataOneForUnusedInventoryVolume}
                yAxisDataTwo={yAxisData.yAxisDataTwoForUnusedInventoryVolume}
                yAxisLabel="Volume"
                {...commonGraphProps}
              />
            }
            secondGraph={
              <SummaryOfApprovedSitesGraph
                yAxisDataOne={yAxisData.yAxisDataOneForSafetyStockVolume}
                yAxisDataTwo={yAxisData.yAxisDataTwoForSafetyStockVolume}
                yAxisLabel="Volume"
                {...commonGraphProps}
              />
            }
            thirdGraph={
              <SummaryOfApprovedSitesGraph
                yAxisDataOne={yAxisData.yAxisDataOneForCycleStockVolume}
                yAxisDataTwo={yAxisData.yAxisDataTwoForCycleStockVolume}
                yAxisLabel="Volume"
                {...commonGraphProps}
              />
            }
          />
        </TabPanel>
      </Paper>
    </Box>
  )
}
