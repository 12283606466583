export const MAXIMUM_MIN_CYCLE_VALUE = 8
export const MINIMUM_MIN_CYCLE_VALUE = 1
export const FOQ = 'FOQ'
export const FOC = 'FOC'

export const MAX_FILE_SIZE = 52428800 // 50MB

export const PROJECT_ACCESS_OPTIONS = {
  'Generated by me': 'Generated by me',
  'Not generated by me': 'Not generated by me',
  'Generated by anyone': 'Generated by anyone',
}

export const PROJECT_VISIBLITY = {
  PUBLIC: 'public',
  PRIVATE: 'private',
}
