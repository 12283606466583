import { Box, Typography } from '@material-ui/core'
//import { ChangeEvent, useState } from 'react'
import { DropDown } from 'supplyvue-ui'
import { useStyles } from './SkuGroupInventoryAnalysis.styles'
import { SkuGroupInventoryAnalysisGraph } from './SkuGroupInventoryAnalysis.graph'
import { SkuGroupInventoryGraphPropsTypes } from './SkuGroupInventoryAnalysis.types'
import { ChangeEvent, useMemo } from 'react'

export const SkuGroupInventoryGraphView = ({
  historyLength,
  historyOptions,
  handleHistoryLengthChange,
  data,
}: SkuGroupInventoryGraphPropsTypes) => {
  const classes = useStyles()

  const xAxisData = useMemo(() => {
    if (data.graphData) {
      return data.graphData.map(({ label }) => label)
    }
    return []
  }, [data])

  const totalSalesVolumeY = useMemo(() => {
    if (data.graphData) {
      return data.graphData.map(({ totalSalesVolume }) => totalSalesVolume)
    }
    return []
  }, [data])

  const forecastVolumeY = useMemo(() => {
    if (data.graphData) {
      return data.graphData.map(({ forecastVolume }) => forecastVolume)
    }
    return []
  }, [data])

  const forecastAverageY = Array.from({
    length: data.graphData?.length ?? 0,
  }).map(() => data.forecastAverage)

  const salesAverageY = Array.from({
    length: data.graphData?.length ?? 0,
  }).map(() => data.salesAverage)

  const salesAveragePlusOneSDY = Array.from({
    length: data.graphData?.length ?? 0,
  }).map(() => data.salesAveragePlusOneSD)

  const salesAveragePlusTwoSDY = Array.from({
    length: data.graphData?.length ?? 0,
  }).map(() => data.salesAveragePlusTwoSD)

  return (
    <Box display="flex" flexDirection="column" padding={2}>
      <Box>
        <Typography variant="h4">Week planning period</Typography>
      </Box>
      <Box display="flex" marginTop={1}>
        <Box>
          <DropDown
            className={classes.select}
            label="Select history length"
            defaultLabel="Select history length"
            menuItems={historyOptions}
            value={historyLength ?? ''}
            onChange={(
              e: ChangeEvent<{
                name?: string | undefined
                value: unknown
              }>
            ) => handleHistoryLengthChange(e.target.value as number)}
          />
        </Box>
        <Box marginLeft={1}>
          {/* <Box>
            <DropDown
              className={classes.select}
              label="Select period length"
              defaultLabel="Select period length"
              menuItems={periodLengthOptions}
              value={periodLength ?? ''}
              onChange={(
                e: ChangeEvent<{
                  name?: string | undefined
                  value: unknown
                }>
              ) => handlePeriodChange(e.target.value as string)}
            />
          </Box> */}
        </Box>
      </Box>
      <Box marginTop={2}>
        <SkuGroupInventoryAnalysisGraph
          xAxisData={xAxisData}
          forecastAverageY={forecastAverageY}
          forecastVolumeY={forecastVolumeY}
          salesAverageY={salesAverageY}
          salesAveragePlusOneSDY={salesAveragePlusOneSDY}
          salesAveragePlusTwoSDY={salesAveragePlusTwoSDY}
          totalSalesVolumeY={totalSalesVolumeY}
        />
      </Box>
    </Box>
  )
}
