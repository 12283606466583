import { MenuItem } from './InventoryAnalysisFilters.types'

export const CYCLE_TIME_MENU_ITEMS: MenuItem[] = [
  {
    key: '1',
    value: 1,
  },
  {
    key: '2',
    value: 2,
  },
  {
    key: '4',
    value: 4,
  },
  {
    key: '8',
    value: 8,
  },
]
