import {
  AccordionSummary as MuiAccordionSummary,
  Typography,
  Checkbox,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FormControlLabel } from 'Features/Project/WorkcentreModal/FormControlLabel/FormControlLabel.view'

import { useStyles } from './AccordionSummary.styles'
import { AccordionSummaryProps } from './AccordionSummary.types'
import {
  isAllWorkcentresSelected,
  isSomeWorkCentresSelected,
  isAllProductCountsInSiteWorkCentersZero,
} from 'Features/Project/WorkcentreModal/WorkcentreModal.utils'

export const AccordionSummary = ({
  sourceSiteData,
  handleCheckboxChange,
  readOnly,
}: AccordionSummaryProps) => {
  const classes = useStyles()
  return (
    <MuiAccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={sourceSiteData.siteLabel}
      classes={{
        root: classes.accordionSummaryRoot,
        content: classes.accordionSummaryContent,
        expanded: classes.accordionSummaryContentExpanded,
      }}
    >
      {readOnly ? (
        <Typography>{sourceSiteData.siteLabel}</Typography>
      ) : (
        <FormControlLabel
          label={sourceSiteData.siteLabel}
          control={
            <Checkbox
              disabled={isAllProductCountsInSiteWorkCentersZero(
                sourceSiteData.siteWorkcentres
              )}
              color="primary"
              className={classes.checkbox}
              checked={isAllWorkcentresSelected(sourceSiteData.siteWorkcentres)}
              indeterminate={isSomeWorkCentresSelected(
                sourceSiteData.siteWorkcentres
              )}
              onChange={(event) =>
                handleCheckboxChange(event, sourceSiteData.siteLabel)
              }
            />
          }
        />
      )}
    </MuiAccordionSummary>
  )
}
