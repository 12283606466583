import { ErrorBoundary as Boundary } from 'react-error-boundary'
import { ErrorFallback } from 'Features/Error/ErrorFallback'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const appInsights = useAppInsightsContext()
  const errorHandler = (error: Error) => {
    console.log('Logging error to App Insights')
    appInsights.trackException({
      exception: error,
      properties: { errorBoundary: true },
    })
  }
  return (
    <Boundary FallbackComponent={ErrorFallback} onError={errorHandler}>
      {children}
    </Boundary>
  )
}
