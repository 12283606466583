import { useQuery } from 'react-query'
import { requestV2 } from 'Features/Auth/axios-client'

const fetchCompareScenarios = async (projectId: string) => {
  const { data } = await requestV2({
    url: `/projects/${projectId}/compare-scenarios`,
  })
  return data
}

export const useCompareScenariosData = (projectId: string) => {
  return useQuery(
    ['compare-scenarios', projectId],
    () => fetchCompareScenarios(projectId),
    {
      select: (data) => data,
      refetchOnWindowFocus: false,
    }
  )
}
