import { useQuery, useQueryClient, useMutation } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import {
  CalculateMultiplePlanTemplateVariables,
  ExportPlanTemplatesVariables,
  MultipleTemplateErrorResponse,
  UpdatePlanTemplateVariables,
  UseUpdatePlanTemplateParams,
} from './PlanTemplateSummary.types'

const fetchPlanTemplateSummary = async (
  projectId: string,
  planTemplateId: string
) => {
  const { data } = await request({
    url: `/projects/${projectId}/plan-templates/${planTemplateId}/summary`,
  })
  return data
}

export const usePlanTemplateSummaryData = (
  projectId: string,
  planTemplateId: string
) => {
  return useQuery(
    ['plan-template-summary', projectId, planTemplateId],
    () => fetchPlanTemplateSummary(projectId, planTemplateId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  )
}

const updatePlanTemplateSummary = async (
  updatePlanTemplateVariables: UpdatePlanTemplateVariables
) => {
  const { data } = await request({
    url: `/plan-templates`,
    method: 'put',
    data: updatePlanTemplateVariables,
  })

  if (
    data?.message &&
    updatePlanTemplateVariables.crewCostPerHour !== undefined
  ) {
    data.message = 'Crew cost per hour has been updated successfully.'
  } else if (
    data?.message &&
    updatePlanTemplateVariables.productionCostPerHour !== undefined
  ) {
    data.message = 'Production cost per hour has been updated successfully.'
  } else if (
    data?.message &&
    updatePlanTemplateVariables.workcentreCapacity !== undefined
  ) {
    data.message = 'Capacity hours has been updated successfully'
  } else if (
    data?.message &&
    updatePlanTemplateVariables.preferredMethod !== undefined
  ) {
    data.message = 'Preferred plan template method updated successfully'
  } else if (
    data?.message &&
    updatePlanTemplateVariables.settingsOptions !== undefined
  ) {
    data.message = 'Settings Option updated successfully'
  }

  return data
}

export const useUpdatePlanTemplateSummary = ({
  onSuccess,
  onError,
}: UseUpdatePlanTemplateParams) => {
  const queryClient = useQueryClient()
  return useMutation(updatePlanTemplateSummary, {
    onSuccess: async ({ message }) => {
      await queryClient.invalidateQueries('plan-template-summary')
      onSuccess(message)
    },
    onError: async ({ response }) => {
      await queryClient.invalidateQueries('plan-template-summary')
      onError(response.message)
    },
  })
}

const fetchPlanTemplateSettings = async (
  projectId: string,
  planTemplateId: string
) => {
  const { data } = await request({
    url: `/projects/${projectId}/plan-templates/${planTemplateId}/settings`,
  })
  return data
}

export const usePlanTemplateSettingsData = (
  projectId: string,
  planTemplateId: string
) => {
  return useQuery(
    ['plan-template-settings', projectId, planTemplateId],
    () => fetchPlanTemplateSettings(projectId, planTemplateId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  )
}
const calculateMultiplePlanTemplates = async (
  calculateMultiplePlanTemplateVariables: CalculateMultiplePlanTemplateVariables
) => {
  const { data } = await request({
    url: `/plan-templates/run-multiple-templates`,
    method: 'put',
    data: {
      data: {
        snapshotId: calculateMultiplePlanTemplateVariables.snapshotId,
        projectId: calculateMultiplePlanTemplateVariables.projectId,
        planTemplateId: calculateMultiplePlanTemplateVariables.planTemplateId,
        summaryData: calculateMultiplePlanTemplateVariables.summaryData,
      },
    },
  })
  return data
}

export const useCalculateMultiplePlanTemplates = (
  onSuccess: () => void,
  onError: (errorResponse: MultipleTemplateErrorResponse) => void
) => {
  return useMutation(calculateMultiplePlanTemplates, {
    onSuccess: () => {
      onSuccess()
    },
    onError: ({ response }) => {
      onError(response.data)
    },
  })
}

const fetchGeneratingStatuses = async (planTemplateId: string) => {
  const { data } = await request({
    url: `/plan-templates/${planTemplateId}/templates-generating-status`,
    method: 'get',
  })
  return data
}

export const useGeneratingStatuses = (planTemplateId: string) => {
  const queryClient = useQueryClient()
  return useQuery(
    ['templates-generating-status', planTemplateId],
    () => fetchGeneratingStatuses(planTemplateId),
    {
      select: (data) => data?.data,
      refetchInterval: 30 * 1000,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: async () => {
        await queryClient.invalidateQueries('plan-template-summary')
      },
      onError: async () => {
        await queryClient.invalidateQueries('plan-template-summary')
      },
    }
  )
}

const exportPlanTemplates = async (
  exportPlanTemplateVariables: ExportPlanTemplatesVariables
) => {
  const { data } = await request({
    url: `/plan-templates/export-templates`,
    method: 'post',
    data: {
      planTemplateId: exportPlanTemplateVariables.planTemplateId,
      workcentreModeExports: exportPlanTemplateVariables.workcentreModeExports,
    },
  })
  return data
}

export const useExportPlanTemplates = (
  onSuccess: () => void,
  onError: () => void
) => {
  return useMutation(exportPlanTemplates, {
    onSuccess: () => {
      onSuccess()
    },
    onError: () => {
      onError()
    },
  })
}
