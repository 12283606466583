import { useQuery, useQueryClient, useMutation } from 'react-query'
import { requestV2 } from 'Features/Auth/axios-client'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { APIResponseHandler } from 'Common/Types/Common.types'
import { UpdateReplenishmentSettingsOutputParams } from './ViewInventory.types'

const fetchInventoryModellingData = async (
  replenishmentPolicyId: string,
  sourceSiteId: string,
  siteKey: string
) => {
  const { data } = await requestV2({
    url: `/replenishment-policies/${replenishmentPolicyId}/source-sites/${sourceSiteId}/replenishment-settings-output?site=${siteKey}`,
  })

  return data
}

export const useInventoryModellingData = (
  replenishmentPolicyId: string,
  sourceSiteId: string,
  siteKey: string
) => {
  return useQuery(
    [
      'replenishment-settings-output',
      replenishmentPolicyId,
      sourceSiteId,
      siteKey,
    ],
    () =>
      fetchInventoryModellingData(replenishmentPolicyId, sourceSiteId, siteKey),
    {
      select: ({ data }) => {
        return data
      },
      refetchOnWindowFocus: false,
    }
  )
}

const downloadInventoryModellingCSV = async (
  replenishmentPolicyId: string,
  sourceSiteId: string,
  siteKey: string,
  method: string
) => {
  const { data } = await requestV2({
    responseType: 'blob',
    url: `/replenishment-policies/${replenishmentPolicyId}/source-sites/${sourceSiteId}/replenishment-settings-output?site=${siteKey}&method=${method}&download=true`,
  })
  const fileName = `inventory_modelling_${format(new Date(), 'yyyyMMdd')}`
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  return data
}

export const useDownloadInventoryModellingFile = (
  replenishmentPolicyId: string,
  sourceSiteId: string,
  siteKey: string,
  method: string
) => {
  return useQuery(
    [
      'download-replenishment-settings-output',
      replenishmentPolicyId,
      sourceSiteId,
      siteKey,
      method,
    ],
    () =>
      downloadInventoryModellingCSV(
        replenishmentPolicyId,
        sourceSiteId,
        siteKey,
        method
      ),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      enabled: false,
    }
  )
}

const applySafetyStockMethod = async (method: string) => {
  return localStorage.setItem('currentSafetyStockMethod', method)
}

export const useSafetyStockMethod = (
  method: string | null
): [string | null | undefined, (method: string) => void] => {
  const qc = useQueryClient()

  const { data } = useQuery(['current-safety-stock-method'], () =>
    localStorage.getItem('currentSafetyStockMethod')
  )

  const { mutate: setSafetyStockMethod } = useMutation(applySafetyStockMethod, {
    onMutate: (mutatedData) => {
      const current = data
      qc.setQueryData('current-safety-stock-method', mutatedData)
      return current
    },
  })

  useEffect(() => {
    if (method !== null) {
      setSafetyStockMethod(method)
    }
  }, [])

  return [data, setSafetyStockMethod]
}

const updateReplenishmentSettingsOutputData = async ({
  sourceSiteId,
  replenishmentPolicyId,
  updatedData,
  siteKey,
}: UpdateReplenishmentSettingsOutputParams) => {
  const { data } = await requestV2({
    url: `/replenishment-policies/${replenishmentPolicyId}/source-sites/${sourceSiteId}/replenishment-settings-output`,
    method: 'put',
    data: {
      siteKey,
      data: updatedData,
    },
  })
  return data
}

export const useUpdateReplenishmentSettingsOutputData = ({
  onSuccess,
  onError,
}: APIResponseHandler) => {
  const queryClient = useQueryClient()

  return useMutation(updateReplenishmentSettingsOutputData, {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries('replenishment-settings-output')
      queryClient.invalidateQueries('site-connections')
      queryClient.invalidateQueries('kpi-table-data')
      queryClient.invalidateQueries('analysis-summary')
      onSuccess(message)
    },
    onError: ({ response }) => onError(response.data.error),
  })
}
