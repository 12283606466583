import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    border: `1px solid ${theme.palette.borders.grey}`,
    display: 'flex',
    padding: '5px',
    '& svg, & h4': {
      margin: '5px',
      cursor: 'pointer',
      fontSize: '1rem',
    },
    '& hr': {
      margin: '0.5rem',
    },
  },
}))
