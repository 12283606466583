import { BatchSettingsParametersType } from './BulkUpdate.types'

export const INITIAL_STATE: BatchSettingsParametersType = {
  batchGroupingCode: '',
  minimumBatchSize: '',
  minimumBatchSizeIncrement: '',
  unitOfMeasure: '',
  unitOfMeasureId: '',
  replenishmentType: '',
  replenishmentTypeId: '',
  salesRate: '',
  minimumCycle: '',
  maximumCycle: '',
  runRate: '',
  minimumBatchSizeActionType: '0',
  minimumBatchSizeIncrementActionType: '0',
  runRateActionType: '0',
}
