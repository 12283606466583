import { useMemo } from 'react'
import { Graph, hovertemplate } from 'Common/Graph/Graph'
import { SegmentGraphProps } from './SegmentGraph.types'
const color = [
  'rgb(78,121,167)',
  'rgb(160,203,232)',
  'rgb(242,142,43)',
  'rgb(255,190,125)',
  'rgb(89,161,79)',
  'rgb(136,207,121)',
  'rgb(182,153,45)',
  'rgb(241,206,99)',
  'rgb(73,152,148)',
  'rgb(134,188,182)',
  'rgb(225,87,89)',
  'rgb(255,157,154)',
  'rgb(211,201,201)',
]
export const SegmentGraph = ({
  xAxisData,
  proposedInventory,
  currentInventory,
  yAxisLabel,
}: SegmentGraphProps) => {
  const data = useMemo(
    () => [
      {
        x: xAxisData,
        y: proposedInventory,
        hovertemplate: hovertemplate('Segment', 'Proposed inventory '),
        marker: {
          color: color,
        },
        name: 'Proposed inventory',
        type: 'bar',
      },
      {
        x: xAxisData,
        y: currentInventory,
        hovertemplate: hovertemplate('Segment ', 'Current inventory'),
        marker: {
          color: color,
        },
        name: 'Current inventory',
        type: 'bar',
      },
    ],
    [xAxisData, proposedInventory, currentInventory]
  )

  const layout = {
    height: 700,
    yaxis: {
      showgrid: true,
      title: {
        text: yAxisLabel,
      },
      automargin: true,
    },
    xaxis: {
      zeroline: true,
      nticks: 13,
      tickangle: -90,
      title: {
        text: 'Segment',
        standoff: 1,
      },
    },
    legend: { orientation: 'h', x: 0.15, y: -0.3 },
  }

  return <Graph data={data} layout={layout} />
}
