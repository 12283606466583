import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.palette.common.white,
    height: '40px',
  },
}))

export const autocompleteStyles = {
  width: '300px',
  height: '60px',
  border: 'none',
  backgroundColor: 'white',
}
