import { useMemo } from 'react'
import { PlanDetailsColumns } from 'Features/PlanDetails/PlanDetails.types'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'
import { Table as MUITable } from 'Common/MUITable/MUITable'
import { useStyles } from './PlanDetails.styles'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import clsx from 'clsx'

type SequenceTableProps = {
  data: any
}

export const SequenceTable = ({ data }: SequenceTableProps) => {
  const classes = useStyles()

  const tableData = data.map((item: any, idx: number) => {
    return {
      ...item,
      id: idx,
    }
  })

  const skuCodes = [
    ...new Set(
      data.map((item: PlanDetailsColumns) => {
        return item.productCode
      })
    ),
  ]

  const batchCodes = [
    ...new Set(
      data.map((item: PlanDetailsColumns) => {
        return item.batchGroupCode
      })
    ),
  ]

  const repl_types = [
    ...new Set(
      data.map((item: PlanDetailsColumns) => {
        return item.replenishmentType
      })
    ),
  ]

  const renderZero = (value: number) => {
    if (value === 0) {
      return <div></div>
    } else {
      return <div>{value}</div>
    }
  }

  const MUIColumns: GridColDef[] = useMemo(() => {
    if (!data) {
      return []
    }

    return [
      {
        field: 'workcentreCode',
        headerName: 'Workcentre',
        type: 'string',
        width: 200,
      },
      {
        field: 'productCode',
        headerName: 'SKU group code',
        sortable: true,
        filterable: true,
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuCodes,
              label: 'Sku codes',
            },
          },
        ],
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        renderCell: (params: any) => {
          const { description } = params.row
          if (description === 'nan') {
            return <div></div>
          } else {
            return <div>{description}</div>
          }
        },
        type: 'string',
        flex: 2,
      },
      {
        field: 'batchGroupCode',
        headerName: 'Batch group code',
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: batchCodes,
              label: 'search',
            },
          },
        ],
      },
      {
        field: 'replenishmentType',
        headerName: 'Replenishment Type',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            foq: params.row.replenishmentType === 'FOQ',
          })
        },
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: repl_types,
              label: 'search',
            },
          },
        ],
        type: 'string',
        width: 175,
      },
      {
        field: 'minimumOrderQuantity',
        headerName: 'MOQ',
        type: 'number',
        flex: 1,
      },
      {
        field: 'minimumOrderIncrement',
        headerName: 'MOI',
        type: 'number',
        flex: 1,
      },
      {
        field: 'cycle',
        headerName: 'Cycle',
        type: 'number',
      },
      {
        field: 'week_1',
        headerName: 'Week 1',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            zero: params.row.week_1 !== 0,
          })
        },
        renderCell: (params: GridCellParams) => renderZero(params.row.week_1),
        type: 'number',
      },
      {
        field: 'week_2',
        headerName: 'Week 2',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            zero: params.row.week_2 !== 0,
          })
        },
        renderCell: (params: any) => renderZero(params.row.week_2),
        type: 'number',
      },
      {
        field: 'week_3',
        headerName: 'Week 3',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            zero: params.row.week_3 !== 0,
          })
        },
        renderCell: (params: any) => renderZero(params.row.week_3),
        type: 'number',
      },
      {
        field: 'week_4',
        headerName: 'Week 4',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            zero: params.row.week_4 !== 0,
          })
        },
        renderCell: (params: any) => renderZero(params.row.week_4),
        type: 'number',
      },
      {
        field: 'week_5',
        headerName: 'Week 5',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            zero: params.row.week_5 !== 0,
          })
        },
        renderCell: (params: any) => renderZero(params.row.week_5),
        type: 'number',
      },
      {
        field: 'week_6',
        headerName: 'Week 6',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            zero: params.row.week_6 !== 0,
          })
        },
        renderCell: (params: any) => renderZero(params.row.week_6),
        type: 'number',
      },
      {
        field: 'week_7',
        headerName: 'Week 7',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            zero: params.row.week_7 !== 0,
          })
        },
        renderCell: (params: any) => renderZero(params.row.week_7),
        type: 'number',
      },
      {
        field: 'week_8',
        headerName: 'Week 8',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            zero: params.row.week_8 !== 0,
          })
        },
        renderCell: (params: any) => renderZero(params.row.week_8),
        type: 'number',
      },
    ]
  }, [data])

  return (
    <div className={classes.runTimeTable}>
      <MUITable
        rows={tableData}
        columns={MUIColumns}
        rowHeight={40}
        showCellVerticalBorder
        showColumnVerticalBorder
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pagination
        pageSizeOptions={[20, 30, 50]}
        disableRowSelectionOnClick
      />
    </div>
  )
}
