import { Box } from '@material-ui/core'
import { Button } from 'supplyvue-ui'
import AddIcon from 'Assets/icons/add.svg'
import { DemandScenarioHeaderActionsProps } from './DemandScenarioHeaderActions.types'
import { useStyles } from './DemandScenarioHeaderActions.styles'

export const DemandScenarioHeaderActions = ({
  handleNewDemandScenarioClick,
}: DemandScenarioHeaderActionsProps) => {
  const classes = useStyles()
  return (
    <Box className={classes.wrapper}>
      <Button startIcon={<AddIcon />} onClick={handleNewDemandScenarioClick}>
        New demand scenario
      </Button>
    </Box>
  )
}
