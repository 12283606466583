import { useMutation, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import { updatePlanTemplateSettingsParamsType } from './UpdatePlanTemplateSettingsModal.types'
import { APIResponseHandler } from 'Common/Types/Common.types'

const updatePlanTemplateSettings = async (
  planTemplateValues: updatePlanTemplateSettingsParamsType
) => {
  const { data } = await request({
    url: `/plan-templates`,
    method: 'put',
    data: planTemplateValues,
  })
  return data
}

export const useUpdatePlanTemplateSettingsData = ({
  onSuccess,
  onError,
}: APIResponseHandler) => {
  const queryClient = useQueryClient()

  return useMutation(updatePlanTemplateSettings, {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries('plan-template-settings')
      onSuccess(message)
    },
    onError: ({ response }) => onError(response.data.error),
  })
}
