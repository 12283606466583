import { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Table } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { ReplenishmentSettingsTableProps } from 'Features/NewPlanTemplate/ReplenishmentSettings/ReplenishmentSettings.types'
import { Loader } from 'Common/Loader/Loader'
import { useStyles } from './ReplenishmentSettings.styles'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'

export const ReplenishmentSettingsTable = ({
  data,
  setSelectedRows,
  isLoading,
}: ReplenishmentSettingsTableProps) => {
  const classes = useStyles()

  const tableData = data.map((item: any, idx: any) => {
    return {
      ...item,
      id: idx,
    }
  })

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(
      tableData.filter((row: { id: GridRowId }) => selectedIds.has(row.id))
    )
  }

  const skuCodes = [...new Set(data.map((item: any) => item.skuGroupCode))]
  const sourceSiteCodes = [
    ...new Set(data.map((item: any) => item.baseSourceSiteCode)),
  ]
  const siteCodes = [
    ...new Set(data.map((item: any) => item.destinationSiteCode)),
  ]

  const columns: GridColDefPremium[] | any[] = useMemo(() => {
    if (!data) {
      return []
    }
    return [
      {
        field: 'skuGroupCode',
        headerName: 'SKU group code',
        minWidth: 150,
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuCodes,
              label: 'Sku codes',
            },
          },
        ],
        flex: 1,
      },
      {
        field: 'baseSourceSiteCode',
        headerName: 'Source Site',
        minWidth: 150,
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: sourceSiteCodes,
              label: 'Source Site codes',
            },
          },
        ],
        flex: 1,
      },
      {
        field: 'destinationSiteCode',
        headerName: 'Site Code',
        minWidth: 150,
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: siteCodes,
              label: 'Site codes',
            },
          },
        ],
        flex: 1,
      },
      {
        field: 'workcentre',
        headerName: 'Work Centre',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'primaryWorkcentreCode',
        headerName: 'Primary Work Centre',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'supplyType',
        headerName: 'Supply Type',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'replenishmentType',
        headerName: 'Replenishment Type',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'segment',
        headerName: 'Segment',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'planningPeriod',
        headerName: 'Planning Period',
        minWidth: 150,
        type: 'number',
        flex: 1,
      },
      {
        field: 'deploymentLeadTime',
        headerName: 'Deployment Lead Time (days)',
        minWidth: 150,
        type: 'number',
        flex: 1,
      },
      {
        field: 'replenishmentCycle',
        headerName: 'Replenishment Cycle',
        minWidth: 150,
        type: 'number',
        flex: 1,
      },
      {
        field: 'minimumOrderQuantity',
        headerName: 'MOQ',
        minWidth: 150,
        type: 'number',
        flex: 1,
      },
      {
        field: 'minimumOrderIncrement',
        headerName: 'MOI',
        minWidth: 150,
        type: 'number',
        flex: 1,
      },
      {
        field: 'serviceLevel',
        headerName: 'Service Level',
        minWidth: 150,
        type: 'string',
        flex: 1,
      },
      {
        field: 'include',
        headerName: 'Stocked',
        minWidth: 150,
        type: 'boolean',
        flex: 1,
      },
    ]
  }, [data])

  if (!data) {
    return <Loader />
  }

  return (
    <Box className={classes.tableContainer}>
      <Table
        rows={tableData}
        checkboxSelection
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
        pagination
        pageSizeOptions={[10, 20, 30, 50]}
        experimentalFeatures={{ lazyLoading: true }}
        loading={isLoading}
      />
    </Box>
  )
}
