import { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { request, requestV2 } from 'Features/Auth/axios-client'
import {
  ApproveNetworkVariables,
  UseApproveNetworkParams,
  SiteConnectionNode,
} from './ReplenishmentPolicy.types'

const fetchSourceSites = async (projectId: string, scenarioId: string) => {
  const { data } = await request({
    url: `/projects/${projectId}/scenarios/${scenarioId}/source-sites?with-workcentres=true`,
  })
  return data
}

export const useSourceSitesData = (projectId: string, scenarioId: string) => {
  return useQuery(
    ['source-sites'],
    () => fetchSourceSites(projectId, scenarioId),
    {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
    }
  )
}

const fetchSiteConnections = async (
  projectId: string,
  snapshotId: string,
  scenarioId: string,
  replenishmentPolicyId: string,
  baseSourceSiteId: number | undefined,
  workcentreKey: number | undefined
) => {
  const { data } = await requestV2({
    url: `/site-connections`,
    method: 'post',
    data: {
      projectId,
      snapshotId,
      scenarioId,
      replenishmentPolicyId,
      baseSourceSiteId,
      ...(!!workcentreKey && { workcentreKey }),
    },
  })
  return data
}
export const useSiteConnectionsDataFake = (
  projectId: string,
  snapshotId: string,
  scenarioId: string,
  replenishmentPolicyId: string,
  baseSourceSiteId: number | undefined,
  workcentreKey: number | undefined,
  onSuccess: (isPolling: boolean, message?: string) => void,
  onError: (message: string) => void
) => {
  const [shouldRefetch, setShouldRefetch] = useState(true)

  return useQuery(
    [
      'site-connections-fake',
      snapshotId,
      replenishmentPolicyId,
      baseSourceSiteId,
      workcentreKey,
    ],
    () =>
      fetchSiteConnections(
        projectId,
        snapshotId,
        scenarioId,
        replenishmentPolicyId,
        baseSourceSiteId,
        workcentreKey
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: shouldRefetch ? 10000 : false,
      enabled: !!snapshotId && !!replenishmentPolicyId && !!baseSourceSiteId,
      onSuccess: async (response) => {
        if (response.data.pollData) {
          setShouldRefetch(true)
          onSuccess(true)
          return
        }
        const stillProcessing = response?.data?.node?.some(
          (node: SiteConnectionNode) => node.approvalStatus === 'PROCESSING'
        )

        if (stillProcessing) {
          setShouldRefetch(true)
          onSuccess(true)
          return
        }

        setShouldRefetch(false)
        if (
          response.data.nodes.every(
            (node: SiteConnectionNode) => node.approvalStatus === 'FAILED'
          )
        ) {
          onSuccess(
            false,
            'Could not approve network. Please retry or contact support team if the problem persists.'
          )
        }
      },
      onError: ({ response }) => {
        setShouldRefetch(false)
        onError(response.data.error)
      },
    }
  )
}

export const useSiteConnectionsData = (
  projectId: string,
  snapshotId: string,
  scenarioId: string,
  replenishmentPolicyId: string,
  baseSourceSiteId: number | undefined,
  workcentreKey: number | undefined,
  onSuccess: (isPolling: boolean, message?: string) => void,
  onError: (message: string) => void
) => {
  const [shouldRefetch, setShouldRefetch] = useState(true)

  return useQuery(
    [
      'site-connections',
      snapshotId,
      replenishmentPolicyId,
      baseSourceSiteId,
      workcentreKey,
    ],
    () =>
      fetchSiteConnections(
        projectId,
        snapshotId,
        scenarioId,
        replenishmentPolicyId,
        baseSourceSiteId,
        workcentreKey
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: shouldRefetch ? 10000 : false,
      enabled: !!snapshotId && !!replenishmentPolicyId && !!baseSourceSiteId,
      onSuccess: async (response) => {
        if (response.data.pollData) {
          setShouldRefetch(true)
          onSuccess(true)
          return
        }

        const stillProcessing = response?.data?.nodes?.some(
          (node: SiteConnectionNode) => node.approvalStatus === 'PROCESSING'
        )
        if (stillProcessing) {
          setShouldRefetch(true)
          return
        }

        setShouldRefetch(false)
        if (
          response.data.nodes.every(
            (node: SiteConnectionNode) => node.approvalStatus === 'FAILED'
          )
        ) {
          onSuccess(
            false,
            'Could not approve network. Please retry or contact support team if the problem persists.'
          )
        } else {
          onSuccess(false)
        }
      },
      onError: ({ response }) => {
        setShouldRefetch(false)
        onError(response.data.error)
      },
    }
  )
}

const approveNetwork = async (
  approveNetworkVariables: ApproveNetworkVariables
) => {
  const { projectId, snapshotId, scenarioId, baseSourceSiteKey, periodType } =
    approveNetworkVariables
  const { data } = await request({
    url: `/safety-stock/calculate`,
    method: 'put',
    data: {
      projectId,
      snapshotId,
      scenarioId,
      baseSourceSiteKey,
      periodType,
    },
  })
  return data
}

export const useApproveNetwork = ({
  onSuccess,
  onError,
}: UseApproveNetworkParams) => {
  const queryClient = useQueryClient()

  return useMutation(approveNetwork, {
    onSuccess: async ({ message }) => {
      await queryClient.invalidateQueries('site-connections')
      onSuccess(message)
    },
    onError: async ({ response }) => {
      await queryClient.invalidateQueries('site-connections')
      onError(response.data.error)
    },
  })
}
