import { Paper, Box, Typography } from '@material-ui/core'
import { Input, DropDown } from 'supplyvue-ui'
import { useStyles } from './UpdatePlanTemplateSettingsModal.styles'
import { PlanTemplateSettingsProps } from './UpdatePlanTemplateSettingsModal.types'
import {
  SALES_RATE_TYPE,
  MIN_AVG_BATCHES_PER_CYCLE,
  SET_SALES_RATE_TYPE,
  SET_SALES_RATE_PERIOD,
  SET_MIN_AVG_BATCH_PER_CYCLE,
  SET_PLANTEMPLATE_BASED_ON,
} from './PlanTemplateSettings.constants'
import { PLAN_TEMPLETE_BASED_ON_OPTIONS } from 'Features/PlanTemplate/WorkcenterSettings/WorkcenterSettings.constants'

export const PlanTemplateSettingsView = ({
  salesRateTypeLabel,
  salesRatePeriodLabel,
  minBatchesPerCycleLabel,
  salesRateType,
  salesRatePeriod,
  minimumAverageBatchesPerProductionCycle,
  updatePlanTemplateSettingsState,
  planTemplateBasedOnLabel,
  templateBasedOn,
}: PlanTemplateSettingsProps) => {
  const classes = useStyles()

  const handleSalesRatePeriodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const numberRegex = /^([0-9]){0,8}$/i
    if (event.target.value === '') {
      updatePlanTemplateSettingsState({
        type: SET_SALES_RATE_PERIOD,
        payload: event.target.value,
      })
    } else if (numberRegex.test(event.target.value)) {
      updatePlanTemplateSettingsState({
        type: SET_SALES_RATE_PERIOD,
        payload: parseInt(event.target.value),
      })
    }
  }

  return (
    <>
      <Paper elevation={0}>
        <Box display="flex" p={4} minHeight="20vh">
          <Box className={classes.formControl}>
            <label htmlFor={salesRateTypeLabel}>
              <Typography className={classes.label} variant="h4">
                {salesRateTypeLabel}
              </Typography>
            </label>
            <DropDown
              id={salesRateTypeLabel}
              label={salesRateTypeLabel}
              menuItems={SALES_RATE_TYPE}
              value={salesRateType}
              className={classes.select}
              onChange={(e) =>
                updatePlanTemplateSettingsState({
                  type: SET_SALES_RATE_TYPE,
                  payload: e.target.value as string,
                })
              }
            />
          </Box>
          <Box className={classes.formControl}>
            <label htmlFor={salesRatePeriodLabel}>
              <Typography className={classes.label} variant="h4">
                {salesRatePeriodLabel}
              </Typography>
            </label>
            <Input
              id={salesRatePeriodLabel}
              value={salesRatePeriod}
              onChange={handleSalesRatePeriodChange}
              className={classes.input}
            />
          </Box>
          <Box>
            <label htmlFor={minBatchesPerCycleLabel}>
              <Typography className={classes.label} variant="h4">
                {minBatchesPerCycleLabel}
              </Typography>
            </label>
            <DropDown
              id={minBatchesPerCycleLabel}
              label={minBatchesPerCycleLabel}
              menuItems={MIN_AVG_BATCHES_PER_CYCLE}
              value={minimumAverageBatchesPerProductionCycle}
              onChange={(e) =>
                updatePlanTemplateSettingsState({
                  type: SET_MIN_AVG_BATCH_PER_CYCLE,
                  payload: e.target.value as number,
                })
              }
              className={classes.select}
            />
          </Box>
        </Box>
        <Box display="flex" p={4} minHeight="20vh">
          <Box>
            <label htmlFor={planTemplateBasedOnLabel}>
              <Typography className={classes.label} variant="h4">
                {planTemplateBasedOnLabel}
              </Typography>
            </label>
            <DropDown
              id={planTemplateBasedOnLabel}
              label={planTemplateBasedOnLabel}
              menuItems={PLAN_TEMPLETE_BASED_ON_OPTIONS}
              value={templateBasedOn}
              onChange={(e) =>
                updatePlanTemplateSettingsState({
                  type: SET_PLANTEMPLATE_BASED_ON,
                  payload: e.target.value as string,
                })
              }
              className={classes.select}
            />
          </Box>
        </Box>
      </Paper>
    </>
  )
}
