import { useMemo } from 'react'
import { Graph } from 'Common/Graph/Graph'
import { CostTradeOffGraphProps } from './CostTradeOff.types'

export const CostTradeOffGraph = ({
  xAxisData,
  totalWorkcentreCost,
  totalInventoryCost,
}: CostTradeOffGraphProps) => {
  const hovertemplate = () => {
    return (
      ` <b>Scenario: </b>%{x}<br>` +
      ` <b>Cost: </b> %{y}<br>` +
      '<extra></extra>'
    )
  }

  const data = useMemo(() => {
    const trace1 = {
      x: xAxisData,
      y: totalWorkcentreCost,
      name: 'Total workcentre cost ($)',
      type: 'bar',
      customdata: totalInventoryCost,
      textfont: { color: 'transparent' },
      width: 0.65,
      hovertemplate: hovertemplate(),
      marker: {
        color: '#F28E2B',
      },
    }

    const trace2 = {
      x: xAxisData,
      y: totalInventoryCost,
      name: 'Total inventory cost ($)',
      type: 'bar',
      customdata: totalWorkcentreCost,
      textfont: { color: 'transparent' },
      width: 0.65,
      hovertemplate: hovertemplate(),
      marker: {
        color: '#4E79A7',
      },
    }

    return [trace2, trace1]
  }, [xAxisData, totalWorkcentreCost, totalInventoryCost])

  const layout = {
    yaxis: {
      showgrid: true,
      automargin: true,
    },
    xaxis: {
      zeroline: true,
      nticks: 10,
    },
    barmode: 'stack',
    legend: { orientation: 'h', x: 0.05, y: -0.2 },
  }
  return <Graph data={data} layout={layout} />
}
