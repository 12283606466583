import { Box, Paper, Typography, Divider } from '@material-ui/core'
import { Page } from 'Common/Page/Page'
import React, { useEffect, useRef, useState } from 'react'
import { Button, DropDown, Input, PageHeader } from 'supplyvue-ui'
import { useStyles } from './NewDemandScenario.styles'
import { QuarterlyValue } from './QuarterlyValue/QuarterlyValue.view'
import { NewDemandScenarioTable } from './NewDemandScenarioTable/NewDemandScenario.table'
import {
  useDemandScenarioList,
  useDemandScenarioTableData,
  useCreateDemandScenario,
} from '../DemandScenario.data'
import { useHistory, useParams } from 'react-router-dom'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import {
  DemandScenarioParams,
  DemandScenario,
  QuarterKeysType,
  QuarterTypes,
} from '../DemandScenario.types'
import { toast } from 'react-hot-toast'
import {
  ValueType,
  QuarterlyValueType,
} from './QuarterlyValue/QuarterlyValue.types'
import { NewDemandScenarioTableColumns } from './NewDemandScenarioTable/NewDemandScenarioTable.types'
import { FilteredRows } from 'supplyvue-ui/dist/types/components/Table/Table.types'
import { Loader } from 'Common/Loader/Loader'
import { isEqual } from 'lodash'

export const NewDemandScenario = () => {
  const classes = useStyles()
  const history = useHistory()
  const {
    projectId,
    snapshotId,
    scenarioId,
  } = useParams<DemandScenarioParams>()
  useBreadcrumbsData({ project_id: projectId, snapshot_id: snapshotId })

  const {
    data: { data: demandScenarios = [], forecastTypes = [] } = {},
  } = useDemandScenarioList(snapshotId, scenarioId)

  const fromDemandScenarioOptions = useRef([])
  const baseLineDemandScenarioId = useRef('')

  const defaultQuarterlyValue: QuarterlyValueType = {
    value: '',
    type: 'byPercentage',
  }

  const [demandScenarioName, setDemandScenarioName] = useState('')
  const [fromDemandScenario, setFromDemandScenario] = useState('')
  const [isForecastTypeSelected, setIsforecastTypeSelected] = useState(false)

  const [tableData, setTableData] = useState<
    NewDemandScenarioTableColumns[] | null
  >()

  const {
    data: demandScenarioTableData,
    isLoading,
  } = useDemandScenarioTableData(
    fromDemandScenario,
    baseLineDemandScenarioId.current,
    isForecastTypeSelected
  )

  useEffect(() => {
    setTableData(demandScenarioTableData)
  }, [demandScenarioTableData])

  const onCreateDemandScenarioSuccess = (message: string) => {
    toast.success(message)
    history.push(
      `/projects/${projectId}/snapshots/${snapshotId}/scenarios/${scenarioId}/demand-scenario`
    )
  }

  const onCreateDemandScenarioFail = (message: string) => {
    toast.error(message)
  }
  const {
    mutate: createDemandScenario,
    isLoading: isDemanScenarioCreating,
  } = useCreateDemandScenario({
    onCreateDemandScenarioSuccess,
    onCreateDemandScenarioFail,
  })

  const [Y1Q1, setY1Q1Value] = useState<QuarterlyValueType>(
    defaultQuarterlyValue
  )
  const [Y1Q2, setY1Q2Value] = useState<QuarterlyValueType>(
    defaultQuarterlyValue
  )
  const [Y1Q3, setY1Q3Value] = useState<QuarterlyValueType>(
    defaultQuarterlyValue
  )
  const [Y1Q4, setY1Q4Value] = useState<QuarterlyValueType>(
    defaultQuarterlyValue
  )
  const [Y2Q1, setY2Q1Value] = useState<QuarterlyValueType>(
    defaultQuarterlyValue
  )
  const [Y2Q2, setY2Q2Value] = useState<QuarterlyValueType>(
    defaultQuarterlyValue
  )
  const [Y2Q3, setY2Q3Value] = useState<QuarterlyValueType>(
    defaultQuarterlyValue
  )
  const [Y2Q4, setY2Q4Value] = useState<QuarterlyValueType>(
    defaultQuarterlyValue
  )
  const [Y3Q1, setY3Q1Value] = useState<QuarterlyValueType>(
    defaultQuarterlyValue
  )

  const filteredSKUGroup = useRef<string[]>([])
  const allFilteredRows = useRef<FilteredRows<NewDemandScenarioTableColumns>>(
    []
  )

  useEffect(() => {
    if (demandScenarios) {
      fromDemandScenarioOptions.current = demandScenarios
        .map((demandScenario: DemandScenario) => ({
          key: demandScenario.name,
          value: demandScenario.id,
        }))
        .concat(
          forecastTypes.map((foreCast: string) => ({
            key: foreCast,
            value: foreCast,
            forecastType: true,
          }))
        )

      const defaultDemandScenario = demandScenarios.find(
        (demandScenario: DemandScenario) =>
          demandScenario.name === 'Baseline Forecast'
      )
      baseLineDemandScenarioId.current = defaultDemandScenario?.id
      setFromDemandScenario(defaultDemandScenario?.id ?? '')
    }
  }, [demandScenarios, forecastTypes])

  const handleNewDemandScenarioNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDemandScenarioName(event.target?.value)
  }

  const handleDropdownChange = (
    event: React.ChangeEvent<{ name?: string; value: string | unknown }>
  ) => {
    if (forecastTypes.includes(event.target?.value)) {
      setIsforecastTypeSelected(true)
    } else {
      setIsforecastTypeSelected(false)
    }
    setFromDemandScenario(event.target?.value as string)
    setTableData(null)
  }

  const updateQuarterlyValueType = (
    valueType: ValueType,
    method: (quarterlyValue: QuarterlyValueType) => void,
    quarterlyValue: QuarterlyValueType
  ) => {
    method({ ...quarterlyValue, type: valueType, value: '' })
  }

  const onQuarterlyValueChange = (
    value: string,
    method: (quarterlyValue: QuarterlyValueType) => void,
    quarterlyValue: QuarterlyValueType
  ) => {
    const validNumber = new RegExp(/^\d*\.?\d*$/)
    if (validNumber.test(value)) {
      method({ ...quarterlyValue, value: value })
    }
  }

  const getAdjustments = (
    adjustMentType: string
  ): { [quarter: string]: number } => {
    const quarters: { [quarter: string]: QuarterlyValueType } = {
      Y1Q1,
      Y1Q2,
      Y1Q3,
      Y1Q4,
      Y2Q1,
      Y2Q2,
      Y2Q3,
      Y2Q4,
      Y3Q1,
    }
    let adjustments = {} as { [quarter: string]: number }

    Object.keys(quarters).forEach((quarter) => {
      if (
        quarters[quarter].value &&
        quarters[quarter].type === adjustMentType
      ) {
        adjustments = {
          ...adjustments,
          [quarter]: Number(quarters[quarter].value),
        }
      }
    })
    return adjustments
  }

  const handleSaveButtonClick = () => {
    if (Boolean(demandScenarioName)) {
      const data = {
        demandScenarioName,
        snapshotId,
        demandScenarioId: isForecastTypeSelected
          ? baseLineDemandScenarioId.current
          : fromDemandScenario,
        percentageAdjustments: getAdjustments('byPercentage'),
        valueAdjustments: getAdjustments('byValue'),
        skuGroups: filteredSKUGroup.current,
        ...(isForecastTypeSelected && {
          forecastType: fromDemandScenario,
        }),
      }
      createDemandScenario(data)
      // Save
    } else {
      toast.error('Please enter a demand scenario name.')
    }
  }

  const handleApplyParametersButtonClick = () => {
    const quarters: QuarterTypes = {
      Y1Q1,
      Y1Q2,
      Y1Q3,
      Y1Q4,
      Y2Q1,
      Y2Q2,
      Y2Q3,
      Y2Q4,
      Y3Q1,
    }

    const originalTableData = tableData?.length ? [...tableData] : []

    allFilteredRows.current.forEach(({ original }) => {
      Object.keys(quarters).forEach((quarter: QuarterKeysType) => {
        if (quarters[quarter].value) {
          const filteredRowFromOriginalTableData = originalTableData?.find(
            (item) => isEqual(item, original)
          )
          if (quarters[quarter].type === 'byValue') {
            if (filteredRowFromOriginalTableData)
              filteredRowFromOriginalTableData[quarter] = Number(
                quarters[quarter].value
              )
          } else {
            const quarterValue = Number(original[quarter])
            if (filteredRowFromOriginalTableData)
              filteredRowFromOriginalTableData[quarter] = Number(
                (
                  quarterValue *
                  (Number(quarters[quarter].value) / 100)
                ).toFixed(2)
              )
          }
        }
      })
      return original
    })

    setTableData(originalTableData)
  }

  const isApplyParameterDisabled = (): boolean => {
    return !Boolean(
      Y1Q1.value ||
        Y1Q2.value ||
        Y1Q3.value ||
        Y1Q4.value ||
        Y2Q1.value ||
        Y2Q2.value ||
        Y2Q3.value ||
        Y2Q4.value ||
        Y3Q1.value
    )
  }

  const getFilteredRows = (
    filteredRows: FilteredRows<NewDemandScenarioTableColumns>
  ) => {
    filteredSKUGroup.current = filteredRows?.map(
      (row) => row?.original?.skuGroup
    )
    allFilteredRows.current = [...filteredRows]
  }

  return (
    <Page>
      <Box display="flex" flexDirection="column" flex="auto">
        <PageHeader title="New demand scenario"></PageHeader>
        <Paper className={classes.paper}>
          {isDemanScenarioCreating ? (
            <Loader />
          ) : (
            <>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="20px"
              >
                <Box display="flex">
                  <Typography variant="h4">
                    Enter demand scenario name
                  </Typography>
                  <Input
                    fullWidth
                    value={demandScenarioName}
                    onChange={handleNewDemandScenarioNameChange}
                  ></Input>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography variant="h4">From demand scenario</Typography>
                  <Box marginLeft="10px">
                    <DropDown
                      value={fromDemandScenario}
                      className={classes.dropdown}
                      label={'label'}
                      menuItems={fromDemandScenarioOptions.current}
                      onChange={handleDropdownChange}
                      name={'fromDemendScenario'}
                      defaultLabel={'Default demand scenario'}
                    ></DropDown>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h4">
                    Period = 52 weeks, end date = 31/12/2021
                  </Typography>
                </Box>
              </Box>
              <Divider></Divider>
              <Box display="flex" flexDirection="column" paddingTop="20px">
                <Box display="flex" alignItems="center">
                  <QuarterlyValue
                    label="Y1 Q1"
                    handleValueTypeChange={(e) =>
                      updateQuarterlyValueType(
                        e.target.value as ValueType,
                        setY1Q1Value,
                        Y1Q1
                      )
                    }
                    quarterlyValue={Y1Q1}
                    handleQuarterlyValueChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      onQuarterlyValueChange(
                        event.target.value,
                        setY1Q1Value,
                        Y1Q1
                      )
                    }
                  />

                  <QuarterlyValue
                    label="Y1 Q2"
                    handleValueTypeChange={(e) =>
                      updateQuarterlyValueType(
                        e.target.value as ValueType,
                        setY1Q2Value,
                        Y1Q2
                      )
                    }
                    quarterlyValue={Y1Q2}
                    handleQuarterlyValueChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      onQuarterlyValueChange(
                        event.target.value,
                        setY1Q2Value,
                        Y1Q2
                      )
                    }
                  />

                  <QuarterlyValue
                    label="Y1 Q3"
                    handleValueTypeChange={(e) =>
                      updateQuarterlyValueType(
                        e.target.value as ValueType,
                        setY1Q3Value,
                        Y1Q3
                      )
                    }
                    quarterlyValue={Y1Q3}
                    handleQuarterlyValueChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      onQuarterlyValueChange(
                        event.target.value,
                        setY1Q3Value,
                        Y1Q3
                      )
                    }
                  />
                  <QuarterlyValue
                    label="Y1 Q4"
                    handleValueTypeChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      updateQuarterlyValueType(
                        e.target.value as ValueType,
                        setY1Q4Value,
                        Y1Q4
                      )
                    }
                    quarterlyValue={Y1Q4}
                    handleQuarterlyValueChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      onQuarterlyValueChange(
                        event.target.value,
                        setY1Q4Value,
                        Y1Q4
                      )
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  marginTop="20px"
                  marginBottom="20px"
                >
                  <QuarterlyValue
                    label="Y2 Q1"
                    handleValueTypeChange={(e) =>
                      updateQuarterlyValueType(
                        e.target.value as ValueType,
                        setY2Q1Value,
                        Y2Q1
                      )
                    }
                    quarterlyValue={Y2Q1}
                    handleQuarterlyValueChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      onQuarterlyValueChange(
                        event.target.value,
                        setY2Q1Value,
                        Y2Q1
                      )
                    }
                  />

                  <QuarterlyValue
                    label="Y2 Q2"
                    handleValueTypeChange={(e) =>
                      updateQuarterlyValueType(
                        e.target.value as ValueType,
                        setY2Q2Value,
                        Y2Q2
                      )
                    }
                    quarterlyValue={Y2Q2}
                    handleQuarterlyValueChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      onQuarterlyValueChange(
                        event.target.value,
                        setY2Q2Value,
                        Y2Q2
                      )
                    }
                  />
                  <QuarterlyValue
                    label="Y2 Q3"
                    handleValueTypeChange={(e) =>
                      updateQuarterlyValueType(
                        e.target.value as ValueType,
                        setY2Q3Value,
                        Y2Q3
                      )
                    }
                    quarterlyValue={Y2Q3}
                    handleQuarterlyValueChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      onQuarterlyValueChange(
                        event.target.value,
                        setY2Q3Value,
                        Y2Q3
                      )
                    }
                  />
                  <QuarterlyValue
                    label="Y2 Q4"
                    handleValueTypeChange={(e) =>
                      updateQuarterlyValueType(
                        e.target.value as ValueType,
                        setY2Q4Value,
                        Y2Q4
                      )
                    }
                    quarterlyValue={Y2Q4}
                    handleQuarterlyValueChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      onQuarterlyValueChange(
                        event.target.value,
                        setY2Q4Value,
                        Y2Q4
                      )
                    }
                  />
                  <QuarterlyValue
                    label="Y3 Q1"
                    handleValueTypeChange={(e) =>
                      updateQuarterlyValueType(
                        e.target.value as ValueType,
                        setY3Q1Value,
                        Y3Q1
                      )
                    }
                    quarterlyValue={Y3Q1}
                    handleQuarterlyValueChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      onQuarterlyValueChange(
                        event.target.value,
                        setY3Q1Value,
                        Y3Q1
                      )
                    }
                  />
                </Box>
                <Box display="flex" justifyContent="end">
                  <Box marginRight="20px">
                    <Button variant="contained" onClick={handleSaveButtonClick}>
                      Save
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={handleApplyParametersButtonClick}
                      disabled={isApplyParameterDisabled()}
                    >
                      Apply parameters
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Paper>
        <Box marginTop="20px">
          <NewDemandScenarioTable
            data={tableData ?? demandScenarioTableData}
            isLoading={isLoading}
            getFilteredRows={getFilteredRows}
            allFilteredRowsLength={allFilteredRows?.current?.length ?? 0}
          />
        </Box>
      </Box>
    </Page>
  )
}
