import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  accordionDetailsRoot: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
  },
  accordionDetailsContent: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.borders.grey}`,
    padding: '0px 32px',
    minHeight: '48px',
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
}))
