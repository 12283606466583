import { DeleteProjectModalProps } from 'Features/Project/DeleteProjectModal/DeleteProjectModal.types'
import { useStyles } from './DeleteProjectModal.styles'
import { Box, Typography } from '@material-ui/core'
import { Button, Modal } from 'supplyvue-ui'

export const DeleteProjectModal = ({
  isModalOpen,
  handleModalClose,
  projectName,
  handleConfirmClick,
}: DeleteProjectModalProps) => {
  const classes = useStyles()

  const body = (
    <Box className={classes.contentWrapper}>
      <Typography>
        Are you sure you want to delete project
        <Typography variant="h4">{projectName}</Typography>
      </Typography>

      <Box className={classes.buttonGroup}>
        <Button variant="outlined" onClick={handleModalClose}>
          No
        </Button>
        <Button variant="contained" onClick={handleConfirmClick}>
          Yes
        </Button>
      </Box>
    </Box>
  )

  return (
    <Modal
      open={isModalOpen}
      handleClose={handleModalClose}
      body={body}
      aria-labelledby={'Delete project'}
      header={'Delete project'}
    />
  )
}
