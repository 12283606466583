import { Box, TextField, Typography } from '@material-ui/core'
import { useStyles } from './WorkcentreRouting.styles'
import { Button } from 'supplyvue-ui'
import { WorkcentreRoutingTable } from 'Features/NewPlanTemplate/WorkcentreSettings/WorkcentreRouting/WorkcentreRouting.table'
import { ChangeEvent, useEffect, useState } from 'react'
import { productSettings } from 'Features/NewPlanTemplate/WorkcentreSettings/WorkcentreRouting/WorkcentreRouting.types'
import { useParams } from 'react-router-dom'
import { PlanTemplatePageParams } from 'Features/NewPlanTemplate/NewPlanTemplate.types'
import { useWorkcentreSettingsData } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.data'
import { Autocomplete } from '@mui/material'
import { toast } from 'react-hot-toast'
import { useUpdatGroupSettings } from 'Features/NewPlanTemplate/WorkcentreSettings/WorkcentreRouting/WorkcentreRouting.data'
import { Loader } from 'Common/Loader/Loader'
import { ProductSettings } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/BulkUpdate/BulkUpdate.types'

export const WorkcentreRouting = () => {
  const { snapshotId, projectId, planTemplateId } =
    useParams<PlanTemplatePageParams>()
  const classes = useStyles()

  const [selectedSku, setSelectedSku] = useState<string>('')
  const [skuList, setSkuList] = useState<string[]>([])
  const [skuMappings, setSkuMappings] = useState({})

  const [productData, setProductData] = useState<any[]>([])

  const [batchCodes, setBatchCodes] = useState<string[]>([])

  const [skuWorkcentreMapping, setSkuWorkcentreMapping] = useState({})

  const [primaryWorkcentre, setPrimaryWorkcentre] = useState<string>('')
  const [primaryWorkCentres, setPrimaryWorkCentres] = useState<String[]>([])

  const [segment, setSegment] = useState<string>('')
  const [segments, setSegments] = useState<string[]>([])

  const [batchCodeFilter, setBatchCodeFilter] = useState<[]>([])

  const [primaryWorkcentreFilter, setPrimaryWorkcentreFilter] =
    useState<string>('')

  const [segmentFilter, setSegmentFilter] = useState<string>('')

  const [format, setFormat] = useState<string>('')
  const [volume, setVolume] = useState<number>()
  const [volumeSplit, setVolumeSplit] = useState<number>()

  const [tableData, setTableData] = useState<productSettings[] | []>([])
  const [initialTableData, setInitialTableData] = useState<
    productSettings[] | []
  >([])

  const { data, isLoading } = useWorkcentreSettingsData(
    snapshotId,
    projectId,
    planTemplateId
  )

  const onSuccess = (message: string) => {
    toast.success(message)
  }

  const onError = () => {
    toast.error('Error Saving Data')
  }

  const { mutate: updateGroupSettings, isLoading: isUpdating } =
    useUpdatGroupSettings(onSuccess, onError)

  // AddUpdating Spinner when data is being updated
  // Convert the individual useStates to a reducer

  useEffect(() => {
    let allowedSkus = productData
      .filter((item) => {
        let keepItem = true
        if (
          batchCodeFilter.length > 0 &&
          //@ts-ignore
          !batchCodeFilter.includes(item.batchGroupingCode)
        ) {
          keepItem = false
        }
        if (
          primaryWorkcentreFilter &&
          item.primaryWorkCentreCode != primaryWorkcentreFilter
        ) {
          keepItem = false
        }
        if (segmentFilter && item.segment != segmentFilter) {
          keepItem = false
        }

        return keepItem
      })
      .map((item) => item.productCode)

    setSkuList([...new Set(allowedSkus)])
  }, [batchCodeFilter, primaryWorkcentreFilter, segmentFilter])

  useEffect(() => {
    if (data) {
      let uniqueSkusSet = new Set()
      let uniquebatchCodesSet = new Set()
      let uniquePrimarySet = new Set()
      let uniqueSegmentSet = new Set()

      let skuKeyMappings: { [key: string | number]: number } = {}
      let skuWCCount: { [key: string]: number } = {}
      let salesVolumes: { [key: string]: number } = {}

      let uniqueSkus = data.productSettings
        .map((item: ProductSettings) => {
          if (!skuKeyMappings.hasOwnProperty(item.productCode)) {
            skuKeyMappings[item.productCode] = item.skuGroupKey
          }
          skuWCCount[item.productCode] = (skuWCCount[item.productCode] || 0) + 1
          salesVolumes[item.productCode] =
            (salesVolumes[item.productCode] || 0) + item.salesRate
          return item.productCode
        })
        .filter((value: string) => {
          if (skuWCCount[value] === 1) {
            return false
          }
          if (salesVolumes[value] === 0) {
            return false
          }
          if (!uniqueSkusSet.has(value)) {
            uniqueSkusSet.add(value)
            return true
          }
          return false
        })
        .filter((item: boolean | null) => item !== null)

      let productData = data.productSettings.filter((item) => {
        return !!uniqueSkus.includes(item.productCode)
      })

      let uniqueBatchCodes = productData
        .map((item: ProductSettings) => item.batchGroupingCode)
        .filter((value: string | number) => {
          if (!uniquebatchCodesSet.has(value)) {
            uniquebatchCodesSet.add(value)
            return true
          }
          return false
        })
        .filter((item: boolean | null) => item !== null)

      let uniquePrimary = productData
        .map((item) => item.primaryWorkCentreCode)
        .filter((value) => {
          if (!uniquePrimarySet.has(value)) {
            uniquePrimarySet.add(value)
            return true
          }
          return false
        })
        .filter((item) => item !== null)

      let uniqueSegment = productData
        .map((item) => item.segment)
        .filter((value) => {
          if (!uniqueSegmentSet.has(value)) {
            uniqueSegmentSet.add(value)
            return true
          }
          return false
        })
        .filter((item) => item !== null)

      if (uniqueSkus) {
        setSelectedSku(uniqueSkus[0])
      }

      setBatchCodes(uniqueBatchCodes)
      setPrimaryWorkCentres(uniquePrimary)
      setSegments(uniqueSegment)
      setSkuList(uniqueSkus)
      setSkuMappings(skuKeyMappings)
      setProductData(productData)
      setSkuWorkcentreMapping([data.skuWorkcentreMapping])
    }
  }, [data])

  useEffect(() => {
    if (selectedSku) {
      const totalSales = productData.reduce((result, current) => {
        if (current.productCode != selectedSku) {
          return result
        } else {
          return result + current.salesRate
        }
      }, 0)

      let variables = productData.reduce((result, current) => {
        if (current.productCode != selectedSku) {
          return result
        } else {
          result.skuGroupKey = current.skuGroupKey
          result.productCode = current.productCode
          result.descrition = current.descrition
          result.batchGroupingCode = current.batchGroupingCode
          result.productFormat = current.productFormat
          result.segment = current.segment
          result.totalSalesVolume = totalSales
          result.primaryWorkCentreCode = current.primaryWorkCentreCode

          if (result.hasOwnProperty('volumeSplit')) {
            result.volumeSplit.push({
              workcentreKey: current.workcentreKey,
              workcentreCode: current.workcentreCode,
              salesVolume: current.salesRate,
            })
          } else {
            result.volumeSplit = [
              {
                workcentreKey: current.workcentreKey,
                workcentreCode: current.workcentreCode,
                salesVolume: current.salesRate,
              },
            ]
          }

          return result
        }
      }, {})

      let workcentresSet = new Set()
      let workcentres = skuWorkcentreMapping[0][
        skuMappings[selectedSku]
      ].reduce((result, current) => {
        result.push({
          workcentreKey: current.workcentreKey,
          workcentreCode: current.workcentreCode,
          sourceSiteKey: current.sourceSiteKey,
          sourceSiteCode: current.sourceSiteCode,
        })
        workcentresSet.add(current.workcentreKey)
        return result
      }, [])

      const volumeSplits = {}
      for (let i = 0; i < variables.volumeSplit.length; i++) {
        // @ts-ignore
        volumeSplits[variables.volumeSplit[i].workcentreCode] =
          variables.volumeSplit[i].salesVolume
      }

      setPrimaryWorkcentre(variables.primaryWorkCentreCode)
      setSegment(variables.segment)
      setFormat(variables.productFormat)
      setVolume(variables.totalSalesVolume)

      const _tableData = workcentres.map((item) => {
        // How to get the volume split and volume data out
        return {
          workcentreKey: item.workcentreKey,
          workcentreCode: item.workcentreCode,
          sourceSiteKey: item.sourceSiteKey,
          sourceSiteCode: item.sourceSiteCode,
          volumeSplit:
            // @ts-ignore
            volumeSplits[item.workcentreCode]
              ? (
                  (volumeSplits[item.workcentreCode] /
                    variables.totalSalesVolume) *
                  100
                ).toFixed(1)
              : 0,
          // @ts-ignore
          volume: volumeSplits[item.workcentreCode]
            ? // @ts-ignore
              volumeSplits[item.workcentreCode]
            : 0,
        }
      })

      const tableDataIds = _tableData.map((item, idx) => {
        return {
          ...item,
          id: idx,
        }
      })
      setTableData(tableDataIds)
      setInitialTableData(tableDataIds)
    } else {
      setTableData([])
      setPrimaryWorkcentre('')
      setSegment('')
      setFormat('')
      setVolume(undefined)
    }
  }, [selectedSku])

  const handleSaveClick = () => {
    // @ts-ignore

    let data = {
      skuGroups: {},
      fields: { salesRate: 1 },
      sourceSites: {},
    }

    // @ts-ignore
    let structure = tableData.reduce((result, current) => {
      // @ts-ignore
      data.skuGroups[current.workcentreKey] = [
        // @ts-ignore
        { [skuMappings[selectedSku]]: [{ salesRate: current.volume }] },
      ]
      // @ts-ignore
      data.sourceSites[current.workcentreKey] = current.sourceSiteKey
      return result
    }, {})

    const updateMode = 'SKU'
    updateGroupSettings({
      planTemplateId,
      snapshotId,
      projectId,
      updateMode,
      data,
    })
  }

  const handleClearFilters = () => {
    setBatchCodeFilter([])
    setPrimaryWorkcentreFilter('')
    setSegmentFilter('')
  }

  const handleClearClick = () => {
    setTableData(initialTableData)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.sideBar}>
          <Box className={classes.sideBarTitle}>
            <Typography variant="h3">Sku Filters</Typography>
          </Box>
          <Box className={classes.sideBarFilters}>
            <Box className={classes.sidebarBlock}>
              <Autocomplete
                multiple
                className={classes.autocomplete}
                value={batchCodeFilter}
                onChange={(e: ChangeEvent, newValue) =>
                  setBatchCodeFilter(newValue)
                }
                PaperProps={{
                  style: {
                    maxHeight: 200, // Adjust the height as needed
                    overflow: 'auto',
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Batch Code"
                  />
                )}
                options={batchCodes}
              />
            </Box>
            <Box className={classes.sidebarBlock}>
              <Autocomplete
                className={classes.autocomplete}
                value={primaryWorkcentreFilter}
                inputValue={primaryWorkcentreFilter}
                onChange={(e: ChangeEvent, newValue) =>
                  setPrimaryWorkcentreFilter(newValue)
                }
                onInputChange={(e: ChangeEvent, newValue) =>
                  setPrimaryWorkcentreFilter(newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Primary Work Centre"
                  />
                )}
                options={primaryWorkCentres}
              />
            </Box>
            <Box className={classes.sidebarBlock}>
              <Autocomplete
                className={classes.autocomplete}
                value={segmentFilter}
                inputValue={segmentFilter}
                onChange={(e: ChangeEvent, newValue) =>
                  setSegmentFilter(newValue)
                }
                onInputChange={(e: ChangeEvent, newValue) =>
                  setSegmentFilter(newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Segment" />
                )}
                options={segments}
              />
            </Box>
            <Box className={classes.sidebarBlock}>
              <Autocomplete
                className={classes.autocomplete}
                value={selectedSku}
                onChange={(e: ChangeEvent, newValue) =>
                  setSelectedSku(newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Sku Code Selection"
                  />
                )}
                options={skuList}
              />
            </Box>

            <Box className={classes.clearFilters}>
              <Button variant="contained" onClick={handleClearFilters}>
                Clear Filters
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.skuContent}>
          <Box className={classes.infoRow}>
            <Box className={classes.infoRowBlock}>
              <label>
                <Typography variant="h3">Selected Sku</Typography>
              </label>
              <label>
                <Typography variant="h7">{selectedSku}</Typography>
              </label>
            </Box>
            <Box className={classes.infoRowBlock}>
              <label>
                <Typography variant="h3">Primary Work Centre</Typography>
              </label>
              <label>
                <Typography variant="h7">{primaryWorkcentre}</Typography>
              </label>
            </Box>
            <Box className={classes.infoRowBlock}>
              <label>
                <Typography variant="h3">Segment</Typography>
              </label>
              <label>
                <Typography variant="h7">{segment}</Typography>
              </label>
            </Box>
            <Box className={classes.infoRowBlock}>
              <label>
                <Typography variant="h3">Format</Typography>
              </label>
              <label>
                <Typography variant="h7">{format}</Typography>
              </label>
            </Box>
            <Box className={classes.infoRowBlock}>
              <label>
                <Typography variant="h3">Total Sales Volume</Typography>
              </label>
              <label>
                <Typography variant="h7">{volume}</Typography>
              </label>
            </Box>
          </Box>
          <Box className={classes.tableContainer}>
            {tableData.length > 0 ? (
              <WorkcentreRoutingTable
                tableData={tableData}
                setTableData={setTableData}
                totalSalesVolume={volume}
                setVolumeSplit={setVolumeSplit}
              />
            ) : (
              <Typography color="primary" variant="h6">
                Select Sku
              </Typography>
            )}
          </Box>
          <Box className={classes.buttonGroup}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClearClick}
            >
              Undo
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
              disabled={volumeSplit !== 100}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
