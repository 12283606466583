import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Box, Typography, TextField, Paper, Divider } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  NetworkGraphOverlay,
  getLayoutedElements,
  Button,
  Dialog,
  PageHeader,
} from 'supplyvue-ui'
import { toast } from 'react-hot-toast'
import {
  SourceSiteOption,
  WorkCenterOption,
  ReplenishmentPolicyPageParams,
} from './ReplenishmentPolicy.types'
import {
  useStyles,
  autocompleteStyles,
  networkGraphStyles,
} from './ReplenishmentPolicy.styles'
import { Page } from 'Common/Page/Page'
import { Loader } from 'Common/Loader/Loader'
// import { PageHeader } from 'Common/PageHeader/PageHeader'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import {
  useSiteConnectionsData,
  useSourceSitesData,
  useApproveNetwork,
} from './ReplenishmentPolicy.data'
import { getGraphData } from './ReplenishmentPolicy.utils'
import { SiteParameters } from './SiteParameters/SiteParameters.view'
import { SummaryOfApprovedSites } from './SummaryOfApprovedSites/SummaryOfApprovedSites.view'
import { ButtonGroup } from '@mui/material'

export const ReplenishmentPolicyPage = () => {
  const {
    projectId,
    scenarioId,
    replenishmentPolicyId,
    sourceSiteId,
    workcentreId,
  } = useParams<ReplenishmentPolicyPageParams>()
  useBreadcrumbsData({
    project_id: projectId,
    replenishment_policy_id: replenishmentPolicyId,
  })

  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()

  const [selectedSourceSite, setSelectedSourceSite] =
    useState<SourceSiteOption | null>(null)
  const [workcenters, setWorkcenters] = useState<WorkCenterOption[] | []>([])
  const [selectedWorkcenter, setSelectedWorkcenter] =
    useState<WorkCenterOption | null>(null)

  const [showDialog, setShowDialog] = useState(false)
  const [isPollingData, setIsPollingData] = useState(false)
  const [periodType, setPeriodType] = useState<number>(1)
  // periodType = 0 => Months
  // periodType = 1 => Weeks

  const {
    data: sourceSites,
    isLoading: isSourceSitesLoading,
    isError: isSourceSitesError,
  } = useSourceSitesData(projectId, scenarioId)

  const onSiteConnectionsSuccess = (isPolling: boolean, message?: string) => {
    setIsPollingData(isPolling)
    if (message) {
      toast.error(message)
    }
  }

  const onSiteConnectionsError = (message: string) => {
    setIsPollingData(false)
    toast.error(message)
  }

  const {
    data: siteConnections,
    isLoading: isSiteConnectionsLoading,
    refetch: refetchSiteConnections,
    isFetching: isSiteConnectionsFetching,
  } = useSiteConnectionsData(
    projectId,
    sourceSites?.snapshotId,
    scenarioId,
    replenishmentPolicyId,
    selectedSourceSite?.value,
    selectedWorkcenter?.key,
    onSiteConnectionsSuccess,
    onSiteConnectionsError
  )

  /**** If any one site is being calculated show it in Amber */
  const [calculationstatus, setCalculationStatus] = useState(
    'APPROVED_OR_FAILED_OR_PENDING'
  )

  useEffect(() => {
    if (siteConnections?.data?.nodes) {
      const calculationData = siteConnections.data.nodes
        .map((node: { approvalStatus: string }) => node.approvalStatus)
        .join('-')
      setCalculationStatus(calculationData)
    }
  }, [siteConnections])
  /**** END If any one site is being calculated show it in Amber */
  const { mutate: approveNetwork, isLoading: isApprovingNetwork } =
    useApproveNetwork({
      onSuccess: (msg) => toast.success(msg),
      onError: (msg) => toast.error(msg),
    })

  useEffect(() => {
    if (sourceSites) {
      const firstSite = sourceSites.baseSourceSites[0]
      history.push(
        `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings/${firstSite.value}`
      )
    }
  }, [sourceSites, history, projectId, replenishmentPolicyId, scenarioId])

  useEffect(() => {
    if (sourceSites) {
      const selectedSourceSiteFromSummaryPage =
        sourceSites.baseSourceSites.filter(
          (sourceSite: SourceSiteOption) =>
            sourceSite.value === parseInt(sourceSiteId)
        )[0]
      setSelectedSourceSite(selectedSourceSiteFromSummaryPage)
      if (selectedSourceSiteFromSummaryPage) {
        const selectedWorkcentreFromSummaryPage =
          selectedSourceSiteFromSummaryPage.workcentres.filter(
            (workcenter: WorkCenterOption) =>
              workcenter.key === parseInt(workcentreId)
          )[0]
        setWorkcenters(selectedSourceSiteFromSummaryPage.workcentres)
        setSelectedWorkcenter(selectedWorkcentreFromSummaryPage)
      }
    }
  }, [sourceSites, sourceSiteId, workcentreId])

  useEffect(() => {
    if (selectedWorkcenter?.key || selectedSourceSite?.value) {
      refetchSiteConnections()
    }
  }, [
    selectedWorkcenter?.key,
    selectedSourceSite?.value,
    refetchSiteConnections,
  ])

  const handleSourceSiteChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    option: SourceSiteOption | null
  ) => {
    if (option?.value) {
      setSelectedSourceSite(option)
      setWorkcenters(option.workcentres)
      setSelectedWorkcenter(null)
    } else {
      setSelectedSourceSite(null)
      setSelectedWorkcenter(null)
    }
    setIsPollingData(false)
  }

  const handleWorkcenterChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    option: WorkCenterOption | null
  ) => {
    if (option) {
      setSelectedWorkcenter(option)
    } else {
      setSelectedWorkcenter(null)
    }
    setIsPollingData(false)
  }

  const handleApprovedNodeClick = (nodeId: string) => {
    history.push(
      `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings/inventory-analysis/${selectedSourceSite?.value}/${nodeId}`
    )
  }

  const handleApproveNetworkClick = () => {
    setShowDialog(true)
  }

  const handleApproveNetworkConfirmation = () => {
    setShowDialog(false)
    approveNetwork({
      projectId,
      snapshotId: sourceSites?.snapshotId,
      scenarioId,
      baseSourceSiteKey: selectedSourceSite?.value,
      periodType: periodType,
    })
  }

  const handleDrawerClose = () => {
    queryClient.invalidateQueries('site-connections')
  }

  const handleViewScenarioClick = () => {
    history.push(
      `/projects/${projectId}/compare-all-scenarios/view-scenario/${scenarioId}`
    )
  }

  const handleNetworkCapacitySettingsClick = () => {
    history.push(
      `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}`
    )
  }

  const handleSettingsOutputClick = () => {
    history.push(
      `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings/${sourceSiteId}/network-replenishment-settings-output`
    )
  }
  if (isSourceSitesLoading) {
    return <Loader />
  }

  return (
    <Page>
      <Box className={classes.wrapper}>
        <PageHeader
          title="Network replenishment settings"
          rightElement={
            <Box display="flex" alignItems="center">
              <Button
                onClick={handleNetworkCapacitySettingsClick}
                variant="text"
              >
                Network capacity settings
              </Button>
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
              <Button onClick={handleViewScenarioClick} variant="text">
                View scenario
              </Button>

              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
              <Button onClick={handleSettingsOutputClick} variant="text">
                Network replenishment settings output
              </Button>
            </Box>
          }
        />
        <Box className={classes.actionsWrapper}>
          <Box className={classes.sourceSiteWrapper}>
            <Box mr="24px">
              <Typography variant="body1" component="span">
                Source site
              </Typography>
            </Box>
            <Box mr="32px">
              <Autocomplete
                size="small"
                options={sourceSites?.baseSourceSites}
                getOptionLabel={(option: SourceSiteOption) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select source site"
                    variant="outlined"
                  />
                )}
                style={autocompleteStyles}
                onChange={handleSourceSiteChange}
                value={selectedSourceSite}
              />
            </Box>
            <Box mr="32px">
              <Autocomplete
                size="small"
                options={workcenters}
                getOptionLabel={(option: WorkCenterOption) => option.code}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select work centre"
                    variant="outlined"
                  />
                )}
                style={autocompleteStyles}
                onChange={handleWorkcenterChange}
                disabled={!selectedSourceSite}
              />
            </Box>
          </Box>
          <Box className={classes.sourceSiteWrapper}>
            <ButtonGroup size="small" className={classes.button}>
              <Button
                variant={periodType ? 'outlined' : 'contained'}
                onClick={() => setPeriodType(0)}
                size="small"
                className={classes.button}
              >
                Months
              </Button>
              <Button
                variant={!periodType ? 'outlined' : 'contained'}
                size="small"
                onClick={() => setPeriodType(1)}
                className={classes.button}
              >
                Weeks
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        {isSourceSitesError ? null : isPollingData ? (
          <Paper className={classes.paper}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <Box>
                <Loader />
              </Box>
              <Box mb={1}>
                <Typography variant="h2">Validating the network</Typography>
              </Box>
              <Typography variant="h4">
                This may take a while. You can return to this screen after a few
                minutes.
              </Typography>
            </Box>
          </Paper>
        ) : isSiteConnectionsLoading && isSiteConnectionsFetching ? (
          <Loader />
        ) : selectedSourceSite?.value && siteConnections ? (
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              style={{ background: 'white' }}
            >
              <Box className={classes.networkOverlayGraph}>
                <NetworkGraphOverlay
                  key={calculationstatus}
                  sourceSiteId={selectedSourceSite.value.toString()}
                  replenishmentPolicyId={replenishmentPolicyId}
                  snapshotId={sourceSites?.snapshotId}
                  initialLayoutedElements={getLayoutedElements(
                    getGraphData(
                      { ...siteConnections.data },
                      selectedSourceSite.value.toString()
                    )
                  )}
                  styles={networkGraphStyles}
                  overlayComponent={SiteParameters}
                  onNodeClickNavigation={handleApprovedNodeClick}
                  onDrawerClose={handleDrawerClose}
                />
              </Box>
              <Box width="500px">
                <SummaryOfApprovedSites graphData={siteConnections.graphData} />
              </Box>
            </Box>
            <Box
              className={classes.actionButton}
              onClick={handleApproveNetworkClick}
            >
              <Button
                variant="contained"
                disabled={isApprovingNetwork || siteConnections.data.pollData}
              >
                Approve network
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Dialog
        title="Approve network"
        content="This will approve all the sites in the network"
        primaryButtonLabel="Confirm"
        secondaryButtonLabel="Cancel"
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        handlePrimaryButtonClick={handleApproveNetworkConfirmation}
        handleSecondaryButtonClick={() => setShowDialog(false)}
      />
    </Page>
  )
}
