import { Summary } from './Summary/Summary.types'
import { SummaryResponseType } from './PlanTemplateGeneration.types'

const summaryDataLabels = {
  averageChangeoversPerWeek: 'Average changeovers per week',
  averageChangeoverTime: 'Average changeover time per product',
  averageUtilisedHours: 'Average utilised hours',
  percentageChangeoverTime: 'Percentage changeover time',
  utilisationVolatility: 'Utilisation volatility',
  averageChangeoverCostPerWeek: 'Average changeovers cost per week',
  incrementalCostOfProduction:
    'Incremental cost of production average per week',
  crewCostAveragePerWeek: 'Crew cost average per week',
  averageRunRate: 'Weighted Average run rate',
  maxHours: 'Max hours',
}

export const mapSummaryObjectToArray = (summary: SummaryResponseType) => {
  let arr: Summary = []
  for (const data in summaryDataLabels) {
    arr = [
      ...arr,
      {
        label: summaryDataLabels[data as keyof SummaryResponseType],
        value: `${summary[data as keyof SummaryResponseType] ?? ''}${
          data === 'utilisationVolatility' ||
          data === 'percentageChangeoverTime'
            ? '%'
            : ''
        }`,
      },
    ]
  }
  return arr
}
