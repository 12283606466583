import { Box } from '@material-ui/core'
import { Button } from 'supplyvue-ui'
import CompareIcon from '@material-ui/icons/Compare'
import { CircularProgress } from '@mui/material'
import { CloudDownload } from '@material-ui/icons'
import { HeaderActionsProps } from './HeaderActions.types'
import { useStyles } from './HeaderActions.styles'

export const HeaderActions = ({
  handleCompareScenarioClick,
  downloadDisabled,
  isDownloading,
  handleDownloadModalOpen
}: HeaderActionsProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Button onClick={() => handleDownloadModalOpen(true)}
              startIcon={!isDownloading && <CloudDownload />}
              disabled={isDownloading || downloadDisabled}
      >
        {isDownloading && (
          <CircularProgress
            color="inherit"
            size={16}
            sx={{
              marginRight: '8px',
            }}
          />
        )}
        Download
      </Button>
      <Button startIcon={<CompareIcon />} onClick={handleCompareScenarioClick}>
        Compare all scenarios
      </Button>
    </Box>
  )
}
