import { CalendarInput, DropDown, Table } from 'supplyvue-ui'
import { Column, TableInstance } from 'react-table'
import {
  NetworkReplenishmentPolicyOutputTableProps,
  NetworkReplenishmentPolicyOutputTableColumns,
} from './NetworkReplenishmentSettingsOutput.types'
import { Loader } from 'Common/Loader/Loader'
import { ChangeEvent } from 'react'

const ExportOptions = [
  {
    key: 'Include',
    value: 'Include',
  },
  {
    key: 'Exclude',
    value: 'Exclude',
  },
]

const SKUOptions = [
  {
    key: 'SKU group',
    value: 'SKU group',
  },
  {
    key: 'SKU',
    value: 'SKU',
  },
]
export const NetworkReplenishmentPolicyOutputTable = ({
  data,
  isLoading,
  updateField,
}: NetworkReplenishmentPolicyOutputTableProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 405
    const availableHeight = window.innerHeight - 690
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  const columns: Column<NetworkReplenishmentPolicyOutputTableColumns>[] = [
    {
      Header: 'Base source site',
      accessor: 'baseSourceSite',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Site',
      accessor: 'site',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Approval status',
      accessor: 'approvalStatus',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Export',
      accessor: 'export',
      disableFilters: true,
      disableSortBy: true,
      Cell: (props: TableInstance) => {
        return (
          <DropDown
            value={props.value}
            label="Export"
            menuItems={ExportOptions}
            onChange={(
              e: ChangeEvent<{
                name?: string | undefined
                value: unknown
              }>
            ) =>
              updateField(
                props.row?.original,
                e.target?.value as string,
                'export'
              )
            }
            name="Export"
          />
        )
      },
    },

    {
      Header: 'Level',
      accessor: 'level',
      disableFilters: true,
      disableSortBy: true,
      Cell: (props: TableInstance) => {
        return (
          <DropDown
            value={props.value}
            label="Export"
            menuItems={SKUOptions}
            onChange={(
              e: ChangeEvent<{
                name?: string | undefined
                value: unknown
              }>
            ) =>
              updateField(
                props.row?.original,
                e.target?.value as string,
                'level'
              )
            }
            name="Level"
          />
        )
      },
    },
    {
      Header: 'Effective date',
      accessor: 'effectiveDate',
      disableFilters: true,
      disableSortBy: true,
      Cell: (props: TableInstance) => {
        return (
          <CalendarInput
            format="dd MMMM yyyy"
            inputVariant="outlined"
            iconLabel="End date"
            value={props.value}
            maxDate={new Date()}
            onChange={(e, v) =>
              updateField(props.row?.original, v as string, 'effectiveDate')
            }
          />
        )
      },
    },
  ]
  if (isLoading) {
    return <Loader />
  }
  return (
    <Table
      data={data}
      height={getTableHeight()}
      columns={columns}
      stickyHeader={true}
    />
  )
}
