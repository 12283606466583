import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  ScenariosView: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  button: {
    borderRadius: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 5,
  },
  tableContainer: {
    display: 'flex',
    width: '100%',
    minHeight: window.innerHeight - 580 > 300 ? window.innerHeight - 580 : 300,
    height: window.innerHeight - 420,
    maxWidth: window.innerWidth - 100,
  },
  emptyTable: {
    display: 'flex',
    width: window.outerWidth + 200,
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: window.innerHeight - 420,
  },
  summaryCharts: {
    display: 'flex',
    flexDirection: 'row',
    height: 600,
    maxWidth: window.innerWidth - 100,
  },
  summaryTable: {
    display: 'flex',
    width: '100%',
    marginTop: '20px',
    maxWidth: window.innerWidth - 100,
  },
  toolbarIcon: {
    color: theme.palette.primary.main,
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    color: theme.palette.primary.main,
    border: 'none',
    boxShadow: 'none',
    marginBottom: '3px',
  },
}))
