export const inputValuesInitialState = {
  skuGroupCode: '',
  skuGroupDescription: '',
  batchGroupCode: '',
  skuCode: '',
  skuDescription: '',
  masterSku: '',
  primaryWorkCentreCode: '',
}

export const booleanDropdownOptions = [
  { key: 'Yes', value: 'true' },
  { key: 'No', value: 'false' },
]
export const stringDropdownOptions = [
  { key: 'Yes', value: 'Yes' },
  { key: 'No', value: 'No' },
]

export const sortByKeys = {
  skuGroupCode: 'sku_group_code',
  skuGroupDescription: 'sku_group_description',
  batchGroupCode: 'batch_group_code',
  batchGroupingCode: 'batch_group_code',
  skuCode: 'sku_code',
  skuDescription: 'sku_description',
  masterSku: 'master_sku',
  primaryWorkCentreCode: 'primary_workcentre_code',
  minimumProductOrderQuantity: 'minimum_product_order_qunatity',
  runRate: 'run_rate',
  skuStatus: 'sku_status',
}

export const DEFAULT_PAGE_SIZE = 10

export const DEFAULT_PAGE_INDEX = 0
