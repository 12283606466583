import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { reactPlugin } from './AppInsights'

export const AppInsightsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  )
}
