import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: '5px',
  },
  skuView: {
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
  },
  bulkUpdate: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  leftObject: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  buttonGroup: {
    display: 'flex',
    color: `${theme.palette.primary.main}`,
  },
  uploadDownload: {
    display: 'flex',
    color: `${theme.palette.primary.main}`,
    gap: '10px',
  },
  button: {
    borderRadius: 0,
  },
  buttonToggle: {
    borderRadius: 0,
    width: 90,
    height: 30,
  },
}))
