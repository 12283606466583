import {
  Box,
  Typography,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
} from '@material-ui/core'
import { ChangeoverListProps } from './ChangeoverList.types'
import { useStyles } from './ChangeoverList.styles'
import Alert from '@material-ui/lab/Alert'
import { Modal } from 'supplyvue-ui'
import { Loader } from 'Common/Loader/Loader'

export const ChangeoverListView = ({
  isModalOpen,
  handleModalClose,
  changeoverCountLabel,
  changeoverTimeLabel,
  changeoverCostLabel,
  changeoverData,
  uploadFileData,
  isLoading,
}: ChangeoverListProps) => {
  const classes = useStyles()

  if (uploadFileData) {
    const { valid, rowSummary } = uploadFileData
    if (valid) {
      return (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Alert icon={false} severity="success">
            <Typography variant="body1">
              File uploaded successfully! Click <b>save</b> to view changeover
              summary
            </Typography>
          </Alert>
        </Box>
      )
    } else if (!valid) {
      return (
        <Box width="100%" mr={2}>
          <List className={classes.listWrapper} subheader={<li />}>
            {Object.keys(rowSummary).map((row) => (
              <li key={`section-${row}`} className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader className={classes.listText}>
                    {`Row ${row}`}
                  </ListSubheader>
                  {rowSummary[parseInt(row)].map((item) => (
                    <ListItem key={`item-${item}`}>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </ul>
              </li>
            ))}
          </List>
        </Box>
      )
    }
  }

  /** Render the list of changeovers */
  return (
    <Box>
      <Box>
        <Modal
          disableBackdropClick
          open={isModalOpen}
          handleClose={handleModalClose}
          header={'Batch group changeover summary'}
          body={
            <Box>
              {isLoading ? (
                <Loader />
              ) : (
                <Box>
                  <Box display="flex">
                    <Box className={classes.column}>
                      <Typography variant="h4">
                        {changeoverCountLabel}
                      </Typography>
                    </Box>
                    <Box className={classes.column}>
                      <Typography variant="h4">
                        {changeoverTimeLabel}
                      </Typography>
                    </Box>
                    <Box className={classes.column}>
                      <Typography variant="h4">
                        {changeoverCostLabel}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" mb={2}>
                    <Box className={classes.column}></Box>
                    <Box className={classes.column}>
                      <Typography variant="body2">(minutes)</Typography>
                    </Box>
                    <Box className={classes.column}></Box>
                  </Box>
                  <Box style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                    {changeoverData?.map((row, index) => {
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          mb={2}
                          key={index}
                        >
                          <Box className={classes.column}>
                            <Typography variant="body1">{row.count}</Typography>
                          </Box>
                          <Box className={classes.column}>
                            <Typography variant="body1">{row.time}</Typography>
                          </Box>
                          <Box className={classes.column}>
                            <Typography variant="body1">{row.cost}</Typography>
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              )}
            </Box>
          }
          className={classes.modal}
        />
      </Box>
    </Box>
  )
}
