import { useMemo } from 'react'
import { Graph } from 'Common/Graph/Graph'
import { SequenceOfEventsGraphProps } from './SequenceOfEvents.types'
import { calculateGraphData } from './SequenceOfEvents.utils'
import { colors } from '../Summary.styles'

export const SequenceOfEventsGraph = ({
  graphData,
  capacityReferenceValue,
  averageUtilisationPercentage,
  averageUtilisationHours,
}: SequenceOfEventsGraphProps) => {
  const productionTimeHovertemplate = () => {
    return (
      ` %{text}<br>` +
      ` %{x}<br>` +
      ` Production time:  %{y}h<br>` +
      '<extra></extra>'
    )
  }
  const changeOverTimeHovertemplate = () => {
    return ` %{text}<br>` + ` Change over time:  %{y}h<br>` + '<extra></extra>'
  }

  const inBatchChangeOverTimeHovertemplate = () => {
    return (
      ` %{text}<br>` +
      ` in Batch Change over time:  %{y}h<br>` +
      '<extra></extra>'
    )
  }

  const data = useMemo(() => {
    const xAxisData = graphData?.map((item) => {
      return `${item.week}`
    })
    const defaultProps = {
      x: xAxisData,
      type: 'bar',
      textfont: { color: 'transparent' },
      width: 0.45,
    }

    const productionTimeProps = {
      ...defaultProps,
      name: 'Production time',
      hovertemplate: productionTimeHovertemplate(),
      showlegend: false,
      marker: {
        color: '0077b6',
      },
    }

    const changeOverTimeProps = {
      ...defaultProps,
      name: 'Changeover time',
      hovertemplate: changeOverTimeHovertemplate(),
      showlegend: false,
      marker: {
        color: 'fe7f2d',
      },
    }

    const inBatchChangeOverTimeProps = {
      ...defaultProps,
      name: 'Within Batch Changeover time',
      hovertemplate: inBatchChangeOverTimeHovertemplate(),
      showlegend: false,
      marker: {
        color: 'ffd166',
      },
    }

    const graphDataList = calculateGraphData(graphData)

    const traceList = []
    for (let i = 0; i < graphDataList.length; i++) {
      const productionTimeTrace = {
        ...productionTimeProps,
        y: graphDataList[i].productionTime,
        text: graphDataList[i].productCode,
      }

      const changeOverTimeTrace = {
        ...changeOverTimeProps,
        y: graphDataList[i].changeOverTime,
        text: graphDataList[i].changeOverText,
      }

      const inBatchChangeOverTimeTrace = {
        ...inBatchChangeOverTimeProps,
        y: graphDataList[i].inBatchChangeOverTime,
        text: graphDataList[i].changeOverText,
      }
      if (!i) {
        productionTimeTrace.showlegend = true
        changeOverTimeTrace.showlegend = true
        inBatchChangeOverTimeTrace.showlegend = true
      }
      traceList.push(productionTimeTrace)
      traceList.push(changeOverTimeTrace)
      traceList.push(inBatchChangeOverTimeTrace)
    }

    return traceList
  }, [graphData])

  const layout = {
    height: 630,
    yaxis: {
      title: {
        text: 'Hours',
        standoff: 1,
      },
      tickmode: 'array',
      linewidth: 1,
      showgrid: true,
      linecolor: colors.lineColor,
      showline: true,
      tickfont: {
        family: 'proxima-nova',
        size: 14,
        color: colors.fontColor,
      },
    },
    xaxis: {
      type: 'category',
      zeroline: true,
      title: {
        text: 'Week',
      },
      showline: true,
      linewidth: 1,
      linecolor: colors.lineColor,
      tickfont: {
        family: 'proxima-nova',
        size: 14,
        color: colors.fontColor,
      },
    },
    barmode: 'stack',
    autoexpand: false,
    autosize: true,
    annotations: [
      {
        x: 1,
        y: capacityReferenceValue,
        xref: 'paper',
        yref: 'y',
        text: `${'Capacity:' + capacityReferenceValue}h`,
        yanchor: 'bottom',
        showarrow: false,
        ax: 0,
        ay: -40,
        font: {
          color: colors.fontColor,
        },
      },
      {
        x: 1,
        y: averageUtilisationHours,
        xref: 'paper',
        yref: 'y',
        text: `Average utilisation: ${averageUtilisationHours}h (${averageUtilisationPercentage}%)`,
        yanchor: 'bottom',
        showarrow: false,
        ax: 0,
        ay: -40,
        font: {
          color: colors.fontColor,
        },
      },
    ],
    shapes: [
      {
        type: 'line',
        xref: 'paper',
        x0: 0,
        x1: 1,
        yref: 'y',
        opacity: 0.5,
        y0: capacityReferenceValue,
        y1: capacityReferenceValue,
        line: {
          width: 1,
          color: colors.referenceLineColor,
        },
      },
      {
        type: 'line',
        xref: 'paper',
        x0: 0,
        x1: 1,
        yref: 'y',
        y0: averageUtilisationHours,
        y1: averageUtilisationHours,
        opacity: 0.5,
        line: {
          width: 1,
          color: colors.referenceLineColor,
        },
      },
    ],
    legend: {
      orientation: 'h',
      x: 0.15,
      y: 1.2,
    },
    margin: {
      l: 60,
      r: 50,
      b: 50,
      t: 50,
    },
  }

  return <Graph data={data} layout={layout} responsive={false} />
}
