import { useQuery, useMutation, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import {
  CreateProjectVariables,
  DeleteProjectVariables,
  useCreateProjectDataParams,
  useDeleteProjectParams,
} from './ProjectList.types'
import { UpdateProjectAccessParams } from '../ProjectCard/ProjectCard.types'

const deleteProject = async ({ projectId }: DeleteProjectVariables) => {
  const { data } = await request({
    url: `/projects`,
    method: 'delete',
    data: {
      projectId,
    },
  })
  return data
}

export const useDeleteProject = ({
  onDeleteSuccess,
}: useDeleteProjectParams) => {
  const queryClient = useQueryClient()
  return useMutation(deleteProject, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['projects'])
      onDeleteSuccess()
    },
  })
}

const fetchProjectList = async () => {
  const { data } = await request({
    url: `/projects`,
  })
  return data
}

export const useProjectListData = () => {
  return useQuery(['projects'], () => fetchProjectList(), {
    select: (data) => data.data,
    refetchOnWindowFocus: false,
  })
}

const createProject = async ({ projectName }: CreateProjectVariables) => {
  const { data } = await request({
    url: `/projects`,
    method: 'post',
    data: {
      projectName,
    },
  })
  return data
}

export const useCreateProjectData = ({
  onCreateProjectSuccess,
}: useCreateProjectDataParams) => {
  return useMutation(createProject, {
    onSuccess: ({ data, message }) => {
      onCreateProjectSuccess(data.id, message)
    },
  })
}

const updateProjectAccess = async ({
  projectId,
  projectAccess,
}: UpdateProjectAccessParams) => {
  const { data } = await request({
    url: `/projects/${projectId}/update-access`,
    method: 'put',
    data: projectAccess,
  })
  return data
}

export const useUpdateProjectAccess = (
  onProjectAccessUpdateSuccess: () => void,
  onProjectAccessUpdateFail: () => void,
  apiToInvaildate: string[] = ['projects']
) => {
  const queryClient = useQueryClient()
  return useMutation(updateProjectAccess, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(apiToInvaildate)
      onProjectAccessUpdateSuccess()
    },
    onError: () => {
      onProjectAccessUpdateFail()
    },
  })
}
