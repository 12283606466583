import { Box, Paper } from '@material-ui/core'
import { useStyles } from './SegmentAnalysis.styles'
import { DifferenceGraph } from './SegmentAnalysis.difference.graph'
import { CurrentBAUGraph } from './SegmentAnalysis.currentBAU.graph'
import { Loader } from 'Common/Loader/Loader'

export const SegmentAnalysisView = ({
  segmentGraphDataLoading,
  segmentGraphData,
  method,
}: any) => {
  const classes = useStyles()
  let yAxisLabel = ''
  if (method == 'demand_variability') {
    yAxisLabel = 'Demand variability stock volume'
  } else if (method == 'hybrid') {
    yAxisLabel = 'Hybrid stock volume'
  } else if (method == 'forecast_error') {
    yAxisLabel = 'Forecast error stock volume'
  } else if (method == 'proposed') {
    yAxisLabel = 'Proposed stock volume'
  }

  if (segmentGraphDataLoading) {
    return <Loader />
  }
  return (
    <Box className={classes.wrapper}>
      <Paper>
        <Box width="100%" padding={2} height={890}>
          <Box>
            <DifferenceGraph
              yAxisLabel={yAxisLabel}
              traceData={segmentGraphData?.differenceGraph ?? []}
            />
          </Box>
          <Box>
            <CurrentBAUGraph
              yAxisLabel="Current BAU stock volume"
              traceData={segmentGraphData?.currentBAUGraph ?? []}
              referenceData={segmentGraphData?.differenceGraph ?? []}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
