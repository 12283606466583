import { Box, Typography } from '@material-ui/core'
import { CostAnalysisViewProps } from './CostAnalysis.type'
import { CostDriverTable } from './CostDriversTable/CostDriver.table'
import { CostOutputsTable } from './CostOutputsTable/CostOutputs.table'
import { CostTradeOffGraph } from './CostTradeOff/CostTradeOff.graph'

export const CostAnalysisView = ({
  costDrivers,
  costGraph,
  costOutputs,
}: CostAnalysisViewProps) => {
  const totalWorkcentreCost =
    costGraph?.map((item) =>
      item?.totalWorkcentreCost ? item.totalWorkcentreCost : 0
    ) || []
  const totalInventoryCost =
    costGraph?.map((item) =>
      item?.totalInventoryCost ? item.totalInventoryCost : 0
    ) || []

  const scenarioNames =
    costGraph?.map((item) => (item?.label ? item.label : '')) || []

  return (
    <Box display="flex" flexDirection="row">
      <Box width="40%">
        <Typography variant="h4">
          Cost trade off for different scenarios
        </Typography>
        {costGraph?.length && (
          <CostTradeOffGraph
            xAxisData={scenarioNames}
            totalWorkcentreCost={totalWorkcentreCost}
            totalInventoryCost={totalInventoryCost}
          />
        )}
      </Box>
      <Box width="60%" display="flex" flexDirection="column">
        <Box mb={2}>
          <Typography variant="h4">Cost drivers</Typography>
          <CostDriverTable
            data={costDrivers?.length ? costDrivers : []}
            isLoading={false}
          />
        </Box>
        <Box>
          <Typography variant="h4">Cost outputs</Typography>
          <CostOutputsTable
            data={costOutputs?.length ? costOutputs : []}
            isLoading={false}
          />
        </Box>
      </Box>
    </Box>
  )
}
