import { useMutation, useQuery, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import {
  UseDeleteDemandScenarioArgs,
  DeleteDemandScenarioArgs,
  UseCreateDemandScenarioArgs,
  CreateDemandScenarioParams,
  ActivateDemandScenarioParams,
} from './DemandScenario.types'

const fetchDemandScenarioList = async (
  snapshotId: string,
  scenarioId: string
) => {
  const data = await request({
    url: `/snapshots/${snapshotId}/demand-scenarios?scenario_id=${scenarioId}`,
  })
  return data
}
export const useDemandScenarioList = (
  snapshotId: string,
  scenarioId: string
) => {
  return useQuery(
    ['demand-scenario-list', snapshotId],
    () => {
      return fetchDemandScenarioList(snapshotId, scenarioId)
    },
    {
      select: (data) => data?.data,
      refetchOnWindowFocus: false,
    }
  )
}

const deleteDemandScenario = async ({
  snapshotId,
  demandScenarioId,
}: DeleteDemandScenarioArgs) => {
  const data = await request({
    url: `/snapshots/${snapshotId}/demand-scenarios/${demandScenarioId}`,
    method: 'delete',
  })
  return data
}

export const useDeleteDemandScenario = ({
  onDeleteDemandScenarioSuccess,
}: UseDeleteDemandScenarioArgs) => {
  const queryClient = useQueryClient()
  return useMutation(deleteDemandScenario, {
    onSuccess: async ({ data: { message } }) => {
      await queryClient.invalidateQueries('demand-scenario-list')
      onDeleteDemandScenarioSuccess(message)
    },
  })
}

const fetchDemandScenarioTableData = async (
  demandScenarioId: string,
  baseLineForecastId?: string,
  isForecastTypeSelected?: boolean
) => {
  const dsId = isForecastTypeSelected ? baseLineForecastId : demandScenarioId // If any forecasttype is selected we send baselineforecast id instead of it and send forecast type as a query param.
  const url = isForecastTypeSelected
    ? `/demand-scenarios/${dsId}/quarterly-forecast?forecast_type=${demandScenarioId}`
    : `/demand-scenarios/${dsId}/quarterly-forecast`
  const data = await request({
    url,
  })
  return data
}

export const useDemandScenarioTableData = (
  demandScenarioId: string,
  baseLineForecastId?: string,
  isForecastTypeSelected?: boolean
) => {
  return useQuery(
    ['demand-scenario-table-data', demandScenarioId],
    () =>
      fetchDemandScenarioTableData(
        demandScenarioId,
        baseLineForecastId,
        isForecastTypeSelected
      ),
    {
      select: ({ data }) => {
        const finalData = data?.data
        return finalData
      },
      refetchOnWindowFocus: false,
      enabled: Boolean(demandScenarioId),
      cacheTime: 0,
    }
  )
}

const createDemandScenario = async (
  demandScenarioParams: CreateDemandScenarioParams
) => {
  const { snapshotId } = demandScenarioParams
  delete demandScenarioParams['snapshotId']

  const data = await request({
    url: `/snapshots/${snapshotId}/demand-scenarios`,
    method: 'post',
    data: {
      demandScenarioId: demandScenarioParams.demandScenarioId,
      demandScenarioName: demandScenarioParams.demandScenarioName,
      ...(demandScenarioParams.percentageAdjustments &&
        Object.keys(demandScenarioParams.percentageAdjustments)?.length > 0 && {
          percentageAdjustments: demandScenarioParams.percentageAdjustments,
        }),
      ...(demandScenarioParams.valueAdjustments &&
        Object.keys(demandScenarioParams.valueAdjustments)?.length > 0 && {
          valueAdjustments: demandScenarioParams.valueAdjustments,
        }),
      ...(demandScenarioParams.skuGroups &&
        demandScenarioParams.skuGroups.length > 0 && {
          skuGroups: demandScenarioParams.skuGroups,
        }),
      ...(demandScenarioParams.forecastType && {
        forecastType: demandScenarioParams.forecastType,
      }),
    },
  })

  return data
}

export const useCreateDemandScenario = ({
  onCreateDemandScenarioSuccess,
  onCreateDemandScenarioFail,
}: UseCreateDemandScenarioArgs) => {
  const queryClient = useQueryClient()
  return useMutation(createDemandScenario, {
    onSuccess: async ({ data: { message } }) => {
      await queryClient.invalidateQueries('demand-scenario-list')
      onCreateDemandScenarioSuccess(message)
    },
    onError: ({ message }) => {
      onCreateDemandScenarioFail(message)
    },
  })
}

const updateActiveDemandScenario = async (
  activateDemandScenarioParams: ActivateDemandScenarioParams
) => {
  const data = await request({
    method: 'put',
    url: `/scenarios`,
    data: activateDemandScenarioParams,
  })
  return data
}

export const useUpdateActiveDemandScenario = () => {
  const queryClient = useQueryClient()
  return useMutation(updateActiveDemandScenario, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('demand-scenario-list')
    },
  })
}
