import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: '10px',
  },
  errorMessage: {
    marginRight: '10px',
    fontSize: '.75rem',
    color: theme.palette.error.main,
  },
  input: {
    width: '150px',
    backgroundColor: 'white',
    [theme.breakpoints.down('lg')]: {
      width: '100px',
    },
  },
  formControl: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  popperText: {
    padding: '16px',
  },
  helpIcon: {
    padding: 0,
    height: '20px',
    width: '20px',
  },
  calculateButtonConstrained: {
    display: 'flex',
    alignItems: 'center',
  },
  calculateButtonUnconstrained: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  tabs: { borderBottom: `1px solid ${theme.palette.borders.grey}` },
  activeTab: { backgroundColor: theme.palette.background.paper },
  iconButton: { display: 'flex', alignItems: 'center', marginLeft: '20px' },
  calculateButton: {
    width: '116px',
  },
}))
