import { Box } from '@material-ui/core'
import { Page } from 'Common/Page/Page'
import { PageHeader } from 'supplyvue-ui'
import { DemandScenarioHeaderActions } from './DemandScenarioHeaderAction.view'
import { DemandScenarioCard } from './DemandScenarioCard/DemandScenarioCard.view'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { useParams, useHistory } from 'react-router-dom'
import { DemandScenarioParams, DemandScenario } from './DemandScenario.types'
import { EmptyState } from 'Common/EmptyState/EmptyState'
import ScenarioIcon from 'Assets/react-icons/ScenarioIcon'
import { toast } from 'react-hot-toast'

import {
  useDemandScenarioList,
  useDeleteDemandScenario,
  useUpdateActiveDemandScenario,
} from './DemandScenario.data'
import { Loader } from 'Common/Loader/Loader'

export const DemadScenarioPage = () => {
  const history = useHistory()
  const {
    projectId,
    snapshotId,
    scenarioId,
  } = useParams<DemandScenarioParams>()
  useBreadcrumbsData({ project_id: projectId, snapshot_id: snapshotId })

  const {
    data: { data: demandScenarios = [] } = {},
    isLoading,
  } = useDemandScenarioList(snapshotId, scenarioId)
  const onDeleteDemandScenarioSuccess = (message: string) => {
    toast.success(message)
  }
  const {
    mutate: deleteDemandScenario,
    isLoading: isDeleting,
  } = useDeleteDemandScenario({
    onDeleteDemandScenarioSuccess,
  })

  const { mutate: activateDemandScenario } = useUpdateActiveDemandScenario()

  const handleNewDemandScenarioClick = () => {
    history.push(
      `/projects/${projectId}/snapshots/${snapshotId}/scenarios/${scenarioId}/demand-scenario/details`
    )
  }

  const handleViewDemandScenarioClick = (demandScenarioId: string) => {
    history.push(
      `/projects/${projectId}/snapshots/${snapshotId}/scenarios/${scenarioId}/demand-scenario/${demandScenarioId}`
    )
  }

  const handleDeleteDemandScenarionClick = (demandScenarioId: string) => {
    deleteDemandScenario({ snapshotId, demandScenarioId })
  }

  const handleRadioClick = (demandScenarioId: string) => {
    activateDemandScenario({ projectId, scenarioId, demandScenarioId })
  }
  if (isLoading || isDeleting) {
    return <Loader />
  }
  return (
    <Page>
      <Box display="flex" flexDirection="column" flex="auto">
        <PageHeader
          title="Demand scenario"
          rightElement={
            <DemandScenarioHeaderActions
              handleNewDemandScenarioClick={handleNewDemandScenarioClick}
            />
          }
        ></PageHeader>
        <Box>
          {demandScenarios?.length > 0 ? (
            demandScenarios.map((demandScenario: DemandScenario) => {
              return (
                <DemandScenarioCard
                  key={demandScenario.id}
                  viewDemandScenarioLabel="View"
                  handleViewDemandScenarioClick={handleViewDemandScenarioClick}
                  demandScenario={demandScenario}
                  handleDeleteDemandScenarionClick={
                    handleDeleteDemandScenarionClick
                  }
                  handleRadioClick={handleRadioClick}
                />
              )
            })
          ) : (
            <EmptyState
              icon={<ScenarioIcon width="200px" height="200px" />}
              text="Please create a first demand scenario."
              actionText="Create demand scenario"
              handleClick={handleNewDemandScenarioClick}
            />
          )}
        </Box>
      </Box>
    </Page>
  )
}
