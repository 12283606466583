import { SnapshotDetails } from './SnapshotModal.types'
import { format, isValid } from 'date-fns'

export const getLastSunday = () => {
  const today = new Date()
  today.setDate(today.getDate() - today.getDay())
  return today
}

export const getSnapshotDetailsInString = (
  snapshot:
    | SnapshotDetails
    | Omit<SnapshotDetails, 'snapshotId' | 'snapshotUserId'>
) => {
  return `${snapshot.snapshotName} ( period = ${
    snapshot.snapshotLength
  } weeks, end date = ${format(
    new Date(snapshot.snapshotEndDate),
    'dd/MM/yyyy'
  )} ) `
}

export const getSelectSnapshotDropdownMenuItems = (data: SnapshotDetails[]) => {
  return data?.map((snapshot: SnapshotDetails) => {
    const key = getSnapshotDetailsInString(snapshot)
    return {
      key,
      value: snapshot.snapshotId,
    }
  })
}

export const isValidDate = (date: Date | null) => {
  const MINYEAR = 1900
  const today = new Date()
  const isPastYear = date && date.getFullYear() < today.getFullYear()
  const isPastMonthOfSameYear =
    date &&
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() < today.getMonth()
  const isDateValidForSameMonthAndYear =
    date &&
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() <= today.getDate()

  return (
    date &&
    isValid(date) &&
    date.getFullYear() >= MINYEAR &&
    (isPastYear || isPastMonthOfSameYear || isDateValidForSameMonthAndYear)
  )
}
