import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  selectAll: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
    marginBottom: '15px',
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
  label: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}))
