import { useMutation, useQuery, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import { ErrorData } from 'Features/PlanTemplate/ProductSettings/ProductSettingsForm/ProductSettingsForm.types'
import { MultiBulkUpdate } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/BulkUpdate/BulkUpdate.types'
import {
  BatchGroupingSettings,
  ProductSettings,
  SkuSettingsUploadParams,
} from 'Features/PlanTemplate/StepCard/StepCard.types'
import { SkuSettingsDownloadParams } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.types'

const fetchWorkcentreSettings = async (
  snapshotId: string,
  projectId: string,
  planTemplateId: string
) => {
  const { data } = await request({
    url: `/plan-template/workcentre-settings`,
    method: 'post',
    data: {
      snapshotId,
      projectId,
      planTemplateId,
    },
  })
  return data
}

export const useWorkcentreSettingsData = (
  snapshotId: string,
  projectId: string,
  planTemplateId: string
) => {
  return useQuery(
    ['workcentre-settings-all', snapshotId, projectId, planTemplateId],
    () => fetchWorkcentreSettings(snapshotId, projectId, planTemplateId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  )
}

const getSkuDownloadData = async (
  downloadParams: SkuSettingsDownloadParams
) => {
  const { data } = await request({
    method: 'post',
    data: downloadParams,
    url: `/sku-settings-download`,
  })
  return data
}

export const useSkuDownloadData = (
  onSuccess: (data: any) => void,
  onError: () => void
) => {
  return useMutation(getSkuDownloadData, {
    onSuccess: (data) => {
      onSuccess(data)
    },
    onError: () => {
      onError()
    },
  })
}

const updateGroupSettings = async (groupDetails: MultiBulkUpdate) => {
  const data = await request({
    url: `/plan-templates/${groupDetails.planTemplateId}/bulk-settings`,
    method: 'put',
    data: groupDetails,
  })
  return data
}

export const useUpdatGroupSettings = (
  onSuccess: (
    message: string,
    updatedData: ProductSettings[] | any,
    updatedBatchGrouping: BatchGroupingSettings[] | any
  ) => void,
  onError: (errorData: ErrorData) => void
) => {
  const queryClient = useQueryClient()
  return useMutation(updateGroupSettings, {
    onSuccess: async ({ data }, variables) => {
      await queryClient.invalidateQueries([
        'workcentre-settings-all',
        variables.snapshotId,
        variables.projectId,
        variables.planTemplateId,
      ])
      onSuccess(
        data.message,
        data?.data?.productSettings,
        data?.data?.batchGroupingSettings
      )
    },
    onError: async ({ response }) => {
      onError(response.data)
    },
  })
}

const uploadSkuSettingsFile = async ({
  planTemplateId,
  file,
}: SkuSettingsUploadParams) => {
  const formData = new FormData()

  formData.append('planTemplateId', planTemplateId)
  formData.append('settings_file', file)

  const { data } = await request({
    method: 'post',
    url: `/plan-templates/sku-settings-upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
  return data
}

export const useUploadSkuSettingsFile = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient()
  return useMutation(uploadSkuSettingsFile, {
    onSuccess: async ({ data }, variables) => {
      console.log(variables)
      await queryClient.invalidateQueries([
        'workcentre-settings-all',
        variables.snapshotId,
        variables.projectId,
        variables.planTemplateId,
      ])
      onSuccess()
    },
    onError: () => {
      onError()
    },
  })
}
