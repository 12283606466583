import { Box, Paper, Typography } from '@material-ui/core'
import { useStyles } from './AnalysisSummary.styles'
import { AnalysisSummaryTable } from './AnalysisSummary.table'
import { AnalysisSummaryProps } from './AnalysisSummary.types'
export const AnalysisSummaryView = ({
  changeSummaryMethod,
  method,
  summaryData,
  reportingUnitOfMeasure,
}: AnalysisSummaryProps) => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <Box className={classes.wrapper}>
        <Box
          marginTop={2}
          display="flex"
          justifyContent="space-between"
          alignItems="centre"
        >
          <Box>
            <Typography variant="h4">Summary</Typography>
          </Box>
        </Box>

        {summaryData && (
          <>
            <Box marginTop={4} className={classes.tableContainer}>
              <Box
                className={`${classes.clickableBox}  ${
                  method === 'forecast_error'
                    ? classes.selectedSummary
                    : classes.summaryBox
                }`}
                onClick={() => changeSummaryMethod('forecast_error')}
              >
                <Typography variant="h4">By forecast error</Typography>
              </Box>
              <AnalysisSummaryTable
                data={summaryData.forecastError}
                reportingUnitOfMeasure={reportingUnitOfMeasure}
              />
            </Box>

            <Box marginTop={3} className={classes.tableContainer}>
              <Box
                className={`${classes.clickableBox}  ${
                  method === 'demand_variability'
                    ? classes.selectedSummary
                    : classes.summaryBox
                }`}
                onClick={() => changeSummaryMethod('demand_variability')}
              >
                <Typography variant="h4">By demand variability</Typography>
              </Box>
              <AnalysisSummaryTable
                data={summaryData.demandVariability}
                reportingUnitOfMeasure={reportingUnitOfMeasure}
              />
            </Box>

            <Box marginTop={3} className={classes.tableContainer}>
              <Box
                className={`${classes.clickableBox}  ${
                  method === 'hybrid'
                    ? classes.selectedSummary
                    : classes.summaryBox
                }`}
                onClick={() => changeSummaryMethod('hybrid')}
              >
                <Typography variant="h4">Hybrid </Typography>
              </Box>
              <AnalysisSummaryTable
                data={summaryData.hybrid}
                reportingUnitOfMeasure={reportingUnitOfMeasure}
              />
            </Box>

            <Box marginTop={3} className={classes.tableContainer}>
              <Box
                className={`${classes.clickableBox}  ${
                  method === 'proposed'
                    ? classes.selectedSummary
                    : classes.summaryBox
                }`}
                onClick={() => changeSummaryMethod('proposed')}
              >
                <Typography variant="h4">Proposed </Typography>
              </Box>
              <AnalysisSummaryTable
                data={summaryData.proposed}
                reportingUnitOfMeasure={reportingUnitOfMeasure}
              />
            </Box>
            <Box marginTop={3} className={classes.tableContainer}>
              <Box
                className={`${classes.clickableBox}  ${classes.summaryBox} ${classes.baseLineBox}`}
              >
                <Typography variant="h4">Current </Typography>
              </Box>
              <AnalysisSummaryTable
                data={summaryData.baseline}
                reportingUnitOfMeasure={reportingUnitOfMeasure}
              />
            </Box>
          </>
        )}
      </Box>
    </Paper>
  )
}
