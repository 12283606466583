import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { WorkcentreModalStyleProps } from './WorkcentreModal.types'

export const useStyles = makeStyles<Theme, WorkcentreModalStyleProps>(
  (theme: Theme) => ({
    footer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'flex-end',
      postion: 'sticky',
    },
    modalActionButton: {
      marginLeft: '20px',
      minWidth: '105px',
    },
    modalBody: (props) => ({
      minWidth: '700px',
      height: props.readOnly ? 'calc(50vh + 110px)' : '50vh',
    }),
    selectAll: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '7px',
      marginBottom: '15px',
    },
    modal: {
      background: theme.palette.background.grey,
    },
    accordionsWrapper: (props) => ({
      overflowY: 'scroll',
      height: props.readOnly ? '100%' : 'calc(100% - 60px)',
    }),
    progress: {
      marginRight: '8px',
    },
  })
)
