import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PlanTemplatePageParams } from 'Features/NewPlanTemplate/NewPlanTemplate.types'
import {
  useWorkcentreSettingsData,
  useUpdatGroupSettings,
  useSkuDownloadData,
  useUploadSkuSettingsFile,
} from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.data'
import { Loader } from 'Common/Loader/Loader'
import { SkuTable } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.table'
import { Box } from '@material-ui/core'
import { useStyles } from './Sku.styles'
import { Toolbar } from 'Common/Toolbar'
import { Button } from 'supplyvue-ui'
import { ProductSettingsFormBulkUpdateView } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/BulkUpdate/BulkUpdate.view'
import {
  mapDropdownMenuItemsInLabel,
  mapDropdownMenuItemsInName,
} from 'Common/Utils/utils'
import {
  SkuProps,
  ProductSettingsParametersType,
  ErrorData,
} from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.types'
import {
  ProductSettings,
  ProductSettingsFormData,
} from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/BulkUpdate/BulkUpdate.types'
import { CYCLE_MENU_ITEMS } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/Sku.constants'
import { BatchGroupingSettings } from 'Features/PlanTemplate/StepCard/StepCard.types'
import { INITIAL_STATE as FORM_INITIAL_STATE } from 'Features/NewPlanTemplate/WorkcentreSettings/Parameters/SKU/BulkUpdate/BulkUpdate.view'
import { toast } from 'react-hot-toast'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import { format } from 'date-fns'
import { CircularProgress } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { MAX_FILE_SIZE } from 'Common/Constants/Constants'

const INITIAL_STATE = {
  batchGroupingCode: '',
  salesRate: '',
  runRate: '',
  minimumOrderQuantity: '',
  minimumOrderIncrement: '',
  minimumCycle: '',
  maximumCycle: '',
  salesWeeksPerMOQ: '',
  replenishmentTypeId: '',
  productionUnitOfMeasure: '',
  runRateUnitOfMeasure: '',
  replenishmentType: '',
  include: '',
}

export const SkuView = ({ dropdownOptions, batchGroupingCodes }: SkuProps) => {
  const { snapshotId, projectId, planTemplateId } =
    useParams<PlanTemplatePageParams>()

  const { data, isLoading } = useWorkcentreSettingsData(
    snapshotId,
    projectId,
    planTemplateId
  )

  const onUploadError = () => {
    toast.error('Upload failed')
  }

  const onUploadSuccess = () => {
    toast.success('Upload successful')
  }
  const { mutate: uploadSkuFile, isLoading: isFileUploading } =
    useUploadSkuSettingsFile(onUploadSuccess, onUploadError)

  const handleUploadFile = (file: File) => {
    uploadSkuFile({
      planTemplateId: planTemplateId,
      projectId: projectId,
      snapshotId: snapshotId,
      file,
    })
  }

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: '.csv',
      maxFiles: 1,
      maxSize: MAX_FILE_SIZE,
    })

  useEffect(() => {
    if (fileRejections.length) {
      toast.error('File size cannot exceed 50MB')
    } else if (acceptedFiles.length) {
      handleUploadFile(acceptedFiles[0])
    }
  }, [acceptedFiles, fileRejections])

  const onDownloadSuccess = (data: any) => {
    const fileName = `SkuSettings__${format(new Date(), 'yyyyMMdd')}`
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.csv`)
    document.body.appendChild(link)
    link.click()
    toast.success('Successfully downloaded Sku Data')
  }

  const onDownloadError = () => {
    toast.error('Error Downloading Sku Settings')
  }

  const { mutate: downloadData, isLoading: isDownloading } = useSkuDownloadData(
    onDownloadSuccess,
    onDownloadError
  )

  const [tableData, setTableData] = useState<any>([])

  useEffect(() => {
    if (data) {
      setTableData(data.productSettings)
    }
  }, data?.productSettings)

  const bulkEditData = useRef(INITIAL_STATE as ProductSettingsParametersType)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<ProductSettings[] | null>([])
  const [isIncludeUpdating, setIsIncludeUpdating] = useState(false)
  const [errorData, setErrorData] = useState<null | ErrorData>(null)
  const [formData, setFormData] =
    useState<ProductSettingsFormData>(FORM_INITIAL_STATE)

  const [selectedProductBatchMinCycle, setSelectedProductBatchMinCycle] =
    useState<number | null>(null)

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  const onEditClick = () => {
    handleModalOpen()
  }

  const onSuccess = (
    message: string,
    updatedData: ProductSettings[],
    updatedBatchGrouping: BatchGroupingSettings[]
  ) => {
    {
      setFormData(FORM_INITIAL_STATE)
      setErrorData(null)
      setTableData(updatedData)
      setIsIncludeUpdating(false)
      handleModalClose()
    }

    toast.success(message)
  }

  const onError = (errorData: ErrorData) => {
    setErrorData(errorData)
  }

  const { replenishmentType, unitOfMeasure } = dropdownOptions
  const { mutate: updateGroupSettings, isLoading: isUpdating } =
    useUpdatGroupSettings(onSuccess, onError)

  const uniqueBatchGroupingCodes = [...new Set(batchGroupingCodes)]
  const batchGroupingCodeOptions = uniqueBatchGroupingCodes.map((code) => ({
    key: code,
    value: code,
  }))

  const classes = useStyles()

  const applyCalculations = (
    editedTableData: ProductSettingsParametersType,
    field: string,
    SKUGroup: number
  ): number => {
    let value = parseFloat(editedTableData[`${field}`] as string)
    if (editedTableData[`${field}ActionType`] === '1') {
      value =
        tableData.find((x) => x.skuGroupKey === SKUGroup)[field] *
        (editedTableData[`${field}`] / 100 + 1)
    }
    if (editedTableData[`${field}ActionType`] === '2') {
      value =
        tableData.find((x) => x.skuGroupKey === SKUGroup)[field] *
        ((100 - editedTableData[`${field}`]) / 100)
    }
    return parseFloat(value.toFixed(5) as string)
  }

  const handleApplychangesClick = async (
    editedTableData: ProductSettingsParametersType
  ) => {
    let workcentreKeys = {}
    // This assumes that every sku has a single uniquesourcesite
    let sourceSiteKeys = {}
    if (selectedRows) {
      workcentreKeys = selectedRows.reduce((accumulator: any, value) => {
        if (!accumulator.hasOwnProperty(value.workcentreKey)) {
          accumulator[value.workcentreKey] = []
          // @ts-ignore
          sourceSiteKeys[value.workcentreKey] = value.sourceSiteKey
        }
        return accumulator
      }, {})
    }

    bulkEditData.current = editedTableData

    setErrorData(null)

    const SKUGroups =
      selectedRows &&
      selectedRows.map((row) => {
        const structure = {
          [row.skuGroupKey]: [
            {
              ...(editedTableData.batchGroupingCode && {
                batchGroupingCode: editedTableData.batchGroupingCode,
              }),
              ...(editedTableData.replenishmentTypeId && {
                replenishmentTypeId:
                  editedTableData.replenishmentTypeId as number,
              }),
              ...(editedTableData.salesRate && {
                salesRate: applyCalculations(
                  editedTableData,
                  'salesRate',
                  row.skuGroupKey
                ),
              }),
              ...(editedTableData.runRate && {
                runRate: applyCalculations(
                  editedTableData,
                  'runRate',
                  row.skuGroupKey
                ),
              }),
              ...(editedTableData.minimumOrderQuantity && {
                minimumOrderQuantity: applyCalculations(
                  editedTableData,
                  'minimumOrderQuantity',
                  row.skuGroupKey
                ),
              }),
              ...(editedTableData.minimumOrderIncrement && {
                minimumOrderIncrement: applyCalculations(
                  editedTableData,
                  'minimumOrderIncrement',
                  row.skuGroupKey
                ),
              }),
              ...(editedTableData.minimumCycle && {
                minimumCycle: editedTableData.minimumCycle as number,
              }),
              ...(editedTableData.maximumCycle && {
                maximumCycle: editedTableData.maximumCycle as number,
              }),
              ...(editedTableData.include !== '' && {
                include: editedTableData.include,
              }),
            },
          ],
        }
        // @ts-ignore
        workcentreKeys[row.workcentreKey].push(structure)
      })

    const fields = {
      ...(editedTableData.batchGroupingCode && {
        batchGroupingCode: editedTableData.batchGroupingCode,
      }),
      ...(editedTableData.replenishmentTypeId && {
        replenishmentTypeId: editedTableData.replenishmentTypeId as number,
      }),
      ...(editedTableData.salesRate && {
        salesRate: parseFloat(editedTableData.salesRate as string),
      }),
      ...(editedTableData.runRate && {
        runRate: parseFloat(editedTableData.runRate as string),
      }),
      ...(editedTableData.minimumOrderQuantity && {
        minimumOrderQuantity: parseFloat(
          editedTableData.minimumOrderQuantity as string
        ),
      }),
      ...(editedTableData.minimumOrderIncrement && {
        minimumOrderIncrement: parseFloat(
          editedTableData.minimumOrderIncrement as string
        ),
      }),
      ...(editedTableData.minimumCycle && {
        minimumCycle: editedTableData.minimumCycle as number,
      }),
      ...(editedTableData.maximumCycle && {
        maximumCycle: editedTableData.maximumCycle as number,
      }),
      ...(editedTableData.include !== '' && {
        include: editedTableData.include,
      }),
    }

    const updateMode = 'SKU'

    updateGroupSettings({
      planTemplateId,
      snapshotId,
      projectId,
      updateMode,
      data: {
        skuGroups: workcentreKeys,
        fields,
        sourceSites: sourceSiteKeys,
      },
    })
  }

  const handleDownload = () => {
    downloadData({ snapshotId, planTemplateId, projectId })
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Box className={classes.skuView}>
        <ProductSettingsFormBulkUpdateView
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          selectedProductBatchMinCycle={selectedProductBatchMinCycle}
          replenishmentTypes={mapDropdownMenuItemsInLabel(replenishmentType)}
          orderUnitMeasureTypes={mapDropdownMenuItemsInName(unitOfMeasure)}
          rateUnitMeasureTypes={mapDropdownMenuItemsInName(unitOfMeasure)}
          minimumCycleTypes={CYCLE_MENU_ITEMS}
          maximumCycleTypes={CYCLE_MENU_ITEMS}
          batchGroupingCodeOptions={batchGroupingCodeOptions}
          handleSaveClick={handleApplychangesClick}
          isUpdating={isUpdating && !isIncludeUpdating}
          data={formData}
          setData={setFormData}
        />
        <Box className={classes.toolbar}>
          <Box className={classes.leftObject}>
            <Box className={classes.bulkUpdate}>
              <Toolbar onEditClick={onEditClick} />
            </Box>
            {/*
            <Box>
              <Box className={classes.buttonGroup}>
                <Button
                  className={classes.buttonToggle}
                  variant={!selectedMode ? 'contained' : 'outlined'}
                  onClick={() => handleButtonClick(0)}
                >
                  Sku
                </Button>
                <Button
                  className={classes.buttonToggle}
                  variant={selectedMode ? 'contained' : 'outlined'}
                  onClick={() => handleButtonClick(1)}
                >
                  Maker
                </Button>
              </Box>
            </Box>
            */}
          </Box>
          <Box>
            <Box className={classes.uploadDownload}>
              <Button
                className={classes.button}
                variant="outlined"
                onClick={handleDownload}
                startIcon={
                  !isDownloading ? (
                    <GetAppIcon />
                  ) : (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      sx={{
                        marginRight: '8px',
                      }}
                    />
                  )
                }
                disabled={isDownloading}
              >
                Download CSV File
              </Button>
              <Box {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  className={classes.button}
                  variant="outlined"
                  startIcon={
                    !isFileUploading ? (
                      <PublishIcon />
                    ) : (
                      <CircularProgress
                        color="inherit"
                        size={16}
                        sx={{
                          marginRight: '8px',
                        }}
                      />
                    )
                  }
                >
                  Upload CSV File
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <SkuTable
          data={tableData}
          setSelectedRows={setSelectedRows}
          isLoading={isFileUploading}
        />
      </Box>
    </>
  )
}
